import React, { useEffect } from "react";
import {
  Input,
  Button,
  Form,
  Typography,
  Row,
  Col,
  Checkbox,
  InputNumber,
  Modal,
  notification,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import "./index.css";
import { FormDetailMemoNonPO } from "../../interfaces/prmemo.interface";

const { Text } = Typography;
const { TextArea } = Input;

interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  title: string;
  isAllApproved: boolean;
  isCalculateTax: boolean;
  onFinish: (values: FormDetailMemoNonPO) => void;
  data: FormDetailMemoNonPO | undefined;
}

const Component = ({
  visible,
  setVisible,
  title,
  isAllApproved,
  isCalculateTax,
  onFinish,
  data = undefined,
}: Props): JSX.Element => {
  const [form] = Form.useForm<FormDetailMemoNonPO>();

  notification.config({
    maxCount: 1,
    duration: 0,
    placement: "top",
  });

  const formatNumber = (value: number | undefined) => {
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 4,
    }).format(Number(value || 0));
  };

  const inputNumberCheck = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9.]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const onFormSubmit = (values: FormDetailMemoNonPO) => {
    onFinish({ ...values, paymentItem: data?.paymentItem });
    form.resetFields();
    setVisible(false);
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    if (data && visible) {
      form.setFieldsValue(data);
    }
  }, [data, form, visible]);

  useEffect(() => {
    if (title === "Add") {
      form.setFieldsValue({
        isCalculateTax,
      });
    }
  }, [form, isCalculateTax, title, visible]);

  return (
    <Modal
      title={<Text strong>{title}</Text>}
      visible={visible}
      centered
      footer={null}
      onCancel={onCancel}
      className="custom-modal"
      forceRender
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={data}
        onFinish={onFormSubmit}
      >
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item name="index" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="itemText"
              label="Item Text"
              required
              rules={[
                {
                  required: true,
                  message: "Item Text is Required",
                },
              ]}
            >
              <TextArea
                disabled={isAllApproved}
                maxLength={172}
                placeholder="คำอธิบายรายการ"
                className="FieldInputStyle"
                showCount
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </Form.Item>
          </Col>
          <Col span={12} sm={18}>
            <Form.Item
              name="amount"
              label="Amount (Excluded Vat)"
              required
              rules={[
                {
                  required: true,
                  message: "Amount (Excluded Vat) is Required",
                },
              ]}
            >
              <InputNumber
                disabled={isAllApproved}
                controls={false}
                placeholder="Amount (Excluded Vat)"
                formatter={(value) =>
                  value ? formatNumber(Number(value)) : ""
                }
                onKeyPress={inputNumberCheck}
                className="FieldInputStyle"
              />
            </Form.Item>
          </Col>
          <Col span={12} sm={6}>
            <Form.Item name="isCalculateTax" valuePropName="checked" label=" ">
              <Checkbox>Calculate Tax</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start" gutter={8}>
          <Col>
            <Button type="primary" icon={<CheckOutlined />} htmlType="submit">
              Save Changes
            </Button>
          </Col>
          <Col>
            <Button onClick={onCancel}>Cancel</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Component;
