import { Payment } from "../../interfaces/payment.interface";
import { ReactComponent as Check } from "../../assets/icon/check.svg";
import { ReactComponent as Close } from "../../assets/icon/close.svg";
import TagIcon from "../TagIcon";

const Component = ({ record }: { record: Payment }): JSX.Element => {
  if (record.activityState === "approved") {
    return (
      <TagIcon
        textIcon="#01713C"
        color="#E5F2ED"
        text="APPROVED"
        icon={<Check />}
        border="#66D19E"
      />
    );
  } else if (record.activityState === "rejected") {
    return (
      <TagIcon
        textIcon="#D82E18"
        color="#FFEFED"
        text="REJECTED"
        icon={<Close />}
        border="#F1998E"
      />
    );
  }
  return <></>;
};

export default Component;
