/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PaperClipOutlined,
  InfoCircleOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Tooltip, Space, Typography, Row, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
// import dayjs from "dayjs";
import moment from "moment";
import Highlighter from "react-highlight-words";
// import pvApproved from "../../assets/images/pvstatus/approved.png";
// import pvCancelled from "../../assets/images/pvstatus/cancelled.png";
// import pvRejected from "../../assets/images/pvstatus/rejected.png";
import { Exclamation, Thunder } from "../../components/CustomIcon";
import ShowStatus from "../../components/ShowStatus";
import UserStatusRow from "../../components/UserStatusRow";
import { Payment } from "../../interfaces/payment.interface";
import { RequestList } from "../../interfaces/paymentList.interface";
import { User } from "../../interfaces/user.interface";
import { CancelByRow } from "../../libs/cancelRequest";
import formatData from "../../libs/formatData";

interface ColumnsProps {
  auth: User | undefined;
  searchText: string;
  setVisibleDrawer: (value: boolean) => void;
  setVisibleRejectModal: (value: boolean) => void;
  setSelectRequestListPayment: (record: RequestList) => void;
}

export const renderApproveListHistoryColumns = ({
  auth,
  searchText,
  setVisibleDrawer,
  setVisibleRejectModal,
  setSelectRequestListPayment,
}: ColumnsProps): ColumnsType<RequestList> => {
  return [
    {
      title: "Status",
      width: 60,
      render: (record) => (
        <div style={{ display: "flex" }}>
          {record.isUrgent && (
            <Tooltip title="Urgent">
              <Exclamation />
            </Tooltip>
          )}
          {moment().format("YYYY-MM-DD") > record.dueDate && (
            <Tooltip title="Overdue">
              <Thunder style={{ marginRight: "5px" }} />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Request No./PR Number",
      dataIndex: "payNumber",
      width: 110,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 90,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Purchasing Group",
      dataIndex: "purGroup",
      width: 130,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Payment Description",
      dataIndex: "paymentDesc",
      width: 200,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          // textToHighlight={`${value.substring(0, 30)}...`}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "amount",
      title: "Amount",
      width: 110,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {`
            ${formatData.toCurrency(record.amount)} ${record.currency} 
            `}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "files",
      title: "Attachment",
      width: 90,
      render: (record) => (
        <Space size="small">
          <PaperClipOutlined rotate={135} />
          <Typography.Text>
            {record.fileSapnum + record.fileApprNum}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: "Approval Status",
      dataIndex: "accountText",
      width: 230,
      render: (value: string, row) => {
        return (
          <div style={{ zIndex: -99, overflowX: "scroll" }}>
            <UserStatusRow
              approvers={row.approvers}
              userInfo={row.sendApproveBy}
              isBanking={false}
            />
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "amount",
      width: 110,
      render: (value: number, record) => {
        return (
          <Row>
            <ShowStatus
              record={
                {
                  activityState:
                    record?.approverStatus?.toLocaleLowerCase() || "",
                } as Payment
              }
            />
          </Row>
        );
      },
    },
    {
      key: "action",
      title: "Detail/Cancel",
      dataIndex: "amount",
      fixed: "right",
      width: 100,
      render: (value: number, record) => {
        const authUserId: any = auth?.userId ? auth?.userId : null;
        const approverLists: any = record.approvers ? record.approvers : null;
        return (
          <Row>
            <Tooltip placement="top" title="Detail">
              <Button
                type="text"
                icon={<InfoCircleOutlined />}
                onClick={() => {
                  setVisibleDrawer(true);
                  setSelectRequestListPayment(record);
                }}
              />
            </Tooltip>
            {record.approverStatus === "APPROVED" &&
              record.pvDocNo === "" &&
              CancelByRow(approverLists, authUserId) && (
                <Tooltip placement="top" title="Cancel Approved">
                  <Button
                    type="text"
                    icon={<UndoOutlined />}
                    onClick={() => {
                      setSelectRequestListPayment(record);
                      setVisibleRejectModal(true);
                    }}
                  />
                </Tooltip>
              )}
            {record.approverStatus === "REJECTED" &&
              record.pvDocNo === "" &&
              CancelByRow(approverLists, authUserId) && (
                <Tooltip placement="top" title="Cancel Rejected">
                  <Button
                    type="text"
                    icon={<UndoOutlined />}
                    onClick={() => {
                      setSelectRequestListPayment(record);
                      setVisibleRejectModal(true);
                    }}
                  />
                </Tooltip>
              )}
          </Row>
        );
      },
    },
  ];
};
