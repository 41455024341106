import { FC, useMemo, Dispatch, SetStateAction } from "react";
import { Row, Col, Select, Typography, Button } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import { UpDownIcon } from "../CustomIcon";
import "./index.css";

interface Props {
  pageSize: number;
  pageSizeOption: number[];
  total: number;
  current: number;
  setPage: Dispatch<SetStateAction<number>>;
  onChange: (page: number, pageSize: number) => void;
}

const { Option } = Select;

const { Text } = Typography;

const Component: FC<Props> = ({
  pageSize,
  pageSizeOption = [10, 20, 50, 100],
  total = 1,
  current,
  setPage,
  onChange,
}: Props) => {
  const pageCount = useMemo(() => {
    return Math.ceil(total / pageSize) || 1;
  }, [total, pageSize]);

  return (
    <Row className="table-footer" style={{ alignItems: "center" }}>
      <Col
        className="footer-pageSize"
        span="auto"
        style={{ display: "flex", gap: 5 }}
      >
        <Text>Items per page</Text>
        <Select
          value={pageSize}
          onChange={(val) => {
            onChange(1, val);
          }}
          suffixIcon={<UpDownIcon />}
        >
          {pageSizeOption.map((option: number) => (
            <Option value={option}> {option} </Option>
          ))}
        </Select>
      </Col>
      <Col
        className="footer-content"
        span="auto"
        flex="auto"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Text>
          {(current - 1) * pageSize + 1}-
          {current * pageSize < total ? current * pageSize : total} of {total}{" "}
          items
        </Text>
        <Text>
          Page{" "}
          <Select
            value={current}
            onChange={(val) => {
              onChange(val, pageSize);
            }}
            suffixIcon={<UpDownIcon />}
          >
            {Array(pageCount || 1)
              .fill(0)
              .map((value, index: number) => (
                <Option value={index + 1}> {index + 1} </Option>
              ))}
          </Select>
        </Text>
      </Col>
      <Col className="footer-page" span="auto">
        {current} of {pageCount} pages
      </Col>
      <Button
        className="footer-control ant-col ant-col-auto mx-3"
        icon={<CaretLeftOutlined />}
        disabled={current === 1}
        onClick={() => {
          setPage((cur: number) => cur - 1);
          onChange(current - 1, pageSize);
        }}
      />
      <Button
        className="footer-control ant-col ant-col-auto mx-3"
        icon={<CaretRightOutlined />}
        disabled={current === pageCount}
        onClick={() => {
          setPage((cur) => cur + 1);
          onChange(current + 1, pageSize);
        }}
      />
    </Row>
  );
};

export default Component;
