import { FC, useState, useMemo, useEffect } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/lib/table";
import type { Key, TableRowSelection } from "antd/lib/table/interface";
import Footer from "./Footer";
import "./index.css";
import { PRMemoResponseLists } from "../../interfaces/prmemo.interface";

interface Props {
  data: PRMemoResponseLists[];
  columns: ColumnsType<PRMemoResponseLists>;
  rowKey: string;
  loading: boolean;
  isRowSelection?: boolean;
  onSelectRowTable?: (value: Key[]) => void;
  setSelectedRow?: (record: PRMemoResponseLists[]) => void;
}

const Component: FC<Props> = ({
  data,
  columns,
  rowKey,
  loading,
  isRowSelection,
  onSelectRowTable,
  setSelectedRow,
}: Props) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setPage(1);
  }, [data]);

  const dataSource: PRMemoResponseLists[] = useMemo(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize - 1;

    return data.filter(
      (_item, index) => index >= startIndex && index <= endIndex
    );
  }, [data, page, pageSize]);

  const rowSelection: TableRowSelection<PRMemoResponseLists> | undefined =
    useMemo(() => {
      if (!isRowSelection) {
        return undefined;
      }

      return {
        type: "checkbox",
        onChange: (value: Key[], record) => {
          if (
            typeof onSelectRowTable === "function" &&
            typeof setSelectedRow === "function"
          ) {
            onSelectRowTable(value);
            setSelectedRow(record);
          }
        },
        getCheckboxProps: (record: PRMemoResponseLists) => ({
          disabled: record.isDelete,
        }),
      };
    }, [isRowSelection, onSelectRowTable, setSelectedRow]);

  return (
    <Table
      className="table-striped-rows"
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      rowKey={rowKey}
      rowSelection={rowSelection}
      pagination={false}
      bordered
      scroll={{ x: 1700, y: 800 }}
      style={{ overflowX: "scroll", backgroundColor: "white" }}
      footer={() => (
        <Footer
          pageSize={pageSize}
          pageSizeOption={[10, 20, 50, 100]}
          total={data.length}
          current={page}
          setPage={setPage}
          onChange={(current, currenPageSize) => {
            setPage(current);
            setPageSize(currenPageSize);
          }}
        />
      )}
    />
  );
};

Component.defaultProps = {
  isRowSelection: false,
  onSelectRowTable: () => {},
  setSelectedRow: () => {},
};

export default Component;
