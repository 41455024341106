import React, { FC, useMemo } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  Radio,
  Checkbox,
  DatePicker,
  InputNumber,
  Upload,
  FormProps,
  FormInstance,
  Typography,
} from "antd";
import { UploadChangeParam, RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import {
  FileListUpload,
  ResponseAPIUploadFile,
} from "../../interfaces/uploadFile.interface";
import { User } from "../../interfaces/user.interface";
import { filterOption } from "../../libs/filterOptionSelect";
import FileList from "../FileList";
import "./index.css";

export interface MemoFormProps extends FormProps {
  form: FormInstance;
  auth: User | undefined;
  inputNames: string[];
  company: ItemProps[];
  purchasingGroup: ItemProps[];
  payPurchasingGroup: ItemProps[];
  accountAssign: ItemProps[];
  materialGroup: ItemProps[];
  vendorData: ItemProps[];
  onChangeVendorName: (e: string) => void;
  isDisableVendorInput: boolean;
  isApprProject: string;
  formatNumber: (value: number | undefined) => string;
  defaultCurrency: string;
  currencyData: ItemProps[];
  userList: ItemProps[];
  localCurrentAmount: number | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFile: (file: UploadFileCustom) => File[] | (UploadFileCustom & any[]);
  fileList: (ResponseAPIUploadFile | FileListUpload)[];
  onUploadFileChange: (file: RcFile) => void;
  onDeleteFile: (fileIndex: number) => void;
  isNonPO: boolean;
  isAdmin: boolean;
  createByEmail: string;
}

export interface ItemProps {
  key: string;
  label: string;
  value: string;
}

interface UploadFileCustom {
  // eslint-disable-next-line react/require-default-props
  value?: File[];
  file: File;
  // eslint-disable-next-line react/require-default-props
  fileList: File[];
}

const Component: FC<MemoFormProps> = ({
  form,
  auth,
  inputNames,
  company,
  purchasingGroup,
  payPurchasingGroup,
  accountAssign,
  materialGroup,
  vendorData,
  onChangeVendorName,
  isDisableVendorInput,
  isApprProject,
  formatNumber,
  defaultCurrency,
  currencyData,
  userList,
  localCurrentAmount,
  getFile,
  fileList,
  onUploadFileChange,
  onDeleteFile,
  isNonPO,
  isAdmin,
  createByEmail,
  ...rest
}) => {
  const { Text } = Typography;
  const { TextArea } = Input;

  const accoId = Form.useWatch<string | undefined>("accoId", form);
  const accoType = Form.useWatch<string | undefined>("accoType", form);
  const contactInfo = Form.useWatch<string | undefined>("contactInfo", form);

  const filteredACcount = useMemo(() => {
    return accountAssign.filter((item) => !item.label.startsWith("A"));
  }, [accountAssign]);

  const isWarningPhoneNumber = useMemo(() => {
    if (contactInfo) {
      const regexpPhone = new RegExp(/^(?!02)0[1-9]/g);
      const regexp4Digit = new RegExp(/^[1-9]/g);
      if (
        (regexp4Digit.test(contactInfo) && contactInfo.length === 4) ||
        (regexpPhone.test(contactInfo) && contactInfo.length === 10) ||
        (contactInfo.startsWith("02") && contactInfo.length === 9)
      ) {
        return false;
      }

      return true;
    }
    return false;
  }, [contactInfo]);

  const inputNumberCheck = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9.]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const onChangePurchaseGroup = (value: string) => {
    // const qtyUnit = dataSourceUnit.find((item) => `${item.unitCode}` === value);
    form.setFieldsValue({
      // perUnit: qtyUnit?.unitCode,
      payPurgId: value,
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      initialValues={{ files: [] }}
      // eslint-disable-next-line no-template-curly-in-string
      validateMessages={{ required: "${label} is required" }}
      {...rest}
    >
      <Row gutter={[32, 0]}>
        <Col span={24} md={14} lg={14} xl={12}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Row justify="space-between">
                <Text strong>HEADER</Text>
                <Text strong>
                  {form.getFieldValue("payNumber")
                    ? `Request No./PR Number ${form.getFieldValue("payNumber")}`
                    : ""}
                </Text>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} sm={8}>
                      <Form.Item name="payId" hidden initialValue={0} />
                      <Form.Item name="docYear" hidden>
                        <Input type="hidden" />
                      </Form.Item>

                      <Form.Item
                        name="compId"
                        label="Company"
                        required
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          disabled={isAdmin}
                          placeholder="- Select -"
                          options={company}
                          showSearch
                          filterOption={filterOption}
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={16}>
                      <Form.Item label=" " name="disableCompany">
                        <Input disabled className="FieldInputStyle" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} sm={8}>
                      <Form.Item
                        name="accoId"
                        label="Budget Type"
                        required
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          disabled={isAdmin}
                          placeholder="- Select -"
                          options={isNonPO ? filteredACcount : accountAssign}
                          showSearch
                          filterOption={filterOption}
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={16}>
                      <Form.Item label=" " name="disableAssignment">
                        <Input disabled className="FieldInputStyle" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} sm={9}>
                      <Form.Item
                        name="accoType"
                        label="Budget Code"
                        required
                        rules={[
                          {
                            required: true,
                            message: "On / Out of Budget is Required",
                          },
                        ]}
                      >
                        <Radio.Group disabled={isAdmin}>
                          <Radio value="IN">On Budget</Radio>
                          <Radio value="OUT">Out of Budget</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item name="isNonPO" hidden initialValue={isNonPO} />
                    </Col>
                    {isNonPO &&
                      ["F", "O"].some((word) =>
                        accountAssign
                          .find((item) => item.value.toString() === accoId)
                          ?.label.startsWith(word)
                      ) &&
                      accoType === "IN" && (
                        <Col span={24} sm={15}>
                          <Form.Item
                            name="orderNumber"
                            label="Order Number"
                            rules={[
                              {
                                required: accoType === "IN",
                              },
                            ]}
                          >
                            <Input
                              disabled={isAdmin}
                              placeholder="รหัสงบประมาณ"
                              className="FieldInputStyle"
                              maxLength={10}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    {isNonPO &&
                      accountAssign
                        .find((item) => item.value.toString() === accoId)
                        ?.label.startsWith("P") &&
                      accoType === "IN" && (
                        <Col span={24} sm={15}>
                          <Form.Item
                            name="orderNumberWBS"
                            label="WBS no."
                            rules={[
                              {
                                required: accoType === "IN",
                              },
                            ]}
                          >
                            <Input
                              disabled={isAdmin}
                              placeholder="WBS no."
                              className="FieldInputStyle"
                              maxLength={24}
                            />
                          </Form.Item>
                        </Col>
                      )}
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} sm={8}>
                      <Form.Item
                        name="matgId"
                        label="Expense Type"
                        required
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          disabled={isAdmin}
                          placeholder="- Select -"
                          options={materialGroup}
                          showSearch
                          filterOption={filterOption}
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={16}>
                      <Form.Item label=" " name="disableMaterial">
                        <Input disabled className="FieldInputStyle" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={16}>
                      <Form.Item
                        initialValue={isAdmin ? createByEmail : auth?.email}
                        name="createByEmail"
                        label="Requester (Email)"
                        required
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          disabled
                          placeholder="Purchaser"
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="contactInfo"
                        label="Phone No."
                        required
                        validateStatus={
                          isWarningPhoneNumber ? "warning" : "success"
                        }
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          disabled={isAdmin}
                          placeholder="เบอร์ติดต่อผู้ขอ"
                          className="FieldInputStyle"
                          onKeyPress={inputNumberCheck}
                        />
                      </Form.Item>
                      {isWarningPhoneNumber && (
                        <Typography.Text type="warning">
                          Phone number format is incorrect
                        </Typography.Text>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} sm={8}>
                      <Form.Item
                        name="purgId"
                        label="Purchasing Group"
                        required
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          disabled={isAdmin}
                          placeholder="- Select -"
                          options={purchasingGroup}
                          showSearch
                          filterOption={filterOption}
                          onChange={(value) => onChangePurchaseGroup(value)}
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={16}>
                      <Form.Item label=" " name="disablePurchasing">
                        <Input disabled className="FieldInputStyle" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="payBy"
                    label="Pay By (Email)"
                    initialValue={`${auth?.userId}`}
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      disabled={isAdmin}
                      showSearch
                      filterOption={filterOption}
                      placeholder="Pay By (Email)"
                      className="FieldInputStyle"
                      options={userList}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} sm={8}>
                      <Form.Item
                        name="payPurgId"
                        label="Pay By Purchasing Group"
                        required
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          disabled={isAdmin}
                          placeholder="- Select -"
                          options={payPurchasingGroup}
                          showSearch
                          filterOption={filterOption}
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={16}>
                      <Form.Item label=" " name="disablePayPurchasing">
                        <Input disabled className="FieldInputStyle" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item name="emailInfo" label="E-mail Info">
                    <Select
                      disabled={isAdmin}
                      mode="tags"
                      placeholder="E-mail Info"
                      className="FieldInputStyle"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="prHeaderNote"
                    label="Header Text"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextArea
                      disabled={isAdmin}
                      placeholder="เรื่องที่ขออนุมัติ"
                      className="FieldInputStyle"
                      maxLength={1000}
                      showCount
                      autoSize={{ minRows: 3, maxRows: 10 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="toCEOGroup"
                    label="Authorized Approver"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      disabled={isAdmin}
                      placeholder="เรียนผู้บริหาร"
                      className="FieldInputStyle"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="apprObjective"
                    label="Objective"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextArea
                      disabled={isAdmin}
                      placeholder="วัตถุประสงค์"
                      className="FieldInputStyle"
                      maxLength={5000}
                      showCount
                      autoSize={{ minRows: 3, maxRows: 10 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="period"
                    label="Period"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker.RangePicker
                      disabled={isAdmin}
                      allowClear
                      format="DD/MM/YYYY"
                      className="date-picker-table"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="apprPayType"
                    label="Payment Type"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Radio.Group disabled={isAdmin}>
                      <Radio value="Partial">Partial</Radio>
                      <Radio value="Full">Full</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} sm={8}>
                      <Form.Item
                        name="accountNum"
                        label="Vendor"
                        required
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          // disabled={isAdmin}
                          placeholder="- Select -"
                          options={vendorData}
                          showSearch
                          filterOption={filterOption}
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={16}>
                      <Form.Item label=" " name="disableVendor">
                        <Input
                          onChange={(e) => onChangeVendorName(e.target.value)}
                          disabled={isAdmin || isDisableVendorInput}
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="paymentDesc"
                    label="Payment Description"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <TextArea
                      disabled={isAdmin}
                      showCount
                      maxLength={500}
                      placeholder="รายละเอียดการขออนุมัติ"
                      className="FieldInputStyle"
                      autoSize={{ minRows: 3, maxRows: 10 }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="isUrgent" valuePropName="checked">
                    <Checkbox disabled={isAdmin}>Urgent</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24} sm={14}>
                  <Form.Item
                    name="amount"
                    label="Amount (Included Vat)"
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <InputNumber
                      disabled={isAdmin}
                      controls={false}
                      placeholder="Amount (Included Vat)"
                      min={0}
                      formatter={formatNumber}
                      className="FieldInputStyle"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} sm={4}>
                  <Form.Item
                    name="currency"
                    label="Currency"
                    initialValue={defaultCurrency}
                    required
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      disabled={isAdmin}
                      placeholder="- Select -"
                      options={currencyData}
                      showSearch
                      filterOption={filterOption}
                      className="FieldInputStyle"
                    />
                  </Form.Item>
                </Col>
                <Col span={12} sm={6}>
                  <Form.Item
                    name="isCalculateTax"
                    valuePropName="checked"
                    label=" "
                  >
                    <Checkbox disabled={isAdmin}>Calculate Tax</Checkbox>
                  </Form.Item>
                </Col>
                {form.getFieldValue("currency") === "THB" || (
                  <Col span={24} sm={14}>
                    <Form.Item
                      name="exchangeRate"
                      label="Exchange Rate"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        disabled={isAdmin}
                        placeholder="Exchange Rate"
                        formatter={formatNumber}
                        className="FieldInputStyle"
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24} sm={14}>
                  <Form.Item label="Local Current Amount">
                    <InputNumber
                      value={localCurrentAmount}
                      controls={false}
                      // formatter={(value) =>
                      //   value ? formatNumber(Number(value)) : ""
                      // }
                      disabled
                      formatter={formatNumber}
                      placeholder="Local Current Amount"
                      className="FieldInputStyle"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24} md={10} lg={10} xl={{ span: 10, offset: 2 }}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                name={["memoNo"]}
                label="Memo No."
                required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={isAdmin}
                  placeholder="Memo No."
                  className="FieldInputStyle"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["documentDate"]}
                label="Date"
                required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  disabled={isAdmin}
                  allowClear
                  format="DD/MM/YYYY"
                  className="date-picker-table"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <Text strong>ATTACHEDS</Text>
            </Col>
            <Col span={24}>
              <div style={{ marginBottom: "15px" }}>
                <Form.Item
                  name="dragger"
                  valuePropName="value"
                  getValueFromEvent={getFile}
                  noStyle
                >
                  <Upload.Dragger
                    disabled={isAdmin}
                    beforeUpload={() => false}
                    fileList={fileList}
                    onChange={(info: UploadChangeParam<UploadFile>) => {
                      onUploadFileChange(info.file as RcFile);
                    }}
                    showUploadList={false}
                    multiple
                  >
                    <Text>Drag files here or </Text>
                    <br />
                    <Text strong style={{ color: "#174291" }}>
                      Choose files from your computer
                    </Text>
                  </Upload.Dragger>
                </Form.Item>
              </div>
              <FileList fileList={fileList} onDeleteFile={onDeleteFile} />
            </Col>
            <Col span={24}>
              <Form.Item
                name="isApprProject"
                label="Approve Request Type"
                initialValue="General"
              >
                <Radio.Group disabled={isAdmin}>
                  <Radio value="General">General</Radio>
                  <Radio value="Project">Project</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {isApprProject === "Project" && (
              <div
                style={{
                  backgroundColor: "#F5F5F5",
                  borderRadius: "8px",
                  padding: "16px",
                }}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name={["backgroud"]}
                      label="Project Background"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TextArea
                        disabled={isAdmin}
                        placeholder="ความเป็นมาของเรื่องที่ขออนุมัติ"
                        className="FieldInputStyle"
                        maxLength={5000}
                        showCount
                        autoSize={{ minRows: 3, maxRows: 10 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={["budgetYear"]}
                      label="Fiscal Year"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePicker
                        disabled={isAdmin}
                        allowClear
                        format="YYYY"
                        picker="year"
                        className="date-picker-table"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={["responseExpenseGroup"]}
                      label="Expense Department"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TextArea
                        disabled={isAdmin}
                        placeholder="หน่วยงานที่รับผิดชอบค่าใช้จ่าย"
                        className="FieldInputStyle"
                        maxLength={200}
                        showCount
                        autoSize={{ minRows: 2, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={["responseGroup"]}
                      label="Operation Department"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TextArea
                        disabled={isAdmin}
                        placeholder="หน่วยงานที่ดำเนินการ"
                        className="FieldInputStyle"
                        maxLength={200}
                        showCount
                        autoSize={{ minRows: 2, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={["responsePerson"]}
                      label="Responsible Person"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TextArea
                        disabled={isAdmin}
                        placeholder="ผู้ดำเนินงานโครงการ"
                        className="FieldInputStyle"
                        maxLength={200}
                        showCount
                        autoSize={{ minRows: 2, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={["benefit"]}
                      label="Benefit"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TextArea
                        disabled={isAdmin}
                        placeholder="ประโยชน์ของโครงการ"
                        className="FieldInputStyle"
                        maxLength={5000}
                        showCount
                        autoSize={{ minRows: 3, maxRows: 10 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name={["impactOrg"]}
                      label="Impact"
                      required
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <TextArea
                        disabled={isAdmin}
                        placeholder="ผลกระทบกับองค์กร/หน่วยงาน"
                        className="FieldInputStyle"
                        maxLength={1000}
                        showCount
                        autoSize={{ minRows: 3, maxRows: 10 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Component;
