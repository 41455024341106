interface Location {
  pathname: string;
  search: string;
  hash: string;
  key?: string;
  state: unknown;
}

export const get = (): Location | null => {
  return (
    JSON.parse(window.localStorage.getItem("redirectPath") as string) || null
  );
};

export const set = (value: Location | undefined): void => {
  console.log("set", value);
  return window.localStorage.setItem("redirectPath", JSON.stringify(value));
};

export const clear = (): void => {
  window.localStorage.clear();
};
