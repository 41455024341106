/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
  Upload,
  Select,
  DatePicker,
  Spin,
  message,
  Modal,
  InputNumber,
  notification,
  Button,
} from "antd";
import cloneDeep from "lodash/cloneDeep";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import moment from "moment";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import TitleHeader from "../../components/TitleHeader";
import {
  AccountAssignment,
  Company,
  Currency,
  MaterialGroup,
  PurchasingGroup,
  Vendor,
} from "../../interfaces/master.interface";
import {
  getMasterAccountAssignment,
  getMasterCompany,
  getMasterCurrency,
  getMasterMaterialGroup,
  getMasterPurchasingGroup,
  getMasterVendor,
} from "../../api/master";
import FileList from "../../components/FileList";
import TableImmediatelyDetail from "../../components/DetailsTable";
import "./index.css";
import useAuthentication from "../../hooks/useAuthentication";
import { uploadFileList } from "../../api/service";
import { submitImmediately } from "../../api/requestImmediately";
import {
  FormHeaderImmediately,
  PaymentInfoItem,
  RequestImmediately,
} from "../../interfaces/immediately.interface";
import { filterOption } from "../../libs/filterOptionSelect";
import { sendApprove } from "../../api/approval";
import {
  FileListUpload,
  ResponseAPIUploadFile,
} from "../../interfaces/uploadFile.interface";
import { checkLimitSize, getFileName } from "../../libs/file";
import CustomModal from "../../components/CustomModal";
import useOptions from "../../hooks/useOptions";

const { Text } = Typography;
const { TextArea } = Input;

interface UploadFileCustom {
  // eslint-disable-next-line react/require-default-props
  value?: File[];
  file: File;
  // eslint-disable-next-line react/require-default-props
  fileList: File[];
}

// interface SelectOption {
//   key: string;
//   label: string;
//   value: string;
// }

interface PrepareFileData {
  FileBase64: string;
  FileType: string;
  ExtensionFile: string;
}

const defaultCurrency = "THB";

const Component = (): JSX.Element => {
  const [vatValue, setVat] = useState<number>(0);
  const auth = useAuthentication();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [count, setCount] = useState(0);
  const [fileList, setFileList] = useState<
    Array<ResponseAPIUploadFile | FileListUpload>
  >([]);
  const [dataTable, setDataTable] = useState<PaymentInfoItem[]>([]);
  const [dataSourcePurchasingGroup, setDataSourcePurchasingGroup] = useState<
    PurchasingGroup[]
  >([]);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);
  const [dataSourceMaterialGroup, setDataSourceMaterialGroup] = useState<
    MaterialGroup[]
  >([]);
  const [dataSourceAccountAssign, setDataSourceAccountAssign] = useState<
    AccountAssignment[]
  >([]);
  const [dataSourceVendor, setDataSourceVendor] = useState<Vendor[]>([]);
  const [dataSourceCurrency, setDataSourceCurrency] = useState<Currency[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [isDisaVendorInput, setIsDisableVendorInput] = useState<boolean>(true);
  const [visibleWarningModal, setVisibleWarningModal] =
    useState<boolean>(false);
  const [visibleWarning2Modal, setVisibleWarning2Modal] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const isCalculateTaxValue = Form.useWatch<boolean>("isCalculateTax", form);
  const taxInvoiceNoValue = Form.useWatch<string>("taxInvoiceNo", form);
  const invoiceDateValue = Form.useWatch<string>("invoiceDate", form);
  const dueDateValue = Form.useWatch<string>("dueDate", form);
  const [selectedCompany, setSelectedCompany] = useState<string>();
  const [selectedPurchase, setSelectedPurchase] = useState<string>();
  const [selectedAccount, setSelectedAccount] = useState<string>();
  const [selectedMaterial, setSelectedMaterial] = useState<string>();
  const [selectedVendor, setSelectedVendor] = useState<string>();
  const [isSameVendor, setIsSameVendor] = useState<boolean>(false);
  const [saveType, setSaveType] = useState<string>("");
  const [amount, setAmount] = useState<number>();
  const [currency, setCurrency] = useState<string>(defaultCurrency);
  const [exchangeRate, setExchangeRate] = useState<number>(1);
  const [isUrgent, setIsUrgent] = useState<boolean>(false);
  const [itemsPrepareDetail, setItemsPrepareDetail] = useState<
    PaymentInfoItem[]
  >([]);

  const history = useHistory();

  const dataTableRef = useRef<HTMLDivElement>(null);

  const {
    companies,
    purchasingGroups,
    accountAssign,
    expenseType,
    vendorData,
    currencyData,
  } = useOptions({
    dataSourceCompany,
    dataSourcePurchasingGroup,
    dataSourceAccountAssign,
    dataSourceMaterialGroup,
    dataSourceVendor,
    dataSourceCurrency,
  });

  const localCurrentAmount = useMemo(() => {
    if (!amount) {
      return undefined;
    }

    if (currency === "THB") {
      if (isCalculateTaxValue) {
        return amount;
      }

      return amount;
    }

    if (isCalculateTaxValue) {
      return amount * exchangeRate;
    }

    return amount * exchangeRate;
  }, [amount, currency, exchangeRate, isCalculateTaxValue]);

  const formatNumber = (value: number | undefined) => {
    if (isEmpty(value)) {
      return "";
    }
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 4,
    }).format(Number(value || null));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const inputNumberCheck = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9.]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const getTotalAmount: number = useMemo(() => {
    return dataTable
      .filter((value: PaymentInfoItem) => !value.isDeleted)
      .reduce((acc, cur) => {
        // const setNewAmount = cur.isCalculateTax
        //   ? cur.totalValue - (cur.totalValue * 7) / 107
        //   : cur.totalValue;
        const setNewAmount = cur.isCalculateTax
          ? (cur.totalValue * 100) / 100
          : cur.totalValue;
        return acc + setNewAmount;
      }, 0);
  }, [dataTable]);

  const getTotalTaxAmount: number = useMemo(() => {
    return dataTable
      .filter((value: PaymentInfoItem) => !value.isDeleted)
      .reduce((acc, cur) => {
        // const allTax = cur.isCalculateTax ? (cur.totalValue * 7) / 107 : 0;
        const allTax = cur.isCalculateTax
          ? Math.round(cur.totalValue * 7) / 100
          : 0;
        return acc + allTax;
      }, 0);
  }, [dataTable]);

  useEffect(() => {
    setVat(Number(getTotalTaxAmount.toFixed(2)));
  }, [getTotalTaxAmount]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getMasterPurchasingGroup(auth?.userId),
      getMasterCompany(auth?.userId),
      getMasterAccountAssignment(auth?.userId),
      getMasterMaterialGroup(auth?.userId),
      getMasterCurrency(),
    ])
      .then(
        ([
          purchasingGroupData,
          companyData,
          accountAssignment,
          responseMaterial,
          currencyResponse,
        ]) => {
          setDataSourcePurchasingGroup(purchasingGroupData.responseData);
          setDataSourceCompany(companyData.responseData);
          setDataSourceAccountAssign(accountAssignment.responseData);
          setDataSourceMaterialGroup(responseMaterial.responseData);
          setDataSourceCurrency(currencyResponse.responseData);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  }, [auth?.userId]);

  useEffect(() => {
    if (selectedCompany !== undefined && dataSourceCompany.length > 0) {
      form.setFieldsValue({
        accountNum: "",
        disable_vendor: "",
      });
      setSelectedVendor("");
      setLoading(true);
      const { compId } = dataSourceCompany.filter(
        (value) => value.compId === parseInt(selectedCompany || "0", 10)
      )[0];
      getMasterVendor(compId).then((result) => {
        setDataSourceVendor(result.responseData);
        setLoading(false);
      });
    }
  }, [dataSourceCompany, form, selectedCompany]);

  const getBase64Upload = (
    file: RcFile,
    callback: { (value: string | ArrayBuffer | null): void }
  ) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const onUploadFileChange = (file: RcFile): void => {
    if (file && checkLimitSize(file)) {
      notification.error({
        message: "ไม่สามารถอัพโหลดไฟล์ได้",
        description: "ไฟล์มีขนาดใหญ่เกินกว่า 30MB",
      });
      return;
    }

    getBase64Upload(file, (imageUrl: any) => {
      const prepareFile: any = file;

      prepareFile.rawName = getFileName(file.name);
      prepareFile.base64 = imageUrl;

      setFileList([...fileList, prepareFile] as any);
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      disable_company:
        dataSourceCompany.find((item) => `${item.compId}` === selectedCompany)
          ?.companyName || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceCompany, selectedCompany]);

  useEffect(() => {
    form.setFieldsValue({
      disable_purchasing:
        dataSourcePurchasingGroup.find(
          (item) => `${item.purgId}` === selectedPurchase
        )?.purGroupName || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourcePurchasingGroup, selectedPurchase]);

  useEffect(() => {
    form.setFieldsValue({
      disable_assignment:
        dataSourceAccountAssign.find(
          (item) => `${item.accoId}` === selectedAccount
        )?.accAssName || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceAccountAssign, selectedAccount]);

  useEffect(() => {
    form.setFieldsValue({
      disable_material:
        dataSourceMaterialGroup.find(
          (item) => `${item.matgId}` === selectedMaterial
        )?.materialGroupName || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceMaterialGroup, selectedMaterial]);

  useEffect(() => {
    if (selectedVendor?.substring(0, 2) === "29") {
      setIsDisableVendorInput(false);
    } else {
      setIsDisableVendorInput(true);
    }
    form.setFieldsValue({
      disable_vendor:
        dataSourceVendor.find((item) => `${item.accountNum}` === selectedVendor)
          ?.accountText || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSourceVendor, selectedVendor]);

  const getFile = (file: UploadFileCustom) => {
    if (Array.isArray(file)) {
      return file;
    }
    return file && file.fileList;
  };

  const onClear = () => {
    setAmount(0);
    setFileList([]);
    setItemsPrepareDetail([]);
    setDataSourceVendor([]);
    setSelectedCompany(undefined);
    setSelectedPurchase(undefined);
    setSelectedMaterial(undefined);
    setSelectedAccount(undefined);
    setSelectedVendor(undefined);
    form.resetFields();
  };

  const onChangeVendorName = (name: string) => {
    const newDataArr = dataSourceVendor.map((item) => {
      if (item.accountNum === selectedVendor)
        return {
          ...item,
          accountText: name,
        };

      return item;
    });
    setDataSourceVendor(newDataArr);
  };

  const onSaveRequest = useCallback(
    async (type: string, values: FormHeaderImmediately) => {
      const payId = 0;
      const companyObject = dataSourceCompany.find(
        (item) => `${item.compId}` === selectedCompany
      );
      const purchaseObject = dataSourcePurchasingGroup.find(
        (item) => `${item.purgId}` === selectedPurchase
      );
      const accountObject = dataSourceAccountAssign.find(
        (item) => `${item.accoId}` === selectedAccount
      );
      const materialObject = dataSourceMaterialGroup.find(
        (item) => `${item.matgId}` === selectedMaterial
      );
      const vendorObject = dataSourceVendor.find(
        (item) => `${item.accountNum}` === selectedVendor
      );

      const prepareDetail: PaymentInfoItem[] = dataTable
        .filter((value: PaymentInfoItem) => !value.isDeleted)
        .map((item) => {
          return {
            payItemId: item.payItemId,
            accoId: accountObject?.accoId || 0,
            accountAssignCategory: accountObject?.accAssName || "",
            accountAssignCategoryId: accountObject?.accAssId || "",
            paymentItem: item.paymentItem,
            paymentText: item.paymentText,
            totalValue: item.totalValue,
            exchangeRate: values.exchangeRate || 0,
            currency,
            purGroup: purchaseObject?.purGroupId || "",
            companyCode: companyObject?.companyId || "",
            matgId: materialObject?.matgId || 0,
            materailCode: `${materialObject?.materialGroupId}`,
            materialGroup: materialObject?.materialGroupName || "",
            accountAssignCode: item.accountAssignCode,
            orderNumberWbs: item.orderNumberWbs,
            orderNumberWbsdesc: item.orderNumberWbsdesc,
            orderNumber: item.orderNumber,
            orderName: item.orderName,
            taxCode: item.taxCode || "",
            docYear: item.docYear,
            accountText: item.accountText,
            accountNum: item.accountNum,
            isCalculateTax: item.isCalculateTax,
            prNo: item.prNo,
            poNo: item.poNo,
            fiDocNo: item?.fiDocNo === null ? "" : item?.fiDocNo,
            taxInvoiceNo: item.taxInvoiceNo,
            invoiceDate: item.invoiceDate,
            isExceptionBudget: item.isExceptionBudget,
          };
        });

      const prepareData: RequestImmediately = {
        payId,
        referPayId: 0,
        vat: vatValue,
        compId: companyObject?.compId || 0,
        company: companyObject?.companyId || "",
        companyName: companyObject?.abbr || "",
        purgId: purchaseObject?.purgId || 0,
        purGroup: purchaseObject?.purGroupId || "",
        purGroupName: purchaseObject?.purGroupName || "",
        accoId: accountObject?.accoId || 0,
        accountAssignCategory: accountObject?.accAssName || "",
        accountAssignCategoryId: accountObject?.accAssId || "",
        matgId: materialObject?.matgId || 0,
        matGroup: materialObject?.materialGroupId || "",
        dueDate: values.dueDate.format(),
        invoiceDate: values.invoiceDate?.format() || "",
        taxInvoiceNo: values.taxInvoiceNo,
        createBy: Number(auth?.userId),
        emailInfo: values.emailInfo?.join(";") || null,
        prHeaderNote: values.prHeaderNote,
        accountText: vendorObject?.accountText || "",
        accountNum: vendorObject?.accountNum || "",
        isSameVendor,
        isCalculateTax: isCalculateTaxValue,
        amount: values.amount,
        exchangeRate: values.exchangeRate || 0,
        currency,
        isImmediately: true,
        isUrgent,
        assigment: "",
        paymentDesc: values.paymentDesc,
        docType: "KA",
        reference: "",
        docYear: "",
        paymentRef: "",
        paymentInfoItem: prepareDetail,
        paymentInfoAttach: [],
      };

      setLoading(true);
      setLoadingButton(true);
      await Promise.all(
        fileList.map((file: any) => {
          const FileName = file.rawName;
          const ExtensionFile = file.fileExtension;

          const prepareFileData: PrepareFileData = {
            FileBase64: file.base64,
            FileType: FileName,
            ExtensionFile,
          };

          return uploadFileList(prepareFileData)
            .then((res) => {
              const ret: any = {
                payAttcId: 0,
                filePath: res.responseData[0].filePath,
                filename: res.responseData[0].fileName,
                fileExtension: prepareFileData.ExtensionFile,
                readOnly: false,
                isDelete: false,
              };
              // return res.responseData[0];
              return ret;
            })
            .catch((err) => {
              return err;
            });
        })
      )
        .then(async (responseFile) => {
          await submitImmediately({
            ...prepareData,
            paymentInfoAttach: responseFile,
          })
            .then((result: any) => {
              if (type === "release") {
                sendApprove({
                  payId: [result.responseData[0].payId],
                  sendApproveBy: `${auth?.userId}`,
                }).then(() => {
                  Modal.success({
                    content: `ส่งข้อมูลเพื่อขออนุมัติสำเร็จ Pay Request No คือ ${result.responseData[0].payNumber}`,
                    onOk() {
                      setLoadingButton(false);
                      history.push("/requester");
                    },
                  });
                });
              } else {
                Modal.success({
                  content: `บันทึกข้อมูลสำเร็จ Pay Request No คือ ${result.responseData[0].payNumber}`,
                  onOk() {
                    setLoadingButton(false);
                    history.push("/requester");
                  },
                });
              }
            })
            .catch(() => {
              setLoading(false);
              setLoadingButton(false);
              // Modal.error({
              //   title: `เกิดข้อผิดพลาด`,
              //   content: `โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
              // });
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [
      auth?.userId,
      currency,
      dataSourceAccountAssign,
      dataSourceCompany,
      dataSourceMaterialGroup,
      dataSourcePurchasingGroup,
      dataSourceVendor,
      dataTable,
      fileList,
      history,
      isCalculateTaxValue,
      isSameVendor,
      isUrgent,
      selectedAccount,
      selectedCompany,
      selectedMaterial,
      selectedPurchase,
      selectedVendor,
      vatValue,
    ]
  );

  const onSaveRequestValidate = (type: string) => {
    setSaveType(type);
    form
      .validateFields()
      .then(async (values: FormHeaderImmediately) => {
        if (!dataTable.length) {
          message.error("กรุณากรอกข้อมูลในตารางให้ครบถ้วน", 10);
          if (dataTableRef.current) dataTableRef.current.scrollIntoView();
          return;
        }
        const vat = (getTotalAmount + vatValue) * 100;

        if (Math.round(vat) / 100 !== values.amount) {
          message.error("กรุณากรอกจำนวนเงินให้ถูกต้อง", 10);
          form.scrollToField("amount");
          return;
        }

        const vendorObject = dataSourceVendor.find(
          (item) => item.accountNum === values.accountNum
        );

        if (vendorObject) {
          if (invoiceDateValue) {
            const paymentTermWithInvoice = dayjs(invoiceDateValue).add(
              vendorObject.paymentTermDays,
              "day"
            );
            if (dayjs(dueDateValue).isBefore(invoiceDateValue, "day")) {
              message.error(
                "กรุณาระบุวันที่ Due Date ให้มากกว่าหรือเท่ากับวันที่ Invoice Date",
                10
              );
              return;
            }
            if (!dayjs(dueDateValue).isSame(paymentTermWithInvoice, "day")) {
              setVisibleWarningModal(true);
              return;
            }
          } else {
            const paymentTermWithEntry = dayjs().add(
              vendorObject.paymentTermDays,
              "day"
            );
            if (dayjs(dueDateValue).isBefore(dayjs(), "day")) {
              setVisibleWarning2Modal(true);
              return;
            }
            if (!dayjs(dueDateValue).isSame(paymentTermWithEntry, "day")) {
              setVisibleWarningModal(true);
              return;
            }
          }
        }
        onSaveRequest(type, values);
      })
      .catch((error) => {
        message.error("กรุณากรอกข้อมูลให้ครบถ้วน", 10);
        form.scrollToField(error.errorFields[0].name);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const contentWarningModal = useMemo(() => {
    return (
      <div>
        <Typography.Text style={{ marginBottom: "20px" }}>
          วันที่ Due Date = {dayjs(dueDateValue)?.format("DD.MM.YYYY")}{" "}
          ไม่สอดคล้องกับ Payment Terms ต้องการดำเนินการต่อหรือไม่
        </Typography.Text>
      </div>
    );
  }, [dueDateValue]);

  const contentWarning2Modal = useMemo(() => {
    return (
      <div>
        <Typography.Text style={{ marginBottom: "20px" }}>
          กรุณาตรวจสอบ Due Date เนื่องจากระบุข้อมูลน้อยกว่า วันที่ปัจจุบัน
        </Typography.Text>
      </div>
    );
  }, []);

  const footerWarningModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button onClick={() => setVisibleWarningModal(false)}>Cancel</Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            onSaveRequest(saveType, form.getFieldsValue());
            setVisibleWarningModal(false);
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [form, loading, onSaveRequest, saveType]);

  const footerWarning2Modal = useMemo(() => {
    return (
      <Row justify="end">
        <Button onClick={() => setVisibleWarning2Modal(false)}>Cancel</Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            onSaveRequest(saveType, form.getFieldsValue());
            setVisibleWarning2Modal(false);
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [form, loading, onSaveRequest, saveType]);

  const onDeleteFile = (fileIndex: number) => {
    const newFileList = fileList.filter((ele, index) => {
      return index !== fileIndex;
    });
    setFileList(cloneDeep(newFileList));
  };

  // <Form.Item label="Header Text">
  //   <Form.Item
  //     name="headerText"
  //     noStyle
  //     rules={[{ required: true, message: "Purchasing Group is required" }]}
  //   >
  //     <Input
  //       style={{
  //         marginRight: "8px",
  //         width: "66%",
  //       }}
  //       placeholder="Header Text"
  //     />
  //   </Form.Item>
  // </Form.Item>;

  return (
    <Spin spinning={loading}>
      <div style={{ padding: 20 }}>
        <TitleHeader
          title="Request Immediately"
          dataSizes={count}
          loading={loadingButton}
          onSaveRequest={onSaveRequestValidate}
          onClear={onClear}
        />
        <div
          style={{
            borderRadius: "16px",
            padding: "32px",
            border: "1px solid #E2E2E2",
            boxSizing: "border-box",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            scrollToFirstError
            initialValues={{ files: [] }}
          >
            <Row gutter={[32, 0]}>
              <Col span={24} md={14} lg={14} xl={12}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Text strong>HEADER</Text>
                  </Col>
                  <Col span={24}>
                    <Row gutter={8}>
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} sm={8}>
                            <Form.Item
                              name="compId"
                              label="Company"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Company is Required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="- Select -"
                                options={companies}
                                showSearch
                                filterOption={filterOption}
                                onChange={setSelectedCompany}
                                className="FieldInputStyle"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} sm={16}>
                            <Form.Item label=" " name="disable_company">
                              <Input disabled className="FieldInputStyle" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} sm={8}>
                            <Form.Item
                              name="purgId"
                              label="Purchasing Group"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Purchasing Group is Required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="- Select -"
                                options={purchasingGroups}
                                showSearch
                                filterOption={filterOption}
                                onChange={setSelectedPurchase}
                                className="FieldInputStyle"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} sm={16}>
                            <Form.Item label=" " name="disable_purchasing">
                              <Input
                                value={
                                  dataSourcePurchasingGroup.find(
                                    (item) =>
                                      `${item.purgId}` === selectedPurchase
                                  )?.purGroupName || ""
                                }
                                disabled
                                className="FieldInputStyle"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} sm={8}>
                            <Form.Item
                              name="accoId"
                              label="Budget Type"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Budget Type is Required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="- Select -"
                                options={accountAssign}
                                showSearch
                                filterOption={filterOption}
                                onChange={setSelectedAccount}
                                className="FieldInputStyle"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} sm={16}>
                            <Form.Item label=" " name="disable_assignment">
                              <Input
                                value={
                                  dataSourceAccountAssign.find(
                                    (item) =>
                                      `${item.accoId}` === selectedAccount
                                  )?.accAssName || ""
                                }
                                disabled
                                className="FieldInputStyle"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} sm={8}>
                            <Form.Item
                              name="matgId"
                              label="Expense Type"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Expense Type is Required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="- Select -"
                                options={expenseType}
                                showSearch
                                filterOption={filterOption}
                                onChange={setSelectedMaterial}
                                className="FieldInputStyle"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} sm={16}>
                            <Form.Item label=" " name="disable_material">
                              <Input
                                value={
                                  dataSourceMaterialGroup.find(
                                    (item) =>
                                      `${item.matgId}` === selectedMaterial
                                  )?.materialGroupName || ""
                                }
                                disabled
                                className="FieldInputStyle"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} sm={8}>
                            <Form.Item
                              name="accountNum"
                              label="Vendor"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Vendor is Required",
                                },
                              ]}
                            >
                              <Select
                                placeholder="- Select -"
                                options={vendorData}
                                showSearch
                                filterOption={filterOption}
                                onChange={setSelectedVendor}
                                className="FieldInputStyle"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={24} sm={16}>
                            <Form.Item label=" " name="disable_vendor">
                              <Input
                                value={
                                  dataSourceVendor.find(
                                    (item) =>
                                      `${item.accountNum}` === selectedVendor
                                  )?.accountText || ""
                                }
                                onChange={(e) =>
                                  onChangeVendorName(e.target.value)
                                }
                                disabled={isDisaVendorInput}
                                className="FieldInputStyle"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="sameVendor" valuePropName="checked">
                          <Checkbox
                            value={isSameVendor}
                            onChange={(event) =>
                              setIsSameVendor(event.target.checked)
                            }
                          >
                            Use same vendor in details
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24} sm={14}>
                        <Form.Item
                          name="invoiceDate"
                          label="Invoice Date"
                          required={isCalculateTaxValue}
                          rules={[
                            {
                              required: isCalculateTaxValue,
                              message: "Invoice Date is Required",
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD-MM-YYYY"
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} sm={14}>
                        <Form.Item
                          name="taxInvoiceNo"
                          label="Tax Invoice"
                          required={isCalculateTaxValue}
                          rules={[
                            {
                              required: isCalculateTaxValue,
                              message: "Tax Invoice is Required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="เลขที่ใบกำกับภาษี"
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} sm={14}>
                        <Form.Item
                          name="amount"
                          label="Amount (Included Vat)"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Amount (Included Vat) is Required",
                            },
                          ]}
                        >
                          <InputNumber
                            controls={false}
                            // formatter={(value) =>
                            //   value ? formatNumber(Number(value)) : ""
                            // }
                            // onKeyPress={inputNumberCheck}
                            placeholder="Amount (Included Vat)"
                            min={0}
                            formatter={formatNumber}
                            className="FieldInputStyle"
                            onChange={(value) => setAmount(Number(value))}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} sm={4}>
                        <Form.Item
                          name="currency"
                          label="Currency"
                          initialValue={defaultCurrency}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Currency is Required",
                            },
                          ]}
                        >
                          <Select
                            placeholder="- Select -"
                            options={currencyData}
                            showSearch
                            filterOption={filterOption}
                            onChange={setCurrency}
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} sm={6}>
                        <Form.Item
                          name="isCalculateTax"
                          valuePropName="checked"
                          label=" "
                        >
                          <Checkbox
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            onChange={(e) =>
                              form.validateFields([
                                ["invoiceDate"],
                                ["taxInvoiceNo"],
                              ])
                            }
                          >
                            Calculate Tax
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {currency === "THB" || (
                        <Col span={24} sm={14}>
                          <Form.Item
                            name="exchangeRate"
                            label="Exchange Rate"
                            required
                            rules={[
                              {
                                required: true,
                                message: "Exchange Rate is Required",
                              },
                            ]}
                          >
                            <InputNumber
                              controls={false}
                              placeholder="Exchange Rate"
                              formatter={formatNumber}
                              className="FieldInputStyle"
                              onChange={(value) =>
                                setExchangeRate(Number(value))
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={24} sm={14}>
                        <Form.Item label="Local Current Amount">
                          <InputNumber
                            value={localCurrentAmount}
                            controls={false}
                            disabled
                            formatter={formatNumber}
                            placeholder="Local Current Amount"
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="emailInfo" label="E-mail Info">
                          <Select
                            mode="tags"
                            placeholder="E-mail Info"
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="prHeaderNote"
                          label="Header Text"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Header Text is Required",
                            },
                          ]}
                        >
                          <TextArea
                            placeholder="เรื่องที่ขออนุมัติ"
                            className="FieldInputStyle"
                            maxLength={1000}
                            showCount
                            autoSize={{ minRows: 3, maxRows: 10 }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="paymentDesc"
                          label="Payment Description"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Payment Description is Required",
                            },
                          ]}
                        >
                          <TextArea
                            showCount
                            maxLength={500}
                            placeholder="รายละเอียดการขออนุมัติ"
                            className="FieldInputStyle"
                            autoSize={{ minRows: 3, maxRows: 10 }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} sm={14}>
                        <Form.Item
                          name="dueDate"
                          label="Due Date"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Due Date is Required",
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD-MM-YYYY"
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="isUrgent" valuePropName="checked">
                          <Checkbox
                            value={isUrgent}
                            onChange={(event) =>
                              setIsUrgent(event.target.checked)
                            }
                          >
                            Urgent
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24} md={10} lg={10} xl={{ span: 10, offset: 2 }}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Text strong>ATTACHEDS</Text>
                  </Col>
                  <Col span={24}>
                    <div style={{ marginBottom: "15px" }}>
                      <Form.Item
                        name="dragger"
                        valuePropName="value"
                        getValueFromEvent={getFile}
                        noStyle
                      >
                        <Upload.Dragger
                          beforeUpload={() => false}
                          fileList={fileList}
                          onChange={(info: UploadChangeParam<UploadFile>) => {
                            onUploadFileChange(info.file as RcFile);
                          }}
                          showUploadList={false}
                          multiple
                        >
                          <Text>Drag files here or </Text>
                          <br />
                          <Text strong style={{ color: "#174291" }}>
                            Choose files from your computer
                          </Text>
                        </Upload.Dragger>
                      </Form.Item>
                    </div>
                    <FileList fileList={fileList} onDeleteFile={onDeleteFile} />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Text strong>DETAILS</Text>
              </Col>
              <Col span={24} ref={dataTableRef}>
                <TableImmediatelyDetail
                  isAllApproved={false}
                  isCalculateTax={isCalculateTaxValue}
                  dataTable={itemsPrepareDetail}
                  selectedAccount={selectedAccount}
                  isSameVendor={isSameVendor}
                  currentTaxInvoice={taxInvoiceNoValue}
                  currentAccount={selectedAccount || ""}
                  currentVendor={selectedVendor || ""}
                  currentInvoiceDate={
                    invoiceDateValue ? moment(invoiceDateValue) : ""
                  }
                  optionsVendor={vendorData}
                  optionsAccount={accountAssign}
                  dataSourceVendor={dataSourceVendor}
                  dataSourceAccountAssign={dataSourceAccountAssign}
                  setDataTable={setDataTable}
                />
              </Col>
            </Row>
          </Form>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Text strong>TOTAL</Text>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                <Col span={24} sm={{ span: 12, offset: 6 }}>
                  <Form>
                    <Form.Item
                      label="Total Amount"
                      colon
                      className="FormItemInputStyle"
                    >
                      <InputNumber
                        value={getTotalAmount}
                        disabled
                        formatter={formatNumber}
                        placeholder="Total Amount"
                        className="FieldInputStyle"
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24} sm={{ span: 12, offset: 6 }}>
                  <Form>
                    <Form.Item
                      label="Total Tax Amount"
                      colon
                      className="FormItemInputStyle"
                    >
                      <InputNumber
                        controls={false}
                        onChange={(value) => {
                          setVat(value);
                        }}
                        value={vatValue}
                        formatter={formatNumber}
                        placeholder="Total Tax Amount"
                        className="FieldInputStyle"
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24} sm={{ span: 12, offset: 6 }}>
                  <Form>
                    <Form.Item
                      label="Grand Total"
                      colon
                      className="FormItemInputStyle"
                    >
                      <InputNumber
                        value={getTotalAmount + vatValue}
                        disabled
                        formatter={formatNumber}
                        placeholder="Grand Total"
                        className="FieldInputStyle"
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      {visibleWarningModal && (
        <CustomModal
          visible={visibleWarningModal}
          setVisible={setVisibleWarningModal}
          title="Warning"
          content={contentWarningModal}
          footer={footerWarningModal}
        />
      )}
      {visibleWarning2Modal && (
        <CustomModal
          visible={visibleWarning2Modal}
          setVisible={setVisibleWarning2Modal}
          title="Warning"
          content={contentWarning2Modal}
          footer={footerWarning2Modal}
        />
      )}
    </Spin>
  );
};

export default Component;
