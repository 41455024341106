/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement } from "react";
import { Modal, Input, Typography } from "antd";
// import { EyeFilled } from "@ant-design/icons";
// import { FileIcon } from "../CustomIcon";
// import { Payment } from "../../interfaces/payment.interface";
import "./index.css";

const { TextArea } = Input;
const { Title, Text } = Typography;

export interface ComponentProps<T extends unknown> {
  visible: boolean | string;
  setVisible: (val: boolean) => void;
  title: string;
  content: ReactElement;
  footer: ReactElement;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Component = <T extends object>({
  visible,
  setVisible,
  title,
  content,
  footer,
}: ComponentProps<T>): ReactElement => {
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Modal
        title={<Text strong>{title}</Text>}
        visible={visible as boolean}
        footer={footer}
        onCancel={handleCancel}
        className="custom-modal"
      >
        {content}
      </Modal>
    </>
  );
};

export default Component;
