import {
  PaperClipOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Tooltip, Space, Typography, Row, Button, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
// import moment from "moment";
import Highlighter from "react-highlight-words";
import { SetStateAction } from "react";
import { Exclamation } from "../../components/CustomIcon";
import UserStatusRow from "../../components/UserStatusRow";
import formatData from "../../libs/formatData";
import { getPaymentListMemoPreview } from "../../api/prmemo";
import { RequestList } from "../../interfaces/paymentList.interface";

interface ColumnsProps {
  searchText: string;
  setLoading: (value: SetStateAction<boolean>) => void;
  setVisibleDrawer: (value: boolean) => void;
  setVisibleRejectModal: (value: boolean) => void;
  setVisibleApproveModal: (value: boolean) => void;
  setSelectRequestListPayment: (record: RequestList) => void;
}

export const renderPRAdminApproveListColumns = ({
  searchText,
  setLoading,
  setVisibleDrawer,
  setVisibleRejectModal,
  setVisibleApproveModal,
  setSelectRequestListPayment,
}: ColumnsProps): ColumnsType<RequestList> => {
  return [
    {
      title: "Status",
      width: 60,
      render: (record) => (
        <div style={{ display: "flex" }}>
          {record.isUrgent && (
            <Tooltip title="Urgent">
              <Exclamation />
            </Tooltip>
          )}
          {/* {moment().format("YYYY-MM-DD") > record.dueDate && (
            <Tooltip title="Overdue">
              <Thunder style={{ marginRight: "5px" }} />
            </Tooltip>
          )} */}
        </div>
      ),
    },
    {
      title: "Request No./PR Number",
      dataIndex: "payNumber",
      width: 110,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Memo No.",
      dataIndex: "memoNo",
      width: 100,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Header Note",
      dataIndex: "prHeaderNote",
      width: 150,
      render: (value: string, record) => (
        <>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchText]}
            // autoEscape={true}
            textToHighlight={value}
            className="new-line"
          />
          {record.isNonPo || (
            <Tag
              color="geekblue"
              style={{ marginLeft: "8px", borderRadius: "8px" }}
            >
              PO <CheckOutlined />
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 90,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "purchasingGroup",
      title: "Purchasing Group",
      dataIndex: "purGroup",
      width: 120,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "amount",
      title: "Amount",
      width: 110,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {`
            ${formatData.toCurrency(record.amount)} ${record.currency}
            `}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "item",
      title: "Items",
      width: 55,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {record.paymentInfoItem?.length || 0}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "files",
      title: "Attachment",
      width: 90,
      render: (record) => (
        <Space size="small">
          <PaperClipOutlined rotate={135} />
          <Typography.Text>
            {record.paymentInfoAttachHistory?.length || 0}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: "Approval Status",
      dataIndex: "accountText",
      width: 230,
      render: (value: string, row) => {
        return (
          <div style={{ zIndex: -99, overflowX: "scroll" }}>
            <UserStatusRow
              approvers={row.approvers}
              userInfo={row.sendApproveBy}
              isBanking={false}
            />
          </div>
        );
      },
    },
    {
      key: "action",
      title: "Detail/Reject/Approve",
      fixed: "right",
      width: 170,
      render: (record) => (
        <Row>
          <Tooltip placement="top" title="Detail">
            <Button
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={() => {
                setVisibleDrawer(true);
                setSelectRequestListPayment(record);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Preview Document">
            <Button
              type="text"
              icon={<FileSearchOutlined />}
              onClick={() => {
                setLoading(true);
                setSelectRequestListPayment(record);
                getPaymentListMemoPreview({
                  payId: record.payId,
                  sendApproveBy: String(record.sendApproveBy.userId) || "",
                  documentType: "PRWM",
                })
                  .then((result) => {
                    const file = new Blob([result], {
                      type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                  })
                  // eslint-disable-next-line no-console
                  .catch((error) => console.log("can't open file:", error))
                  .finally(() => setLoading(false));
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Reject">
            <Button
              type="text"
              icon={<CloseOutlined style={{ color: "#FF4D4E" }} />}
              onClick={() => {
                setSelectRequestListPayment(record);
                setVisibleRejectModal(true);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Approve">
            <Button
              type="text"
              icon={<CheckOutlined style={{ color: "#03703C" }} />}
              onClick={() => {
                setSelectRequestListPayment(record);
                setVisibleApproveModal(true);
              }}
            />
          </Tooltip>
        </Row>
      ),
    },
  ];
};
