import React, { FC } from "react";

const Component: FC = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1667 3.83333L11.9917 5.00833L14.1417 7.16667H5.66675V8.83333H14.1417L11.9917 10.9833L13.1667 12.1667L17.3334 8L13.1667 3.83333ZM2.33341 2.16667H9.00008V0.5H2.33341C1.41675 0.5 0.666748 1.25 0.666748 2.16667V13.8333C0.666748 14.75 1.41675 15.5 2.33341 15.5H9.00008V13.8333H2.33341V2.16667Z"
        fill="#141414"
      />
    </svg>
  );
};
export default Component;
