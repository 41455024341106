import { FC, useState } from "react";
import { Col, Divider, Row, Typography } from "antd";
import dayjs from "dayjs";
import formatData from "../../../libs/formatData";
import {
  PaymentInfoItemMemo,
  PRMemoResponseLists,
} from "../../../interfaces/prmemo.interface";
import { Company, PurchasingGroup } from "../../../interfaces/master.interface";

type Props = {
  data?: PRMemoResponseLists;
  dataCompany?: Company[];
  dataPurGroup?: PurchasingGroup[];
  item: PaymentInfoItemMemo;
  index: number;
};

const DetailItem: FC<Props> = ({
  data,
  item,
  index,
  dataCompany,
  dataPurGroup,
}: Props) => {
  const [showDetail, setShowDetail] = useState<boolean>();
  const company = dataCompany?.find(
    (comp) => comp.compId.toString() === item.companyCode
  );
  // const purGroup = dataPurGroup?.find(
  //   (pur) => pur.purGroupId === item.purGroup
  // );

  const getText = (text1?: string | number, text2?: string | number) => {
    if (text1 && text2) {
      return `${text1} ${text2}`;
    }
    return "-";
  };

  // const getTitleItemText = (
  //   text1?: string | number,
  //   text2?: string | number
  // ) => {
  //   if (text1 && text2) {
  //     return `${text1} - ${text2}`;
  //   } else if (!text2) {
  //     return `${text1}`;
  //   }
  //   return "-";
  // };

  const result = [
    {
      key: "Title",
      // title: getTitleItemText(item?.paymentText, item?.extendItemText) || "",
      title: item?.paymentText || "",
      value: formatData?.toCurrency(item.totalValue) || "-",
    },
    ...(item?.extendItemText !== ""
      ? [
          {
            key: "TitleExtend",
            title: item?.extendItemText || "",
            // value: formatData?.toCurrency(item.totalValue) || "-",
          },
        ]
      : []),
    {
      key: "Currency",
      title: "Currency:",
      value: item?.currency || "",
    },
    {
      key: "CalcTax",
      title: "Calc Tax:",
      value: `${item?.isCalculateTax ? "Yes" : "No"}`,
    },
    {
      key: "OrderNumber",
      title: "WBS/Order No:",
      value: item?.orderNumberWbs || item?.orderNumber || "-",
    },
    ...(showDetail
      ? [
          {
            key: "valuatoinPrice",
            title: "Valuation Price:",
            value: item?.valuationPrice || "-",
          },
          {
            key: "per",
            title: "Per:",
            value: getText(item.per, item.perUnit) || "-",
          },
          {
            key: "purOrg",
            title: "Purchasing Org:",
            value: getText(company?.companyId, company?.companyName) || "-",
          },
          {
            key: "purOrg",
            title: "Purchasing Grp:",
            value: getText(item?.purGroupId, item?.purGroupName) || "-",
          },
          {
            key: "deliveryDate",
            title: "Delivery Date:",
            value: dayjs(item.deliveryDate).format("DD.MM.YYYY") || "-",
          },
          {
            key: "trackNo",
            title: "Track No:",
            value: item?.trackNo || "-",
          },
          {
            key: "asset",
            title: "Asset:",
            value: item?.assetCode || "-",
          },
          // {
          //   key: "constCenter",
          //   title: "Cost Center:",
          //   value: item.cosCenter || "-",
          // },
          {
            key: "glAccount",
            title: "G/L Account",
            value: item?.glAccount || "-",
          },
          {
            key: "storageLoc",
            title: "Storage Loc",
            value: item?.storageLoCode || "-",
          },
          {
            key: "requestBy",
            title: "Requested By",
            // value: data?.createBy.email || "-",
            value: item?.requestedBy || "-",
          },
          {
            key: "desiredVendor",
            title: "Desired Vendor",
            // value: item?.desiredAccountText || "-",
            value: getText(
              item?.desiredAccountText
                ? parseInt(item?.desiredAccountNum, 10).toString()
                : "",
              item?.desiredAccountText
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      {result.map((items, idx) =>
        items.key === "TitleExtend" ? (
          <Row
            style={{
              backgroundColor: idx % 2 ? "#F6F6F6" : "#FFFFFF",
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <Col span={2} style={{ textAlign: "center" }}>
              {idx ? null : (
                <Typography.Text strong>{index + 1}</Typography.Text>
              )}
            </Col>
            <Col span={18}>
              <Typography.Text>{items.title}</Typography.Text>
            </Col>
            <Col span={4}>
              <Typography.Text>{items.value}</Typography.Text>
            </Col>
          </Row>
        ) : (
          <Row
            style={{
              backgroundColor: idx % 2 ? "#F6F6F6" : "#FFFFFF",
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <Col span={2} style={{ textAlign: "center" }}>
              {idx ? null : (
                <Typography.Text strong>{index + 1}</Typography.Text>
              )}
            </Col>
            <Col span={idx ? 8 : 18}>
              <Typography.Text>{items.title}</Typography.Text>
            </Col>
            <Col span={idx ? 14 : 4}>
              <Typography.Text>{items.value}</Typography.Text>
            </Col>
          </Row>
        )
      )}
      {data?.isNonPo || (
        <Col style={{ marginTop: "8px" }}>
          <Typography.Text
            strong
            onClick={() => setShowDetail(!showDetail)}
            style={{
              color: "#174291",
              marginLeft: "8%",
              cursor: "pointer",
            }}
          >
            {showDetail ? "Hide Detail" : "Show Detail"}
          </Typography.Text>
        </Col>
      )}
      {result.length - 1 !== index && <Divider />}
    </>
  );
};

DetailItem.defaultProps = {
  data: {} as PRMemoResponseLists,
  dataCompany: [] as Company[],
  dataPurGroup: [] as PurchasingGroup[],
};

export default DetailItem;
