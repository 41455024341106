/* eslint-disable no-nested-ternary */
import {
  EditOutlined,
  PlusOutlined,
  SwapOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Tag, Typography } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Retweet } from "../../assets/icon/retweet.svg";
import { ReactComponent as Save } from "../../assets/icon/save.svg";
// import { ReactComponent as Snippets } from "../../assets/icon/snippets.svg";
import { PaymentType } from "../../enum/banking.enum";

export interface ComponentProps {
  title: string;
  dataSizes?: number;
  timeUpdate?: string;
  loading?: boolean;
  isAllApproved?: boolean;
  paymentType?: string;
  isEnableAdmin?: boolean;
  disableITAdmin?: boolean;
  onClear?: () => void;
  onSaveRequest?: (value: string) => void;
  onSyncDataSource?: () => void;
}

const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Component = ({
  title,
  timeUpdate,
  dataSizes = 0,
  loading,
  isAllApproved = false,
  paymentType,
  isEnableAdmin = false,
  disableITAdmin = false,
  onClear = () => {},
  onSyncDataSource,
  onSaveRequest = () => {},
}: ComponentProps) => {
  const [timeLeft, setTimeLeft] = useState<string>("a few seconds ago");
  const location = useLocation();
  const history = useHistory();
  const pathList = useMemo(() => {
    const pathname = location.pathname.replace("/", "");
    return pathname;
  }, [location]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(moment(timeUpdate).fromNow());
    }, 60 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [timeUpdate]);

  const pathArray = pathList.split("/");

  const renderTitleHeaderRight = (
    isImmediately = false,
    isAdmin = false,
    isNonPO = false,
    onRelease = "release"
  ) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {!isAdmin && (
        <Button
          style={{
            display: "flex",
            alignContent: "center",
          }}
          disabled={disableITAdmin}
          onClick={() => {
            onClear();
          }}
        >
          <Text strong>Clear</Text>
        </Button>
      )}
      {/* {!disableITAdmin && (        
      )} */}
      <Button
        icon={
          <Save
            style={{
              color: "white",
              fontSize: "20px",
              marginRight: "10px",
            }}
          />
        }
        loading={loading}
        disabled={disableITAdmin}
        style={{
          marginLeft: "10px",
          backgroundColor: "#174291",
          display: "flex",
          alignContent: "center",
        }}
        onClick={() => {
          onSaveRequest("save");
        }}
        htmlType="submit"
      >
        <Text style={{ color: "white" }} strong>
          Save Request
        </Text>
      </Button>
      <Button
        icon={<PlusOutlined style={{ color: "white", fontSize: "20px" }} />}
        loading={loading}
        disabled={disableITAdmin}
        style={{
          marginLeft: "10px",
          backgroundColor: "#174291",
          display: "flex",
          alignContent: "center",
        }}
        onClick={() => {
          onSaveRequest(onRelease);
        }}
      >
        <Text style={{ color: "white" }} strong>
          {isAdmin
            ? "Release to Approve"
            : isNonPO
            ? "Release to Approve"
            : isImmediately
            ? "Release to Approve"
            : "Send to Admin"}
        </Text>
      </Button>
    </div>
  );

  const TitleRight = () => {
    switch (true) {
      case pathArray[0] === "requester" &&
        pathArray[1] === "immediately" &&
        !isAllApproved:
        return renderTitleHeaderRight(true, false, false);
      case isAllApproved:
        return <></>;
      case pathList.includes("requester/memo/withpo"):
        return renderTitleHeaderRight(false, false, false);
      case pathList.includes("requester/memo/nonpo"):
        return renderTitleHeaderRight(false, false, true);
      case pathList.includes("admin/memo"):
        return renderTitleHeaderRight(false, true, false, "admin_release");
      case pathList === "requester":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Text
              style={{
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.5)",
                marginRight: 16,
              }}
            >
              Last Updated: {timeLeft}
            </Text>
            <Button
              icon={<Retweet style={{ marginRight: "12px" }} />}
              style={{
                backgroundColor: "#545454",
                display: "flex",
                alignContent: "center",
              }}
              loading={loading}
              onClick={onSyncDataSource}
            >
              <Text style={{ color: "white" }} strong>
                Sync SAP
              </Text>
            </Button>
            {/* Hide Request Immediately */}
            <Button
              icon={
                <PlusOutlined style={{ color: "white", fontSize: "20px" }} />
              }
              style={{
                marginLeft: "10px",
                backgroundColor: "#174291",
                display: "flex",
                alignContent: "center",
              }}
              onClick={() => history.push("requester/immediately")}
            >
              <Text style={{ color: "white" }} strong>
                Request Immediately
              </Text>
            </Button>
          </div>
        );
      case pathList === "pr/requester":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              icon={<PlusOutlined style={{ fontSize: "20px" }} />}
              style={{
                marginLeft: "10px",
                backgroundColor: "white",
                display: "flex",
                alignContent: "center",
              }}
              onClick={() => history.push("requester/memo/nonpo")}
            >
              <Text strong>Create PR MEMO (without PO)</Text>
            </Button>
            <Button
              icon={
                <PlusOutlined style={{ color: "white", fontSize: "20px" }} />
              }
              style={{
                marginLeft: "10px",
                backgroundColor: "#174291",
                display: "flex",
                alignContent: "center",
              }}
              onClick={() => history.push("requester/memo/withpo")}
            >
              <Text style={{ color: "white" }} strong>
                Create PR Memo (With PO)
              </Text>
            </Button>
          </div>
        );
      case pathList === "banking":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Text
              style={{
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.5)",
                marginRight: 16,
              }}
            >
              Last Updated: {timeLeft}
            </Text>
            <Button
              icon={<Retweet style={{ marginRight: "12px" }} />}
              style={{
                backgroundColor: "#545454",
                display: "flex",
                alignContent: "center",
              }}
              loading={loading}
              onClick={onSyncDataSource}
            >
              <Text style={{ color: "white" }} strong>
                Sync SAP
              </Text>
            </Button>
          </div>
        );
      case pathList.includes("banking/detail"):
        return (
          <div>
            {/* {paymentType !== "CHEQUE" && isEnableAdmin && (
              <Button
                icon={
                  <SendOutlined style={{ color: "white", fontSize: "20px" }} />
                }
                loading={loading}
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#174291",
                  display: "flex",
                  alignContent: "center",
                }}
                onClick={() => {
                  onSaveRequest("release");
                }}
              >
                <Text style={{ color: "white" }} strong>
                  Release Payment
                </Text>
              </Button>
            )} */}
          </div>
        );
      default:
        return <></>;
    }
  };

  const TitleLeft = () => {
    switch (true) {
      case pathArray[0] === "requester" && pathArray[1] === "immediately":
        return (
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <Typography.Title level={4} style={{ marginRight: 16 }}>
              {title}
            </Typography.Title>
            {/* <Button
              icon={
                <Snippets
                  style={{
                    color: "white",
                    fontSize: "20px",
                    marginRight: "10px",
                  }}
                />
              }
              style={{
                display: "flex",
                alignContent: "center",
              }}
              onClick={() => {}}
            >
              <Text strong>Load Template</Text>
            </Button> */}
          </div>
        );
      case pathArray[0] === "banking" && pathArray[1] === "detail":
        return (
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <Typography.Title level={4} style={{ marginRight: 16 }}>
              {title}
            </Typography.Title>
            {paymentType === PaymentType.TRANSFER ? (
              <Tag style={{ borderRadius: "6px" }} color="blue">
                <SwapOutlined /> {PaymentType.TRANSFER_TEXT}
              </Tag>
            ) : (
              <Tag style={{ borderRadius: "6px" }} color="red">
                <EditOutlined /> {PaymentType.CHEQUE_TEXT}
              </Tag>
            )}
          </div>
        );
      default:
        return (
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <Typography.Title level={4} style={{ marginRight: 16 }}>
              {title}
            </Typography.Title>
            <Typography.Text>{`${dataSizes} item(s)`}</Typography.Text>
          </div>
        );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-between",
        marginBottom: 16,
      }}
    >
      <TitleLeft />
      <TitleRight />
    </div>
  );
};

export default Component;
