/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
  Upload,
  Select,
  DatePicker,
  Spin,
  message,
  Modal,
  notification,
  InputNumber,
  Button,
} from "antd";
import cloneDeep from "lodash/cloneDeep";
import dayjs from "dayjs";
import {
  UploadFile,
  RcFile,
  UploadChangeParam,
} from "antd/lib/upload/interface";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { PlusOutlined, SaveFilled } from "@ant-design/icons";
import isEmpty from "lodash/isEmpty";
import {
  AccountAssignment,
  Company,
  Currency,
  MaterialGroup,
  PurchasingGroup,
  Vendor,
} from "../../interfaces/master.interface";
import {
  getMasterAccountAssignment,
  getMasterCompany,
  getMasterCurrency,
  getMasterMaterialGroup,
  getMasterPurchasingGroup,
  getMasterVendor,
} from "../../api/master";
import FileList from "../../components/FileList";
import TableImmediatelyDetail from "../../components/DetailsTable";
import useAuthentication from "../../hooks/useAuthentication";
import { uploadFileList } from "../../api/service";
import { submitImmediately } from "../../api/requestImmediately";
import {
  FormRequestImmediately,
  PaymentInfoItem,
  RequestImmediately,
} from "../../interfaces/immediately.interface";
import { filterOption } from "../../libs/filterOptionSelect";
import { sendApprove } from "../../api/approval";
import { getPaymentListPayId } from "../../api/paymentList";
import "./index.css";
import {
  FileListUpload,
  ResponseAPIUploadFile,
} from "../../interfaces/uploadFile.interface";
import { checkLimitSize, getFileName } from "../../libs/file";
import CustomModal from "../../components/CustomModal";
import useOptions from "../../hooks/useOptions";

const { Text } = Typography;
const { TextArea } = Input;

interface UploadFileCustom {
  // eslint-disable-next-line react/require-default-props
  value?: File[];
  file: File;
  // eslint-disable-next-line react/require-default-props
  fileList: File[];
}

// interface SelectOption {
//   key: string;
//   label: string;
//   value: string;
// }

interface PrepareFileData {
  FileBase64: string;
  FileType: string;
  ExtensionFile: string;
}

const defaultCurrency = "THB";

const Component: FC = () => {
  const history = useHistory();
  const auth = useAuthentication();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [isDisaVendorInput, setIsDisableVendorInput] = useState<boolean>(true);
  const [visibleWarningModal, setVisibleWarningModal] =
    useState<boolean>(false);
  const [visibleWarning2Modal, setVisibleWarning2Modal] =
    useState<boolean>(false);
  const [saveType, setSaveType] = useState<string>("");
  const [form] = Form.useForm<FormRequestImmediately>();
  const accoIdValue = Form.useWatch<string>("accoId", form);
  const [vatValue, setVat] = useState<number>(0);
  const accountNumValue = Form.useWatch<string>("accountNum", form);
  const isSameVendorValue = Form.useWatch<boolean>("isSameVendor", form);
  const dueDateValue = Form.useWatch<string>("dueDate", form);
  const invoiceDateValue = Form.useWatch<string>("invoiceDate", form);
  const taxInvoiceNoValue = Form.useWatch<string>("taxInvoiceNo", form);
  const amountValue = Form.useWatch<number>("amount", form);
  const currencyValue = Form.useWatch<string>("currency", form);
  const isCalculateTaxValue = Form.useWatch<boolean>("isCalculateTax", form);
  const exchangeRateValue = Form.useWatch<number>("exchangeRate", form);
  const [payNumber, setPayNumber] = useState<string>("");
  const [isDisabledITAdmin, setIsDisabledITAdmin] = useState<boolean>(false);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);
  const [dataSourcePurchasingGroup, setDataSourcePurchasingGroup] = useState<
    PurchasingGroup[]
  >([]);
  const [dataSourceAccountAssign, setDataSourceAccountAssign] = useState<
    AccountAssignment[]
  >([]);
  const [dataSourceMaterialGroup, setDataSourceMaterialGroup] = useState<
    MaterialGroup[]
  >([]);
  const [dataSourceVendor, setDataSourceVendor] = useState<Vendor[]>([]);
  const [dataSourceCurrency, setDataSourceCurrency] = useState<Currency[]>([]);

  const dataTableRef = useRef<HTMLDivElement>(null);

  const {
    companies,
    purchasingGroups,
    accountAssign,
    expenseType,
    vendorData,
    currencyData,
  } = useOptions({
    dataSourceCompany,
    dataSourcePurchasingGroup,
    dataSourceAccountAssign,
    dataSourceMaterialGroup,
    dataSourceVendor,
    dataSourceCurrency,
  });

  notification.config({
    maxCount: 1,
    duration: 0,
    placement: "top",
  });

  const [fileList, setFileList] = useState<
    Array<ResponseAPIUploadFile | FileListUpload>
  >([]);
  const [dataTable, setDataTable] = useState<PaymentInfoItem[]>([]);
  const [itemsPrepareDetail, setItemsPrepareDetail] = useState<
    PaymentInfoItem[]
  >([]);

  const params: { payId: string } = useParams();

  const isDisabled = useMemo(() => {
    return false;
  }, []);

  const getBase64Upload = (
    file: RcFile,
    callback: { (value: string | ArrayBuffer | null): void }
  ) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const localCurrentAmount = useMemo(() => {
    if (!amountValue) {
      return null;
    }

    if (currencyValue === "THB") {
      if (isCalculateTaxValue) {
        return amountValue;
      }

      return amountValue;
    }

    if (isCalculateTaxValue) {
      return amountValue * exchangeRateValue;
    }

    return amountValue * exchangeRateValue;
  }, [amountValue, currencyValue, exchangeRateValue, isCalculateTaxValue]);

  const formatNumber = (value: number | undefined) => {
    if (isEmpty(value)) {
      return "";
    }
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 4,
    }).format(Number(value || 0));
  };

  const getTotalAmount: number = useMemo(() => {
    return dataTable
      .filter((value: PaymentInfoItem) => !value.isDeleted)
      .reduce((acc, cur) => {
        // const setNewAmount = cur.isCalculateTax
        //   ? cur.totalValue - (cur.totalValue * 7) / 107
        //   : cur.totalValue;
        const setNewAmount = cur.isCalculateTax
          ? (cur.totalValue * 100) / 100
          : cur.totalValue;
        return acc + setNewAmount;
      }, 0);
  }, [dataTable]);

  const getTotalTaxAmount: number = useMemo(() => {
    return dataTable
      .filter((value: PaymentInfoItem) => !value.isDeleted)
      .reduce((acc, cur) => {
        const allTax = cur.isCalculateTax
          ? Math.round(cur.totalValue * 7) / 100
          : 0;
        return acc + allTax;
      }, 0);
  }, [dataTable]);

  useEffect(() => {
    setVat(Number(getTotalTaxAmount.toFixed(2)));
  }, [getTotalTaxAmount]);

  const goBackRequesterList = useCallback(() => {
    notification.destroy();
    history.replace("/requester");
  }, [history]);

  useEffect(() => {
    setLoading(true);
    getPaymentListPayId(params.payId)
      .then(async (responseDocument) => {
        const document = responseDocument.responseData[0];

        await Promise.all([
          getMasterCompany(auth?.userId),
          getMasterPurchasingGroup(auth?.userId),
          getMasterAccountAssignment(auth?.userId),
          getMasterMaterialGroup(auth?.userId),
          getMasterVendor(document.compId),
          getMasterCurrency(),
        ])
          .then(
            ([
              responseCompany,
              responsePurchasingGroup,
              responseAccountAssignment,
              responseMaterialGroup,
              responseVendor,
              responseCurrency,
            ]) => {
              setDataSourceCompany(responseCompany.responseData);
              setDataSourcePurchasingGroup(
                responsePurchasingGroup.responseData
              );
              setDataSourceAccountAssign(
                responseAccountAssignment.responseData
              );
              setDataSourceMaterialGroup(responseMaterialGroup.responseData);
              setDataSourceVendor(responseVendor.responseData);
              setDataSourceCurrency(responseCurrency.responseData);

              const companyObject = responseCompany.responseData.find(
                (item) => item.compId === document.compId
              );
              const purchaseObject = responsePurchasingGroup.responseData.find(
                (item) => item.purgId === document.purgId
              );
              const accountObject = responseAccountAssignment.responseData.find(
                (item) => item.accoId === document.accoId
              );
              const materialObject = responseMaterialGroup.responseData.find(
                (item) => item.matgId === document.matgId
              );
              const vendorObject = responseVendor.responseData.find(
                (item) => item.accountNum === document.accountNum
              );

              const prepareDetail: PaymentInfoItem[] = (
                document.paymentInfoItem || []
              ).map((item, index) => {
                return {
                  accoId: item?.accoId,
                  accountAssignCategory: item?.accountAssignCategory,
                  accountAssignCode: item.accountAssignCode
                    ? item.accountAssignCode
                    : "",
                  accountAssignCategoryId: item?.accountAssignCategory,
                  accountNum: item.accountNum ? item.accountNum : "",
                  accountText: item.accountText ? item.accountText : "",
                  companyCode: item.companyCode ? item.companyCode : "",
                  currency: item?.currency,
                  docYear: item.docYear ? item.docYear : "",
                  exchangeRate: item?.exchangeRate,
                  isCalculateTax:
                    item?.isCalculateTax === null
                      ? false
                      : item?.isCalculateTax,
                  invoiceDate: item?.invoiceDate
                    ? moment(item?.invoiceDate).format()
                    : "",
                  taxInvoiceNo: item.taxInvoiceNo ? item.taxInvoiceNo : "",
                  key: index,
                  materailCode: item.materailCode ? item.materailCode : "",
                  materialGroup: item?.materialGroup,
                  matgId: item?.matgId,
                  orderName: item.orderName ? item.orderName : "",
                  orderNumber: item.orderNumber ? item.orderNumber : "",
                  orderNumberWbs: item?.orderNumberWbs,
                  orderNumberWbsdesc: item?.orderNumberWbsdesc,
                  payItemId: item?.payItemId,
                  paymentItem: item?.paymentItem,
                  paymentText: item?.paymentText,
                  purGroup: `${item?.purGroup}`,
                  taxCode: item?.taxCode || "",
                  totalValue: item?.totalValue,
                  prNo: item?.prNo || "",
                  poNo: item?.poNo || "",
                  fiDocNo: item.fiDocNo ? item.fiDocNo : "",
                  isExceptionBudget: item?.isExceptionBudget,
                };
              });
              setItemsPrepareDetail(prepareDetail);

              const prepareFile = document.paymentInfoAttach?.map((element) => {
                const fileData: any = {
                  payAttcId: element.payAttcId,
                  filePath: element.filePath,
                  name: element.filename,
                  filename: element.filename,
                  fileExtension: element.fileExtension,
                  readOnly: element.readOnly,
                  isDelete: element.isDelete,
                };
                return fileData;
              });

              setFileList(prepareFile || []);
              if (vendorObject?.accountNum.substring(0, 2) === "29") {
                setIsDisableVendorInput(false);
              }
              const firstSpace = document.accountText.indexOf(" ");
              const accountTextSplit = document.accountText.substring(
                firstSpace + 1,
                document.accountText.length
              );
              setPayNumber(document.payNumber);
              setIsDisabledITAdmin(
                (auth?.userId.toString() || "") !== document.createBy
              );
              form.setFieldsValue({
                vat: document.vat,
                payNumber: document.payNumber,
                compId: document.compId.toString(),
                company: companyObject?.companyId,
                companyName: companyObject?.abbr,
                _ShowCompany: companyObject?.companyName,
                purgId: purchaseObject?.purgId.toString(),
                purGroup: purchaseObject?.purGroupId,
                purGroupName: purchaseObject?.purGroupName,
                _ShowPurchasingGroup: purchaseObject?.purGroupName,
                accoId: accountObject?.accoId.toString(),
                accountAssignCategory: accountObject?.accAssName,
                accountAssignCategoryId: accountObject?.accAssId,
                _ShowAccountAssignment: accountObject?.accAssName,
                matgId: materialObject?.matgId.toString(),
                matGroup: materialObject?.materialGroupName,
                _ShowMaterialGroup: materialObject?.materialGroupName,
                accountNum: vendorObject?.accountNum.toString(),
                accountText: accountTextSplit,
                _ShowVendor: vendorObject?.accountText,
                isSameVendor: document?.isSameVendor,
                invoiceDate: document.invoiceDate
                  ? moment(document?.invoiceDate)
                  : undefined,
                taxInvoiceNo: document?.taxInvoiceNo || undefined,
                amount: document?.amount,
                currency: document?.currency,
                isCalculateTax: document?.isCalculateTax,
                exchangeRate: document?.exchangeRate,
                _ShowLocalCurrentAmount: 0, // TODO
                emailInfo: document?.emailInfo
                  ? document?.emailInfo.split(";")
                  : undefined,
                prHeaderNote: document?.prHeaderNote,
                paymentDesc: document?.paymentDesc,
                dueDate: document?.dueDate
                  ? moment(document?.dueDate)
                  : undefined,
                isUrgent: document?.isUrgent,
                assigment: document?.assigment ? document.assigment : "",
                reference: document?.reference ? document.reference : "",
                docYear: document?.docYear ? document.docYear : "",
                paymentRef: document?.paymentRef ? document.paymentRef : "",
              });
              setVat(document.vat ? document?.vat : 0);
            }
          )
          .catch(() => {
            notification.error({
              message: "ไม่สามารถโหลดข้อมูลที่เกี่ยวข้องได้",
              description:
                "กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่ที่เกี่ยวข้อง",
              onClose: goBackRequesterList,
            });
          });
      })
      .catch(() => {
        notification.error({
          message: "ไม่พบเอกสาร",
          description: "กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่ที่เกี่ยวข้อง",
          onClose: goBackRequesterList,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [auth?.userId, form, goBackRequesterList, history, params.payId]);

  const onChangeCompany = (compId: string) => {
    const companyObject = dataSourceCompany.find(
      (item) => `${item.compId}` === compId
    );

    if (!companyObject) {
      notification.error({
        message: "เกิดปัญหาในการโหลดข้อมูล Company",
        description: "กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่ที่เกี่ยวข้อง",
        onClose: goBackRequesterList,
      });
      return;
    }

    form.setFieldsValue({
      company: companyObject?.companyId,
      companyName: companyObject?.abbr,
      _ShowCompany: companyObject?.companyName,
      accountNum: undefined,
      accountText: undefined,
      _ShowVendor: undefined,
    });

    setLoading(true);
    getMasterVendor(companyObject.compId)
      .then((responseVendor) => {
        setDataSourceVendor(responseVendor.responseData);
      })
      .catch(() => {
        notification.error({
          message: "เกิดปัญหาในการโหลดข้อมูล Vendor",
          description: "กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่ที่เกี่ยวข้อง",
          onClose: goBackRequesterList,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangePurchasingGroup = (purgId: string) => {
    const purchaseObject = dataSourcePurchasingGroup.find(
      (item) => `${item.purgId}` === purgId
    );

    if (!purchaseObject) {
      notification.error({
        message: "เกิดปัญหาในการโหลดข้อมูล Purchasing Group",
        description: "กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่ที่เกี่ยวข้อง",
        onClose: goBackRequesterList,
      });
      return;
    }

    form.setFieldsValue({
      purGroup: purchaseObject.purGroupId,
      purGroupName: purchaseObject.purGroupName,
      _ShowPurchasingGroup: purchaseObject.purGroupName,
    });
  };

  const onChangeAccountAssignment = (accoId: string) => {
    const accountObject = dataSourceAccountAssign.find(
      (item) => `${item.accoId}` === accoId
    );

    if (!accountObject) {
      notification.error({
        message: "เกิดปัญหาในการโหลดข้อมูล Budget Type",
        description: "กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่ที่เกี่ยวข้อง",
        onClose: goBackRequesterList,
      });
      return;
    }

    form.setFieldsValue({
      accountAssignCategory: accountObject?.accAssName,
      accountAssignCategoryId: accountObject?.accAssId,
      _ShowAccountAssignment: accountObject?.accAssName,
    });
  };

  const onChangeMaterialGroup = (matgId: string) => {
    const materialObject = dataSourceMaterialGroup.find(
      (item) => `${item.matgId}` === matgId
    );

    if (!materialObject) {
      notification.error({
        message: "เกิดปัญหาในการโหลดข้อมูล Expense Type",
        description: "กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่ที่เกี่ยวข้อง",
        onClose: goBackRequesterList,
      });
      return;
    }

    form.setFieldsValue({
      matGroup: materialObject?.materialGroupName,
      _ShowMaterialGroup: materialObject?.materialGroupName,
    });
  };

  const onChangeVendor = (accountNum: string) => {
    const vendorObject = dataSourceVendor.find(
      (item) => item.accountNum === accountNum
    );

    if (!vendorObject) {
      notification.error({
        message: "เกิดปัญหาในการโหลดข้อมูล Vendor",
        description: "กรุณาลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่ที่เกี่ยวข้อง",
        onClose: goBackRequesterList,
      });
      return;
    }

    form.setFieldsValue({
      accountText: vendorObject?.accountText,
      _ShowVendor: vendorObject?.accountText,
    });
    if (accountNum.substring(0, 2) === "29") {
      setIsDisableVendorInput(false);
    } else {
      setIsDisableVendorInput(true);
    }
  };

  const onChangeVendorName = (name: string) => {
    const newDataArr = dataSourceVendor.map((item) => {
      if (item.accountNum === accountNumValue)
        return {
          ...item,
          accountText: name,
        };

      return item;
    });
    setDataSourceVendor(newDataArr);
  };

  const onUploadFileChange = (file: RcFile): void => {
    if (file && checkLimitSize(file)) {
      notification.error({
        message: "ไม่สามารถอัพโหลดไฟล์ได้",
        description: "ไฟล์มีขนาดใหญ่เกินกว่า 30MB",
      });
      return;
    }

    getBase64Upload(file, (imageUrl: any) => {
      const prepareFile: any = file;

      prepareFile.rawName = getFileName(file.name);
      prepareFile.base64 = imageUrl;

      setFileList([...fileList, prepareFile] as any);
    });
  };

  const getFile = (file: UploadFileCustom) => {
    if (Array.isArray(file)) {
      return file;
    }
    return file && file.fileList;
  };

  const onClear = () => {
    form.resetFields();
    setFileList([]);
    setItemsPrepareDetail([]);
    setDataSourceVendor([]);
  };

  const onSaveRequest = useCallback(
    async (type: string, values: FormRequestImmediately) => {
      const companyObject = dataSourceCompany.find(
        (item) => `${item.compId}` === values.compId
      );
      const purchaseObject = dataSourcePurchasingGroup.find(
        (item) => `${item.purgId}` === values.purgId
      );
      const accountObject = dataSourceAccountAssign.find(
        (item) => `${item.accoId}` === values.accoId
      );
      const materialObject = dataSourceMaterialGroup.find(
        (item) => `${item.matgId}` === values.matgId
      );
      const vendorObject = dataSourceVendor.find(
        (item) => `${item.accountNum}` === values.accountNum
      );

      const prepareDetail: PaymentInfoItem[] = dataTable
        .filter((item) => {
          if (item.isDeleted) {
            if (!item.payItemId) {
              return false;
            }
          }
          return true;
        })
        .map((item) => {
          return {
            payItemId: item.payItemId,
            accoId: accountObject?.accoId || 0,
            accountAssignCategory: accountObject?.accAssName || "",
            accountAssignCategoryId: accountObject?.accAssId || "",
            paymentItem: item.paymentItem,
            paymentText: item.paymentText,
            totalValue: item.totalValue,
            exchangeRate: values.exchangeRate || 0,
            currency: values.currency,
            purGroup: purchaseObject?.purGroupId || "",
            companyCode: companyObject?.companyId || "",
            matgId: materialObject?.matgId || 0,
            materailCode: `${materialObject?.materialGroupId}`,
            materialGroup: materialObject?.materialGroupName || "",
            accountAssignCode: item.accountAssignCode,
            orderNumberWbs: item.orderNumberWbs,
            orderNumberWbsdesc: item.orderNumberWbsdesc,
            orderNumber: item.orderNumber,
            orderName: item.orderName,
            taxCode: item.taxCode || "",
            docYear: item.docYear,
            accountText: item.accountText,
            accountNum: item.accountNum,
            isCalculateTax: item.isCalculateTax,
            prNo: item.prNo,
            poNo: item.poNo,
            fiDocNo: item.fiDocNo,
            taxInvoiceNo: item.taxInvoiceNo,
            invoiceDate: item.invoiceDate,
            isExceptionBudget: item.isExceptionBudget || false,
            isDelete: item.isDeleted,
          };
        });

      const prepareData: RequestImmediately = {
        vat: vatValue,
        payId: parseInt(params.payId, 10),
        referPayId: 0,
        compId: companyObject?.compId || 0,
        company: companyObject?.companyId || "",
        companyName: companyObject?.abbr || "",
        purgId: purchaseObject?.purgId || 0,
        purGroup: purchaseObject?.purGroupId || "",
        purGroupName: purchaseObject?.purGroupName || "",
        accoId: accountObject?.accoId || 0,
        accountAssignCategory: accountObject?.accAssName || "",
        accountAssignCategoryId: accountObject?.accAssId || "",
        matgId: materialObject?.matgId || 0,
        matGroup: materialObject?.materialGroupId || "",
        dueDate: values.dueDate.format(),
        invoiceDate: values.invoiceDate?.format() || "",
        taxInvoiceNo: values.taxInvoiceNo,
        createBy: Number(auth?.userId),
        emailInfo: values.emailInfo?.join(";") || null,
        prHeaderNote: values.prHeaderNote,
        accountText: values.accountText,
        accountNum: vendorObject?.accountNum || "",
        isSameVendor: isSameVendorValue,
        isCalculateTax: values.isCalculateTax,
        amount: values.amount || undefined,
        exchangeRate: values.exchangeRate || 0,
        currency: values.currency,
        isImmediately: true,
        isUrgent: values.isUrgent,
        assigment: values.assigment,
        paymentDesc: values.paymentDesc,
        docType: "KA",
        reference: values.reference,
        docYear: values.docYear,
        paymentRef: values.paymentRef,
        paymentInfoItem: prepareDetail,
        paymentInfoAttach: [],
      };

      setLoading(true);
      setLoadingButton(true);

      const fileNewUpload = fileList.filter((item) => item instanceof File);
      const fileUploaded = fileList.filter((item) => !(item instanceof File));

      await Promise.all(
        fileNewUpload.map((file: any) => {
          const FileName = file.rawName;
          const ExtensionFile = file.fileExtension;

          const prepareFileData: PrepareFileData = {
            FileBase64: file.base64,
            FileType: FileName,
            ExtensionFile,
          };

          return uploadFileList(prepareFileData)
            .then((res) => {
              return {
                fileName: res.responseData[0].fileName,
                filePath: res.responseData[0].filePath,
                ExtensionFile,
              };
            })
            .catch((err) => {
              return err;
            });
        })
      )
        .then(async (responseFile) => {
          const paymentInfoAttach = [
            ...fileUploaded.map((item: any) => {
              return {
                payAttcId: item.payAttcId,
                filePath: item.filePath,
                filename: item.filename,
                fileExtension: item.fileExtension,
                readOnly: false,
                isDelete: item.isDelete || false,
              };
            }),
            ...responseFile.map((item) => {
              return {
                payAttcId: 0,
                filePath: item.filePath,
                filename: item.fileName,
                fileExtension: item.ExtensionFile,
                readOnly: false,
                isDelete: false,
              };
            }),
          ];
          await submitImmediately({
            ...prepareData,
            paymentInfoAttach,
          })
            .then((result: any) => {
              if (type === "release") {
                sendApprove({
                  payId: [result.responseData[0].payId],
                  sendApproveBy: `${auth?.userId}`,
                }).then(() => {
                  Modal.success({
                    content: `ส่งข้อมูลเพื่อขออนุมัติสำเร็จ Pay Request No คือ ${result.responseData[0].payNumber}`,
                    onOk() {
                      setLoadingButton(false);
                      history.push("/requester");
                    },
                  });
                });
              } else {
                Modal.success({
                  content: `บันทึกข้อมูลสำเร็จ Pay Request No คือ ${result.responseData[0].payNumber}`,
                  onOk() {
                    setLoadingButton(false);
                    history.push("/requester");
                  },
                });
              }
            })
            .catch(() => {
              setLoading(false);
              setLoadingButton(false);
              // Modal.error({
              //   title: `เกิดข้อผิดพลาด`,
              //   content: `โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
              // });
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [
      auth?.userId,
      dataSourceAccountAssign,
      dataSourceCompany,
      dataSourceMaterialGroup,
      dataSourcePurchasingGroup,
      dataSourceVendor,
      dataTable,
      fileList,
      history,
      isSameVendorValue,
      params.payId,
      vatValue,
    ]
  );

  const onSaveRequestValidate = (type: string) => {
    setSaveType(type);
    form
      .validateFields()
      .then(async (values: FormRequestImmediately) => {
        if (!dataTable.length) {
          message.error("กรุณากรอกข้อมูลในตารางให้ครบถ้วน", 10);
          if (dataTableRef.current) dataTableRef.current.scrollIntoView();
          return;
        }
        const vat = (getTotalAmount + vatValue) * 100;

        if (Math.round(vat) / 100 !== values.amount) {
          message.error("กรุณากรอกจำนวนเงินให้ถูกต้อง", 10);
          form.scrollToField("amount");
          return;
        }

        const vendorObject = dataSourceVendor.find(
          (item) => item.accountNum === values.accountNum
        );

        if (vendorObject) {
          if (invoiceDateValue) {
            const paymentTermWithInvoice = dayjs(invoiceDateValue).add(
              vendorObject.paymentTermDays,
              "day"
            );
            if (dayjs(dueDateValue).isBefore(invoiceDateValue, "day")) {
              message.error(
                "กรุณาระบุวันที่ Due Date ให้มากกว่าหรือเท่ากับวันที่ Invoice Date",
                10
              );
              return;
            }
            if (!dayjs(dueDateValue).isSame(paymentTermWithInvoice, "day")) {
              setVisibleWarningModal(true);
              return;
            }
          } else {
            const paymentTermWithEntry = dayjs().add(
              vendorObject.paymentTermDays,
              "day"
            );
            if (dayjs(dueDateValue).isBefore(dayjs(), "day")) {
              setVisibleWarning2Modal(true);
              return;
            }
            if (!dayjs(dueDateValue).isSame(paymentTermWithEntry, "day")) {
              setVisibleWarningModal(true);
              return;
            }
          }
        }
        onSaveRequest(type, values);
      })
      .catch((err) => {
        message.error("กรุณากรอกข้อมูลให้ครบถ้วน", 10);
        form.scrollToField(err.errorFields[0].name);
      });
  };

  const contentWarningModal = useMemo(() => {
    return (
      <div>
        <Typography.Text style={{ marginBottom: "20px" }}>
          วันที่ Due Date = {dayjs(dueDateValue)?.format("DD.MM.YYYY")}{" "}
          ไม่สอดคล้องกับ Payment Terms ต้องการดำเนินการต่อหรือไม่
        </Typography.Text>
      </div>
    );
  }, [dueDateValue]);

  const contentWarning2Modal = useMemo(() => {
    return (
      <div>
        <Typography.Text style={{ marginBottom: "20px" }}>
          กรุณาตรวจสอบ Due Date เนื่องจากระบุข้อมูลน้อยกว่า วันที่ปัจจุบัน
        </Typography.Text>
      </div>
    );
  }, []);

  const footerWarningModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button onClick={() => setVisibleWarningModal(false)}>Cancel</Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            console.log("success from modal", saveType, form.getFieldsValue());
            onSaveRequest(saveType, form.getFieldsValue());
            setVisibleWarningModal(false);
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [form, loading, onSaveRequest, saveType]);

  const footerWarning2Modal = useMemo(() => {
    return (
      <Row justify="end">
        <Button onClick={() => setVisibleWarning2Modal(false)}>Cancel</Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            console.log("success from modal", saveType, form.getFieldsValue());
            onSaveRequest(saveType, form.getFieldsValue());
            setVisibleWarning2Modal(false);
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [form, loading, onSaveRequest, saveType]);

  const onDeleteFile = (fileIndex: number) => {
    const prepareFileList = fileList
      .filter((file, index) => !(file instanceof File && fileIndex === index))
      .map((item: any, index) => {
        return {
          ...item,
          isDelete: fileIndex === index ? true : item.isDelete,
        };
      });

    setFileList(cloneDeep(prepareFileList));
  };

  return (
    <Spin spinning={loading}>
      <div id="page-request-immediately-view">
        <Row justify="space-between" align="middle" className="container-title">
          <Col>
            <Typography.Title level={4}>Request Immediately</Typography.Title>
          </Col>
          {isDisabled || (
            <Col>
              <Row gutter={12}>
                <Col>
                  <Button disabled={isDisabledITAdmin} onClick={onClear}>
                    <Text strong>Clear</Text>
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    loading={loadingButton}
                    disabled={isDisabledITAdmin}
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "#174291",
                      display: "flex",
                      alignContent: "center",
                    }}
                    icon={
                      <SaveFilled
                        style={{
                          color: "white",
                          fontSize: "20px",
                          marginRight: "10px",
                        }}
                      />
                    }
                    onClick={() => {
                      onSaveRequestValidate("save");
                    }}
                  >
                    <Text strong>Save Request</Text>
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    loading={loadingButton}
                    disabled={isDisabledITAdmin}
                    icon={
                      <PlusOutlined
                        style={{ color: "white", fontSize: "20px" }}
                      />
                    }
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "#174291",
                      display: "flex",
                      alignContent: "center",
                    }}
                    onClick={() => {
                      onSaveRequestValidate("release");
                    }}
                  >
                    <Text strong>Release to Approve</Text>
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <div
          style={{
            borderRadius: "16px",
            padding: "32px",
            border: "1px solid #E2E2E2",
            boxSizing: "border-box",
          }}
        >
          <Row gutter={[32, 0]}>
            <Col span={24} md={14} lg={14} xl={12}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <Text strong>HEADER</Text>
                </Col>
                <Col span={6}>
                  <Text strong>Pay Request No.</Text>
                </Col>
                <Col span={10}>
                  <Text strong>{payNumber}</Text>
                </Col>
                <Col span={24}>
                  <Form form={form} layout="vertical">
                    <Row gutter={8}>
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} sm={8}>
                            <Form.Item
                              name="compId"
                              label="Company"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Company is Required",
                                },
                              ]}
                            >
                              <Select
                                disabled={isDisabled}
                                placeholder="- Select -"
                                options={companies}
                                showSearch
                                filterOption={filterOption}
                                className="FieldInputStyle"
                                onChange={onChangeCompany}
                              />
                            </Form.Item>
                            <Form.Item name="company" hidden>
                              <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="companyName" hidden>
                              <Input type="hidden" />
                            </Form.Item>
                          </Col>
                          <Col span={24} sm={16}>
                            <Form.Item label=" " name="_ShowCompany">
                              <Input disabled className="FieldInputStyle" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      {/** Purchasing Group */}
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} sm={8}>
                            <Form.Item
                              name="purgId"
                              label="Purchasing Group"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Purchasing Group is Required",
                                },
                              ]}
                            >
                              <Select
                                disabled={isDisabled}
                                placeholder="- Select -"
                                options={purchasingGroups}
                                showSearch
                                filterOption={filterOption}
                                className="FieldInputStyle"
                                onChange={onChangePurchasingGroup}
                              />
                            </Form.Item>
                            <Form.Item name="purGroup" hidden>
                              <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="purGroupName" hidden>
                              <Input type="hidden" />
                            </Form.Item>
                          </Col>
                          <Col span={24} sm={16}>
                            <Form.Item label=" " name="_ShowPurchasingGroup">
                              <Input disabled className="FieldInputStyle" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      {/** Account Assignment */}
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} sm={8}>
                            <Form.Item
                              name="accoId"
                              label="Budget Type"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Budget Type is Required",
                                },
                              ]}
                            >
                              <Select
                                disabled={isDisabled}
                                placeholder="- Select -"
                                options={accountAssign}
                                showSearch
                                filterOption={filterOption}
                                className="FieldInputStyle"
                                onChange={onChangeAccountAssignment}
                              />
                            </Form.Item>
                            <Form.Item name="accountAssignCategory" hidden>
                              <Input type="hidden" />
                            </Form.Item>
                            <Form.Item name="accountAssignCategoryId" hidden>
                              <Input type="hidden" />
                            </Form.Item>
                          </Col>
                          <Col span={24} sm={16}>
                            <Form.Item label=" " name="_ShowAccountAssignment">
                              <Input disabled className="FieldInputStyle" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} sm={8}>
                            <Form.Item
                              name="matgId"
                              label="Expense Type"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Expense Type is Required",
                                },
                              ]}
                            >
                              <Select
                                disabled={isDisabled}
                                placeholder="- Select -"
                                options={expenseType}
                                showSearch
                                filterOption={filterOption}
                                className="FieldInputStyle"
                                onChange={onChangeMaterialGroup}
                              />
                            </Form.Item>
                            <Form.Item name="matGroup" hidden>
                              <Input type="hidden" />
                            </Form.Item>
                          </Col>
                          <Col span={24} sm={16}>
                            <Form.Item label=" " name="_ShowMaterialGroup">
                              <Input disabled className="FieldInputStyle" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Row gutter={[8, 8]}>
                          <Col span={24} sm={8}>
                            <Form.Item
                              name="accountNum"
                              label="Vendor"
                              required
                              rules={[
                                {
                                  required: true,
                                  message: "Vendor is Required",
                                },
                              ]}
                            >
                              <Select
                                disabled={isDisabled}
                                placeholder="- Select -"
                                options={vendorData}
                                showSearch
                                filterOption={filterOption}
                                className="FieldInputStyle"
                                onChange={onChangeVendor}
                              />
                            </Form.Item>
                            <Form.Item name="accountText" hidden>
                              <Input type="hidden" />
                            </Form.Item>
                          </Col>
                          <Col span={24} sm={16}>
                            <Form.Item label=" " name="accountText">
                              <Input
                                disabled={isDisaVendorInput}
                                onChange={(e) =>
                                  onChangeVendorName(e.target.value)
                                }
                                className="FieldInputStyle"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="isSameVendor" valuePropName="checked">
                          <Checkbox disabled={isDisabled}>
                            Use same vendor in details
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24} sm={14}>
                        <Form.Item
                          name="invoiceDate"
                          label="Invoice Date"
                          required={isCalculateTaxValue}
                          rules={[
                            {
                              required: isCalculateTaxValue,
                              message: "Invoice Date is Required",
                            },
                          ]}
                        >
                          <DatePicker
                            disabled={isDisabled}
                            format="DD-MM-YYYY"
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} sm={14}>
                        <Form.Item
                          name="taxInvoiceNo"
                          label="Tax Invoice"
                          rules={[
                            {
                              required: isCalculateTaxValue,
                              message: "Tax Invoice is Required",
                            },
                          ]}
                        >
                          <Input
                            disabled={isDisabled}
                            placeholder="เลขที่ใบกำกับภาษี"
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} sm={14}>
                        <Form.Item
                          name="amount"
                          label="Amount (Included Vat)"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Amount (Included Vat) is Required",
                            },
                          ]}
                        >
                          <InputNumber
                            disabled={isDisabled}
                            placeholder="Amount (Included Vat)"
                            controls={false}
                            // formatter={(value) =>
                            //   value ? formatNumber(Number(value)) : ""
                            // }
                            // onKeyPress={inputNumberCheck}
                            min={0}
                            formatter={formatNumber}
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} sm={4}>
                        <Form.Item
                          name="currency"
                          label="Currency"
                          initialValue={defaultCurrency}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Currency is Required",
                            },
                          ]}
                        >
                          <Select
                            disabled={isDisabled}
                            placeholder="- Select -"
                            options={currencyData}
                            showSearch
                            filterOption={filterOption}
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} sm={6}>
                        <Form.Item
                          name="isCalculateTax"
                          valuePropName="checked"
                          label=" "
                        >
                          <Checkbox
                            disabled={isDisabled}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            onChange={(e) =>
                              form.validateFields([
                                ["invoiceDate"],
                                ["taxInvoiceNo"],
                              ])
                            }
                          >
                            Calculate Tax
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      {currencyValue === "THB" || (
                        <Col span={24} sm={14}>
                          <Form.Item
                            name="exchangeRate"
                            label="Exchange Rate"
                            required
                            rules={[
                              {
                                required: true,
                                message: "Exchange Rate is Required",
                              },
                            ]}
                          >
                            <InputNumber
                              disabled={isDisabled}
                              controls={false}
                              // formatter={(value) =>
                              //   value ? formatNumber(Number(value)) : ""
                              // }
                              // onKeyPress={inputNumberCheck}
                              min={0}
                              formatter={formatNumber}
                              placeholder="Exchange Rate"
                              className="FieldInputStyle"
                            />
                          </Form.Item>
                        </Col>
                      )}
                      {currencyValue === "THB" || (
                        <Col span={24} sm={14}>
                          <Form.Item
                            name="_ShowLocalCurrentAmount"
                            label="Local Current Amount"
                          >
                            <InputNumber
                              value={localCurrentAmount || undefined}
                              controls={false}
                              // formatter={(value) =>
                              //   value ? formatNumber(Number(value)) : ""
                              // }
                              formatter={formatNumber}
                              disabled
                              placeholder="Local Current Amount"
                              className="FieldInputStyle"
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={24}>
                        <Form.Item name="emailInfo" label="E-mail Info">
                          <Select
                            disabled={isDisabled}
                            mode="tags"
                            placeholder="E-mail Info"
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="prHeaderNote"
                          label="Header Text"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Header Text is Required",
                            },
                          ]}
                        >
                          <TextArea
                            disabled={isDisabled}
                            placeholder="เรื่องที่ขออนุมัติ"
                            className="FieldInputStyle"
                            maxLength={1000}
                            showCount
                            autoSize={{ minRows: 3, maxRows: 10 }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="paymentDesc"
                          label="Payment Description"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Payment Description is Required",
                            },
                          ]}
                        >
                          <TextArea
                            disabled={isDisabled}
                            showCount
                            maxLength={500}
                            placeholder="รายละเอียดการขออนุมัติ"
                            className="FieldInputStyle"
                            autoSize={{ minRows: 3, maxRows: 10 }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} sm={14}>
                        <Form.Item
                          name="dueDate"
                          label="Due Date"
                          required
                          rules={[
                            {
                              required: true,
                              message: "Due Date is Required",
                            },
                          ]}
                        >
                          {/* <DatePicker className="FieldInputStyle" /> */}
                          <DatePicker
                            disabled={isDisabled}
                            format="DD-MM-YYYY"
                            className="FieldInputStyle"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item name="isUrgent" valuePropName="checked">
                          <Checkbox disabled={isDisabled}>Urgent</Checkbox>
                        </Form.Item>
                        <Form.Item name="assigment" hidden>
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="reference" hidden>
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="docYear" hidden>
                          <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="paymentRef" hidden>
                          <Input type="hidden" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>

            <Col span={24} md={10} lg={10} xl={{ span: 10, offset: 2 }}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Text strong>ATTACHEDS</Text>
                </Col>
                <Col span={24}>
                  {isDisabled || (
                    <Form
                      style={{ marginBottom: "15px" }}
                      layout="vertical"
                      form={form}
                      initialValues={{ files: [] }}
                    >
                      <Form.Item
                        name="dragger"
                        valuePropName="value"
                        getValueFromEvent={getFile}
                        noStyle
                      >
                        <Upload.Dragger
                          beforeUpload={() => false}
                          fileList={fileList}
                          onChange={(info: UploadChangeParam<UploadFile>) => {
                            onUploadFileChange(info.file as RcFile);
                          }}
                          showUploadList={false}
                          multiple
                        >
                          <Text>Drag files here or </Text>
                          <br />
                          <Text strong style={{ color: "#174291" }}>
                            Choose files from your computer
                          </Text>
                        </Upload.Dragger>
                      </Form.Item>
                    </Form>
                  )}
                  <FileList
                    isDisabled={isDisabled}
                    fileList={fileList}
                    onDeleteFile={onDeleteFile}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Text strong>DETAILS</Text>
            </Col>
            <Col span={24} ref={dataTableRef}>
              <TableImmediatelyDetail
                isAllApproved={isDisabled}
                isCalculateTax={isCalculateTaxValue}
                isSameVendor={isSameVendorValue}
                currentInvoiceDate={
                  invoiceDateValue ? moment(invoiceDateValue) : ""
                }
                currentTaxInvoice={taxInvoiceNoValue}
                currentAccount={accoIdValue}
                currentVendor={accountNumValue}
                optionsVendor={vendorData}
                optionsAccount={accountAssign}
                dataSourceVendor={dataSourceVendor}
                dataSourceAccountAssign={dataSourceAccountAssign}
                dataTable={itemsPrepareDetail}
                selectedAccount={accoIdValue}
                setDataTable={setDataTable}
              />
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Text strong>TOTAL</Text>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                <Col span={24} sm={{ span: 12, offset: 6 }}>
                  <Form>
                    <Form.Item
                      label="Total Amount"
                      colon
                      className="FormItemInputStyle"
                    >
                      <InputNumber
                        // value={
                        //   dataTable.length > 0 ? getTotalAmount : undefined
                        // }
                        value={getTotalAmount}
                        disabled
                        formatter={formatNumber}
                        // formatter={(value) =>
                        //   value ? formatNumber(Number(value)) : ""
                        // }
                        placeholder="Total Amount"
                        className="FieldInputStyle"
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24} sm={{ span: 12, offset: 6 }}>
                  <Form>
                    <Form.Item
                      label="Total Tax Amount"
                      colon
                      className="FormItemInputStyle"
                    >
                      <InputNumber
                        controls={false}
                        onChange={(value) => {
                          setVat(value);
                        }}
                        value={vatValue}
                        formatter={formatNumber}
                        placeholder="Total Tax Amount"
                        className="FieldInputStyle"
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24} sm={{ span: 12, offset: 6 }}>
                  <Form>
                    <Form.Item
                      label="Grand Total"
                      colon
                      className="FormItemInputStyle"
                    >
                      <InputNumber
                        value={getTotalAmount + vatValue}
                        disabled
                        formatter={formatNumber}
                        placeholder="Grand Total"
                        className="FieldInputStyle"
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      {visibleWarningModal && (
        <CustomModal
          visible={visibleWarningModal}
          setVisible={setVisibleWarningModal}
          title="Warning"
          content={contentWarningModal}
          footer={footerWarningModal}
        />
      )}
      {visibleWarning2Modal && (
        <CustomModal
          visible={visibleWarning2Modal}
          setVisible={setVisibleWarning2Modal}
          title="Warning"
          content={contentWarning2Modal}
          footer={footerWarning2Modal}
        />
      )}
    </Spin>
  );
};

export default Component;
