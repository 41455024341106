import { AxiosError } from "axios";
import axios from "../libs/axios";
import {
  ResponseRequestList,
  PayloadRequestList,
  PayloadSendApprovePaymentList,
} from "../interfaces/paymentList.interface";
import { ResponseMemoList } from "../interfaces/prmemo.interface";
import { PayloadMemoAdminList } from "../interfaces/memoAdmin.interface";

export const getPaymentListPayId = (id: string): Promise<ResponseRequestList> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/paymentlist/${id}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getPaymentList = (id: string): Promise<ResponseRequestList> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/paymentlist/user/${id}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getPaymentListHistory = (
  payload: PayloadRequestList
): Promise<ResponseRequestList | ResponseMemoList> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history", payload)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const sendReCallPaymentList = (
  payload: PayloadSendApprovePaymentList
): Promise<ResponseRequestList> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/recall", payload)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getPaymentHistoryApprove = (
  payload: PayloadRequestList
): Promise<ResponseRequestList> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/log", payload)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getPaymentListMemoPayId = (
  id: string
): Promise<ResponseMemoList> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/paymentlist/memo/${id}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getPaymentListMemo = (id: string): Promise<ResponseMemoList> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/paymentlist/memo/user/${id}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getPaymentListMemoAdmin = (
  payload: Partial<PayloadMemoAdminList>
): Promise<ResponseMemoList> =>
  new Promise((resolve, reject) => {
    axios
      .post(`/approval/paymentlist/admin/`, payload)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getPaymentListMemoHistory = (
  payload: PayloadRequestList
): Promise<ResponseMemoList> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history", payload)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getPaymentListMemoAdminHistory = (
  payload: PayloadRequestList
): Promise<ResponseMemoList> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/log", payload)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
