/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Typography,
  Form,
  Spin,
  Row,
  Tabs,
  Input,
  Checkbox,
  notification,
} from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import moment from "moment";
import omitBy from "lodash/omitBy";
import { useHistory, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { RequestList } from "../../interfaces/paymentList.interface";
import TitleHeader from "../../components/TitleHeader";
import FilterInputTable from "../../components/FilterInputTable";
import { CustomDrawer } from "../../components/Drawer";
import { TabsHeader, TabsDetail } from "../../components/Drawer/TabsRequest";
import { TabsAttached } from "../../components/Drawer/Tabs";
import { ReactComponent as TeamLogo } from "../../assets/icon/team.svg";
import { ReactComponent as CompanyLogo } from "../../assets/icon/company.svg";
import {
  deletePaymentListById,
  sendApprove,
  undeletePaymentListById,
  updatePaymentListById,
} from "../../api/approval";
import { getPaymentList } from "../../api/paymentList";
import { Company, PurchasingGroup } from "../../interfaces/master.interface";
import { getMasterCompany, getMasterPurchasingGroup } from "../../api/master";
import CustomModal from "../../components/CustomModal";
import Save from "../../components/CustomIcon/Save";
import SendFilled from "../../components/CustomIcon/SendFilled";
import useAuthentication from "../../hooks/useAuthentication";
import DataTable from "../../components/DataTable/DataTableRequest";
import formatData from "../../libs/formatData";
import { syncsap, uploadFileList } from "../../api/service";
import {
  UploadFileRequest,
  UploadFileResponse,
} from "../../interfaces/serviceAPI.interface";
import { getFileExtension, getFileName } from "../../libs/file";
import {
  PayloadDeleteUndeletePaymentList,
  ResponseRequestListDeleteUndelete,
} from "../../interfaces/payment.interface";
import { renderRequestListColumns } from "./columns";
import { getBooleanFromQuery, getQueryObject } from "../../libs/query";
import useParseQuery from "../../hooks/useParseQuery";
import useOptions from "../../hooks/useOptions";
import { isImmediatelyDrop } from "../../libs/antd/options/optionsValue";
import { filterDataSource } from "../../libs/filter/filterInput";

dayjs.extend(isBetween);

const Component = (): JSX.Element => {
  const [errorPaymentDesc, setErrorPaymentDesc] = useState<
    "" | "success" | "warning" | "error" | "validating" | undefined
  >("");
  const auth = useAuthentication();
  const {
    queryimmediate,
    querypurgeid,
    querycompid,
    querydate,
    querydatetype,
    querysearch,
    replaceLocation,
  } = useParseQuery();
  const [dataSource, setDataSource] = useState<RequestList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [visibleSingleModal, setVisibleSingleModal] = useState<boolean>(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [visibleMutiModal, setVisibleMutiModal] = useState<boolean>(false);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [selectRequestListPayment, setSelectRequestListPayment] =
    useState<RequestList>({} as RequestList);
  const [dataSourcePurchasingGroup, setDataSourcePurchasingGroup] = useState<
    PurchasingGroup[]
  >([]);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);

  const [timeUpdate, setTimeUpdate] = useState<string>();
  const [searchText, setSearchText] = useState("");
  const [filterInput, setFilterInput] = useState<any | undefined>();
  const [onTableSelect, setOnTableSelect] = useState<React.Key[]>([]);
  const [saveFileList, setSaveFileList] = useState<any[]>([]);
  const [saveDeleteApi, setDeleteFileApi] = useState<any[]>([]);
  const history = useHistory();
  const location = useLocation();

  const { company, purchasingGroup } = useOptions({
    dataSourceCompany,
    dataSourcePurchasingGroup,
  });

  const columns = renderRequestListColumns({
    searchText,
    onClickEdit: (record: RequestList) =>
      history.push(`/requester/immediately/${record.payId}`),
    setVisibleDrawer,
    setVisibleSingleModal,
    setVisibleDeleteModal,
    setSelectRequestListPayment,
    setDeleteFileApi,
    setLoading,
  });

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([
        getPaymentList(auth.userId),
        getMasterPurchasingGroup(auth.userId),
        getMasterCompany(auth.userId),
      ])
        .then(([responsePaymentList, responsePurchasing, responseCompany]) => {
          setDataSource(responsePaymentList.responseData);
          setDataSourcePurchasingGroup(responsePurchasing.responseData);
          setDataSourceCompany(responseCompany.responseData);
          setTimeUpdate(moment().format());
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  const onSyncDataSource = useCallback(() => {
    if (auth) {
      setLoading(true);
      syncsap();
      Promise.all([getPaymentList(auth.userId)])
        .then(([responsePaymentList]) => {
          setDataSource(responsePaymentList.responseData);
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
        .finally(() => {
          setLoading(false);
          setTimeUpdate(moment().format());
        });
    }
  }, [auth]);

  const onUpdateDataSource = useCallback(() => {
    if (auth) {
      setLoading(true);
      Promise.all([getPaymentList(auth.userId)])
        .then(([responsePaymentList]) => {
          setDataSource(responsePaymentList.responseData);
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  const onPaymentSaveData = useCallback(
    (payload: RequestList) => {
      return new Promise((resolve, reject) => {
        try {
          const prepareFileList: UploadFileRequest[] = saveFileList.map(
            (file) => {
              const FileName = getFileName(file.name);
              const ExtensionFile = getFileExtension(file.name);

              return {
                FileBase64: file.base64,
                FileType: FileName,
                ExtensionFile,
              };
            }
          );

          Promise.all(
            prepareFileList.map(async (item: UploadFileRequest) =>
              uploadFileList(item)
            )
          )
            .then((response: UploadFileResponse[]) => {
              updatePaymentListById({
                payId: payload.payId,
                isUrgent: payload.isUrgent || false,
                apprType: "PA",
                paymentDesc: payload.paymentDesc || "",
                payAttc: [
                  ...saveDeleteApi.map((item) => {
                    return {
                      ...item,
                      readOnly: false,
                    };
                  }),
                  ...prepareFileList.map((item, index) => {
                    return {
                      payAttcId: 0,
                      filePath: response[index]?.responseData[0]?.filePath,
                      fileName: response[index]?.responseData[0]?.fileName,
                      fileExtension: item.ExtensionFile,
                      readOnly: false,
                      isDelete: false,
                    };
                  }),
                ],
                updateBy: auth?.userId.toString() || "",
              })
                .then((responseUpdate) => {
                  setDeleteFileApi([]);
                  resolve(responseUpdate);
                })
                .catch((error) => reject(error));
            })
            .catch((error) => reject(error));
        } catch (error) {
          reject(error);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth?.userId, dataSource, saveFileList, saveDeleteApi]
  );

  const onPaymentSendApprove = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        sendApprove({
          payId: payIds,
          sendApproveBy: auth?.userId.toString() || "",
        }),
      ]).then(() => {
        setDataSource(
          dataSource.filter((item) => !payIds.includes(item.payId))
        );
        setVisibleDrawer(false);
      });
    },
    [auth, dataSource]
  );

  const onPaymentDeleteUndelete = useCallback(
    (
      payload: PayloadDeleteUndeletePaymentList
    ): Promise<ResponseRequestListDeleteUndelete> => {
      if (selectRequestListPayment.isDelete) {
        return undeletePaymentListById(payload);
      }
      return deletePaymentListById(payload);
    },
    [selectRequestListPayment.isDelete]
  );

  const dataSourceWithFilter = useMemo(() => {
    // query string --> object
    const queryToObject = getQueryObject(filterInput, location.search);

    const filterValidated = {
      ...queryToObject,
      isImmediately: getBooleanFromQuery(
        queryToObject?.isImmediately as string
      ),
      compId: Number(queryToObject?.compId),
      purgId: Number(queryToObject?.purgId),
    };

    return filterDataSource(dataSource, filterValidated) as RequestList[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, filterInput]);

  useEffect(() => {
    const q = getQueryObject(filterInput, location.search);
    replaceLocation({ ...filterInput, page: 1, perPage: q.perPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput]);

  const titleDrawer = useMemo(() => {
    return (
      <Row align="middle">
        <InfoCircleOutlined />
        <Typography.Text style={{ paddingLeft: 10 }}>
          Information
        </Typography.Text>
      </Row>
    );
  }, []);

  const contentDrawer = useMemo(() => {
    const prHeaderNote = selectRequestListPayment?.prHeaderNote;
    const paymentDesc = selectRequestListPayment?.paymentDesc;
    const isUrgent = selectRequestListPayment?.isUrgent;

    return (
      <>
        <Row>
          <Typography.Title
            level={3}
            style={{
              width: "100%",
              paddingLeft: 24,
              paddingRight: 24,
            }}
            className="new-line"
          >
            {prHeaderNote}
          </Typography.Title>
        </Row>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Headers" key="1">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              spinning={loading}
            >
              <Form
                layout="vertical"
                style={{ paddingLeft: 24, paddingRight: 24 }}
              >
                <Form.Item
                  initialValue={paymentDesc}
                  name="paymentDesc"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Payment Description is Required",
                    },
                  ]}
                  label={
                    <Typography.Title level={5}>
                      Payment Description
                    </Typography.Title>
                  }
                >
                  <Input.TextArea
                    placeholder="Description"
                    showCount
                    rows={3}
                    maxLength={500}
                    onChange={(event) => {
                      setSelectRequestListPayment((prev) => {
                        return {
                          ...prev,
                          paymentDesc: event.target.value,
                        };
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item name="isUrgent">
                  <Checkbox
                    checked={isUrgent}
                    onChange={(event) => {
                      setSelectRequestListPayment((prev) => {
                        return {
                          ...prev,
                          isUrgent: event.target.checked,
                        };
                      });
                    }}
                  >
                    Urgent
                  </Checkbox>
                </Form.Item>
              </Form>
            </Spin>
            <TabsHeader data={selectRequestListPayment} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Details" key="2">
            <TabsDetail data={selectRequestListPayment} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attacheds" key="3">
            <TabsAttached
              data={Object.values(
                omitBy(selectRequestListPayment?.paymentInfoAttach, (value) => {
                  return saveDeleteApi.find(
                    (element) => element.payAttcId === value.payAttcId
                  );
                })
              )}
              // data={selectRequestListPayment?.paymentInfoAttach || []}
              setData={setDeleteFileApi}
              saveDeleteApi={saveDeleteApi}
              disabled={false}
              userId={auth?.userId}
              visibleDeleteButton
              setSaveFileList={setSaveFileList}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }, [auth?.userId, loading, saveDeleteApi, selectRequestListPayment]);

  const footerDrawer = useMemo(() => {
    return (
      <div className="drawer-footer">
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            Amount ({selectRequestListPayment.currency}):
          </Typography.Title>
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            <Typography.Text style={{ color: "#174291", paddingLeft: 5 }}>
              {formatData.toCurrency(selectRequestListPayment.amount)}
            </Typography.Text>
          </Typography.Title>
        </Row>
        <Row justify="start" align="middle">
          <Button
            loading={loading}
            disabled={
              (auth?.userId.toString() || "") !==
              selectRequestListPayment.createBy
            }
            icon={<Save color="#141414" />}
            style={{
              display: "flex",
              alignItems: "center",
              padding: 20,
              gap: 10,
            }}
            onClick={() => {
              setLoading(true);
              onPaymentSaveData(selectRequestListPayment).finally(() => {
                setLoading(false);
                setVisibleDrawer(false);
                onUpdateDataSource();
              });
            }}
          >
            <Typography.Text strong>Save</Typography.Text>
          </Button>
          <Button
            loading={loading}
            icon={<SendFilled />}
            disabled={
              (auth?.userId.toString() || "") !==
              selectRequestListPayment.createBy
            }
            style={{
              display: "flex",
              background: "#174291",
              alignItems: "center",
              padding: 20,
              gap: 10,
              marginLeft: 20,
            }}
            onClick={() => setVisibleSingleModal(true)}
          >
            <Typography.Text strong style={{ color: "white" }}>
              Release to Approve
            </Typography.Text>
          </Button>
        </Row>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, onPaymentSaveData, selectRequestListPayment]);

  const contentSingleModal = useMemo(() => {
    const paymentDesc = selectRequestListPayment?.paymentDesc;
    const isUrgent = selectRequestListPayment?.isUrgent;

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Form layout="vertical">
          <Form.Item
            initialValue={paymentDesc}
            name="paymentDesc"
            required
            help={errorPaymentDesc ? "Payment Description is Required" : ""}
            validateStatus={errorPaymentDesc || undefined}
            rules={[]}
            label={
              <Typography.Title level={5}>Payment Description</Typography.Title>
            }
          >
            <Input.TextArea
              placeholder="Description"
              showCount
              rows={3}
              onFocus={() => setErrorPaymentDesc("")}
              maxLength={500}
              onChange={(event) => {
                setSelectRequestListPayment((prev) => {
                  return {
                    ...prev,
                    paymentDesc: event.target.value,
                  };
                });
              }}
            />
          </Form.Item>
          <Form.Item name="isUrgent">
            <Checkbox
              checked={isUrgent}
              onChange={(event) => {
                setSelectRequestListPayment((prev) => {
                  return {
                    ...prev,
                    isUrgent: event.target.checked,
                  };
                });
              }}
            >
              Urgent
            </Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    );
  }, [
    setErrorPaymentDesc,
    errorPaymentDesc,
    loading,
    selectRequestListPayment,
  ]);

  const footerSingleModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button
          loading={loading}
          onClick={() => {
            setErrorPaymentDesc("");
            setVisibleSingleModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            if (isEmpty(selectRequestListPayment.paymentDesc)) {
              setErrorPaymentDesc("error");
              notification.error({
                message: "กรุณากรอกข้อมูลให้ครบถ้วน",
                description: "",
              });
              return;
            }
            setLoading(true);
            Promise.all([
              onPaymentSaveData(selectRequestListPayment),
              onPaymentSendApprove([selectRequestListPayment.payId]),
            ])
              .finally(() => {
                setLoading(false);
                setVisibleSingleModal(false);
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [
    loading,
    onPaymentSaveData,
    selectRequestListPayment,
    onPaymentSendApprove,
  ]);

  const contentMutiModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text>
          ยืนยันการส่งข้อมูลจำนวน {onTableSelect.length} ชุด
        </Typography.Text>
      </Spin>
    );
  }, [loading, onTableSelect]);

  const footerMutiModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleMutiModal(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);

            onPaymentSendApprove(
              onTableSelect.map((item) => Number(item))
            )?.finally(() => {
              setLoading(false);
              setVisibleMutiModal(false);
            });
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [loading, onTableSelect, onPaymentSendApprove]);

  const contentDeleteModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        {selectRequestListPayment.isDelete ? "Undelete" : "Delete"} Request{" "}
        {selectRequestListPayment.payNumber}{" "}
        {selectRequestListPayment.prHeaderNote} ?
      </Spin>
    );
  }, [loading, selectRequestListPayment]);

  const footerDeleteleModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleDeleteModal(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);
            if (auth) {
              Promise.all([
                onPaymentDeleteUndelete({
                  payId: [selectRequestListPayment.payId],
                  userId: String(auth.userId),
                }),
              ])
                .then(([response]) => {
                  setDataSource((prev) => {
                    return prev.map((item) => {
                      return {
                        ...item,
                        isDelete:
                          selectRequestListPayment.payId === item.payId
                            ? response.responseData[0].isDelete
                            : item.isDelete,
                      };
                    });
                  });
                })
                .catch((error) => console.log("error", error))
                .finally(() => {
                  setVisibleDeleteModal(false);
                  setLoading(false);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [auth, loading, onPaymentDeleteUndelete, selectRequestListPayment]);

  return (
    <div style={{ padding: 20 }}>
      <TitleHeader
        title="Payment Request Lists"
        loading={loading}
        dataSizes={dataSourceWithFilter.length}
        timeUpdate={timeUpdate}
        onSyncDataSource={onSyncDataSource}
      />
      <FilterInputTable
        setFilterInput={setFilterInput}
        querysearch={querysearch}
        queryDateType={querydatetype}
        queryDate={querydate}
        onTableSelect={onTableSelect}
        selectItems={[
          {
            icon: CompanyLogo,
            name: "isImmediately",
            placeholder: "Immediately",
            dataItems: isImmediatelyDrop,
            itemIndex: queryimmediate,
            span: 3,
          },
          {
            icon: TeamLogo,
            name: "purgId",
            placeholder: "Purchasing Group",
            dataItems: purchasingGroup,
            itemIndex: querypurgeid,
            span: 3,
          },
          {
            icon: CompanyLogo,
            name: "compId",
            placeholder: "Company",
            dataItems: company,
            itemIndex: querycompid,
            span: 3,
          },
        ]}
        searchText={searchText}
        setMultiConfirm={setVisibleMutiModal}
        setSearchText={setSearchText}
        setModalVisible={setVisibleSingleModal}
      />
      <DataTable
        loading={loading}
        data={dataSourceWithFilter}
        rowKey="payId"
        columns={columns}
        isRowSelection
        onSelectRowTable={setOnTableSelect}
      />
      {visibleDrawer ? (
        <CustomDrawer
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          title={titleDrawer}
          content={contentDrawer}
          footer={footerDrawer}
        />
      ) : null}
      {visibleSingleModal ? (
        <CustomModal
          key="SingleModal"
          visible={visibleSingleModal}
          setVisible={setVisibleSingleModal}
          title="Confirm to Release"
          content={contentSingleModal}
          footer={footerSingleModal}
        />
      ) : null}
      {visibleMutiModal ? (
        <CustomModal
          key="MutipleModal"
          visible={visibleMutiModal}
          setVisible={setVisibleMutiModal}
          title="Confirm to Release"
          content={contentMutiModal}
          footer={footerMutiModal}
        />
      ) : null}
      {visibleDeleteModal ? (
        <CustomModal
          key="DeleteModal"
          visible={visibleDeleteModal}
          setVisible={setVisibleDeleteModal}
          title={`${
            selectRequestListPayment.isDelete ? "Undelete" : "Delete"
          } Request`}
          content={contentDeleteModal}
          footer={footerDeleteleModal}
        />
      ) : null}
    </div>
  );
};

export default Component;
