import { AxiosError } from "axios";
import axios from "../libs/axios";
import {
  AccountAssignmentApi,
  AssetApi,
  CompanyApi,
  CostCenterApi,
  CurrencyApi,
  InfoRecordApi,
  MaterialGroupApi,
  MaterialSetApi,
  MaterialTypeApi,
  PurchasingGroupApi,
  PurchasingOrgApi,
  StorageLoCodeApi,
  UnitApi,
  VendorApi,
} from "../interfaces/master.interface";

export const getMasterPurchasingGroup = (
  userId?: string
): Promise<PurchasingGroupApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/purchasinggroup/${userId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterPurchasingOrg = (): Promise<PurchasingOrgApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/purchasingorganization`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterCompany = (userId?: string): Promise<CompanyApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/company/${userId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterCompanyBanking = (userId?: string): Promise<CompanyApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/companybanking/${userId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterMaterialGroup = (
  userId?: string
): Promise<MaterialGroupApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/materialgroup/${userId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterAccountAssignment = (
  userId?: string
): Promise<AccountAssignmentApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/accountassignment/${userId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterVendor = (comId: number): Promise<VendorApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/vendor/${comId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterCurrency = (): Promise<CurrencyApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/currency`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterAsset = (compId: number): Promise<AssetApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/asset/${compId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterCostCenter = (compId: number): Promise<CostCenterApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/asset/costcenter/${compId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterInfoRecord = (compId: number): Promise<InfoRecordApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/inforecord/${compId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterMaterialSet = (compId: number): Promise<MaterialSetApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/materialset/${compId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterStorageLoCode = (
  compId: number
): Promise<StorageLoCodeApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/sloc/${compId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterUnit = (): Promise<UnitApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/unit`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getMasterMaterialType = (): Promise<MaterialTypeApi> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/materialtype`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
