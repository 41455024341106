/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Typography,
  Popover,
  Checkbox,
  List,
  Space,
} from "antd";
import {
  CopyOutlined,
  EditFilled,
  EllipsisOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { cloneDeep } from "lodash";
import FormContentModal from "./FormContentModal";
import "./index.css";
import { AccountAssignment, Vendor } from "../../interfaces/master.interface";
import {
  FormDetailImmediately,
  PaymentInfoItem,
} from "../../interfaces/immediately.interface";
import formatData from "../../libs/formatData";

const { Text } = Typography;

interface SelectOption {
  key: string;
  label: string;
  value: string;
}

interface Props {
  dataTable?: PaymentInfoItem[];
  setDataTable: (value: PaymentInfoItem[]) => void;
  isAllApproved: boolean;
  isSameVendor: boolean;
  currentAccount: string;
  currentVendor: string;
  currentInvoiceDate: any;
  currentTaxInvoice: string;
  isCalculateTax: boolean;
  optionsVendor: SelectOption[];
  optionsAccount: SelectOption[];
  dataSourceVendor: Vendor[];
  selectedAccount: string | undefined;
  dataSourceAccountAssign: AccountAssignment[];
}

const Component = ({
  dataTable,
  setDataTable,
  isAllApproved,
  isCalculateTax,
  isSameVendor,
  currentTaxInvoice,
  currentAccount,
  currentVendor,
  currentInvoiceDate,
  optionsVendor,
  optionsAccount,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectedAccount,
  dataSourceVendor,
  dataSourceAccountAssign,
}: Props): JSX.Element => {
  const [dataSourece, setDataSource] = useState<PaymentInfoItem[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<FormDetailImmediately>();

  useEffect(() => {
    setDataTable(dataTable || []);
    setDataSource(dataTable || []);
  }, [dataTable, setDataTable]);

  useEffect(() => {
    const data = cloneDeep(dataSourece);
    setDataTable(data);
  }, [dataSourece, setDataTable]);

  useEffect(() => {
    // console.log("isCalculateTax : ", isCalculateTax);
  }, [isCalculateTax]);
  const records = dataSourece.filter((value) => !value.isDeleted);

  const handleAdd = (values: FormDetailImmediately) => {
    const accountObject = dataSourceAccountAssign.find(
      (item) => `${item.accoId}` === values.accoId
    );

    setDataSource((prev) => [
      ...prev,
      {
        key: `LOCAL-${dataSourece.length}`,
        payItemId: 0,
        paymentItem: `${records.length + 1}`.padStart(3, "0"),
        paymentText: values.itemText,
        totalValue: values.amount,
        exchangeRate: 0,
        currency: "",
        purGroup: "",
        companyCode: "",
        matgId: 0,
        materailCode: "",
        materialGroup: "",
        accoId: accountObject?.accoId || 0,
        accountAssignCategory: accountObject?.accAssName || "",
        accountAssignCategoryId: accountObject?.accAssId || "",
        accountAssignCode: accountObject?.accAssId || "",
        orderNumberWbs: values.orderNumberWbs || "",
        orderNumberWbsdesc: "",
        orderNumber: values.orderNumber || "",
        orderName: "",
        taxCode: "",
        isCalculateTax: values.isCalculateTax,
        accountNum: `${values.accountNum}`,
        accountText: `${values.accountText}`,
        invoiceDate: values.invoiceDate
          ? values.invoiceDate.format()
          : undefined,
        taxInvoiceNo: values.taxInvoiceNo,
        isExceptionBudget: values.isExceptionBudget,
        fiDocNo: values.fiDocNo,
        docYear: values.docYear ? values.docYear.format("YYYY") : undefined,
        prNo: "",
        poNo: "",
      },
    ]);
  };

  const handleBeforeEdit = (data: PaymentInfoItem, index: number) => {
    setSelectedRow({
      index,
      payItemId: data.payItemId,
      accoId: `${data.accoId}`,
      accountNum: data.accountNum,
      amount: data.totalValue,
      isCalculateTax: data.isCalculateTax,
      itemText: data.paymentText,
      orderNumber: data.orderNumber,
      orderNumberWbs: data.orderNumberWbs,
      _ShowAccountAssignment:
        dataSourceAccountAssign.find((item) => item.accoId === data.accoId)
          ?.accAssName || "",
      accountText: data.accountText || "",
      invoiceDate: data.invoiceDate ? moment(data.invoiceDate) : undefined,
      taxInvoiceNo: data.taxInvoiceNo,
      isExceptionBudget: data.isExceptionBudget,
      fiDocNo: data.fiDocNo,
      docYear: data.docYear ? moment(data.docYear) : undefined,
      prNo: "",
      poNo: "",
      paymentItem: data?.paymentItem,
    });

    setVisibleModalEdit(true);
  };

  const handleEdit = (values: FormDetailImmediately) => {
    const accountObject = dataSourceAccountAssign.find(
      (item) => `${item.accoId}` === values.accoId
    );
    const vendorObject = dataSourceVendor.find(
      (item) => item.accountNum === values.accountNum
    );

    setDataSource((prev: PaymentInfoItem[]): PaymentInfoItem[] => {
      return prev.map((value) => {
        if (values.paymentItem !== value.paymentItem) {
          return value;
        }
        return {
          ...value,
          paymentText: values.itemText,
          totalValue: values.amount,
          isCalculateTax: values.isCalculateTax,
          accoId: accountObject?.accoId || 0,
          accountAssignCode: accountObject?.accAssId || "",
          accountAssignCategory: accountObject?.accAssName || "",
          orderNumberWbs: values.orderNumberWbs || "",
          orderNumberWbsdesc: "",
          orderNumber: values.orderNumber || "",
          accountNum: `${vendorObject?.accountNum}`,
          accountText: values?.accountText,
          invoiceDate: values.invoiceDate
            ? values.invoiceDate.format()
            : undefined,
          taxInvoiceNo: values.taxInvoiceNo,
          isExceptionBudget: values.isExceptionBudget,
          fiDocNo: values.fiDocNo,
          docYear: values.docYear ? values.docYear.format("YYYY") : undefined,
        };
      });
    });
  };

  const handleDuplicate = async (data: PaymentInfoItem) => {
    setDataSource((prev) => [
      ...prev,
      {
        ...data,
        payItemId: 0,
        key: `LOCAL-${dataSourece.length}`,
        paymentItem: `${records.length + 1}`.padStart(3, "0"),
      },
    ]);
  };

  const handleDelete = (payItemId: number, paymentItem: string) => {
    setDataSource((prev: PaymentInfoItem[]): PaymentInfoItem[] => {
      const item: any = records.find((value) => {
        return value.payItemId
          ? value.payItemId === payItemId
          : value.paymentItem === paymentItem;
      });
      let newIndex = 0;
      return prev.map((value: PaymentInfoItem) => {
        if (payItemId !== 0 && value.payItemId === payItemId) {
          return {
            ...item,
            isDeleted: true,
            paymentItem: null,
          };
        }
        if (value.isDeleted) {
          return value;
        }
        if (paymentItem === value.paymentItem) {
          return {
            ...item,
            isDeleted: true,
          };
        }
        newIndex += 1;
        return {
          ...value,
          key: `LOCAL-${newIndex}`,
          paymentItem: `${newIndex}`.padStart(3, "0"),
        };
      });
    });
  };

  const columns = [
    {
      dataIndex: "paymentItem",
      title: "Item",
      width: 50,
      align: "center",
      fixed: true,
      render: (index: number) => {
        return `#${index}`;
      },
    },
    {
      dataIndex: "paymentText",
      title: "Item Text",
      width: 150,
      fixed: true,
    },
    {
      dataIndex: "totalValue",
      title: "Amount",
      width: 100,
      align: "right",
      fixed: true,
      render: (value: number) => {
        return formatData.toCurrency(value);
      },
    },
    {
      dataIndex: "isCalculateTax",
      title: "Calc. Tax",
      align: "center",
      width: 50,
      render: (data: boolean) => {
        return (
          <Checkbox disabled={isAllApproved} value={data} checked={data} />
        );
      },
    },
    {
      title: "Budget Type",
      width: 150,
      render: (_item: never, record: PaymentInfoItem) => {
        return `${record.accountAssignCode} - ${record.accountAssignCategory}`;
      },
    },
    {
      dataIndex: "orderNumberWbs",
      title: "WBS",
      width: 150,
    },
    {
      dataIndex: "orderNumber",
      title: "Order No.",
      width: 150,
    },
    {
      dataIndex: "accountNum",
      title: "Vendor",
      width: 150,
    },
    {
      dataIndex: "accountText",
      title: "Vendor Name",
      width: 150,
      // render: (value: any) => {
      //   const accText = value
      //     ?.split(" ")
      //     ?.slice(0, value.split(" ").length)
      //     ?.join(" ");
      //   return accText;
      // },
    },
    {
      dataIndex: "pvNumber",
      title: "PV number",
      width: 150,
    },
    {
      dataIndex: "pvStatus",
      title: "PV status",
      width: 150,
    },
    {
      title: "AC",
      align: "center",
      width: 50,
      fixed: "right",
      render: (_item: never, record: PaymentInfoItem, index: number) => {
        const stylesListItem = {
          borderBottom: "unset",
          padding: "5px 10px",
          cursor: "pointer",
        };
        const stylesSpace = { display: "flex", justifyContent: "center" };
        const stylesEdit = { color: "#174291" };
        const stylesDupplicate = { color: "#545454" };
        const stylesDelete = { color: "#D82E18" };

        return (
          <Popover
            placement="bottom"
            trigger="focus"
            content={
              <List itemLayout="horizontal" style={{ width: 100 }}>
                <List.Item
                  style={stylesListItem}
                  onClick={() => {
                    handleBeforeEdit(record, index);
                  }}
                >
                  <Space
                    size={8}
                    align="center"
                    onClick={() => {
                      handleBeforeEdit(record, index);
                    }}
                  >
                    <div style={stylesSpace}>
                      <EditFilled
                        style={stylesEdit}
                        onClick={() => {
                          handleBeforeEdit(record, index);
                        }}
                      />
                    </div>
                    <Text
                      style={stylesEdit}
                      onClick={() => {
                        handleBeforeEdit(record, index);
                      }}
                    >
                      {isAllApproved ? "Details" : "Edit"}
                    </Text>
                  </Space>
                </List.Item>
                {!isAllApproved && (
                  <List.Item
                    style={stylesListItem}
                    onClick={() => handleDuplicate(record)}
                  >
                    <Space size={8} align="center">
                      <div style={stylesSpace}>
                        <CopyOutlined style={stylesDupplicate} />
                      </div>
                      <Text style={stylesDupplicate}>Copy Item</Text>
                    </Space>
                  </List.Item>
                )}
                {!isAllApproved && (
                  <List.Item
                    style={stylesListItem}
                    onClick={() => {
                      handleDelete(record.payItemId, record.paymentItem);
                    }}
                  >
                    <Space size={8} align="center">
                      <div style={stylesSpace}>
                        <MinusCircleOutlined style={stylesDelete} />
                      </div>
                      <Text style={stylesDelete}>Delete</Text>
                    </Space>
                  </List.Item>
                )}
              </List>
            }
          >
            <Button
              type="link"
              icon={<EllipsisOutlined rotate={90} color="black" />}
            />
          </Popover>
        );
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        className="DetailsTable"
        columns={columns as any}
        dataSource={records}
        onChange={() => {}}
        scroll={{ x: 1500 }}
        pagination={false}
        footer={() => (
          <>
            {!isAllApproved && (
              <Button
                icon={<PlusOutlined style={{ fontSize: "14px" }} />}
                style={{
                  textAlign: "start",
                  margin: "10px 0px",
                  width: "fit-content",
                }}
                type="link"
                block
                onClick={() => {
                  setVisibleModal(true);
                }}
              >
                <Text style={{ color: "#174291" }} strong>
                  Add Item
                </Text>
              </Button>
            )}
          </>
        )}
      />
      <FormContentModal
        isAllApproved={isAllApproved}
        visible={visibleModal}
        setVisible={setVisibleModal}
        title="Add"
        getTaxInvoice={currentTaxInvoice}
        isCalculateTax={isCalculateTax}
        isSameVendor={isSameVendor}
        currentAccount={currentAccount}
        currentVendor={currentVendor}
        currentInvoiceDate={currentInvoiceDate}
        optionsVendor={optionsVendor}
        optionsAccount={optionsAccount}
        dataSourceVendor={dataSourceVendor}
        dataSourceAccountAssign={dataSourceAccountAssign}
        onFinish={(values) => handleAdd(values)}
        data={undefined}
      />
      <FormContentModal
        isAllApproved={isAllApproved}
        visible={visibleModalEdit}
        setVisible={setVisibleModalEdit}
        title="Edit"
        getTaxInvoice={undefined}
        isCalculateTax={isCalculateTax}
        isSameVendor={false}
        currentAccount={currentAccount}
        currentVendor={currentVendor}
        currentInvoiceDate={currentInvoiceDate}
        optionsVendor={optionsVendor}
        optionsAccount={optionsAccount}
        dataSourceVendor={dataSourceVendor}
        dataSourceAccountAssign={dataSourceAccountAssign}
        onFinish={(values) => handleEdit(values)}
        data={selectedRow}
      />
    </>
  );
};

export default Component;

Component.defaultProps = {
  dataTable: [],
};
