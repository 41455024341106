/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMemo } from "react";
import {
  Company,
  PurchasingGroup,
  AccountAssignment,
  MaterialGroup,
  Vendor,
  Currency,
  PurchasingOrg,
  Unit,
  Asset,
  CostCenter,
  InfoRecord,
  StorageLoCode,
  MaterialSet,
} from "../interfaces/master.interface";
import { User } from "../interfaces/user.interface";

export interface OptionsProps {
  dataSourceCompany?: Company[];
  dataSourcePurchasingGroup?: PurchasingGroup[];
  dataSourcePayByPurchasingGroup?: PurchasingGroup[];
  dataSourceAccountAssign?: AccountAssignment[];
  dataSourceMaterialGroup?: MaterialGroup[];
  dataSourceMaterialSet?: MaterialSet[];
  dataSourceVendor?: Vendor[];
  dataSourceCurrency?: Currency[];
  dataSourcePurchasingOrg?: PurchasingOrg[];
  dataSourceUnit?: Unit[];
  dataSourceAsset?: Asset[];
  dataSourceCostCenter?: CostCenter[];
  dataSourceInfoRecord?: InfoRecord[];
  dataSourceStorageLoc?: StorageLoCode[];
  dataSourceUser?: User[];
}

const useOptions = ({
  dataSourceCompany,
  dataSourcePurchasingGroup,
  dataSourcePayByPurchasingGroup,
  dataSourceAccountAssign,
  dataSourceMaterialGroup,
  dataSourceMaterialSet,
  dataSourceVendor,
  dataSourceCurrency,
  dataSourcePurchasingOrg,
  dataSourceUnit,
  dataSourceAsset,
  dataSourceCostCenter,
  dataSourceInfoRecord,
  dataSourceStorageLoc,
  dataSourceUser,
}: OptionsProps) => {
  const userList = useMemo(() => {
    const data = dataSourceUser?.map((item) => {
      return {
        key: `USER-${item.userId}`,
        label: `${item.email}`,
        value: `${item.userId}`,
      };
    });

    return data || [];
  }, [dataSourceUser]);

  const company = useMemo(() => {
    const data = dataSourceCompany?.map((item) => {
      return {
        label: `${item.companyId} ${item.abbr}`,
        value: item.compId,
      };
    });

    return [
      { label: "All Company", value: null, name: "ALL" },
      ...(data || []),
    ];
  }, [dataSourceCompany]);

  const purchasingGroup = useMemo(() => {
    const data = dataSourcePurchasingGroup?.map((item) => {
      return {
        label: `${item.purGroupId} ${item.purGroupName}`,
        value: item.purgId,
      };
    });

    return [
      { label: "All Purchasing Group", value: null, name: "ALL" },
      ...(data || []),
    ];
  }, [dataSourcePurchasingGroup]);

  const companies = useMemo(() => {
    const data = dataSourceCompany?.map((item, index) => {
      return {
        key: `Company-${index}`,
        label: `${item.companyId} ${item.abbr}`,
        value: `${item.compId}`,
      };
    });

    return data || [];
  }, [dataSourceCompany]);

  const purchasingGroups = useMemo(() => {
    const data = dataSourcePurchasingGroup?.map((item, index) => {
      return {
        key: `PurchasingGroup-${index}`,
        label: `${item.purGroupId} ${item.purGroupName}`,
        value: `${item.purgId}`,
      };
    });

    return data || [];
  }, [dataSourcePurchasingGroup]);

  const payPurchasingGroup = useMemo(() => {
    const data = dataSourcePayByPurchasingGroup?.map((item, index) => {
      return {
        key: `PurchasingGroup-${index}`,
        label: `${item.purGroupId} ${item.purGroupName}`,
        value: `${item.purgId}`,
      };
    });

    return data || [];
  }, [dataSourcePayByPurchasingGroup]);

  const accountAssign = useMemo(() => {
    const data = dataSourceAccountAssign?.map((item, index) => {
      return {
        key: `AccountAssignment-${index}`,
        label: `${item.accAssId} ${item.accAssName}`,
        value: `${item.accoId}`,
      };
    });

    return data || [];
  }, [dataSourceAccountAssign]);

  const materialSet = useMemo(() => {
    const data = dataSourceMaterialSet?.map((item, index) => {
      return {
        key: `MaterialSet-${index}`,
        label: `${item.materialCode} ${item.materialText}`,
        // label: `${item.materialCode}`,
        value: `${item.materialCode}`,
      };
    });

    return data || [];
  }, [dataSourceMaterialSet]);

  const expenseType = useMemo(() => {
    const data = dataSourceMaterialGroup?.map((item, index) => {
      return {
        key: `MaterialGroup-${index}`,
        label: `${item.materialGroupId} ${item.materialGroupName}`,
        value: `${item.matgId}`,
      };
    });

    return data || [];
  }, [dataSourceMaterialGroup]);

  const vendorData = useMemo(() => {
    const data = dataSourceVendor?.map((item, index) => {
      return {
        key: `Vendor-${index}`,
        label: `${item.accountNum} ${item.accountText}`,
        value: `${item.accountNum}`,
      };
    });

    return data || [];
  }, [dataSourceVendor]);

  const currencyData = useMemo(() => {
    const data = dataSourceCurrency?.map((item, index) => {
      return {
        key: `${index}`,
        label: `${item.code}`,
        value: `${item.code}`,
      };
    });
    return data || [];
  }, [dataSourceCurrency]);

  const purchasingOrg = useMemo(() => {
    const data = dataSourcePurchasingOrg?.map((item, index) => {
      return {
        key: `${index}`,
        label: `${item.purOrgId} ${item.purOrgName}`,
        value: `${item.purOrgId}`,
      };
    });
    return data || [];
  }, [dataSourcePurchasingOrg]);

  const units = useMemo(() => {
    const data = dataSourceUnit?.map((item, index) => {
      return {
        key: `${index}`,
        label: `${item.unitCode}`,
        value: `${item.unitCode}`,
      };
    });
    return data || [];
  }, [dataSourceUnit]);

  const assets = useMemo(() => {
    const data = dataSourceAsset?.map((item, index) => {
      return {
        key: `${index}`,
        label: `${item.assetText}`,
        value: `${item.assetCode}`,
      };
    });
    return data || [];
  }, [dataSourceAsset]);

  const costCenter = useMemo(() => {
    const data = dataSourceCostCenter?.map((item, index) => {
      return {
        key: `${index}`,
        // label: `${item.costCenterName}`,
        // eslint-disable-next-line radix
        label: `${parseInt(item.costCenter).toString()}`,
        value: `${item.costCenter}`,
      };
    });
    return data || [];
  }, [dataSourceCostCenter]);

  const infoRecord = useMemo(() => {
    const data = dataSourceInfoRecord?.map((item, index) => {
      return {
        key: `${index}`,
        label: `${item.inforecordText}`,
        value: `${item.inforecordCode}`,
      };
    });
    return data || [];
  }, [dataSourceInfoRecord]);

  const storageLoc = useMemo(() => {
    const data = dataSourceStorageLoc?.map((item, index) => {
      return {
        key: `${index}`,
        label: `${item.storageLoText}`,
        value: `${item.storageLoCode}`,
      };
    });
    return data || [];
  }, [dataSourceStorageLoc]);

  return {
    company,
    purchasingGroup,
    payPurchasingGroup,
    companies,
    purchasingGroups,
    accountAssign,
    assets,
    costCenter,
    currencyData,
    infoRecord,
    storageLoc,
    units,
    purchasingOrg,
    vendorData,
    materialSet,
    expenseType,
    userList,
  };
};

export default useOptions;
