import { useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import * as actions from "./store/actions";
import { generateRoutes } from "./libs/route";
import type { RouteConfig } from "./libs/route";
import * as accessTokenManager from "./libs/accessTokenManager";
import * as redirectPathManager from "./libs/redirectPathManager";
import RequestLists from "./containers/RequestLists";
import RequestHistory from "./containers/RequestListHistory";
// import RequestHistory from "./containers/RequestHistory";
// import ApproveHistory from "./containers/ApproverHistory";
import RequestImmediate from "./containers/RequestImmediate";
import ApproveLists from "./containers/ApproveLists";
import ApproveHistory from "./containers/ApproveListHistory";
import Configurations from "./containers/Configurations";
import Users from "./containers/User";
import RequestImmediateId from "./containers/RequestImmediateId";
import RequestImmediateMemo from "./containers/RequestImmediateMemo";
import RequestPRList from "./containers/RequestPRList";
import RequestPRHistory from "./containers/RequestPRHistory";
import AdminPRList from "./containers/AdminPRList";
import AdminPRHistory from "./containers/AdminPRHistory";
import PRMemo from "./containers/PRMemo";
import ApprovePRList from "./containers/ApprovePRList";
import ApprovePRHistory from "./containers/ApprovePRHistory";
import Banking from "./containers/Banking";
import BankingHistory from "./containers/Banking/history";
import BankingDetail from "./containers/Banking/detail";

const routes: Array<RouteConfig> = [
  {
    exact: true,
    path: "/requester",
    component: RequestLists,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/requester/history",
    component: RequestHistory,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/requester/immediately",
    component: RequestImmediate,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/requester/immediately/memo",
    component: RequestImmediateMemo,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/requester/immediately/:payId",
    component: RequestImmediateId,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/approver",
    component: ApproveLists,
    module: "Approve",
  },
  {
    exact: true,
    path: "/approver/history",
    component: ApproveHistory,
    module: "Approve",
  },
  {
    exact: true,
    path: "/pr/requester",
    component: RequestPRList,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/pr/requester/history",
    component: RequestPRHistory,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/pr/approver",
    component: ApprovePRList,
    module: "Approve",
  },
  {
    exact: true,
    path: "/pr/approver/history",
    component: ApprovePRHistory,
    module: "Approve",
  },
  {
    exact: true,
    path: "/pr/admin",
    component: AdminPRList,
    module: "Admin",
  },
  {
    exact: true,
    path: "/pr/admin/history",
    component: AdminPRHistory,
    module: "Admin",
  },
  {
    exact: true,
    path: "/pr/requester/memo/nonpo",
    component: PRMemo,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/pr/requester/memo/nonpo/:payId",
    component: PRMemo,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/pr/requester/memo/withpo",
    component: PRMemo,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/pr/requester/memo/withpo/:payId",
    component: PRMemo,
    module: "Requestor",
  },
  {
    exact: true,
    path: "/pr/admin/memo/:payId",
    component: PRMemo,
    module: "Admin",
  },
  {
    exact: true,
    path: "/banking",
    component: Banking,
    module: "Banking",
  },
  {
    exact: true,
    path: "/banking/detail/:payId",
    component: BankingDetail,
    module: "Banking",
  },
  {
    exact: true,
    path: "/banking/history",
    component: BankingHistory,
    module: "Banking",
  },
  {
    exact: true,
    path: "/banking/history/detail/:payId",
    component: BankingDetail,
    module: "Banking",
  },
  {
    exact: true,
    path: "/banking/ceo",
    component: Banking,
    module: "Executive",
  },
  {
    exact: true,
    path: "/banking/ceo/detail/:payId",
    component: BankingDetail,
    module: "Executive",
  },
  {
    exact: true,
    path: "/banking/ceo/history",
    component: BankingHistory,
    module: "Executive",
  },
  {
    exact: true,
    path: "/banking/ceo/history/detail/:payId",
    component: BankingDetail,
    module: "Executive",
  },
  {
    exact: true,
    path: "/configurations",
    component: Configurations,
    module: "Setting",
  },
  {
    exact: true,
    path: "/system/users",
    component: Users,
    module: "Admin",
  },
];

const App = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isProfileUpdated, setIsProfileUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (isProfileUpdated) return;
    if (!accessTokenManager.get()) {
      setIsProfileUpdated(true);
      // temporary add
      dispatch(
        actions.logout(() => {
          if (!location.pathname.includes("login")) {
            redirectPathManager.set(location);
          }
          setIsProfileUpdated(true);
          history.push("/login");
        })
      );
      return;
    }
    setIsProfileUpdated(true);
    dispatch(
      actions.updateUser((error) => {
        if (error) {
          dispatch(
            actions.logout(() => {
              setIsProfileUpdated(true);
              history.push("/login");
            })
          );
          return;
        }
        setIsProfileUpdated(true);
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, isProfileUpdated]);

  if (!isProfileUpdated) return <></>;

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/requester" />
      </Route>
      <Route exact path="/login" component={Login} />
      {generateRoutes(routes)}
      <Route component={NotFound} />
    </Switch>
  );
};

export default App;
