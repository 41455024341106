import React, { ReactElement } from "react";
import {
  PDFIcon,
  DOCIcon,
  JPGIcon,
  LINKIcon,
  PNGIcon,
  PPTIcon,
  TXTIcon,
  XLSIcon,
} from ".";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ComponentProps<T extends unknown> {
  icon: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Component = <T extends object>({
  icon,
}: ComponentProps<T>): ReactElement => {
  switch (icon) {
    case "PDF":
    case "pdf":
      return <PDFIcon />;
    case "DOC":
    case "doc":
    case "DOCX":
    case "docx":
      return <DOCIcon />;
    case "TXT":
    case "txt":
      return <TXTIcon />;
    case "JPG":
    case "jpg":
      return <JPGIcon />;
    case "PNG":
    case "png":
      return <PNGIcon />;
    case "PPT":
    case "ppt":
    case "PPTX":
    case "pptx":
      return <PPTIcon />;
    case "XLS":
    case "xls":
    case "XLSX":
    case "xlsx":
      return <XLSIcon />;
    default:
      return <LINKIcon />;
  }
};
export default Component;
