import React from "react";

const Component: React.FC = () => (
  <svg
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.51 18L21.5 9L0.51 0L0.5 7L15.5 9L0.5 11L0.51 18Z"
      fill="white"
    />
  </svg>
);

export default Component;
