import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useHistory } from "react-router-dom";
import { Form, Button, Image, Row, Col, Typography, Grid, Spin } from "antd";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import * as actions from "../../store/actions";
import * as redirectPathManager from "../../libs/redirectPathManager";
import WithNonAuthenticated from "../../components/WithNonAuthenticated";
import splashScreen from "../../assets/images/login/bg-mainmenu.png";
import { loginRequest } from "../../libs/azureAd/authConfig";

const Component = (): JSX.Element => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setError] = useState<string | null>(null);
  const breakpoint = Grid.useBreakpoint();

  const isLarge = useMemo(() => {
    return breakpoint.lg;
  }, [breakpoint]);

  useEffect(() => {}, [history]);

  const onFinish = () => {
    setLoading(true);
    const redirectPath = redirectPathManager.get();
    redirectPathManager.clear();

    instance
      .loginPopup(loginRequest)
      .then((response) => {
        instance.setActiveAccount(response.account);
        // eslint-disable-next-line no-console
        console.log("Login response : ", response);
        dispatch(
          actions.signInWithAzure(response.accessToken, (error, message) => {
            if (error === undefined) {
              setLoading(false);
              if (redirectPath) {
                history.push(redirectPath as unknown as Location);
              } else {
                history.push("/");
              }
            } else {
              setError(message as string);
            }
          })
        );
      })
      .catch((error) => {
        setLoading(false);
        // eslint-disable-next-line no-console
        console.log("Error Login onFinish : ", error);
        sessionStorage.clear();
        localStorage.clear();
      });
  };

  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      spinning={loading}
    >
      <Row
        style={{
          height: "100vh",
        }}
      >
        <Col
          span={24}
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isLarge && (
            <Image
              preview={false}
              src={splashScreen}
              style={{ height: "100%", width: "100%", maxHeight: "100vh" }}
            />
          )}
          <Col
            span={24}
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "55%",
            }}
          >
            <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
              <Typography.Title
                level={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 14,
                  color: "#00007D",
                }}
              >
                PR & Payment Approval
              </Typography.Title>
              {errorMsg && (
                <Typography.Text type="danger">{errorMsg}</Typography.Text>
              )}
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  style={{
                    display: "flex",
                    width: "60%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Log in
                  <ArrowRightOutlined />
                </Button>
              </Col>
            </Form>
            <Typography.Text
              type="secondary"
              style={{
                position: "fixed",
                bottom: 0,
                textAlign: "center",
                fontSize: !isLarge ? 8 : "",
                marginBottom: 12,
              }}
            >
              Copyright © 2021 BEC World Public Company Limited. All right
              reserved.
            </Typography.Text>
          </Col>
        </Col>
      </Row>
    </Spin>
  );
};

export default WithNonAuthenticated(Component);
