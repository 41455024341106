/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Typography,
  Popover,
  Checkbox,
  List,
  Space,
} from "antd";
import {
  CopyOutlined,
  EditFilled,
  EllipsisOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { cloneDeep } from "lodash";
import NonPOItemModal from "./NonPOItemModal";
import "./index.css";
import formatData from "../../libs/formatData";
import {
  FormDetailMemoNonPO,
  PaymentInfoItemMemo,
} from "../../interfaces/prmemo.interface";

const { Text } = Typography;

interface Props {
  dataTable?: PaymentInfoItemMemo[];
  setDataTable: (value: PaymentInfoItemMemo[]) => void;
  isAllApproved?: boolean;
  isCalculateTax: boolean;
}

const Component = ({
  dataTable,
  setDataTable,
  isAllApproved = false,
  isCalculateTax,
}: Props): JSX.Element => {
  const [dataSourece, setDataSource] = useState<PaymentInfoItemMemo[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<FormDetailMemoNonPO>();

  useEffect(() => {
    setDataTable(dataTable || []);
    setDataSource(dataTable || []);
  }, [dataTable, setDataTable]);

  useEffect(() => {
    const data = cloneDeep(dataSourece);
    setDataTable(data);
  }, [dataSourece, setDataTable]);

  const records = dataSourece.filter((value) => !value.isDelete);

  const handleAdd = (values: FormDetailMemoNonPO) => {
    setDataSource((prev) => [
      ...prev,
      {
        key: `LOCAL-${dataSourece.length}`,
        payItemId: 0,
        paymentItem: `${records.length + 1}`.padStart(3, "0"),
        paymentText: values.itemText,
        itemText: values.itemText,
        totalValue: values.amount,
        exchangeRate: 0,
        currency: "",
        purOrgId: "",
        orderNumberWbs: values.orderNumberWbs || "",
        orderNumberWbsdesc: "",
        orderNumber: values.orderNumber || "",
        orderName: "",
        isCalculateTax: values.isCalculateTax || false,
        accoId: 0,
        accountAssignCategory: "",
        accountAssignCategoryId: "",
        purGroup: "",
        purGroupId: "",
        purGroupName: "",
        companyCode: "",
        matgId: 0,
        materailCode: "",
        materialGroup: "",
        accountAssignCode: "",
        taxCode: "",
        docYear: "",
        accountText: "",
        accountNum: "",
        fiDocNo: "",
        taxInvoiceNo: "",
        invoiceDate: "",
        isExceptionBudget: true,
        materialId: 0,
        materialName: "",
        qty: 0,
        qtyUnit: "",
        dayFormat: "",
        deliveryDate: "",
        valuationPrice: 0,
        per: 0,
        perUnit: "",
        trackNo: "",
        assetCode: "",
        assetText: "",
        glAccount: "",
        plantId: 0,
        storageLoCode: "",
        storageLoText: "",
        desiredAccountNum: "",
        desiredAccountText: "",
        infoRecord: "",
        companyCodeMemo: "",
        coArea: "",
        costCenter: "",
        requestedBy: "",
        extendItemText: "",
      },
    ]);
  };

  const handleBeforeEdit = (data: PaymentInfoItemMemo, index: number) => {
    setSelectedRow({
      index,
      payItemId: data.payItemId,
      amount: data.totalValue,
      isCalculateTax: data.isCalculateTax,
      itemText: data.paymentText,
      orderNumber: data.orderNumber,
      orderNumberWbs: data.orderNumberWbs,
      paymentItem: data?.paymentItem,
    });

    setVisibleModalEdit(true);
  };

  const handleEdit = (values: FormDetailMemoNonPO) => {
    setDataSource((prev: PaymentInfoItemMemo[]): PaymentInfoItemMemo[] => {
      return prev.map((value) => {
        if (values.paymentItem !== value.paymentItem) {
          return value;
        }
        return {
          ...value,
          paymentText: values.itemText,
          totalValue: values.amount,
          isCalculateTax: values.isCalculateTax,
          orderNumberWbs: values.orderNumberWbs || "",
          orderNumberWbsdesc: "",
          orderNumber: values.orderNumber || "",
        };
      });
    });
  };

  const handleDuplicate = async (data: PaymentInfoItemMemo) => {
    setDataSource((prev) => [
      ...prev,
      {
        ...data,
        payItemId: 0,
        key: `LOCAL-${dataSourece.length}`,
        paymentItem: `${records.length + 1}`.padStart(3, "0"),
      },
    ]);
  };

  const handleDelete = (payItemId: number, paymentItem: string) => {
    setDataSource((prev: PaymentInfoItemMemo[]): PaymentInfoItemMemo[] => {
      const item: any = records.find((value) => {
        return value.payItemId
          ? value.payItemId === payItemId
          : value.paymentItem === paymentItem;
      });
      let newIndex = 0;
      return prev.map((value: PaymentInfoItemMemo) => {
        if (payItemId !== 0 && value.payItemId === payItemId) {
          return {
            ...item,
            isDelete: true,
            paymentItem: null,
          };
        }
        if (value.isDelete) {
          return value;
        }
        if (paymentItem === value.paymentItem) {
          return {
            ...item,
            isDelete: true,
          };
        }
        newIndex += 1;
        return {
          ...value,
          key: `LOCAL-${newIndex}`,
          paymentItem: `${newIndex}`.padStart(3, "0"),
        };
      });
    });
  };

  const columns = [
    {
      dataIndex: "paymentItem",
      title: "Item",
      align: "center",
      width: "10%",
      render: (index: number) => {
        return `#${index}`;
      },
    },
    {
      dataIndex: "paymentText",
      title: "Item Text",
      width: "40%",
    },
    {
      dataIndex: "totalValue",
      title: "Amount (Excluded Vat)",
      align: "right",
      width: "20%",
      render: (value: number) => {
        return formatData.toCurrency(value);
      },
    },
    {
      dataIndex: "isCalculateTax",
      title: "Calc. Tax",
      align: "center",
      render: (data: boolean) => {
        return (
          <Checkbox disabled={isAllApproved} value={data} checked={data} />
        );
      },
    },
    {
      title: "AC",
      align: "center",
      fixed: "right",
      width: "10%",
      render: (_item: never, record: PaymentInfoItemMemo, index: number) => {
        const stylesListItem = {
          borderBottom: "unset",
          padding: "5px 10px",
          cursor: "pointer",
        };
        const stylesSpace = { display: "flex", justifyContent: "center" };
        const stylesEdit = { color: "#174291" };
        const stylesDupplicate = { color: "#545454" };
        const stylesDelete = { color: "#D82E18" };

        return (
          <Popover
            placement="bottom"
            trigger="focus"
            content={
              <List itemLayout="horizontal" style={{ width: 100 }}>
                <List.Item
                  style={stylesListItem}
                  onClick={() => {
                    handleBeforeEdit(record, index);
                  }}
                >
                  <Space
                    size={8}
                    align="center"
                    onClick={() => {
                      handleBeforeEdit(record, index);
                    }}
                  >
                    <div style={stylesSpace}>
                      <EditFilled
                        style={stylesEdit}
                        onClick={() => {
                          handleBeforeEdit(record, index);
                        }}
                      />
                    </div>
                    <Text
                      style={stylesEdit}
                      onClick={() => {
                        handleBeforeEdit(record, index);
                      }}
                    >
                      {isAllApproved ? "Details" : "Edit"}
                    </Text>
                  </Space>
                </List.Item>
                {!isAllApproved && (
                  <List.Item
                    style={stylesListItem}
                    onClick={() => handleDuplicate(record)}
                  >
                    <Space size={8} align="center">
                      <div style={stylesSpace}>
                        <CopyOutlined style={stylesDupplicate} />
                      </div>
                      <Text style={stylesDupplicate}>Copy Item</Text>
                    </Space>
                  </List.Item>
                )}
                {!isAllApproved && (
                  <List.Item
                    style={stylesListItem}
                    onClick={() => {
                      handleDelete(record.payItemId, record.paymentItem);
                    }}
                  >
                    <Space size={8} align="center">
                      <div style={stylesSpace}>
                        <MinusCircleOutlined style={stylesDelete} />
                      </div>
                      <Text style={stylesDelete}>Delete</Text>
                    </Space>
                  </List.Item>
                )}
              </List>
            }
          >
            <Button
              type="link"
              icon={<EllipsisOutlined rotate={90} color="black" />}
            />
          </Popover>
        );
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        className="DetailsTable"
        columns={columns as any}
        dataSource={records}
        onChange={() => {}}
        footer={() => (
          <>
            {!isAllApproved && (
              <Button
                icon={<PlusOutlined style={{ fontSize: "14px" }} />}
                style={{
                  textAlign: "start",
                  margin: "10px 0px",
                  width: "fit-content",
                }}
                type="link"
                block
                onClick={() => {
                  setVisibleModal(true);
                }}
              >
                <Text style={{ color: "#174291" }} strong>
                  Add Item
                </Text>
              </Button>
            )}
          </>
        )}
      />
      <NonPOItemModal
        isAllApproved={isAllApproved}
        visible={visibleModal}
        setVisible={setVisibleModal}
        title="Add"
        isCalculateTax={isCalculateTax}
        onFinish={(values) => handleAdd(values)}
        data={undefined}
      />
      <NonPOItemModal
        isAllApproved={isAllApproved}
        visible={visibleModalEdit}
        setVisible={setVisibleModalEdit}
        title="Edit"
        isCalculateTax={isCalculateTax}
        onFinish={(values) => handleEdit(values)}
        data={selectedRow}
      />
    </>
  );
};

export default Component;

Component.defaultProps = {
  dataTable: [],
  isAllApproved: false,
};
