/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import { PRMemoResponseLists } from "../../../interfaces/prmemo.interface";

type Props = {
  // data?: PRMemoResponseLists;
  data?: any;
};

const Component: React.FC<Props> = ({ data }: Props) => {
  const formatDate = (date: string | undefined) => {
    if (!date) {
      return "-";
    }

    return dayjs(date).format("DD.MM.YYYY");
  };

  const getAccoType = (type?: string) => {
    return type === "IN" ? "On Budget" : "Out of Budget";
  };

  const invoiceDate = data?.invoiceDate ? data?.invoiceDate : "";
  const documentDate = data?.documentDate ? data?.documentDate : "";
  const docinvDate = data?.isImmediately ? invoiceDate : documentDate;
  const emailInfo = data?.emailInfo === null ? "" : data?.emailInfo;
  const WBSno = data?.orderNumberWBS === null ? "" : data?.orderNumberWBS;
  const orderNumber = data?.orderNumber === null ? "" : data?.orderNumber;

  return (
    <div className="tab-header-content">
      <Row key="payNumber">
        <Col span={8}>Req No / PR No:</Col>
        <Col span={16}>{data?.payNumber}</Col>
      </Row>
      <Row key="poNo">
        <Col span={8}>PO No:</Col>
        <Col span={16}>{`${data?.poNo}` || "-"}</Col>
      </Row>
      <Row key="memoNumber">
        <Col span={8}>Memo No:</Col>
        <Col span={16}>{data?.memoNo}</Col>
      </Row>
      <Row key="documentDate">
        <Col span={8}>Document Date:</Col>
        <Col span={16}>{formatDate(docinvDate) || "-"}</Col>
      </Row>
      <Row key="company">
        <Col span={8}>Company:</Col>
        <Col span={16}>{`${data?.company}` || "-"}</Col>
      </Row>
      <Row key="budgetType">
        <Col span={8}>Budget Type:</Col>
        <Col span={16}>
          {`${data?.accountAssignCategoryId} ${data?.accountAssignCategory}` ||
            "-"}
        </Col>
      </Row>
      <Row key="expenseCode">
        <Col span={8}>{getAccoType(data?.accoType) || "-"} :</Col>
        <Col span={4}>WBS/IO:</Col>
        <Col span={12}>{`${orderNumber} ${WBSno}` || "-"}</Col>
      </Row>
      <Row key="expenseType">
        <Col span={8}>Expense Type:</Col>
        <Col span={16}>{`${data?.matGroup} ${data?.matGroupName}` || "-"}</Col>
      </Row>
      <Row key="purchaser">
        <Col span={8}>Requester:</Col>
        <Col span={16}>{`${data?.createBy.email}` || "-"}</Col>
      </Row>
      <Row key="purGroup">
        <Col span={8}>Purchasing Group:</Col>
        <Col span={16}>{`${data?.purGroup}` || "-"}</Col>
      </Row>
      <Row key="payBy">
        <Col span={8}>Pay by:</Col>
        <Col span={16}>{`${data?.payByEmail}` || "-"}</Col>
      </Row>
      <Row key="payPurGroup">
        <Col span={8}>Pay by Purchasing Group:</Col>
        <Col span={16}>
          {`${data?.payPurGroup} ${data?.payPurGroupName}` || "-"}
        </Col>
      </Row>
      <Row key="emailInfo">
        <Col span={8}>Email Info:</Col>
        <Col span={16}>{`${emailInfo}` || "-"}</Col>
      </Row>
      <Row key="prHeaderNote">
        <Col span={8}>Header Text:</Col>
        <Col span={16} className="new-line">
          {`${data?.prHeaderNote}` || "-"}
        </Col>
      </Row>
      <Row key="toCEOGroup">
        <Col span={8}>Authorized Approver:</Col>
        <Col span={16}>{`${data?.toCEOGroup}` || "-"}</Col>
      </Row>
      <Row key="apprObjective">
        <Col span={8}>Objective:</Col>
        <Col span={16} className="new-line">
          {`${data?.apprObjective}` || "-"}
        </Col>
      </Row>
      <Row key="period">
        <Col span={8}>Period:</Col>
        <Col span={16}>
          {`${formatDate(data?.periodFrom)} - ${formatDate(data?.periodTo)}` ||
            "-"}
        </Col>
      </Row>
      <Row key="apprPayType">
        <Col span={8}>Payment type:</Col>
        <Col span={16}>{`${data?.apprPayType}` || "-"}</Col>
      </Row>
      <Row key="accountText">
        <Col span={8}>Vendor:</Col>
        <Col span={16}>{`${data?.accountText}` || "-"}</Col>
      </Row>
      <Row key="paymentDescription">
        <Col span={8}>Payment Description:</Col>
        <Col span={16} className="new-line">
          {`${data?.paymentDesc}` || "-"}
        </Col>
      </Row>
      <Row key="paymentTerm">
        <Col span={8}>Payment Term:</Col>
        <Col span={16}>
          {`${data?.paymentTerm || ""} ${data?.paymentText || ""}`}
        </Col>
      </Row>
      <Row key="isApprProject">
        <Col span={8}>Approve Request Type:</Col>
        <Col span={16}>{`${data?.isApprProject}` || "-"}</Col>
      </Row>
      {data?.isApprProject === "Project" && (
        <>
          <Row key="background">
            <Col span={8}>Project Background:</Col>
            <Col span={16} className="new-line">
              {data?.backgroud || "-"}
            </Col>
          </Row>
          <Row key="budgetYear">
            <Col span={8}>Fiscal Year:</Col>
            <Col span={16}>{data?.budgetYear || "-"}</Col>
          </Row>
          <Row key="responseExpenseGroup">
            <Col span={8}>Expense Department:</Col>
            <Col span={16} className="new-line">
              {data?.responseExpenseGroup || "-"}
            </Col>
          </Row>
          <Row key="responseGroup">
            <Col span={8}>Operation Department:</Col>
            <Col span={16} className="new-line">
              {data?.responseGroup || "-"}
            </Col>
          </Row>
          <Row key="responsePerson">
            <Col span={8}>Responsible Person:</Col>
            <Col span={16} className="new-line">
              {data?.responsePerson || "-"}
            </Col>
          </Row>
          <Row key="benefit">
            <Col span={8}>Benefit:</Col>
            <Col span={16} className="new-line">
              {data?.benefit || "-"}
            </Col>
          </Row>
          <Row key="impactOrg">
            <Col span={8}>Impact:</Col>
            <Col span={16} className="new-line">
              {data?.impactOrg || "-"}
            </Col>
          </Row>
        </>
      )}
      <Row key="taxCode">
        <Col span={8}>Tax Code:</Col>
        <Col span={16}>{`${data?.taxCode}` || "-"}</Col>
      </Row>
    </div>
  );
};

Component.defaultProps = {
  data: {} as PRMemoResponseLists,
};

export default Component;
