import React, { useEffect, useCallback, useState } from "react";
import {
  Input,
  Button,
  Form,
  Typography,
  Row,
  Col,
  Checkbox,
  Select,
  InputNumber,
  Modal,
  DatePicker,
  notification,
  AutoComplete,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import "./index.css";
import { AccountAssignment, Vendor } from "../../interfaces/master.interface";
import { FormDetailImmediately } from "../../interfaces/immediately.interface";
import { FiDocNo } from "../../enum/fiDocNo.enum";
import { filterOption } from "../../libs/filterOptionSelect";

const { Text } = Typography;
const { TextArea } = Input;

interface SelectOption {
  key: string;
  label: string;
  value: string;
}
interface Props {
  visible: boolean;
  setVisible: (value: boolean) => void;
  title: string;
  isAllApproved: boolean;
  isSameVendor: boolean;
  currentAccount: string;
  currentVendor: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentInvoiceDate: any;
  getTaxInvoice: string | null | undefined;
  isCalculateTax: boolean;
  optionsVendor: SelectOption[];
  optionsAccount: SelectOption[];
  dataSourceVendor: Vendor[];
  dataSourceAccountAssign: AccountAssignment[];
  onFinish: (values: FormDetailImmediately) => void;
  data: FormDetailImmediately | undefined;
}

const Component = ({
  visible,
  setVisible,
  title,
  getTaxInvoice,
  isAllApproved,
  isCalculateTax,
  isSameVendor,
  currentAccount,
  currentVendor,
  currentInvoiceDate,
  optionsVendor,
  optionsAccount,
  dataSourceVendor,
  dataSourceAccountAssign,
  onFinish,
  data = undefined,
}: Props): JSX.Element => {
  const [form] = Form.useForm<FormDetailImmediately>();
  const isCalculateTaxValue = Form.useWatch("isCalculateTax", form);
  const [isDisaVendorInput, setIsDisableVendorInput] = useState<boolean>(true);
  const accountAssignValue = Form.useWatch("accoId", form);
  const isExceptionBudgetValue = Form.useWatch("isExceptionBudget", form);
  const fiDocNo = Form.useWatch("fiDocNo", form);

  const options = [
    { value: FiDocNo.PAYROLL },
    { value: FiDocNo.ADVANCE },
    { value: FiDocNo.REIMBURSE },
    { value: FiDocNo.PETTY_CASH },
    { value: FiDocNo.ALLOCATE_EXP },
    { value: FiDocNo.CASH_DISCOUNT },
  ];

  notification.config({
    maxCount: 1,
    duration: 0,
    placement: "top",
  });

  const formatNumber = (value: number | undefined) => {
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 4,
    }).format(Number(value || 0));
  };

  const inputNumberCheck = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9.]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const isRequiredDocYear = (fiDoc: string | undefined) => {
    if (!fiDoc) return false;
    return !(options.find((item) => item.value === fiDoc)?.value === fiDoc);
  };

  const onChangeAmount = useCallback(() => {
    const defaultFormValue = form.getFieldsValue();
    if (!defaultFormValue.amount) {
      form.setFieldsValue({
        _ShowLocalCurrentAmount: 0,
      });
    } else if (isCalculateTaxValue) {
      form.setFieldsValue({
        _ShowLocalCurrentAmount: defaultFormValue.amount,
      });
    } else {
      form.setFieldsValue({
        _ShowLocalCurrentAmount: defaultFormValue.amount,
      });
    }
  }, [form, isCalculateTaxValue]);

  const onChangeAccount = (value: string) => {
    form.setFieldsValue({
      _ShowAccountAssignment:
        dataSourceAccountAssign.find((item) => `${item.accoId}` === value)
          ?.accAssName || "",
    });
  };

  const onChangeVendor = (value: string) => {
    form.setFieldsValue({
      accountText:
        dataSourceVendor.find((item) => `${item.accountNum}` === value)
          ?.accountText || "",
    });
    if (value?.substring(0, 2) === "29") {
      setIsDisableVendorInput(false);
    } else {
      setIsDisableVendorInput(true);
    }
  };

  const onFormSubmit = (values: FormDetailImmediately) => {
    const accountObject = dataSourceAccountAssign.find(
      (item) => `${item.accoId}` === values.accoId
    );
    const isException = values.isExceptionBudget
      ? values.isExceptionBudget
      : false;
    if (accountObject?.accAssId === "F" && values.orderNumber && isException) {
      notification.error({
        message: "กรุณากรอกข้อมูลให้ถูกต้อง",
        description:
          "ไม่สามารถระบุ Order Number และคลิกเลือก Exception Budget ได้",
      });
    } else if (
      accountObject?.accAssId === "F" &&
      !values.orderNumber &&
      !isException
    ) {
      notification.error({
        message: "กรุณากรอกข้อมูลให้ถูกต้อง",
        description:
          "ไม่สามารถระบุ Order Number เป็นค่าว่างและไม่คลิกเลือก Exception Budget ได้",
      });
    } else {
      onFinish({ ...values, paymentItem: data?.paymentItem });
      form.resetFields();
      setVisible(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        _ShowLocalCurrentAmount: data.amount,
      });
      form.setFieldsValue(data);
      if (data.accountNum?.substring(0, 2) === "29") {
        setIsDisableVendorInput(false);
      } else {
        setIsDisableVendorInput(true);
      }
    } else if (currentVendor && currentVendor.substring(0, 2) === "29") {
      setIsDisableVendorInput(false);
    } else {
      setIsDisableVendorInput(true);
    }
  }, [currentVendor, data, form, visible]);

  useEffect(() => {
    if (title === "Add") {
      form.setFieldsValue({
        isCalculateTax,
        accoId: currentAccount,
        _ShowAccountAssignment:
          dataSourceAccountAssign.find(
            (item) => `${item.accoId}` === currentAccount
          )?.accAssName || "",
        taxInvoiceNo: getTaxInvoice !== null ? getTaxInvoice : "",
        invoiceDate: currentInvoiceDate,
      });

      if (isSameVendor && visible) {
        form.setFieldsValue({
          accountNum: currentVendor,
          accountText:
            dataSourceVendor.find(
              (item) => `${item.accountNum}` === currentVendor
            )?.accountText || "",
        });
      } else {
        form.setFieldsValue({
          accountNum: undefined,
          accountText: "",
        });
      }
    }
  }, [
    visible,
    form,
    title,
    getTaxInvoice,
    currentAccount,
    currentInvoiceDate,
    dataSourceAccountAssign,
    isCalculateTax,
    isSameVendor,
    currentVendor,
    dataSourceVendor,
  ]);

  return (
    <Modal
      title={<Text strong>{title}</Text>}
      visible={visible}
      centered
      footer={null}
      onCancel={onCancel}
      className="custom-modal"
      forceRender
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={data}
        onFinish={onFormSubmit}
        scrollToFirstError
      >
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item name="index" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="itemText"
              label="Item Text"
              required
              rules={[
                {
                  required: true,
                  message: "Item Text is Required",
                },
              ]}
            >
              <TextArea
                disabled={isAllApproved}
                maxLength={172}
                placeholder="คำอธิบายรายการ"
                className="FieldInputStyle"
                showCount
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </Form.Item>
          </Col>
          <Col span={12} sm={18}>
            <Form.Item
              name="amount"
              label="Amount (Excluded Vat)"
              required
              rules={[
                {
                  required: true,
                  message: "Amount (Excluded Vat) is Required",
                },
              ]}
            >
              <InputNumber
                disabled={isAllApproved}
                controls={false}
                placeholder="Amount (Excluded Vat)"
                formatter={(value) =>
                  value ? formatNumber(Number(value)) : ""
                }
                onKeyPress={inputNumberCheck}
                className="FieldInputStyle"
                onChange={onChangeAmount}
              />
            </Form.Item>
          </Col>
          <Col span={12} sm={6}>
            <Form.Item name="isCalculateTax" valuePropName="checked" label=" ">
              <Checkbox>Calculate Tax</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="_ShowLocalCurrentAmount"
              label="Local Current Amount"
            >
              <InputNumber
                controls={false}
                formatter={(value) =>
                  value ? formatNumber(Number(value)) : ""
                }
                disabled
                className="FieldInputStyle"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24} sm={8}>
                <Form.Item
                  name="accoId"
                  label="Budget Type"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Budget Type is Required",
                    },
                  ]}
                >
                  <Select
                    disabled={isAllApproved}
                    placeholder="- Select -"
                    options={optionsAccount}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => onChangeAccount(value)}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={16}>
                <Form.Item name="_ShowAccountAssignment" label=" ">
                  <Input disabled className="FieldInputStyle" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {(dataSourceAccountAssign.find(
            (item) => `${item.accoId}` === accountAssignValue
          )?.accAssId === "P" ||
            false) && (
            <Col span={24}>
              <Form.Item
                name="orderNumberWbs"
                label="WBS"
                // required={!isExceptionBudgetValue}
                // rules={[
                //   {
                //     required: !isExceptionBudgetValue,
                //     message: "WBS is Required",
                //   },
                // ]}
              >
                <Input
                  disabled={isAllApproved}
                  placeholder="รหัสงบประมาณ"
                  className="FieldInputStyle"
                />
              </Form.Item>
            </Col>
          )}
          {(dataSourceAccountAssign.find(
            (item) => `${item.accoId}` === accountAssignValue
          )?.accAssId === "F" ||
            false) && (
            <Col span={24}>
              <Form.Item
                name="orderNumber"
                label="Order Number"
                required={!isExceptionBudgetValue}
                rules={[
                  {
                    required: !isExceptionBudgetValue,
                    message: "Order Number is Required",
                  },
                ]}
              >
                <Input
                  disabled={isAllApproved}
                  placeholder="รหัสงบประมาณ"
                  className="FieldInputStyle"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24} sm={8}>
                <Form.Item
                  name="accountNum"
                  label="Vendor"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Vendor is Required",
                    },
                  ]}
                >
                  <Select
                    disabled={isAllApproved}
                    placeholder="- Select -"
                    options={optionsVendor}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => onChangeVendor(value)}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={16}>
                <Form.Item name="accountText" label=" ">
                  <Input
                    disabled={isDisaVendorInput}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="invoiceDate" label="Invoice Date">
                  <DatePicker
                    disabled={isAllApproved}
                    format="DD-MM-YYYY"
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="taxInvoiceNo" label="Tax Invoice">
                  <Input
                    disabled={isAllApproved}
                    placeholder="เลขที่ใบกำกับภาษี"
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item name="isExceptionBudget" valuePropName="checked">
                  <Checkbox disabled={isAllApproved}>Exception Budget</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            {isExceptionBudgetValue && (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="fiDocNo"
                    label="FI DocNo"
                    required
                    rules={[
                      {
                        required: true,
                        message: "FI DocNo is Required",
                      },
                    ]}
                  >
                    <AutoComplete
                      disabled={isAllApproved}
                      className="FieldInputStyle"
                      options={options}
                      placeholder="Select item or type value"
                      filterOption={(inputValue, option) =>
                        option?.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="docYear"
                    label="Doc Year"
                    rules={[
                      {
                        required: isRequiredDocYear(fiDocNo),
                        message: "Doc Year is Required",
                      },
                    ]}
                  >
                    <DatePicker picker="year" className="FieldInputStyle" />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row justify="start" gutter={8}>
          <Col>
            <Button type="primary" icon={<CheckOutlined />} htmlType="submit">
              Save Changes
            </Button>
          </Col>
          <Col>
            <Button onClick={onCancel}>Cancel</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Component;
