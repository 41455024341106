import { AxiosError } from "axios";
import { message } from "antd";
import axios from "../libs/axios";
import type { Response } from "../interfaces/response.interface";
import {
  ResponsePrint,
  PayLoadUpdatePVStatus,
} from "../interfaces/history.interface";
import { PayloadPreviewPaymentList } from "../interfaces/paymentList.interface";

export const getHistoryList = (): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("approval/paymentlist/history", {
        sendApproveBy: "188",
        activityState: null,
        compId: null,
        purgId: null,
        dueDate: {
          dateFrom: "2022-02-24",
          dateTo: "2022-02-28",
        },
        documentDate: {
          dateFrom: "2022-02-24",
          dateTo: "2022-02-28",
        },
        postingDate: null,
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getHistoryPayId = (payId: number): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .get(`approval/paymentlist/history/${payId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const printHistoryLists = (data: ResponsePrint): Promise<Blob> =>
  new Promise((resolve, reject) => {
    axios
      .post("approval/paymentlist/history/print", data, {
        responseType: "blob",
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err: AxiosError) => {
        message.error(`ไม่สามารถพิมพ์เอกสารได้มากกว่า 3 ครั้ง `, 10);
        reject(err);
      });
  });

export const pvStatusHistory = (
  data: PayLoadUpdatePVStatus
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/pvstatus", data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getPaymentListPreview = (
  payload: PayloadPreviewPaymentList
): Promise<Blob> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/preview", payload, {
        responseType: "blob",
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
