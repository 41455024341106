import * as actionTypes from "../actionTypes";
import type { AuthenticationAction } from "../../interfaces/authentication.interface";
import type { User } from "../../interfaces/user.interface";
import * as accessTokenManager from "../../libs/accessTokenManager";
import * as userInfoManager from "../../libs/userInforManager";

const INITIAL_STATE = userInfoManager.get() || ({} as User);

const reducer = (
  state: User = INITIAL_STATE,
  action: AuthenticationAction
): User => {
  switch (action.type) {
    case actionTypes.AUTHENTICATION_LOGIN: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.AUTHENTICATION_LOGOUT: {
      return {} as User;
    }
    case actionTypes.AUTHENTICATION_UPDATE_USER: {
      return {
        ...state,
        tokenId: accessTokenManager.get() || undefined,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
