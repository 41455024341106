import { FC, useMemo } from "react";
import { Button, Col, Row, Typography, Grid, Divider } from "antd";
import { EyeFilled, MinusCircleOutlined } from "@ant-design/icons";
// import last from "lodash/last";
import FileIcon from "../CustomIcon/FileIcon";
import {
  FileListUpload,
  ResponseAPIUploadFile,
} from "../../interfaces/uploadFile.interface";
import { getFileExtension } from "../../libs/file";
import "./index.css";

export interface ComponentProps {
  fileList: Array<ResponseAPIUploadFile | FileListUpload>;
  isDisabled?: boolean;
  onDeleteFile: (index: number) => void;
}

const Component: FC<ComponentProps> = ({
  fileList,
  isDisabled = false,
  onDeleteFile,
}) => {
  const breakpoint = Grid.useBreakpoint();

  const dataSource: Array<ResponseAPIUploadFile | FileListUpload> =
    useMemo(() => {
      // eslint-disable-next-line no-console
      // console.log("fileList : ", fileList);
      return fileList || [];
    }, [fileList]);

  const onClickViewFile = (data: ResponseAPIUploadFile | FileListUpload) => {
    const file: ResponseAPIUploadFile | FileListUpload = data;
    // console.log("onClickViewFile");
    // console.log(file);
    if (!(file instanceof File)) {
      window.open(file.filePath);
    } else {
      const base64ImageData = file.base64;
      const contentType = file.type;

      const byteCharacters = atob(
        base64ImageData.substring(`data:${contentType};base64,`.length)
      );
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);

        const byteNumbers = new Array(slice.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = URL.createObjectURL(blob);

      window.open(blobUrl, "_blank");
    }
  };

  return (
    <div style={{ maxHeight: "420px", overflowX: "hidden", overflowY: "auto" }}>
      {dataSource.map((data, index: number) => {
        const file: ResponseAPIUploadFile | FileListUpload = data;

        if (data instanceof File) {
          file.filename = data.name;
          file.fileExtension = getFileExtension(data.name);
        }

        if (file.isDelete) {
          return null;
        }

        return (
          <Row
            key={index}
            justify="space-between"
            align="middle"
            className="component-file-list"
          >
            <Col span={4} sm={2} md={5} lg={3} xl={3} xxl={2}>
              <FileIcon icon={file.fileExtension} />
            </Col>
            <Col span={15} sm={16} md={9} lg={14} xl={15} xxl={17}>
              <Typography.Paragraph ellipsis={{ rows: 3 }}>
                {data.name}
              </Typography.Paragraph>
            </Col>
            <Col span={5} sm={6} md={10} lg={7} xl={6} xxl={5}>
              <Row justify="end" align="middle" gutter={12}>
                {!isDisabled && (
                  <Col>
                    <Button
                      type="text"
                      danger
                      block
                      icon={<MinusCircleOutlined />}
                      onClick={() => {
                        onDeleteFile(index);
                      }}
                    />
                  </Col>
                )}
                <Col>
                  <Button
                    type="primary"
                    block
                    // disabled={file instanceof File}
                    icon={<EyeFilled size={8} />}
                    className="view-button"
                    onClick={() => onClickViewFile(file)}
                  >
                    {breakpoint.xs ? "" : "View"}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Divider />
          </Row>
        );
      })}
    </div>
  );
};

export default Component;
