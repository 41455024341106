/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Col, Divider, Row, Typography } from "antd";
// import { number } from "prop-types";
// import _ from "lodash";
import { Payment } from "../../../interfaces/payment.interface";
import { History } from "../../../interfaces/history.interface";

type Props = {
  data?: Payment | History;
};

const { Text } = Typography;

const Component: React.FC<Props> = (props: Props) => {
  const { data } = props;
  return (
    <>
      {(
        (data as Payment).paymentInfoItem ||
        (data as History).paymentInfoItemHistory ||
        []
      ).map((paymentInfoItem: any, index) => (
        <div className="tab-detial-wrapper">
          <Row justify="space-between">
            <Col span={2}>
              <Text strong>{index + 1}</Text>
            </Col>
            <Col span={18}>
              <Text strong> {paymentInfoItem.paymentText} </Text>
            </Col>
            <Col span={4}>
              <Text strong> {paymentInfoItem.totalValue}</Text>
            </Col>
          </Row>
          <div className="tab-detail-content">
            {/* <Row>
              <Col span={2}>{}</Col>
              <Col span={8}>({paymentInfoItem.currency}):</Col>
              <Col span={14}>
                {paymentInfoItem.totalValue / paymentInfoItem.exchangeRate}
              </Col>
            </Row> */}
            <Row>
              <Col span={2}>{}</Col>
              <Col span={8}>Budget Type:</Col>
              <Col span={14}>{paymentInfoItem.paymentAssignment}</Col>
            </Row>
            <Row>
              <Col span={2}>{}</Col>
              <Col span={8}>Expense Type:</Col>
              <Col span={14}>{paymentInfoItem.materialGroup}</Col>
            </Row>
            <Row>
              <Col span={2}>{}</Col>
              <Col span={8}>WBS/Order No:</Col>
              <Col span={14}>
                {paymentInfoItem.orderNumber || ""}{" "}
                {paymentInfoItem.orderName || ""}
              </Col>
            </Row>
            <Row>
              <Col span={2}>{}</Col>
              <Col span={8}>Cost Center: </Col>
              <Col span={14}>
                {paymentInfoItem.costCenter || ""}{" "}
                {paymentInfoItem.costCtname || ""}
              </Col>
            </Row>
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
};

Component.defaultProps = {
  data: {} as Payment | History,
};

export default Component;
