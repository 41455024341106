import {
  PaperClipOutlined,
  InfoCircleOutlined,
  TrademarkCircleOutlined,
  FileSearchOutlined,
  SettingOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Tooltip, Space, Typography, Row, Button, Badge, Image } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { getPaymentListPreview } from "../../api/history";
import { Exclamation, Thunder } from "../../components/CustomIcon";
import UserStatusRow from "../../components/UserStatusRow";
import { RequestList } from "../../interfaces/paymentList.interface";
import formatData from "../../libs/formatData";
import pvRejected from "../../assets/images/pvstatus/rejected.png";
import pvCancelled from "../../assets/images/pvstatus/cancelled.png";
import pvApproved from "../../assets/images/pvstatus/approved.png";

interface ColumnsProps {
  searchText: string;
  setVisibleDrawer: (value: boolean) => void;
  setVisibleSingleModal: (value: boolean) => void;
  setVisiblePVModal: (value: boolean) => void;
  setVisibleReversedModal: (value: boolean) => void;
  setSelectRequestListPayment: (record: RequestList) => void;
  setLoading: (value: boolean) => void;
}

export const renderRequestHistoryColumns = ({
  searchText,
  setVisibleDrawer,
  setVisibleSingleModal,
  setVisiblePVModal,
  setVisibleReversedModal,
  setSelectRequestListPayment,
  setLoading,
}: ColumnsProps): ColumnsType<RequestList> => {
  return [
    {
      title: "Status",
      width: 80,
      render: (record) => (
        <div style={{ display: "flex" }}>
          {record.isUrgent && (
            <Tooltip title="Urgent">
              <Exclamation />
            </Tooltip>
          )}
          {moment().format("YYYY-MM-DD") > record.dueDate && (
            <Tooltip title="Overdue">
              <Thunder style={{ marginRight: "5px" }} />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      width: 100,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={dayjs(value).format("DD.MM.YYYY")}
        />
      ),
      sorter: (a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix(),
    },
    {
      title: "Pay Request No",
      dataIndex: "payNumber",
      width: 120,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 100,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Purchasing Group",
      dataIndex: "purGroup",
      width: 110,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "PR No.",
      dataIndex: "prNo",
      width: 105,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "PO No.",
      dataIndex: "poNo",
      width: 105,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Payment Description",
      dataIndex: "paymentDesc",
      width: 120,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Vendor",
      dataIndex: "accountText",
      width: 120,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "amount",
      title: "Amount",
      width: 120,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {`
          ${formatData.toCurrency(record.amount)} ${record.currency}
          `}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "files",
      title: "Attachment",
      width: 100,
      render: (record) => (
        <Space size="small">
          <PaperClipOutlined rotate={135} />
          <Typography.Text>
            {record.fileSapnum + record.fileApprNum}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: "FI Doc No.",
      dataIndex: "fiDocNo",
      width: 90,
      render: (value: string) => {
        return (
          <Row>
            {value !== "" && (
              <Highlighter
                highlightClassName="highlight"
                searchWords={[searchText]}
                textToHighlight={parseInt(value, 10).toString()}
              />
            )}
            {value === "" && (
              <Highlighter
                highlightClassName="highlight"
                searchWords={[searchText]}
                textToHighlight=""
              />
            )}
          </Row>
        );
      },
    },
    {
      title: "Approval Status",
      dataIndex: "accountText",
      width: 240,
      render: (value: string, row) => {
        return (
          <div style={{ zIndex: -99, overflowX: "scroll" }}>
            <UserStatusRow
              approvers={row.approvers}
              userInfo={row.sendApproveBy}
              isBanking={false}
            />
          </div>
        );
      },
    },
    {
      title: "Release Date,Time",
      dataIndex: "sendApproveDate",
      width: 100,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={dayjs(value).format("DD.MM.YYYY, HH:mm")}
        />
      ),
      // sorter: (a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix(),
    },
    {
      key: "action",
      title: "Detail/Recall",
      fixed: "right",
      width: 110,
      render: (record: RequestList) => (
        <Row>
          <Tooltip placement="top" title="Detail">
            <Button
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={() => {
                setVisibleDrawer(true);
                setSelectRequestListPayment(record);
              }}
            />
          </Tooltip>
          {record.approvers &&
            !record.approvers[0].isApprove &&
            !record.approvers[0].isreject && (
              <Tooltip placement="top" title="Recall">
                <Button
                  type="text"
                  icon={<TrademarkCircleOutlined />}
                  onClick={() => {
                    setSelectRequestListPayment(record);
                    setVisibleSingleModal(true);
                  }}
                />
              </Tooltip>
            )}
          {record.activityState === "approved" && (
            <Tooltip placement="top" title="Preview Document">
              <Button
                disabled={record.isDelete}
                type="text"
                icon={<FileSearchOutlined />}
                onClick={() => {
                  setLoading(true);
                  setSelectRequestListPayment(record);
                  getPaymentListPreview({
                    payId: record.payId,
                    sendApproveBy: String(record.sendApproveBy?.userId) || "",
                    documentType: record.isImmediately ? "PVIM" : "PVCR",
                  })
                    .then((result) => {
                      const file = new Blob([result], {
                        type: "application/pdf",
                      });
                      const fileURL = URL.createObjectURL(file);
                      window.open(fileURL);
                    })
                    // .catch((error) => console.log("can't open file:", error))
                    .finally(() => setLoading(false));
                }}
              />
            </Tooltip>
          )}
          {record.reverseDoc !== "" && (
            <Tooltip placement="top" title="Reversed By SAP">
              <Button
                type="text"
                icon={<WarningOutlined style={{ color: "#FFA500" }} />}
                disabled={record.activityState === "rejected"}
                onClick={() => {
                  setSelectRequestListPayment(record);
                  setVisibleReversedModal(true);
                }}
              />
            </Tooltip>
          )}
        </Row>
      ),
    },
    {
      key: "pvdoc",
      title: "PV Info",
      fixed: "right",
      width: 120,
      render: (record: RequestList) => (
        <div style={{ display: "flex" }}>
          {record.activityState === "approved" && (
            <Badge size="small" count={record.printCount} offset={[-10, 5]}>
              <Tooltip placement="top" title="Print">
                <Button
                  type="text"
                  icon={<SettingOutlined style={{ fontSize: "x-large" }} />}
                  style={{ paddingRight: "5px", paddingBottom: "5px" }}
                  onClick={() => {
                    setSelectRequestListPayment(record);
                    setVisiblePVModal(true);
                  }}
                />
              </Tooltip>
            </Badge>
          )}
          <Row>
            {record.pvDocStatus === "approved" && (
              <Image
                preview={false}
                src={pvApproved}
                style={{ width: "27px", paddingRight: "3px" }}
              />
            )}
            {record.pvDocStatus === "cancelled" && (
              <Image
                preview={false}
                src={pvCancelled}
                style={{ width: "27px", paddingRight: "3px" }}
              />
            )}
            {record.pvDocStatus === "rejected" && (
              <Image
                preview={false}
                src={pvRejected}
                style={{ width: "27px", paddingRight: "3px" }}
              />
            )}
            <Highlighter
              highlightClassName="highlight"
              searchWords={[searchText]}
              textToHighlight={`${record.pvDocNo || ""}`}
            />
          </Row>
        </div>
      ),
    },
  ];
};
