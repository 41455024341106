export const isImmediatelyDrop = [
  {
    label: "All Request Type",
    value: null,
    name: "ALL",
  },
  {
    label: "Credit",
    value: false,
    name: "Credit",
  },
  {
    label: "Immediately",
    value: true,
    name: "Immediately",
  },
];

export const activityState = [
  {
    label: "All Approval Status",
    value: null,
    name: "ALL",
  },
  {
    label: "approved",
    value: "approved",
    name: "approved",
  },
  {
    label: "pending",
    value: "pending",
    name: "pending",
  },
  {
    label: "rejected",
    value: "rejected",
    name: "rejected",
  },
];

export const overDueUrgent = [
  {
    label: "All Status (Overdue, Urgent)",
    value: null,
  },
  {
    label: "Overdue",
    value: "isOverdue",
  },
  {
    label: "Urgent",
    value: "isUrgent",
  },
];

export const docType = [
  {
    label: "All Document Type",
    value: null,
  },
  {
    label: "Without PO",
    value: true,
  },
  {
    label: "With PO",
    value: false,
  },
];

export const paymentType = [
  {
    label: "All Payment Type",
    value: null,
  },
  {
    label: "Transfer",
    value: "BATCH",
  },
  {
    label: "Cheque",
    value: "CHEQUE",
  },
];

export const bankingStatus = [
  {
    label: "All Status",
    value: null,
  },
  {
    label: "Waiting",
    value: "WAITING",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  // {
  //   label: "Reject",
  //   value: "reject",
  // },
];
