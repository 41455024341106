/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EyeFilled,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, notification, Row, Typography, Upload } from "antd";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import _ from "lodash";
import React, { useEffect, useState } from "react";
// import { updatePaymentListById } from "../../../api/approval";
// import { PaymentInfoAttach } from "../../../interfaces/payment.interface";
import { checkLimitSize, getFileName } from "../../../libs/file";
import { FileIcon } from "../../CustomIcon";

const { Text } = Typography;

type Props = {
  // record?: Payment;
  data: any[];
  disabled?: boolean;
  userId?: string;
  visibleDeleteButton?: boolean;
  isClearUploadList?: boolean;
  saveDeleteApi?: any[];
  setData?: (value: any[]) => void;
  setSaveFileList?: (value: UploadFile<unknown>[]) => void;
  setIsClearUploadList?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Component: React.FC<Props> = ({
  data,
  disabled,
  userId,
  visibleDeleteButton,
  isClearUploadList,
  saveDeleteApi,
  setSaveFileList,
  setIsClearUploadList,
  setData,
}: Props) => {
  const [fileList, setFileList] = useState<UploadFile<unknown>[]>([]);
  useEffect(() => {
    if (setSaveFileList) {
      if (isClearUploadList && setIsClearUploadList) {
        setFileList([]);
        setSaveFileList([]);
        setIsClearUploadList(false);
      } else {
        setSaveFileList(fileList);
      }
    }
  }, [fileList, isClearUploadList, setIsClearUploadList, setSaveFileList]);

  const getBase64Upload = (
    file: RcFile,
    callback: { (value: string | ArrayBuffer | null): void }
  ) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const onUploadFileChange = (file: RcFile): void => {
    if (file && checkLimitSize(file)) {
      notification.error({
        message: "ไม่สามารถอัพโหลดไฟล์ได้",
        description: "ไฟล์มีขนาดใหญ่เกินกว่า 30MB",
      });
      return;
    }

    getBase64Upload(file, (imageUrl: any) => {
      const prepareFile: any = file;

      prepareFile.rawName = getFileName(file.name);
      prepareFile.base64 = imageUrl;

      setFileList([...fileList, prepareFile] as any);
    });
  };

  const FileListAttached = ({
    realFilename,
    file,
    canDelete,
    canDeleteOld,
    readOnly,
  }: {
    realFilename: string;
    file: any;
    canDelete?: number;
    canDeleteOld?: number;
    readOnly: boolean;
  }): JSX.Element => {
    return (
      <Row align="middle" style={{ margin: "8px 0" }}>
        <Col span={2}>
          <FileIcon icon={realFilename?.split(".")?.[1]} />
        </Col>
        <Col offset={1} span={15}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "1px 0",
            }}
          >
            <Text style={{ fontSize: "15px" }}>{realFilename}</Text>
          </div>
        </Col>
        <Col span={2}>
          {canDelete !== undefined && (
            <Button
              type="text"
              icon={<MinusCircleOutlined style={{ color: "red" }} />}
              onClick={() => {
                const newFileList = _.cloneDeep(fileList);
                newFileList.splice(canDelete, 1);
                setFileList(newFileList);
              }}
            />
          )}
          {setData !== undefined &&
            canDeleteOld !== undefined &&
            visibleDeleteButton &&
            userId === file.createBy.userId &&
            !readOnly && (
              <Button
                type="text"
                icon={<MinusCircleOutlined style={{ color: "red" }} />}
                onClick={() => {
                  const repeatSaveDeleteApi = saveDeleteApi?.find(
                    (item) => item.payAttcId === data[canDeleteOld].payAttcId
                  );
                  if (!repeatSaveDeleteApi) {
                    setData([
                      ...(saveDeleteApi || []),
                      { ...data[canDeleteOld], isDelete: true },
                    ]);
                  }
                }}
              />
            )}
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            icon={<EyeFilled />}
            style={{
              backgroundColor: "#545454",
            }}
            onClick={async () => {
              if (!file.filePath) {
                // const image = new Image();
                // image.src = file.base64;

                // const imgWindow: Window | null = window.open("");
                // imgWindow?.document.write(image.outerHTML);
                const base64ImageData = file.base64;
                const contentType = file.type;

                const byteCharacters = atob(
                  base64ImageData.substring(
                    `data:${contentType};base64,`.length
                  )
                );
                const byteArrays = [];

                for (
                  let offset = 0;
                  offset < byteCharacters.length;
                  offset += 1024
                ) {
                  const slice = byteCharacters.slice(offset, offset + 1024);

                  const byteNumbers = new Array(slice.length);
                  // eslint-disable-next-line no-plusplus
                  for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                  }

                  const byteArray = new Uint8Array(byteNumbers);

                  byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, { type: contentType });
                const blobUrl = URL.createObjectURL(blob);

                window.open(blobUrl, "_blank");
              } else {
                window.open(file.filePath);
              }
            }}
          >
            <Text style={{ color: "white" }}>View</Text>
          </Button>
        </Col>
      </Row>
    );
  };

  FileListAttached.defaultProps = {
    canDelete: undefined,
    canDeleteOld: undefined,
  };

  return (
    <div style={{ padding: "0 32px 0 28px" }}>
      {data?.map((item, index) => (
        <FileListAttached
          key={index}
          realFilename={item.realFilename}
          file={item}
          canDeleteOld={index}
          readOnly={item.readOnly}
        />
      ))}
      {!disabled &&
        fileList.map((item, index) => (
          <FileListAttached
            key={index}
            realFilename={item.name}
            file={item}
            canDelete={index}
            readOnly={false}
          />
        ))}
      {!disabled && (
        <div style={{ paddingTop: "20px" }}>
          <Upload
            beforeUpload={() => false}
            fileList={fileList}
            onChange={(info: UploadChangeParam<UploadFile>) => {
              onUploadFileChange(info.file as RcFile);
            }}
            showUploadList={false}
            multiple
            listType="picture-card"
            className="avatar-uploader"
          >
            <PlusOutlined style={{ paddingRight: "5px" }} /> Upload
          </Upload>
        </div>
      )}
    </div>
  );
};

Component.defaultProps = {
  disabled: true,
  userId: "",
  visibleDeleteButton: false,
  isClearUploadList: false,
  saveDeleteApi: [],
  setSaveFileList: () => {},
  setIsClearUploadList: () => {},
  setData: () => {},
};

export default Component;
