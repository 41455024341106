/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Typography, Spin, Row } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import TitleHeader from "../../components/TitleHeader";
import FilterInputTable from "../../components/FilterInputTable";
import { ReactComponent as CompanyLogo } from "../../assets/icon/company.svg";
import { Company } from "../../interfaces/master.interface";
import { getMasterCompanyBanking } from "../../api/master";
import CustomModal from "../../components/CustomModal";
import useAuthentication from "../../hooks/useAuthentication";
import DataTable from "../../components/DataTable";
import { renderBankingListColumns } from "./columns";
import { getQueryObject } from "../../libs/query";
import useParseQuery from "../../hooks/useParseQuery";
import useOptions from "../../hooks/useOptions";
import {
  bankingStatus,
  paymentType,
} from "../../libs/antd/options/optionsValue";
import {
  approveBanking,
  getBankingList,
  recallBanking,
  cancelBanking,
  renewBanking,
  cancelApproveBanking,
} from "../../api/banking";
import { BankingList } from "../../interfaces/banking.interface";
import { PayloadBankingList } from "../../interfaces/paymentList.interface";
import formatData from "../../libs/formatData";
import setting from "../../setting";

dayjs.extend(isBetween);

const Component = (): JSX.Element => {
  const auth = useAuthentication();
  const {
    queryPaymentType,
    querycompid,
    querydate,
    querydatetype,
    querysearch,
    queryBankingStatus,
    replaceLocation,
  } = useParseQuery();
  const [initial, setInital] = useState(true);
  const [dataSource, setDataSource] = useState<BankingList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [variablePage, setVariablePage] = useState<{
    page: number;
    perPage: number;
  }>({ page: 1, perPage: 10 });
  const [visibleSingleModal, setVisibleSingleModal] = useState<boolean>(false);
  const [visibleMutiModal, setVisibleMutiModal] = useState<boolean>(false);
  const [visibleApproveModal, setVisibleApproveModal] =
    useState<boolean>(false);
  const [visibleCancelApproveModal, setVisibleCancelApproveModal] =
    useState<boolean>(false);
  const [visibleCancelModal, setVisibleCancelModal] = useState<boolean>(false);
  const [visibleRenewModal, setVisibleRenewModal] = useState<boolean>(false);
  const [selectBankingListPayment, setSelectBankingListPayment] =
    useState<BankingList>({} as BankingList);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);

  const [timeUpdate, setTimeUpdate] = useState<string>();
  const [searchText, setSearchText] = useState("");
  const [filterInput, setFilterInput] = useState<any | undefined>();
  const [onTableSelect, setOnTableSelect] = useState<React.Key[]>([]);

  const history = useHistory();
  const location = useLocation();

  const { company } = useOptions({
    dataSourceCompany,
  });

  const isCEO = useMemo(() => {
    return location.pathname.includes("ceo");
  }, [location]);

  const columns = renderBankingListColumns({
    searchText,
    onClickEdit: (record: BankingList) =>
      isCEO
        ? history.push(`/banking/ceo/history/detail/${record.payId}`)
        : history.push(`/banking/history/detail/${record.payId}`),
    setVisibleSingleModal,
    setVisibleApproveModal,
    setVisibleCancelApproveModal,
    setVisibleCancelModal,
    setVisibleRenewModal,
    setSelectBankingListPayment,
    isHistory: true,
    isCEO,
  });

  const payload: PayloadBankingList = useMemo(() => {
    return {
      compId: null,
      dueDate: {
        dateFrom: null,
        dateTo: null,
      },
      entryDate: {
        dateFrom: null,
        dateTo: null,
      },
      isPaymented: isCEO ? true : null,
      isReleased: true,
      paymentType: null,
      status: isCEO ? "APPROVED" : null,
      search: null,
      page: 1,
      perPage: 10,
    };
  }, [isCEO]);

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([getMasterCompanyBanking(auth.userId)])
        .then(([responseCompany]) => {
          setDataSourceCompany(responseCompany.responseData || []);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  const onRecallBanking = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        recallBanking({
          payId: payIds,
          sendBy: parseInt(auth?.userId || "0", 10),
        }),
      ]).then(() => {
        setDataSource(
          dataSource.filter((item) => !payIds.includes(item.payId))
        );
      });
    },
    [auth?.userId, dataSource]
  );

  const onApproveBanking = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        approveBanking({
          payId: payIds,
          sendBy: parseInt(auth?.userId || "0", 10),
        }),
      ]).then(() => {
        window.location.reload();
      });
    },
    [auth?.userId]
  );

  useEffect(() => {
    const queryToObject = getQueryObject(filterInput, location.search);

    const pagination = variablePage;

    if (initial) {
      pagination.page = Number(queryToObject?.page || 1);
      pagination.perPage = Number(queryToObject?.perPage || 10);
    }

    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      setLoading(true);

      const filterValidated = {
        ...queryToObject,
        compId: Number(queryToObject?.compId),
      };

      Promise.all([
        getBankingList({
          ...payload,
          ...filterValidated,
          ...pagination,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(responsePaymentList.responseData);
          setTotalItem(responsePaymentList.total || 0);
          setTimeUpdate(moment().format());
        })
        .finally(() => {
          replaceLocation({
            ...filterInput,
            ...pagination,
          });
          setInital(false);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, variablePage]);

  useEffect(() => {
    if (!initial) {
      replaceLocation({
        ...filterInput,
        page: 1,
        perPage: variablePage.perPage,
      });

      setVariablePage({
        page: 1,
        perPage: variablePage.perPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput]);

  const onSyncDataSource = useCallback(() => {
    const queryToObject = getQueryObject(filterInput, location.search);

    if (auth) {
      setLoading(true);
      const filterValidated = {
        ...queryToObject,
        compId: Number(queryToObject?.compId),
      };
      Promise.all([
        getBankingList({
          ...payload,
          ...filterValidated,
          ...variablePage,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(responsePaymentList.responseData);
          setTotalItem(responsePaymentList.total || 0);
          setTimeUpdate(moment().format());
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("Error : ", error);
        })
        .finally(() => {
          setLoading(false);
          setTimeUpdate(moment().format());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, payload, filterInput, variablePage]);

  const contentRecallModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        {onTableSelect.length > 0 ? (
          <Typography.Text style={{ marginBottom: "20px" }}>
            Are you sure to recall {onTableSelect.length} item(s) ?
          </Typography.Text>
        ) : (
          <Typography.Text style={{ marginBottom: "20px" }}>
            Are you sure to recall payment batch no.{" "}
            {selectBankingListPayment.batchNo} ?
          </Typography.Text>
        )}
      </Spin>
    );
  }, [loading, onTableSelect.length, selectBankingListPayment.batchNo]);

  const footerSingleModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleSingleModal(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);
            Promise.all([onRecallBanking([selectBankingListPayment.payId])])
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
              })
              .finally(() => {
                setLoading(false);
                setVisibleSingleModal(false);
                onSyncDataSource();
              });
          }}
        >
          Recall
        </Button>
      </Row>
    );
  }, [loading, selectBankingListPayment, onRecallBanking, onSyncDataSource]);

  const footerMutiModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleMutiModal(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);
            // eslint-disable-next-line prettier/prettier
            onApproveBanking(
              onTableSelect.map((item) => Number(item))
            )?.finally(
              // eslint-disable-next-line prettier/prettier
              () => {
                setLoading(false);
                setVisibleMutiModal(false);
              }
            );
          }}
        >
          Approve {onTableSelect.length} item(s) ?
        </Button>
      </Row>
    );
  }, [loading, onApproveBanking, onTableSelect]);

  const contentApproveModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        {onTableSelect.length > 0 ? (
          <Typography.Text style={{ marginBottom: "20px" }}>
            Are you sure to approve {onTableSelect.length} item(s) ?
          </Typography.Text>
        ) : (
          <Typography.Text>
            Are you sure to approve {selectBankingListPayment.batchNo}{" "}
            {formatData.toCurrency(selectBankingListPayment.amount)}
            {selectBankingListPayment.currency}?
          </Typography.Text>
        )}
      </Spin>
    );
  }, [loading, onTableSelect.length, selectBankingListPayment]);

  const contentCancelApproveModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text style={{ marginBottom: "20px" }}>
          Are you sure to cancel approve {selectBankingListPayment.batchNo}{" "}
          {formatData.toCurrency(selectBankingListPayment.amount)}
          {selectBankingListPayment.currency}?
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectBankingListPayment]);

  const contentRejectModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text style={{ marginBottom: "20px" }}>
          Are you sure to reject {selectBankingListPayment.batchNo}{" "}
          {formatData.toCurrency(selectBankingListPayment.amount)}
          {selectBankingListPayment.currency}?
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectBankingListPayment]);

  const contentRenewModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text style={{ marginBottom: "20px" }}>
          Are you sure to renew {selectBankingListPayment.batchNo}{" "}
          {formatData.toCurrency(selectBankingListPayment.amount)}
          {selectBankingListPayment.currency}?
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectBankingListPayment]);

  const footerApproveModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CheckOutlined style={{ fontSize: "x-large" }} />}
          block
          style={{
            display: "flex",
            background: "#03703C",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          onClick={() => {
            if (auth) {
              setLoading(true);
              approveBanking({
                payId: [selectBankingListPayment?.payId],
                sendBy: Number(auth.userId),
              }).then(() => {
                setLoading(false);
                setVisibleApproveModal(false);
                onSyncDataSource();
              });
            }
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Approve
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [loading, selectBankingListPayment, auth, onSyncDataSource]);

  const footerCancelApproveModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CloseOutlined />}
          danger
          block
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          onClick={() => {
            if (auth) {
              setLoading(true);
              cancelApproveBanking({
                payId: [selectBankingListPayment?.payId],
                sendBy: Number(auth.userId),
              }).then(() => {
                setLoading(false);
                setVisibleCancelApproveModal(false);
                onSyncDataSource();
              });
            }
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Cancel
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [auth, loading, selectBankingListPayment, onSyncDataSource]);

  const footerRejectModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CloseOutlined />}
          danger
          block
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          onClick={() => {
            if (auth) {
              setLoading(true);
              cancelBanking({
                payId: [selectBankingListPayment?.payId],
                sendBy: Number(auth.userId),
              }).then(() => {
                setLoading(false);
                setVisibleCancelModal(false);
                onSyncDataSource();
              });
            }
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Cancel
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [auth, loading, selectBankingListPayment, onSyncDataSource]);

  const footerRenewModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CloseOutlined />}
          danger
          block
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          onClick={() => {
            if (auth) {
              setLoading(true);
              renewBanking({
                payId: [selectBankingListPayment?.payId],
                sendBy: Number(auth.userId),
              }).then(() => {
                setLoading(false);
                setVisibleRenewModal(false);
                onSyncDataSource();
              });
            }
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Renew
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [auth, loading, selectBankingListPayment, onSyncDataSource]);

  return (
    <div style={{ padding: 20 }}>
      <TitleHeader
        title="History"
        loading={loading}
        dataSizes={dataSource.length}
        timeUpdate={timeUpdate}
      />
      <FilterInputTable
        setFilterInput={setFilterInput}
        querysearch={querysearch}
        queryDateType={querydatetype}
        queryDate={querydate}
        onTableSelect={onTableSelect}
        selectItems={[
          {
            icon: CompanyLogo,
            name: "status",
            placeholder: "Status",
            dataItems: bankingStatus,
            itemIndex: queryBankingStatus,
            span: 3,
          },
          {
            icon: CompanyLogo,
            name: "paymentType",
            placeholder: "Payment Type",
            dataItems: paymentType,
            itemIndex: queryPaymentType,
            span: 3,
          },
          {
            icon: CompanyLogo,
            name: "compId",
            placeholder: "Company",
            dataItems: company,
            itemIndex: querycompid,
            span: 3,
          },
        ]}
        searchText={searchText}
        setMultiConfirm={setVisibleMutiModal}
        setSearchText={setSearchText}
        setModalVisible={setVisibleSingleModal}
      />
      <DataTable
        data={dataSource}
        checkBox
        loading={loading}
        columns={columns}
        totalItem={totalItem}
        searchText={searchText}
        setSearchText={setSearchText}
        variablePage={variablePage}
        setVariablePage={setVariablePage}
        setOnTableSelect={setOnTableSelect}
      />
      {visibleSingleModal ? (
        <CustomModal
          key="SingleModal"
          visible={visibleSingleModal}
          setVisible={setVisibleSingleModal}
          title="Confirm to Recall"
          content={contentRecallModal}
          footer={footerSingleModal}
        />
      ) : null}
      {visibleMutiModal ? (
        <CustomModal
          key="MutipleModal"
          visible={visibleMutiModal}
          setVisible={setVisibleMutiModal}
          title="Confirm to Approve"
          content={contentApproveModal}
          footer={footerMutiModal}
        />
      ) : null}
      {visibleApproveModal ? (
        <CustomModal
          key="ApproveModal"
          visible={visibleApproveModal}
          setVisible={setVisibleApproveModal}
          title="Confirm to Approve"
          content={contentApproveModal}
          footer={footerApproveModal}
        />
      ) : null}
      {visibleCancelApproveModal ? (
        <CustomModal
          key="CancelApproveModal"
          visible={visibleCancelApproveModal}
          setVisible={setVisibleCancelApproveModal}
          title="Confirm to Cancel Approve"
          content={contentCancelApproveModal}
          footer={footerCancelApproveModal}
        />
      ) : null}
      {visibleCancelModal ? (
        <CustomModal
          key="CancelModal"
          visible={visibleCancelModal}
          setVisible={setVisibleCancelModal}
          title="Confirm to Cancel"
          content={contentRejectModal}
          footer={footerRejectModal}
        />
      ) : null}
      {visibleRenewModal ? (
        <CustomModal
          key="RenewModal"
          visible={visibleRenewModal}
          setVisible={setVisibleRenewModal}
          title="Confirm to Renew"
          content={contentRenewModal}
          footer={footerRenewModal}
        />
      ) : null}
    </div>
  );
};

export default Component;
