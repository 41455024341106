import * as actionTypes from "../actionTypes";
import * as accessTokenManager from "../../libs/accessTokenManager";
import * as userInfoManager from "../../libs/userInforManager";
import type {
  DispatchType,
  LoginCallback,
  LogoutCallback,
  UpdateUserCallback,
} from "../../interfaces/authentication.interface";
import * as authenticationAPI from "../../api/authentication";
import { User } from "../../interfaces/user.interface";

export interface LoginData {
  userName: string;
  password: string;
}

export const login =
  (args: LoginData, callback?: LoginCallback) =>
  (dispatch: DispatchType): void => {
    authenticationAPI
      .login({
        userName: args.userName,
        password: args.password,
      })
      .then((result) => {
        const responseData = result.responseData[0];
        accessTokenManager.set(responseData.tokenId);
        userInfoManager.set(responseData);
        dispatch({
          type: actionTypes.AUTHENTICATION_LOGIN,
          payload: responseData,
        });
        callback?.(undefined, undefined, result);
      })
      .catch((error) => {
        if (error.response) {
          // console.log("Error", error.response);
          callback?.(error.response.data, error.response.data.message);
        } else {
          // console.log("Error CORS", error);
        }
      });
  };

export const signInWithAzure =
  (token: string, callback?: LoginCallback) =>
  (dispatch: DispatchType): void => {
    authenticationAPI
      .signInWithAzure(token)
      .then((result) => {
        // console.log("Result : ", result);
        const responseData = result.responseData[0];
        accessTokenManager.set(token);
        userInfoManager.set(responseData);
        dispatch({
          type: actionTypes.AUTHENTICATION_LOGIN,
          payload: responseData,
        });
        callback?.(undefined, undefined, result);
      })
      .catch((error) => {
        if (error.response) {
          // console.log("Error", error.response);
          callback?.(error.response.data, error.response.data.message);
        } else {
          // console.log("Error CORS", error);
        }
      });
  };

export const logout =
  (callback?: LogoutCallback) =>
  (dispatch: DispatchType): void => {
    // console.log("logout");
    accessTokenManager.clear();
    dispatch({
      type: actionTypes.AUTHENTICATION_LOGOUT,
    });
    callback?.(null);
  };

export const updateUser =
  (callback?: UpdateUserCallback) =>
  (dispatch: DispatchType): void => {
    authenticationAPI
      .getProfile(userInfoManager.get() as User)
      .then((result) => {
        dispatch({
          type: actionTypes.AUTHENTICATION_UPDATE_USER,
          payload: result,
        });
        callback?.(null, result);
      })
      .catch((error) => {
        callback?.(error);
      });
  };
