/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Typography,
  Popover,
  Checkbox,
  List,
  Space,
  FormInstance,
} from "antd";
import moment from "moment";
import {
  CopyOutlined,
  EditFilled,
  EllipsisOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { cloneDeep } from "lodash";
import WithPOItemModal from "./withPOItemModal";
import "./index.css";
import {
  AccountAssignment,
  Company,
  MaterialGroup,
  MaterialSet,
  PurchasingGroup,
  PurchasingOrg,
  Unit,
  Vendor,
} from "../../interfaces/master.interface";
import formatData from "../../libs/formatData";
import {
  FormDetailMemoWithPO,
  PaymentInfoItemMemo,
} from "../../interfaces/prmemo.interface";

const { Text } = Typography;

interface SelectOption {
  key: string;
  label: string;
  value: string;
}

interface Props {
  headerForm: FormInstance;
  dataTable?: PaymentInfoItemMemo[];
  setDataTable: (value: PaymentInfoItemMemo[]) => void;
  isAllApproved?: boolean;
  isAdmin?: boolean;
  isSameVendor: boolean;
  isCalculateTax: boolean;
  currencyData: SelectOption[];
  optionsVendor: SelectOption[];
  optionsAccount: SelectOption[];
  optionsCompany: SelectOption[];
  optionsMaterial: SelectOption[];
  optionsExpense: SelectOption[];
  optionsPurGroup: SelectOption[];
  optionsPurOrg: SelectOption[];
  optionsUnit: SelectOption[];
  optionsAsset: SelectOption[];
  optionsCostCenter: SelectOption[];
  optionsInfoRecord: SelectOption[];
  optionsStorageLoc: SelectOption[];
  datasourceCompany: Company[];
  dataSourceMaterialGroup: MaterialGroup[];
  dataSourceMaterialSet: MaterialSet[];
  dataSourceVendor: Vendor[];
  dataSourceAccountAssign: AccountAssignment[];
  dataSourcePurGroup: PurchasingGroup[];
  dataSourcePurOrg: PurchasingOrg[];
  dataSourceUnit: Unit[];
}

const Component = ({
  headerForm,
  dataTable,
  setDataTable,
  isAllApproved = false,
  isAdmin = false,
  currencyData,
  isCalculateTax,
  isSameVendor,
  optionsVendor,
  optionsAccount,
  optionsPurGroup,
  optionsCompany,
  optionsMaterial,
  optionsExpense,
  optionsPurOrg,
  optionsUnit,
  optionsAsset,
  optionsCostCenter,
  optionsInfoRecord,
  optionsStorageLoc,
  datasourceCompany,
  dataSourceMaterialGroup,
  dataSourceMaterialSet,
  dataSourceVendor,
  dataSourceAccountAssign,
  dataSourcePurGroup,
  dataSourcePurOrg,
  dataSourceUnit,
}: Props): JSX.Element => {
  const [dataSourece, setDataSource] = useState<PaymentInfoItemMemo[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<FormDetailMemoWithPO>();

  const modalProps = {
    isAllApproved,
    isAdmin,
    isCalculateTax,
    isSameVendor,
    optionsAccount,
    dataSourceVendor,
    dataSourceAccountAssign,
    currencyData,
    headerForm,
    optionsCompany,
    optionsMaterial,
    optionsPurGroup,
    datasourceCompany,
    dataSourceMaterialGroup,
    optionsVendor,
    optionsExpense,
    dataSourceMaterialSet,
    dataSourcePurGroup,
    optionsPurOrg,
    optionsUnit,
    dataSourcePurOrg,
    dataSourceUnit,
    optionsAsset,
    optionsCostCenter,
    optionsInfoRecord,
    optionsStorageLoc,
  };

  useEffect(() => {
    setDataTable(dataTable || []);
    setDataSource(dataTable || []);
  }, [dataTable, setDataTable]);

  useEffect(() => {
    const data = cloneDeep(dataSourece);
    setDataTable(data);
  }, [dataSourece, setDataTable]);

  const records = dataSourece.filter((value) => !value.isDelete);

  const handleAdd = (values: FormDetailMemoWithPO) => {
    const accountObject = dataSourceAccountAssign.find(
      (item) => item.accoId.toString() === values.accoId
    );
    const matgObject = dataSourceMaterialGroup.find(
      (item) => item.matgId.toString() === values.matgId
    );

    setDataSource((prev) => [
      ...prev,
      {
        ...values,
        key: `LOCAL-${dataSourece.length}`,
        payItemId: 0,
        paymentItem: `${records.length + 1}`.padStart(3, "0"),
        paymentText: values.itemText,
        totalValue:
          values.totalValue > 0 ? values.totalValue : values.valuationPrice,
        matgId: matgObject?.matgId || 0,
        materailCode: values?.materailCode || "",
        accoId: accountObject?.accoId || 0,
        accountAssignCategory: accountObject?.accAssName || "",
        accountAssignCategoryId: accountObject?.accAssId || "",
        accountAssignCode: accountObject?.accAssId || "",
        companyCode: values.companyCode || "",
        purGroupId: values.purGroupId || "",
        purGroupName: values.purGroupName || "",
        orderNumberWbs: values.orderNumberWbs || "",
        orderNumber: values.orderNumber || "",
        orderNumberWbsdesc: "",
        orderName: "",
        dayFormat: "",
        storageLoCode: values.storageLoCode || "",
        storageLoText: values.storageLoCode || "",
        infoRecord: values.infoRecord || "",
        desiredAccountNum: values.desiredAccountNum || "",
        desiredAccountText: values.desiredAccountText || "",
        companyCodeMemo: "",
        taxInvoiceNo: "",
        invoiceDate: "",
        taxCode: "",
        docYear: "",
        fiDocNo: "",
        isExceptionBudget: false,
        assetCode: values.assetCode || "",
        assetText: values.assetText || "",
        isCalculateTax: values.isCalculateTax || false,
        plantId: values.plantId || 0,
        coArea: values.coArea || "",
        requestedBy: values.requestedBy || "",
        accountNum: "",
        accountText: "",
        materialId: 0,
        materialName: "",
        qtyUnit: values.qtyUnit,
        perUnit: values.perUnit,
        deliveryDate: values.deliveryDate.format(),
        // deliveryDate: values.deliveryDate,
        // exchangeRate: values.exchangeRate,
        // currency: values.currency,
        // purGroup: values.purGroup,
        // companyCode: values.companyCode,
        // materialId: "string",
        // materialName: "string",
        // qty: values.qty,
        // valuationPrice: 0.0,
        per: values.per,
        // trackNo: "string",
        // assetCode: "string",
        // glAccount: "string",
        // desiredAccountText: "string",
        // desiredAccountNum: "",
      },
    ]);
  };

  const handleBeforeEdit = (data: PaymentInfoItemMemo, index: number) => {
    const showAccountAssign = dataSourceAccountAssign.find(
      (item) => item.accoId === data.accoId
    )?.accAssName;
    const showCompany = datasourceCompany.find(
      (item) => `${item.compId}` === data.companyCode
    )?.companyName;
    const showMaterialGroup = dataSourceMaterialGroup.find(
      (item) => item.matgId === data.matgId
    )?.materialGroupName;
    const showPurchase = dataSourcePurGroup.find(
      (item) => `${item.purgId}` === data.purGroup
    )?.purGroupName;
    setSelectedRow({
      index,
      ...data,
      payItemId: data.payItemId,
      extendItemText: data.extendItemText,
      accoId: data.accoId.toString(),
      materialId: data.materialId ? data.materialId.toString() : "",
      materailCode: data.materailCode.toString(),
      matgId: data.matgId.toString(),
      accountNum: data.accountNum,
      valuationPrice: data.valuationPrice,
      totalValue: data.totalValue,
      amount: data.totalValue,
      isCalculateTax: data.isCalculateTax,
      itemText: data.paymentText,
      orderNumber: data.orderNumber,
      orderNumberWbs: data.orderNumberWbs,
      _ShowAccountAssignment: showAccountAssign || "",
      accountText: data.accountText || "",
      paymentItem: data?.paymentItem,
      _ShowMaterialGroup: showMaterialGroup,
      _ShowPurGroup: showPurchase,
      _ShowPurchasingOrg: showCompany,
      qtyUnit: data.qtyUnit,
      perUnit: data.perUnit,
      deliveryDate: data.deliveryDate ? moment(data.deliveryDate) : moment(),
      // infoRecord: data?.infoRecord,
      // purGroup: "",
      // companyCode: "",
      // matgId: 0,
      // materailCode: "",
      // materialGroup: "",
      // orderName: "",
      // materialId: "string",
      // materialName: "string",
      // qty: 0,
      // deliveryDate: "string",
      // dayFormat: "string",
      // valuationPrice: 0.0,
      per: data.per,
      // trackNo: "string",
      // assetCode: "string",
      // assetText: "string",
      // glAccount: "string",
      // plantId: "string",
      // storageLoCode: "string",
      // storageLoText: "string",
      // infoRecordCode: "string",
      // infoRecordText: "string",
      // coArea: "string",
      // desiredAccountText: "string",
      // desiredAccountNum: "string",
    });

    setVisibleModalEdit(true);
  };

  const handleEdit = (values: FormDetailMemoWithPO) => {
    setDataSource((prev: PaymentInfoItemMemo[]): PaymentInfoItemMemo[] => {
      return prev.map((value) => {
        if (values.paymentItem !== value.paymentItem) {
          return value;
        }
        return {
          ...value,
          ...values,
          paymentText: values.itemText,
          totalValue:
            values.totalValue > 0 ? values.totalValue : values.valuationPrice,
          isCalculateTax: values.isCalculateTax,
          orderNumberWbs: values.orderNumberWbs || "",
          orderNumberWbsdesc: "",
          orderNumber: values.orderNumber || "",
          accoId: parseInt(values.accoId, 10),
          matgId: parseInt(values.matgId, 10),
          materialId: values.materialId ? parseInt(values.materialId, 10) : 0,
          perUnit: values.perUnit,
          qtyUnit: values.qtyUnit,
          deliveryDate: values.deliveryDate ? values.deliveryDate.format() : "",
        };
      });
    });
  };

  const handleDuplicate = async (data: PaymentInfoItemMemo) => {
    setDataSource((prev) => [
      ...prev,
      {
        ...data,
        payItemId: 0,
        key: `LOCAL-${dataSourece.length}`,
        paymentItem: `${records.length + 1}`.padStart(3, "0"),
      },
    ]);
  };

  const handleDelete = (payItemId: number, paymentItem: string) => {
    setDataSource((prev: PaymentInfoItemMemo[]): PaymentInfoItemMemo[] => {
      const item: any = records.find((value) => {
        return value.payItemId
          ? value.payItemId === payItemId
          : value.paymentItem === paymentItem;
      });
      let newIndex = 0;
      return prev.map((value: PaymentInfoItemMemo) => {
        if (payItemId !== 0 && value.payItemId === payItemId) {
          return {
            ...item,
            isDelete: true,
            paymentItem: null,
          };
        }
        if (value.isDelete) {
          return value;
        }
        if (paymentItem === value.paymentItem) {
          return {
            ...item,
            isDelete: true,
          };
        }
        newIndex += 1;
        return {
          ...value,
          key: `LOCAL-${newIndex}`,
          paymentItem: `${newIndex}`.padStart(3, "0"),
        };
      });
    });
  };

  const columns = [
    {
      dataIndex: "paymentItem",
      title: "Item",
      width: 50,
      align: "center",
      render: (index: number) => {
        return `#${index}`;
      },
    },
    {
      dataIndex: "paymentText",
      title: "Item Text",
      width: 100,
    },
    {
      dataIndex: "totalValue",
      title: "Amount (Excluded Vat)",
      width: 80,
      align: "right",
      render: (value: number) => {
        return formatData.toCurrency(value);
      },
    },
    {
      dataIndex: "isCalculateTax",
      title: "Calc. Tax",
      align: "center",
      width: 50,
      render: (data: boolean) => {
        return (
          <Checkbox disabled={isAllApproved} value={data} checked={data} />
        );
      },
    },
    {
      dataIndex: "orderNumberWbs",
      title: "WBS",
      width: 150,
    },
    {
      dataIndex: "orderNumber",
      title: "Order No.",
      width: 150,
    },
    {
      dataIndex: "pvNumber",
      title: "PV number",
      width: 150,
    },
    {
      title: "AC",
      align: "center",
      width: 50,
      fixed: "right",
      render: (_item: never, record: PaymentInfoItemMemo, index: number) => {
        const stylesListItem = {
          borderBottom: "unset",
          padding: "5px 10px",
          cursor: "pointer",
        };
        const stylesSpace = { display: "flex", justifyContent: "center" };
        const stylesEdit = { color: "#174291" };
        const stylesDupplicate = { color: "#545454" };
        const stylesDelete = { color: "#D82E18" };

        return (
          <Popover
            placement="bottom"
            trigger="focus"
            content={
              <List itemLayout="horizontal" style={{ width: 100 }}>
                <List.Item
                  style={stylesListItem}
                  onClick={() => {
                    handleBeforeEdit(record, index);
                  }}
                >
                  <Space
                    size={8}
                    align="center"
                    onClick={() => {
                      handleBeforeEdit(record, index);
                    }}
                  >
                    <div style={stylesSpace}>
                      <EditFilled
                        style={stylesEdit}
                        onClick={() => {
                          handleBeforeEdit(record, index);
                        }}
                      />
                    </div>
                    <Text
                      style={stylesEdit}
                      onClick={() => {
                        handleBeforeEdit(record, index);
                      }}
                    >
                      {isAllApproved ? "Details" : "Edit"}
                    </Text>
                  </Space>
                </List.Item>
                {!isAllApproved && (
                  <List.Item
                    style={stylesListItem}
                    onClick={() => handleDuplicate(record)}
                  >
                    <Space size={8} align="center">
                      <div style={stylesSpace}>
                        <CopyOutlined style={stylesDupplicate} />
                      </div>
                      <Text style={stylesDupplicate}>Copy Item</Text>
                    </Space>
                  </List.Item>
                )}
                {!isAllApproved && (
                  <List.Item
                    style={stylesListItem}
                    onClick={() => {
                      handleDelete(record.payItemId, record.paymentItem);
                    }}
                  >
                    <Space size={8} align="center">
                      <div style={stylesSpace}>
                        <MinusCircleOutlined style={stylesDelete} />
                      </div>
                      <Text style={stylesDelete}>Delete</Text>
                    </Space>
                  </List.Item>
                )}
              </List>
            }
          >
            <Button
              type="link"
              icon={<EllipsisOutlined rotate={90} color="black" />}
            />
          </Popover>
        );
      },
    },
  ];

  return (
    <>
      <Table
        bordered
        className="DetailsTable"
        columns={columns as any}
        dataSource={records}
        onChange={() => {}}
        scroll={{ x: 1500 }}
        footer={() => (
          <>
            {!isAllApproved && (
              <Button
                icon={<PlusOutlined style={{ fontSize: "14px" }} />}
                style={{
                  textAlign: "start",
                  margin: "10px 0px",
                  width: "fit-content",
                }}
                type="link"
                block
                onClick={() => {
                  setVisibleModal(true);
                }}
              >
                <Text style={{ color: "#174291" }} strong>
                  Add Item
                </Text>
              </Button>
            )}
          </>
        )}
      />
      <WithPOItemModal
        {...modalProps}
        title="Add"
        visible={visibleModal}
        setVisible={setVisibleModal}
        onFinish={(values) => handleAdd(values)}
        data={undefined}
      />
      <WithPOItemModal
        {...modalProps}
        title="Edit"
        visible={visibleModalEdit}
        setVisible={setVisibleModalEdit}
        onFinish={(values) => handleEdit(values)}
        data={selectedRow}
      />
    </>
  );
};

export default Component;

Component.defaultProps = {
  dataTable: [],
  isAllApproved: false,
  isAdmin: false,
};
