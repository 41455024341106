export const getStatus = (
  approve: boolean,
  rejected: boolean,
  reviewed: boolean,
  admin: boolean,
  firstPending: boolean
): string => {
  if (reviewed) {
    if (approve) {
      return "REVIEWED";
    } else if (rejected) {
      return "REVIEW REJECTED";
    } else if (firstPending) {
      return "REVIEW PENDING";
    }
    return "REVIEW";
  } else if (admin) {
    if (approve) {
      return "VERIFIED";
    } else if (rejected) {
      return "ADMIN REJECTED";
    } else if (firstPending) {
      return "ADMIN PENDING";
    }
    return "ADMIN PENDING";
  } else if (approve) {
    return "APPROVED";
  } else if (rejected) {
    return "REJECTED";
  } else if (firstPending) {
    return "PENDING";
  }
  return "pending2";
};
