import {
  PaperClipOutlined,
  InfoCircleOutlined,
  SendOutlined,
  EditOutlined,
  SwapOutlined,
  TrademarkCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { Tooltip, Space, Typography, Row, Button, Tag, Badge } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import UserStatusRow from "../../components/UserStatusRow";
import { PaymentType } from "../../enum/banking.enum";
import { BankingList } from "../../interfaces/banking.interface";
import formatData from "../../libs/formatData";

interface ColumnsProps {
  searchText: string;
  onClickEdit: (record: BankingList) => void;
  setVisibleSingleModal: (value: boolean) => void;
  setVisibleApproveModal?: (value: boolean) => void;
  setVisibleCancelApproveModal?: (value: boolean) => void;
  setVisibleCancelModal?: (value: boolean) => void;
  setVisibleRenewModal?: (value: boolean) => void;
  setSelectBankingListPayment: (record: BankingList) => void;
  isHistory?: boolean;
  isCEO?: boolean;
}

export const renderBankingListColumns = ({
  searchText,
  onClickEdit,
  setVisibleSingleModal,
  setSelectBankingListPayment,
  setVisibleApproveModal = () => {},
  setVisibleCancelApproveModal = () => {},
  setVisibleCancelModal = () => {},
  setVisibleRenewModal = () => {},
  isHistory = false,
  isCEO = false,
}: ColumnsProps): ColumnsType<BankingList> => {
  const getHighlighterComponent = (value: string) => {
    return (
      <Highlighter
        highlightClassName="highlight"
        searchWords={[searchText]}
        // autoEscape={true}
        textToHighlight={value || "--"}
      />
    );
  };

  const getPaymentType = (type: string) => {
    if (type === PaymentType.TRANSFER) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#174291",
          }}
        >
          <SwapOutlined style={{ paddingRight: "4px" }} />
          {PaymentType.TRANSFER_TEXT}
        </div>
      );
    }
    return (
      <div style={{ display: "flex", alignItems: "center", color: "#C14F29" }}>
        <EditOutlined style={{ paddingRight: "4px" }} />
        {PaymentType.CHEQUE_TEXT}
      </div>
    );
  };

  const getHistoryStatus = (type: string) => {
    const getColor = () => {
      if (type === "WAITING") {
        return "geekblue";
      } else if (type === "APPROVED") {
        return "green";
      }
      return "red";
    };

    const capitalize = (text: string) => {
      if (text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }

      return "";
    };

    return (
      <div>
        <Tag style={{ borderRadius: "6px" }} color={getColor()}>
          <Badge color={getColor()} text="" size="small" /> {capitalize(type)}
        </Tag>
      </div>
    );
  };

  return [
    ...(isHistory
      ? [
          {
            title: "Status",
            width: 110,
            dataIndex: "status",
            render: (value: string) => getHistoryStatus(value),
            // render: (value: string) => value,
          },
        ]
      : []),
    {
      title: "Pay Type",
      dataIndex: "paymentType",
      width: 100,
      render: (value: string) => getPaymentType(value),
    },
    {
      key: "company",
      title: "Company",
      dataIndex: "compCode",
      width: isHistory ? 85 : 85,
      render: (value: string) => getHighlighterComponent(value),
    },
    {
      key: "companyName",
      title: "Company Name",
      dataIndex: "company",
      width: 150,
      render: (value: string) => getHighlighterComponent(value),
    },
    {
      key: "batchRef",
      title: "Batch Ref. (Transfer)",
      dataIndex: "batchNo",
      width: 120,
      render: (value: string) => getHighlighterComponent(value),
    },
    {
      key: "total",
      title: "Total",
      dataIndex: "totalDocument",
      width: 60,
      render: (value: number) => getHighlighterComponent(value.toString()),
    },
    {
      key: "currency",
      title: "Currency",
      dataIndex: "currency",
      width: 90,
      render: (value: string) => getHighlighterComponent(value),
    },
    {
      key: "amount",
      title: "Amount (THB)",
      width: 100,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {`
            ${formatData.toCurrency(record.amountInLocal)}
            `}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "amount",
      title: "Amount (CURR)",
      width: 100,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {`
            ${formatData.toCurrency(record.amount)}
            `}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "files",
      title: "Attachment",
      dataIndex: "totalAttachFile",
      width: 100,
      render: (record) => (
        <Space size="small">
          <PaperClipOutlined rotate={135} />
          <Typography.Text>{record}</Typography.Text>
        </Space>
      ),
    },
    {
      key: "dueDate",
      title: "Due Date",
      dataIndex: "dueDate",
      width: 95,
      render: (value: string) =>
        getHighlighterComponent(value && moment(value).format("DD.MM.YYYY")),
    },
    ...(isHistory || isCEO
      ? [
          {
            title: "Approval Status",
            dataIndex: "accountText",
            width: 130,
            render: (_value: string, row: BankingList) => {
              return (
                <UserStatusRow
                  approvers={row.releasedTo}
                  userInfo={row.releasedBy}
                  isBanking
                />
              );
            },
          },
          {
            title: "Last Update",
            dataIndex: "updateDate",
            width: 90,
            render: (value: string) => (
              <Highlighter
                highlightClassName="highlight"
                searchWords={[searchText]}
                textToHighlight={dayjs(value).format("DD.MM.YYYY, HH:mm")}
              />
            ),
          },
        ]
      : []),
    {
      key: "action",
      title: "Detail/Actions",
      fixed: "right",
      width: isHistory ? 190 : 120,
      render: (record: BankingList) => (
        <Row align="middle">
          <Tooltip placement="top" title="Detail">
            <Button
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={() => {
                onClickEdit(record);
              }}
            />
          </Tooltip>
          {isCEO ||
            (isHistory &&
              record.enableAdmin &&
              record.paymentType === PaymentType.TRANSFER &&
              record.status !== "APPROVED" && (
                <Tooltip placement="top" title="Recall">
                  <Button
                    type="text"
                    icon={<TrademarkCircleOutlined />}
                    onClick={() => {
                      setVisibleSingleModal(true);
                      setSelectBankingListPayment(record);
                    }}
                  />
                </Tooltip>
              ))}
          {!isCEO &&
            !isHistory &&
            record.enableAdmin &&
            record.paymentType === PaymentType.TRANSFER && (
              <Tooltip placement="top" title="Release">
                <Button
                  size="small"
                  disabled={false}
                  onClick={() => {
                    setVisibleSingleModal(true);
                    setSelectBankingListPayment(record);
                  }}
                >
                  <SendOutlined style={{ color: "#174291" }} />
                  <Typography.Text style={{ marginLeft: "4px" }} strong>
                    Release
                  </Typography.Text>
                </Button>
              </Tooltip>
            )}
          {/*             
              record.paymentType === PaymentType.TRANSFER &&
              record.status === "NEW" &&
              record.enableAdmin && (
                <Tooltip placement="top" title="Release">
                  <Button
                    size="small"
                    disabled={false}
                    onClick={() => {
                      setVisibleSingleModal(true);
                      setSelectBankingListPayment(record);
                    }}
                  >
                    <Row align="middle">
                      <SendOutlined
                        style={{ color: "#174291", paddingRight: "4px" }}
                      />
                      <Typography.Text strong>Release</Typography.Text>
                    </Row>
                  </Button>
                </Tooltip>
              )
            ))} */}
          {isCEO ||
            (isHistory &&
              record.enableAdmin &&
              record.paymentType === PaymentType.TRANSFER &&
              record.status === "APPROVED" && (
                <>
                  <Tooltip placement="top" title="Cancel Approved">
                    <Button
                      type="text"
                      icon={<CloseOutlined style={{ color: "#FF4D4E" }} />}
                      onClick={() => {
                        setSelectBankingListPayment(record);
                        setVisibleCancelApproveModal(true);
                      }}
                    />
                  </Tooltip>
                </>
              ))}
          {isCEO ||
            (isHistory &&
              record.enableAdmin &&
              record.paymentType === PaymentType.TRANSFER &&
              record.status === "WAITING" && (
                <>
                  <Tooltip placement="top" title="Renew">
                    <Button
                      type="text"
                      icon={<RetweetOutlined style={{ color: "#FF4D4E" }} />}
                      onClick={() => {
                        setSelectBankingListPayment(record);
                        setVisibleRenewModal(true);
                      }}
                    />
                  </Tooltip>
                  {/* <Tooltip placement="top" title="Cancel">
                    <Button
                      type="text"
                      icon={<CloseOutlined style={{ color: "#FF4D4E" }} />}
                      onClick={() => {
                        setSelectBankingListPayment(record);
                        setVisibleCancelModal(true);
                      }}
                    />
                  </Tooltip> */}
                  <Tooltip placement="top" title="Approve">
                    <Button
                      type="text"
                      icon={<CheckOutlined style={{ color: "#03703C" }} />}
                      onClick={() => {
                        setSelectBankingListPayment(record);
                        setVisibleApproveModal(true);
                      }}
                    />
                  </Tooltip>
                </>
              ))}
        </Row>
      ),
    },
  ];
};
