/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Typography, Spin, Row, Tabs, Form, Input } from "antd";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  PrinterOutlined,
  CloseCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import moment from "moment";
import {
  RequestList,
  ResponseRequestList,
} from "../../interfaces/paymentList.interface";
import TitleHeader from "../../components/TitleHeader";
import FilterInputTable from "../../components/FilterInputTable";
import { CustomDrawer } from "../../components/Drawer";
import { TabsHeader, TabsDetail } from "../../components/Drawer/TabsRequest";
import { TabsApprove, TabsAttached } from "../../components/Drawer/Tabs";
import { ReactComponent as Reject } from "../../assets/icon/reject.svg";
import { ReactComponent as TeamLogo } from "../../assets/icon/team.svg";
import { ReactComponent as CompanyLogo } from "../../assets/icon/company.svg";
import { ReactComponent as ConfirmBadge } from "../../assets/icon/confirmBadge.svg";
import { ReactComponent as RejectBadge } from "../../assets/icon/rejectBadge.svg";
import { ReactComponent as ReversedBadge } from "../../assets/icon/reversedBadge.svg";

import {
  getPaymentListHistory,
  sendReCallPaymentList,
} from "../../api/paymentList";
import { modalRejected } from "../../api/modalConfirm";
import { Company, PurchasingGroup } from "../../interfaces/master.interface";
import { getMasterCompany, getMasterPurchasingGroup } from "../../api/master";
import CustomModal from "../../components/CustomModal";
import PVModal from "../../components/PVModal";
import useAuthentication from "../../hooks/useAuthentication";
import DataTable from "../../components/DataTable";
import formatData from "../../libs/formatData";
import { syncsap } from "../../api/service";
import { ReCallIcon } from "../../components/CustomIcon";
import { printHistoryLists, pvStatusHistory } from "../../api/history";
import { renderRequestHistoryColumns } from "./columns";
import useParseQuery from "../../hooks/useParseQuery";
import { getBooleanFromQuery, getQueryObject } from "../../libs/query";
import {
  activityState,
  isImmediatelyDrop,
} from "../../libs/antd/options/optionsValue";
import useOptions from "../../hooks/useOptions";
import { mapDataSource } from "../../libs/array/mappingPaymentList";
import setting from "../../setting";

const { TextArea } = Input;

dayjs.extend(isBetween);

const Component = (): JSX.Element => {
  const auth = useAuthentication();
  const location = useLocation();

  const {
    queryimmediate,
    querypurgeid,
    querycompid,
    querydate,
    querydatetype,
    querysearch,
    queryactivity,
    replaceLocation,
  } = useParseQuery();
  const [initial, setInital] = useState(true);
  const [dataSource, setDataSource] = useState<RequestList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [variablePage, setVariablePage] = useState<{
    page: number;
    perPage: number;
  }>({ page: 1, perPage: 10 });
  const [visibleSingleModal, setVisibleSingleModal] = useState<boolean>(false);
  const [visiblePVModal, setVisiblePVModal] = useState<boolean>(false);
  const [visibleReversedModal, setVisibleReversedModal] =
    useState<boolean>(false);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [selectRequestListPayment, setSelectRequestListPayment] =
    useState<RequestList>({} as RequestList);
  const [dataSourcePurchasingGroup, setDataSourcePurchasingGroup] = useState<
    PurchasingGroup[]
  >([]);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);
  const [form] = Form.useForm();
  const [timeUpdate, setTimeUpdate] = useState<string>();
  const [searchText, setSearchText] = useState("");
  const [filterInput, setFilterInput] = useState<any | undefined>();
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [isReject, setIsReject] = useState<boolean>(false);

  const { company, purchasingGroup } = useOptions({
    dataSourceCompany,
    dataSourcePurchasingGroup,
  });

  const columns = renderRequestHistoryColumns({
    searchText,
    setVisibleDrawer,
    setVisibleSingleModal,
    setVisiblePVModal,
    setVisibleReversedModal,
    setSelectRequestListPayment,
    setLoading,
  });

  const requestPayload = useMemo(() => {
    return {
      waitApproveBy: "",
      activityState: null,
      compId: null,
      purgId: null,
      isImmediately: null,
      isOverdue: false,
      isUrgent: false,
      isCancelable: false,
      dueDate: null,
      documentDate: null,
      postingDate: null,
      page: 1,
      perPage: 10,
    };
  }, []);

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([
        getMasterPurchasingGroup(auth.userId),
        getMasterCompany(auth.userId),
      ])
        .then(([responsePurchasing, responseCompany]) => {
          setDataSourcePurchasingGroup(responsePurchasing.responseData);
          setDataSourceCompany(responseCompany.responseData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  useEffect(() => {
    const queryToObject = getQueryObject(filterInput, location.search);

    const pagination = variablePage;

    if (initial) {
      pagination.page = Number(queryToObject?.page || 1);
      pagination.perPage = Number(queryToObject?.perPage || 10);
    }

    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      setLoading(true);

      const filterValidated = {
        ...queryToObject,
        isImmediately: getBooleanFromQuery(
          queryToObject?.isImmediately as string
        ),
        compId: Number(queryToObject?.compId),
        purgId: Number(queryToObject?.purgId),
      };

      Promise.all([
        getPaymentListHistory({
          sendApproveBy: `${auth.userId}`,
          search: `${searchText}`,
          ...requestPayload,
          ...filterValidated,
          ...pagination,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(
            mapDataSource(responsePaymentList as ResponseRequestList)
          );
          setTotalItem(responsePaymentList.total);
          setTimeUpdate(moment().format());
        })
        .finally(() => {
          replaceLocation({
            ...filterInput,
            ...pagination,
          });
          setInital(false);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, variablePage]);

  useEffect(() => {
    if (!initial) {
      replaceLocation({
        ...filterInput,
        page: 1,
        perPage: variablePage.perPage,
      });

      setVariablePage({
        page: 1,
        perPage: variablePage.perPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput]);

  const onSyncDataSource = useCallback(() => {
    const queryToObject = getQueryObject(filterInput, location.search);

    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      const filterValidated = {
        ...queryToObject,
        isImmediately: getBooleanFromQuery(
          queryToObject?.isImmediately as string
        ),
        compId: Number(queryToObject?.compId),
        purgId: Number(queryToObject?.purgId),
      };
      setLoading(true);
      syncsap();
      Promise.all([
        getPaymentListHistory({
          sendApproveBy: `${auth.userId}`,
          search: `${searchText}`,
          ...requestPayload,
          ...variablePage,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(
            mapDataSource(responsePaymentList as ResponseRequestList)
          );
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("Error : ", error);
        })
        .finally(() => {
          setLoading(false);
          setTimeUpdate(moment().format());
        });
    }
  }, [
    filterInput,
    location.search,
    auth,
    searchText,
    requestPayload,
    variablePage,
  ]);

  const onSendReCallPaymentList = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        sendReCallPaymentList({
          payId: payIds,
          sendApproveBy: auth?.userId.toString() || "",
        }),
      ]).then(() => {
        setDataSource(
          dataSource.filter((item) => !payIds.includes(item.payId))
        );
        setTotalItem(totalItem - 1);
        setVisibleDrawer(false);
      });
    },
    [auth, dataSource, totalItem]
  );

  const setPVmodal = (value: boolean) => {
    setVisiblePVModal(value);
    setIsCancel(value);
    setIsReject(value);
  };

  const titleDrawer = useMemo(() => {
    return (
      <Row align="middle">
        <InfoCircleOutlined />
        <Typography.Text style={{ paddingLeft: 10 }}>
          Information
        </Typography.Text>
      </Row>
    );
  }, []);

  const badgeNote = useMemo(() => {
    const styles: React.CSSProperties = {
      position: "absolute",
      top: 0,
      right: 0,
    };
    if (selectRequestListPayment.activityState === "approved") {
      return <ConfirmBadge style={styles} />;
    } else if (selectRequestListPayment.activityState === "rejected") {
      if (selectRequestListPayment.reverseDoc !== "") {
        return <ReversedBadge style={styles} />;
      }
      return <RejectBadge style={styles} />;
    }
    return null;
  }, [
    selectRequestListPayment.activityState,
    selectRequestListPayment.reverseDoc,
  ]);

  const contentDrawer = useMemo(() => {
    const prHeaderNote = selectRequestListPayment?.prHeaderNote;

    return (
      <>
        <Row>
          <Typography.Title
            level={3}
            style={{
              width: "100%",
              paddingLeft: 24,
              paddingRight: 24,
            }}
            className="new-line"
          >
            <div style={{ position: "relative" }}>
              {prHeaderNote}
              {badgeNote}
            </div>
          </Typography.Title>
        </Row>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Headers" key="1">
            <TabsHeader data={selectRequestListPayment} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Details" key="2">
            <TabsDetail data={selectRequestListPayment} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attacheds" key="3">
            <TabsAttached
              data={selectRequestListPayment?.paymentInfoAttach || []}
              disabled
              visibleDeleteButton={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Approval Status" key="4">
            <TabsApprove
              payId={selectRequestListPayment.payId}
              userInfo={selectRequestListPayment?.sendApproveBy}
              sendApproveDate={selectRequestListPayment?.sendApproveDate}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }, [badgeNote, selectRequestListPayment]);

  const footerDrawer = useMemo(() => {
    return (
      <div className="drawer-footer">
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            Amount ({selectRequestListPayment.currency}):
          </Typography.Title>
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            <Typography.Text style={{ color: "#174291", paddingLeft: 5 }}>
              {formatData.toCurrency(selectRequestListPayment.amount)}
            </Typography.Text>
          </Typography.Title>
        </Row>
        <Row justify="start" align="middle">
          {selectRequestListPayment.approvers &&
            !selectRequestListPayment.approvers[0].isApprove &&
            !selectRequestListPayment.approvers[0].isreject && (
              <Button
                loading={loading}
                icon={<ReCallIcon />}
                style={{
                  display: "flex",
                  background: "#545454",
                  alignItems: "center",
                  padding: 20,
                  gap: 10,
                }}
                onClick={() => setVisibleSingleModal(true)}
              >
                <Typography.Text strong style={{ color: "white" }}>
                  Recall Request
                </Typography.Text>
              </Button>
            )}
        </Row>
      </div>
    );
  }, [loading, selectRequestListPayment]);

  const contentSingleModal = useMemo(() => {
    const prHeaderNote = selectRequestListPayment?.prHeaderNote;

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text className="new-line">
          Recall Request : {prHeaderNote}
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectRequestListPayment]);

  const footerSingleModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleSingleModal(false)}>
          Cancel
        </Button>
        <Button
          style={{
            background: "#545454",
          }}
          loading={loading}
          onClick={() => {
            setLoading(true);
            onSendReCallPaymentList([selectRequestListPayment.payId])?.finally(
              () => {
                setLoading(false);
                setVisibleSingleModal(false);
              }
            );
          }}
        >
          <Typography.Text style={{ color: "white" }}>Recall</Typography.Text>
        </Button>
      </Row>
    );
  }, [loading, onSendReCallPaymentList, selectRequestListPayment.payId]);

  const contentPVModal = useMemo(() => {
    const pvDocNo = selectRequestListPayment?.pvDocNo;
    // const pvDocReason = selectRequestListPayment?.pvDocReason;

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text>Payment Voucher No. : {pvDocNo}</Typography.Text>
        {isCancel && (
          <Form layout="vertical" form={form}>
            <Form.Item name="reason" label="Reason">
              <TextArea
                onChange={(value) => {
                  // setDisableRejectModal(!!value.target.value);
                  selectRequestListPayment.pvDocReason = value.target.value;
                }}
                placeholder="Reason for Cancle or Reject Payment Voucher Document"
              />
            </Form.Item>
          </Form>
        )}
        {isReject && (
          <Form layout="vertical" form={form}>
            <Form.Item name="reason" label="Reason">
              <TextArea
                onChange={(value) => {
                  // setDisableRejectModal(!!value.target.value);
                  selectRequestListPayment.pvDocReason = value.target.value;
                }}
                placeholder="Reason for Cancle or Reject Payment Voucher Document"
              />
            </Form.Item>
          </Form>
        )}
      </Spin>
    );
  }, [form, loading, selectRequestListPayment, isCancel, isReject]);

  const footerPVModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button
          style={{
            background: "#71bf45",
          }}
          icon={<PrinterOutlined style={{ fontSize: "x-large" }} />}
          loading={loading}
          disabled={
            selectRequestListPayment?.pvDocStatus === "rejected" ||
            selectRequestListPayment?.pvDocStatus === "cancelled"
          }
          onClick={() => {
            setLoading(true);
            setVisibleSingleModal(false);
            printHistoryLists({
              payId: selectRequestListPayment.payId,
              sendApproveBy: String(
                selectRequestListPayment.sendApproveBy.userId
              ),
              documentType: selectRequestListPayment.isImmediately
                ? "PVIM"
                : "PVCR",
            })
              .then((result) => {
                const file = new Blob([result], {
                  type: "application/pdf",
                });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(file);
                link.download = `${selectRequestListPayment.purGroup}_${selectRequestListPayment.payNumber}`;
                link.click();
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                setLoading(false);
                setVisiblePVModal(false);
                setIsCancel(false);
                setIsReject(false);
                window.location.reload();
                // onSyncDataSource();
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          <Typography.Text>Print</Typography.Text>
        </Button>
        <Button
          style={{
            background: "#ed986d",
          }}
          icon={<StopOutlined style={{ fontSize: "x-large" }} />}
          loading={loading}
          disabled={selectRequestListPayment?.printCount === 0}
          onClick={() => {
            if (isCancel) {
              setLoading(true);
              pvStatusHistory({
                payId: selectRequestListPayment.payId,
                pvStatus: "cancelled",
                userId: auth?.userId ? auth?.userId.toString() : "",
                reason: selectRequestListPayment.pvDocReason
                  ? selectRequestListPayment.pvDocReason
                  : "",
              })?.finally(() => {
                // onSyncDataSource();
                setLoading(false);
                setVisiblePVModal(false);
                setIsCancel(false);
                setIsReject(false);
                window.location.reload();
              });
            } else {
              setIsCancel(true);
              setIsReject(false);
            }
          }}
        >
          <Typography.Text>Cancel</Typography.Text>
        </Button>
        <Button
          style={{
            background: "#ee1c24",
          }}
          icon={<CloseCircleOutlined style={{ fontSize: "x-large" }} />}
          loading={loading}
          disabled={selectRequestListPayment?.printCount === 0}
          onClick={() => {
            if (isReject) {
              setLoading(true);
              pvStatusHistory({
                payId: selectRequestListPayment.payId,
                pvStatus: "rejected",
                userId: auth?.userId ? auth?.userId.toString() : "",
                reason: selectRequestListPayment.pvDocReason
                  ? selectRequestListPayment.pvDocReason
                  : "",
              })?.finally(() => {
                // onSyncDataSource();
                setLoading(false);
                setVisiblePVModal(false);
                setIsCancel(false);
                setIsReject(false);
                window.location.reload();
              });
            } else {
              setIsCancel(false);
              setIsReject(true);
            }
          }}
        >
          <Typography.Text>Reject</Typography.Text>
        </Button>
      </Row>
    );
  }, [auth, loading, selectRequestListPayment, isCancel, isReject]);

  const contentReversedModal = useMemo(() => {
    // const prHeaderNote = selectRequestListPayment?.prHeaderNote;
    const payNumber = selectRequestListPayment?.payNumber;
    const reversedDoc = selectRequestListPayment?.reverseDoc;
    const reversedYear = selectRequestListPayment?.reverseDocYear;

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text className="new-line">
          Pay Number : {payNumber} {"\n"} Reversed By SAP : {reversedDoc} /{" "}
          {reversedYear}
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectRequestListPayment]);

  const footerReversedModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button
          loading={loading}
          onClick={() => setVisibleReversedModal(false)}
        >
          Cancel
        </Button>
        <Button
          style={{
            background: "#545454",
          }}
          loading={loading}
          onClick={() => {
            setLoading(true);
            modalRejected({
              payId: [selectRequestListPayment?.payId as number],
              userId: `${selectRequestListPayment?.waitApproveBy?.userId}`,
              reason: `Reversed by SAP`,
              isCancel: false,
            })
              .then(() => {
                window.location.reload();
              })
              .finally(() => {
                setLoading(false);
                setVisibleReversedModal(false);
              });
          }}
        >
          <Typography.Text style={{ color: "white" }}>
            Reversed by SAP
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [
    selectRequestListPayment?.waitApproveBy,
    loading,
    selectRequestListPayment?.payId,
  ]);

  return (
    <div style={{ padding: 20 }}>
      <TitleHeader
        title="History"
        loading={loading}
        dataSizes={totalItem}
        timeUpdate={timeUpdate}
        onSyncDataSource={onSyncDataSource}
      />
      <FilterInputTable
        setFilterInput={setFilterInput}
        querysearch={querysearch}
        queryDateType={querydatetype}
        queryDate={querydate}
        selectItems={[
          {
            icon: CompanyLogo,
            name: "isImmediately",
            placeholder: "Immediately",
            dataItems: isImmediatelyDrop,
            itemIndex: queryimmediate,
            span: 3,
          },
          {
            icon: TeamLogo,
            name: "purgId",
            placeholder: "Purchasing Group",
            dataItems: purchasingGroup,
            itemIndex: querypurgeid,
            span: 3,
          },
          {
            icon: CompanyLogo,
            name: "compId",
            placeholder: "Company",
            dataItems: company,
            itemIndex: querycompid,
            span: 3,
          },
          {
            icon: Reject,
            name: "activityState",
            placeholder: "Rejected",
            dataItems: activityState,
            itemIndex: queryactivity,
            span: 3,
          },
        ]}
        searchText={searchText}
        setSearchText={setSearchText}
        setModalVisible={setVisibleSingleModal}
      />
      <DataTable
        data={dataSource}
        checkBox={false}
        loading={loading}
        columns={columns}
        totalItem={totalItem}
        searchText={searchText}
        setSearchText={setSearchText}
        variablePage={variablePage}
        setVariablePage={setVariablePage}
      />
      {visibleDrawer ? (
        <CustomDrawer
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          title={titleDrawer}
          content={contentDrawer}
          footer={footerDrawer}
        />
      ) : null}
      {visibleSingleModal ? (
        <CustomModal
          key="SingleModal"
          visible={visibleSingleModal}
          setVisible={setVisibleSingleModal}
          title="Recall Request"
          content={contentSingleModal}
          footer={footerSingleModal}
        />
      ) : null}
      {visiblePVModal ? (
        <PVModal
          key="PVModal"
          visible={visiblePVModal}
          setVisible={setPVmodal}
          title="Payment Voucher Status"
          content={contentPVModal}
          footer={footerPVModal}
        />
      ) : null}
      {visibleReversedModal ? (
        <CustomModal
          key="ReversedModal"
          visible={visibleReversedModal}
          setVisible={setVisibleReversedModal}
          title="Reversed By SAP"
          content={contentReversedModal}
          footer={footerReversedModal}
        />
      ) : null}
    </div>
  );
};

export default Component;
