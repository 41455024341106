/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useCallback, useState, useMemo } from "react";
import {
  Input,
  Button,
  Form,
  Typography,
  Row,
  Col,
  Checkbox,
  Select,
  InputNumber,
  Modal,
  DatePicker,
  notification,
  FormInstance,
  message,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import "./index.css";
import isEmpty from "lodash/isEmpty";
import {
  AccountAssignment,
  Company,
  MaterialGroup,
  MaterialSet,
  PurchasingGroup,
  PurchasingOrg,
  Unit,
  Vendor,
} from "../../interfaces/master.interface";
import { FormDetailMemoWithPO } from "../../interfaces/prmemo.interface";
import { filterOption } from "../../libs/filterOptionSelect";

const { Text } = Typography;
const { TextArea } = Input;

interface SelectOption {
  key: string;
  label: string;
  value: string;
}

interface Props {
  headerForm: FormInstance;
  visible: boolean;
  setVisible: (value: boolean) => void;
  title: string;
  isAllApproved: boolean;
  isAdmin: boolean;
  isSameVendor: boolean;
  isCalculateTax: boolean;
  optionsCompany: SelectOption[];
  optionsVendor: SelectOption[];
  optionsAccount: SelectOption[];
  optionsPurGroup: SelectOption[];
  optionsMaterial: SelectOption[];
  optionsExpense: SelectOption[];
  optionsUnit: SelectOption[];
  optionsAsset: SelectOption[];
  optionsCostCenter: SelectOption[];
  optionsStorageLoc: SelectOption[];
  datasourceCompany: Company[];
  dataSourceVendor: Vendor[];
  dataSourceAccountAssign: AccountAssignment[];
  dataSourceMaterialSet: MaterialSet[];
  dataSourcePurGroup: PurchasingGroup[];
  dataSourceMaterialGroup: MaterialGroup[];
  dataSourcePurOrg: PurchasingOrg[];
  dataSourceUnit: Unit[];
  onFinish: (values: FormDetailMemoWithPO) => void;
  data: FormDetailMemoWithPO | undefined;
  currencyData: SelectOption[];
}

interface TrackNoInputProps {
  value: string | null;
  onChange: (value: string) => void | null;
}

const TrackNoInput: React.FC<any> = ({
  value = "",
  onChange = () => {},
}: TrackNoInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [val, setVal] = useState(value || "");

  const mdlChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const reg = /^-?\d*(\.\d*)?$/;

    const trackNo = e.target.value;
    const isNumber = reg.test(trackNo);
    if (!isNumber) {
      return;
    }
    if (isNumber || trackNo === "" || trackNo === "-") {
      setVal(trackNo);
      onChange(trackNo);
    }
  };
  return <Input type="text" value={value || ""} onChange={mdlChange} />;
};

const Component = ({
  headerForm,
  visible,
  setVisible,
  title,
  isAllApproved,
  isAdmin = false,
  isCalculateTax,
  isSameVendor,
  optionsCompany,
  optionsPurGroup,
  optionsVendor,
  optionsAccount,
  optionsMaterial,
  optionsExpense,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  optionsAsset,
  optionsCostCenter,
  optionsStorageLoc,
  optionsUnit,
  datasourceCompany,
  dataSourcePurGroup,
  dataSourceVendor,
  dataSourceAccountAssign,
  dataSourceMaterialSet,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dataSourcePurOrg,
  dataSourceUnit,
  dataSourceMaterialGroup,
  onFinish,
  currencyData,
  data = undefined,
}: Props): JSX.Element => {
  const [form] = Form.useForm<FormDetailMemoWithPO>();
  const [isDisaVendorInput, setIsDisableVendorInput] = useState<boolean>(true);
  const [isShowMoreText, setIsShowMoreText] = useState<boolean>(false);
  const accountAssignValue = Form.useWatch("accoId", form);
  const exchangeRate = Form.useWatch<number | undefined>("exchangeRate", form);
  const valuationPrice = Form.useWatch<number | undefined>(
    "valuationPrice",
    form
  );
  const qty = Form.useWatch<number | undefined>("qty", form);

  const currentAccount = Form.useWatch<string>("accoId", headerForm);
  // const currentAccType = Form.useWatch<string>("accoType", headerForm);
  const currentVendor = Form.useWatch<string>("accountNum", headerForm);
  const currentMatgId = Form.useWatch<string>("matgId", headerForm);
  const currentPurgId = Form.useWatch<string>("purgId", headerForm);
  const currentCompId = Form.useWatch<string>("compId", headerForm);
  const currentCurrency = Form.useWatch<string>("currency", headerForm);
  const currentExchangeRate = Form.useWatch<number>("exchangeRate", headerForm);

  // const [currency, setCurrency] = useState("THB");
  const [currency, setCurrency] = useState(currentCurrency);

  notification.config({
    maxCount: 1,
    duration: 0,
    placement: "top",
  });

  // const defaultCurrency = useMemo(() => {
  //   // return "THB";
  //   return currentCurrency;
  // }, [currentCurrency]);

  const accountAssignId = useMemo(() => {
    return dataSourceAccountAssign.find(
      (item) => item.accoId.toString() === accountAssignValue
    )?.accAssId;
  }, [accountAssignValue, dataSourceAccountAssign]);

  useEffect(() => {
    if (accountAssignId)
      form.setFieldsValue({
        glAccount: accountAssignId === "A" ? "13090010" : "69999999",
      });
  }, [accountAssignId, form]);

  const formatNumber = (value: number | undefined) => {
    if (isEmpty(value)) {
      return "";
    }
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 4,
    }).format(Number(value || 0));
  };

  useEffect(() => {
    const ASSETCODE = "A";
    const isAccAss = dataSourceAccountAssign
      .find((item) => item.accoId.toString() === accountAssignValue)
      ?.accAssId?.startsWith(ASSETCODE);
    if (isAccAss) {
      form.setFieldsValue({ assetCode: "999999999" });
    } else {
      form.setFieldsValue({ assetCode: "" });
    }
  }, [accountAssignValue, form, dataSourceAccountAssign]);

  const onChangeAmount = useCallback(() => {
    const defaultFormValue = form.getFieldsValue();
    if (!defaultFormValue.valuationPrice || !defaultFormValue.qty) {
      form.setFieldsValue({
        _ShowLocalCurrentAmount: 0,
        totalValue: 0,
      });
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!defaultFormValue.exchangeRate) {
        form.setFieldsValue({
          _ShowLocalCurrentAmount:
            defaultFormValue.valuationPrice *
            (defaultFormValue.qty / defaultFormValue.per),
          totalValue:
            defaultFormValue.valuationPrice *
            (defaultFormValue.qty / defaultFormValue.per),
        });
      } else if (defaultFormValue.exchangeRate > 0) {
        if (defaultFormValue.currency === "THB") {
          form.setFieldsValue({
            _ShowLocalCurrentAmount:
              defaultFormValue.valuationPrice *
              (defaultFormValue.qty / defaultFormValue.per),
            totalValue:
              defaultFormValue.valuationPrice *
              (defaultFormValue.qty / defaultFormValue.per),
            exchangeRate: 0,
          });
        } else {
          form.setFieldsValue({
            _ShowLocalCurrentAmount:
              defaultFormValue.valuationPrice *
              (defaultFormValue.qty / defaultFormValue.per) *
              defaultFormValue.exchangeRate,
            totalValue:
              defaultFormValue.valuationPrice *
              (defaultFormValue.qty / defaultFormValue.per) *
              defaultFormValue.exchangeRate,
          });
        }
      } else {
        form.setFieldsValue({
          _ShowLocalCurrentAmount:
            defaultFormValue.valuationPrice *
            (defaultFormValue.qty / defaultFormValue.per),
          totalValue:
            defaultFormValue.valuationPrice *
            (defaultFormValue.qty / defaultFormValue.per),
        });
      }
    }
  }, [form]);

  const onChangeAccount = (value: string) => {
    const accAssName = dataSourceAccountAssign.find(
      (item) => `${item.accoId}` === value
    )?.accAssName;

    form.setFieldsValue({
      _ShowAccountAssignment:
        dataSourceAccountAssign.find((item) => `${item.accoId}` === value)
          ?.accAssName || "",
      qtyUnit:
        accAssName === "Project" ||
        accAssName === "Assets" ||
        accAssName === "OPEX Expense"
          ? "UNI"
          : "",
      perUnit:
        accAssName === "Project" ||
        accAssName === "Assets" ||
        accAssName === "OPEX Expense"
          ? "UNI"
          : "",
    });
  };

  const onChangeMaterial = (value: string) => {
    const material = dataSourceMaterialSet.find(
      (item) => `${item.materialCode}` === value
    );
    form.setFieldsValue({
      qtyUnit: material?.unit,
      perUnit: material?.unit,
      itemText: material?.materialText,
    });
  };

  const onChangeQtyUnit = (value: string) => {
    const qtyUnit = dataSourceUnit.find((item) => `${item.unitCode}` === value);
    form.setFieldsValue({
      perUnit: qtyUnit?.unitCode,
    });
  };

  const onChangePurchasingOrg = (value: string) => {
    form.setFieldsValue({
      _ShowPurchasingOrg:
        datasourceCompany.find((item) => `${item.compId}` === value)
          ?.companyName || "",
    });
  };

  const onChangePurchasingGroup = (value: string) => {
    form.setFieldsValue({
      _ShowPurGroup:
        dataSourcePurGroup.find((item) => `${item.purgId}` === value)
          ?.purGroupName || "",
    });
  };

  const onChangeMaterialGroup = (value: string) => {
    form.setFieldsValue({
      _ShowMaterialGroup:
        dataSourceMaterialGroup.find((item) => `${item.matgId}` === value)
          ?.materialGroupName || "",
    });
  };

  const onChangeDesiredVendor = (value: string) => {
    if (value !== currentVendor) {
      message.error(
        `ไม่สามารถเลือก Desired Vendor คนละ Vendor กับ Header ได้`,
        10
      );
      // eslint-disable-next-line no-param-reassign
      value = currentVendor;
      form.setFieldsValue({
        desiredAccountNum: currentVendor,
      });
    }
    form.setFieldsValue({
      desiredAccountText:
        dataSourceVendor.find((item) => `${item.accountNum}` === value)
          ?.accountText || "",
    });
    if (value?.substring(0, 2) === "29") {
      setIsDisableVendorInput(false);
    } else {
      setIsDisableVendorInput(true);
    }
  };

  const onFormSubmit = (values: FormDetailMemoWithPO) => {
    onFinish({ ...values, paymentItem: data?.paymentItem });
    form.resetFields();
    setVisible(false);
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      console.log("data =>", data);
      if (data.exchangeRate && data.exchangeRate > 0) {
        form.setFieldsValue({
          _ShowLocalCurrentAmount:
            data.valuationPrice * (data.qty / data.per) * data.exchangeRate,
        });
      } else {
        form.setFieldsValue({
          _ShowLocalCurrentAmount: data.valuationPrice * (data.qty / data.per),
        });
      }

      if (data.desiredAccountNum?.substring(0, 2) === "29") {
        setIsDisableVendorInput(false);
      } else {
        setIsDisableVendorInput(true);
      }
      setIsShowMoreText(!!data.extendItemText);
    } else if (currentVendor && currentVendor.substring(0, 2) === "29") {
      setIsDisableVendorInput(false);
    } else {
      setIsDisableVendorInput(true);
    }
  }, [currentVendor, data, form, visible]);

  useEffect(() => {
    if (title === "Add") {
      console.log("currentCurrency");
      console.log(currentCurrency);
      // console.log("currency");
      // console.log(currency);
      setIsShowMoreText(false);
      const accAss = dataSourceAccountAssign.find(
        (item) => item.accoId.toString() === currentAccount
      )?.accAssName;
      form.setFieldsValue({
        per: 1,
        isCalculateTax,
        accoId: currentAccount,
        _ShowAccountAssignment:
          dataSourceAccountAssign.find(
            (item) => item.accoId.toString() === currentAccount
          )?.accAssName || "",
        matgId: currentMatgId,
        _ShowMaterialGroup:
          dataSourceMaterialGroup.find(
            (item) => item.matgId.toString() === currentMatgId
          )?.materialGroupName || "",
        purGroup: currentPurgId,
        _ShowPurGroup:
          dataSourcePurGroup.find(
            (item) => item.purgId.toString() === currentPurgId
          )?.purGroupName || "",
        companyCode: currentCompId,
        _ShowPurchasingOrg:
          datasourceCompany.find(
            (item) => item.compId.toString() === currentCompId
          )?.companyName || "",
        qtyUnit:
          accAss === "Project" ||
          accAss === "Assets" ||
          accAss === "OPEX Expense"
            ? "UNI"
            : "",
        perUnit:
          accAss === "Project" ||
          accAss === "Assets" ||
          accAss === "OPEX Expense"
            ? "UNI"
            : "",
        currency: currentCurrency,
        exchangeRate: currentExchangeRate,
      });
      if (isSameVendor && visible) {
        form.setFieldsValue({
          desiredAccountNum: currentVendor,
          desiredAccountText:
            dataSourceVendor.find(
              (item) => `${item.accountNum}` === currentVendor
            )?.accountText || "",
        });
      } else {
        form.setFieldsValue({
          accountNum: undefined,
          accountText: "",
        });
      }
    }
  }, [
    visible,
    form,
    title,
    currentAccount,
    dataSourceAccountAssign,
    currentMatgId,
    dataSourceMaterialGroup,
    currentPurgId,
    dataSourcePurGroup,
    currentCompId,
    isCalculateTax,
    isSameVendor,
    currentVendor,
    dataSourceVendor,
    datasourceCompany,
    currentCurrency,
    currentExchangeRate,
    // currency,
  ]);

  return (
    <Modal
      title={<Text strong>{title}</Text>}
      visible={visible}
      centered
      footer={null}
      onCancel={onCancel}
      className="custom-modal"
      forceRender
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={data}
        onFinish={onFormSubmit}
        scrollToFirstError
        // onFieldsChange={(e) => }
      >
        <Row gutter={8}>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24} sm={8}>
                <Form.Item
                  name="accoId"
                  label="Budget Type"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Budget Type is Required",
                    },
                  ]}
                >
                  <Select
                    disabled={isAdmin || isAllApproved}
                    placeholder="- Select -"
                    options={optionsAccount}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => onChangeAccount(value)}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={16}>
                <Form.Item name="_ShowAccountAssignment" label=" ">
                  <Input disabled className="FieldInputStyle" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24} sm={24}>
                <Form.Item
                  name="materailCode"
                  label="Material"
                  rules={[
                    {
                      required: accountAssignValue === "4",
                      message: "Material is Required",
                    },
                  ]}
                >
                  <Select
                    disabled={isAdmin || isAllApproved}
                    placeholder="- Select -"
                    options={optionsMaterial}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => onChangeMaterial(value)}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Form.Item name="index" hidden>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="itemText"
              label="Item Text"
              required
              rules={[
                {
                  required: true,
                  message: "Item Text is Required",
                },
              ]}
            >
              <TextArea
                // disabled={isAdmin || isAllApproved}
                disabled={isAllApproved}
                placeholder=""
                className="FieldInputStyle"
                maxLength={40}
                showCount
                autoSize={{ minRows: 1, maxRows: 2 }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="moreText" label="&nbsp;">
              <Button
                type="dashed"
                onClick={() =>
                  setIsShowMoreText((state) => {
                    form.setFieldsValue({
                      extendItemText: "",
                    });
                    return !state;
                  })
                }
              >
                Extend
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="qty"
              label="Qty"
              required
              rules={[
                {
                  required: true,
                  message: "Qty is Required",
                },
              ]}
            >
              <InputNumber
                disabled={isAdmin || isAllApproved}
                controls={false}
                formatter={formatNumber}
                placeholder="Qty"
                min={0}
                className="FieldInputStyle"
                onChange={onChangeAmount}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="qtyUnit"
              label="Unit"
              required
              rules={[
                {
                  required: true,
                  message: "Unit is Required",
                },
              ]}
            >
              <Select
                disabled={isAdmin || isAllApproved}
                placeholder="- Select -"
                options={optionsUnit}
                showSearch
                filterOption={filterOption}
                onChange={(value) => onChangeQtyUnit(value)}
                className="FieldInputStyle"
              />
            </Form.Item>
          </Col>
          {isShowMoreText && (
            <Col span={24}>
              <Form.Item name="extendItemText" label="Extend Text">
                <TextArea
                  // disabled={isAdmin || isAllApproved}
                  disabled={isAllApproved}
                  placeholder=""
                  className="FieldInputStyle"
                  maxLength={132}
                  showCount
                  autoSize={{ minRows: 1, maxRows: 2 }}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24} sm={6}>
                <Form.Item
                  name="companyCode"
                  label="Purchasing Org."
                  required
                  rules={[
                    {
                      required: true,
                      message: "Purchasing Org. is Required",
                    },
                  ]}
                >
                  <Select
                    disabled={isAdmin || isAllApproved}
                    placeholder="- Select -"
                    options={optionsCompany}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => onChangePurchasingOrg(value)}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={12}>
                <Form.Item name="_ShowPurchasingOrg" label=" ">
                  <Input disabled className="FieldInputStyle" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="deliveryDate"
                  label="Delivery Date"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Delivery Date is Required",
                    },
                  ]}
                >
                  <DatePicker
                    // disabled={isAdmin || isAllApproved}
                    disabled={isAllApproved}
                    format="DD-MM-YYYY"
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24} sm={8}>
                <Form.Item
                  name="purGroup"
                  label="Purchasing Group"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Purchasing Group is Required",
                    },
                  ]}
                >
                  <Select
                    disabled={isAdmin || isAllApproved}
                    placeholder="- Select -"
                    options={optionsPurGroup}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => onChangePurchasingGroup(value)}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={16}>
                <Form.Item name="_ShowPurGroup" label=" ">
                  <Input disabled className="FieldInputStyle" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24} sm={8}>
                <Form.Item
                  name="matgId"
                  label="Expense Type"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Expense Type is Required",
                    },
                  ]}
                >
                  <Select
                    disabled={isAdmin || isAllApproved}
                    placeholder="- Select -"
                    options={optionsExpense}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => onChangeMaterialGroup(value)}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={16}>
                <Form.Item name="_ShowMaterialGroup" label=" ">
                  <Input disabled className="FieldInputStyle" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12} sm={6}>
            <Form.Item hidden name="totalValue" />
            <Form.Item
              name="valuationPrice"
              label="Valuation"
              required
              rules={[
                {
                  required: true,
                  message: "Valuation is Required",
                },
              ]}
            >
              <InputNumber
                disabled={isAdmin || isAllApproved}
                controls={false}
                placeholder="0.00"
                formatter={formatNumber}
                // onKeyPress={inputNumberCheck}
                className="FieldInputStyle"
                onChange={onChangeAmount}
              />
            </Form.Item>
          </Col>
          <Col span={12} sm={4}>
            <Form.Item
              name="currency"
              label="Currency"
              initialValue={currentCurrency}
              required
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                disabled={isAdmin || isAllApproved}
                placeholder="- Select -"
                options={currencyData}
                showSearch
                filterOption={filterOption}
                className="FieldInputStyle"
                onChange={setCurrency}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="per"
              label="Per"
              required
              rules={[
                {
                  required: true,
                  message: "Per is Required",
                },
              ]}
            >
              <InputNumber
                disabled={isAdmin || isAllApproved}
                controls={false}
                formatter={formatNumber}
                placeholder="Per"
                // onKeyPress={inputNumberCheck}
                className="FieldInputStyle"
                onChange={onChangeAmount}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="perUnit" label="Unit">
              <Select
                disabled={isAdmin || isAllApproved}
                placeholder="- Select -"
                options={optionsUnit}
                showSearch
                filterOption={filterOption}
                className="FieldInputStyle"
              />
            </Form.Item>
          </Col>
          <Col span={12} sm={4}>
            <Form.Item name="isCalculateTax" label=" " valuePropName="checked">
              <Checkbox>Calc.Tax</Checkbox>
            </Form.Item>
          </Col>
          {currentCurrency === "THB" || currency === "THB" || (
            <Col span={24} sm={14}>
              <Form.Item
                name="exchangeRate"
                label="Exchange Rate"
                initialValue={currentExchangeRate}
                required
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  placeholder="Exchange Rate"
                  formatter={formatNumber}
                  className="FieldInputStyle"
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              name="_ShowLocalCurrentAmount"
              label="Local Current Amount"
            >
              <InputNumber
                controls={false}
                formatter={formatNumber}
                disabled
                className="FieldInputStyle"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="trackNo" label="Track No.">
              <TrackNoInput />
            </Form.Item>
          </Col>
          {["A"].some((word) =>
            dataSourceAccountAssign
              .find((item) => item.accoId.toString() === accountAssignValue)
              ?.accAssName.startsWith(word)
          ) && (
            <Col span={24}>
              <Form.Item
                name="assetCode"
                label="Asset No."
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={isAdmin || isAllApproved}
                  placeholder="Asset No."
                  className="FieldInputStyle"
                  maxLength={10}
                />
              </Form.Item>
            </Col>
          )}
          {/* <Form.Item name="assetText" hidden /> */}
          {["A", "P"].some((word) =>
            dataSourceAccountAssign
              .find((item) => item.accoId.toString() === accountAssignValue)
              ?.accAssName.startsWith(word)
          ) && (
            <Col span={24}>
              <Form.Item
                name="orderNumberWbs"
                label="WBS no."
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={isAdmin || isAllApproved}
                  placeholder="WBS no."
                  className="FieldInputStyle"
                  maxLength={24}
                />
              </Form.Item>
            </Col>
          )}
          {["F", "O"].some((word) =>
            dataSourceAccountAssign
              .find((item) => item.accoId.toString() === accountAssignValue)
              ?.accAssName.startsWith(word)
          ) && (
            <Col span={24}>
              <Form.Item
                name="orderNumber"
                label="Order Number"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={isAdmin || isAllApproved}
                  placeholder="รหัสงบประมาณ"
                  className="FieldInputStyle"
                  maxLength={10}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item name="costCenter" label="Cost Center">
              <Select
                disabled={isAdmin || isAllApproved}
                placeholder="- Select -"
                options={optionsCostCenter}
                showSearch
                filterOption={filterOption}
                className="FieldInputStyle"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="glAccount" label="G/L Account">
              <Input
                // disabled
                placeholder="รหัสบัญชี G/L"
                className="FieldInputStyle"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="storageLoCode"
              label="Storage Loc."
              rules={[
                {
                  required: accountAssignValue === "4",
                  message: "Storage Location is Required",
                },
              ]}
            >
              <Select
                disabled={isAdmin || isAllApproved}
                placeholder="- Select -"
                options={optionsStorageLoc}
                showSearch
                filterOption={filterOption}
                className="FieldInputStyle"
              />
            </Form.Item>
          </Col>
          <Form.Item name="storageLoText" hidden />
          <Col span={8}>
            <Form.Item name="requestedBy" label="Requested By">
              <Input
                disabled={isAdmin || isAllApproved}
                placeholder="ผู้ขอ"
                className="FieldInputStyle"
                maxLength={12}
              />
            </Form.Item>
          </Col>
          <Col span={16} />
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24} sm={8}>
                <Form.Item
                  name="desiredAccountNum"
                  label="Desired Vendor"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Desired Vendor is Required",
                    },
                  ]}
                  // rules={[
                  //   {
                  //     // required: isHeaderVendor === false,
                  //     required: itemVendor === currentVendor,
                  //     message: "Desired Vendor is Same Header",
                  //   },
                  // ]}
                >
                  <Select
                    // disabled={isAdmin || isAllApproved}
                    disabled={isAllApproved}
                    placeholder="- Select -"
                    options={optionsVendor}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => onChangeDesiredVendor(value)}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={16}>
                <Form.Item name="desiredAccountText" label=" ">
                  <Input
                    disabled={isAdmin || isDisaVendorInput}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {/* <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24} sm={8}>
                <Form.Item
                  name="companyCode"
                  label="Company Code"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Company Code is Required",
                    },
                  ]}
                >
                  <Select
                    disabled={isAdmin || isAllApproved}
                    placeholder="- Select -"
                    options={optionsCompany}
                    showSearch
                    filterOption={filterOption}
                    onChange={(value) => onChangeCompany(value)}
                    className="FieldInputStyle"
                  />
                </Form.Item>
              </Col>
              <Col span={24} sm={16}>
                <Form.Item name="_ShowCompany" label=" ">
                  <Input disabled className="FieldInputStyle" />
                </Form.Item>
              </Col>
            </Row>
          </Col> */}
        </Row>
        <Row justify="start" gutter={8}>
          <Col>
            <Button
              type="primary"
              icon={<CheckOutlined />}
              htmlType="submit"
              // disabled={isAdmin}
            >
              Save Changes
            </Button>
          </Col>
          <Col>
            <Button onClick={onCancel}>Cancel</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Component;
