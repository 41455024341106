import { useState, useEffect, useContext } from "react";
import { ReactReduxContext } from "react-redux";
import isEmpty from "lodash/isEmpty";
import type { User } from "../interfaces/user.interface";

const useAuthentication = (): User | undefined => {
  const { store } = useContext(ReactReduxContext);

  const [state, setState] = useState<User>(
    store.getState().authentication as User
  );

  store.subscribe(() => {
    const { authentication }: { authentication: User } = store.getState();
    setState(authentication);
  });

  useEffect(() => {
    setState(store.getState().authentication);
    return store.subscribe(() => {
      setState(store.getState().authentication);
    });
  }, [store]);

  return !isEmpty(state) ? state : undefined;
};

export default useAuthentication;
