/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Checkbox, Col, Form, Input, Row } from "antd";
import { Payment } from "../../../interfaces/payment.interface";

type Props = {
  data?: Payment;
  viewHistory?: boolean;
  pathname?: string;
  form?: any;
};

const { TextArea } = Input;

const Component: React.FC<Props> = (props: Props) => {
  const { data, viewHistory, pathname, form } = props;

  return (
    <>
      {!viewHistory && pathname === "requester" && (
        <Form
          name="tab-header-form"
          className="tab-header-form"
          layout="vertical"
          form={form}
        >
          <Form.Item name="paymentDesc" label="Payment Description">
            <TextArea showCount rows={3} maxLength={500} />
          </Form.Item>
          <Form.Item name="isUrgent" valuePropName="checked">
            <Checkbox value="true">Urgent</Checkbox>
          </Form.Item>
        </Form>
      )}
      <div className="tab-header-content">
        <Row key="payNumber">
          <Col span={8}>Pay Request No:</Col>
          <Col span={16}>{data?.paynumber}</Col>
        </Row>
        <Row key="dueDate">
          <Col span={8}>Due Date:</Col>
          <Col span={16}>{data?.dueDate}</Col>
        </Row>
        <Row key="postingDate">
          <Col span={8}>Posting Date:</Col>
          <Col span={16}>{data?.postingDate}</Col>
        </Row>
        <Row key="fiDocNo">
          <Col span={8}>FI Doc No:</Col>
          <Col span={16}>{data?.fiDocNo}</Col>
        </Row>
        <Row key="purGroup">
          <Col span={8}>Purchasing Group:</Col>
          <Col span={16}>{data?.purGroup}</Col>
        </Row>
        <Row key="company">
          <Col span={8}>Company:</Col>
          <Col span={16}>{data?.company}</Col>
        </Row>
        <Row key="prNo">
          <Col span={8}>PR No:</Col>
          <Col span={16}>{data?.prNo}</Col>
        </Row>
        <Row key="poNo">
          <Col span={8}>PO No:</Col>
          <Col span={16}>{data?.poNo}</Col>
        </Row>
        <Row key="accountText">
          <Col span={8}>Vendor:</Col>
          <Col span={16}>{data?.accountText}</Col>
        </Row>
        <Row key="taxCode">
          <Col span={8}>Tax Code:</Col>
          <Col span={16}>{data?.taxCode}</Col>
        </Row>
        <Row key="assigment">
          <Col span={8}>Assignment:</Col>
          <Col span={16}>{data?.assigment}</Col>
        </Row>
        <Row key="paymentTerm">
          <Col span={8}>Payment Term:</Col>
          <Col span={16}>{data?.paymentTerm}</Col>
        </Row>
        <Row key="documentDate">
          <Col span={8}>Document Date:</Col>
          <Col span={16}>{data?.documentDate}</Col>
        </Row>
        <Row key="reference">
          <Col span={8}>Reference:</Col>
          <Col span={16}>-</Col>
        </Row>
      </div>
    </>
  );
};

Component.defaultProps = {
  data: {} as Payment,
  viewHistory: true,
  pathname: "",
  form: "",
};

export default Component;
