interface SelectOption {
  key: string;
  label: string;
  value: string;
}

const filterOption = (
  input: string,
  option: SelectOption | undefined
): boolean => {
  if (!option) {
    return false;
  }
  const searching = option?.label.toLowerCase().indexOf(input.toLowerCase());

  return searching >= 0;
};

export { filterOption };
