/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import {
  Row,
  Typography,
  Form,
  Spin,
  Col,
  Modal,
  Checkbox,
  Avatar,
  Button,
  Select,
  SelectProps,
  Input,
} from "antd";
import moment from "moment";
import { useHistory, useParams, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { LoadingOutlined } from "@ant-design/icons";
import TitleHeader from "../../components/TitleHeader";
import TableBanking from "../../components/DetailsTableBanking";
import useAuthentication from "../../hooks/useAuthentication";
import BankingForm from "../../components/Form/BankingForm";
import {
  BankingDetail,
  BankingPayments,
} from "../../interfaces/banking.interface";
import {
  getBankingApproverList,
  getBankingListDetailById,
  sendApproveBanking,
  previeweMail,
} from "../../api/banking";
import { User } from "../../interfaces/user.interface";
import CustomModal from "../../components/CustomModal";
import { PaymentType } from "../../enum/banking.enum";
import SendFilled from "../../components/CustomIcon/SendFilled";
import { EmailInfo } from "../../enum/bankingEmailInfo.enum";

const { TextArea } = Input;

const defaultCurrency = "THB";

const Component = (): JSX.Element => {
  const auth = useAuthentication();
  const history = useHistory();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [multiConfirm, setMultiConfirm] = useState<boolean>(false);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const [dataSource, setDataSource] = useState<BankingDetail>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataTable, setDataTable] = useState<BankingPayments[]>([]);
  const [approver, setApprover] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [visibleReleaseModal, setVisibleReleaseModal] =
    useState<boolean>(false);
  const [emailInfo, setEmailInfo] = useState<string>("");
  const [form] = Form.useForm<BankingDetail>();
  const [approvalForm] = Form.useForm<BankingDetail>();
  const isCalculateTaxValue = Form.useWatch<boolean>("isCalculateTax", form);
  const sendTo = Form.useWatch<string[]>("sendTo", approvalForm);
  const amount = Form.useWatch<number>("amount", form);
  const currency = Form.useWatch<string>("currency", form);
  const exchangeRate = Form.useWatch<number>("exchangeRate", form);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dueDate = Form.useWatch<Date>("dueDate", form);
  const [itemsPrepareDetail, setItemsPrepareDetail] = useState<
    BankingPayments[]
  >([]);
  const location = useLocation();
  const params: { payId: string } = useParams();

  const dataTableRef = useRef<HTMLDivElement>(null);

  const [current, setCurrent] = useState<number>(0);
  const [previewEmail, setPreviewEmail] = useState<string>("");

  const localCurrentAmount = useMemo(() => {
    if (!amount) {
      return undefined;
    }

    if (currency === "THB") {
      if (isCalculateTaxValue) {
        return amount;
      }

      return amount;
    }

    if (isCalculateTaxValue) {
      return amount * exchangeRate;
    }

    return amount * exchangeRate;
  }, [amount, currency, exchangeRate, isCalculateTaxValue]);

  const formatNumber = (value: number | undefined) => {
    if (isEmpty(value)) {
      return "";
    }
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 4,
    }).format(Number(value || null));
  };

  const get = () => {
    Promise.all([
      getBankingListDetailById(params.payId),
      getBankingApproverList(),
    ])
      .then(([response, responseApprover]) => {
        const documents = response.responseData[0];

        setDataSource(documents);
        setItemsPrepareDetail(
          // documents.payments.filter((value) => !value.isDelete)
          documents.payments.filter((value) => value.status !== "")
        );
        setApprover(responseApprover.responseData);
        form.setFieldsValue({
          ...documents,
          disableCompany: documents.company,
          dueDate: moment(documents?.dueDate).format("DD.MM.YYYY"),
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (params.payId) {
      get();
    }
  }, [auth?.userId]);

  const onSaveRequest = async (type: string) => {
    if (type === "release") {
      setVisibleReleaseModal(true);
    }
  };

  const isCheque = useMemo(() => {
    return dataSource?.paymentType === PaymentType.CHEQUE;
  }, [dataSource]);

  const isEnableAdmin = useMemo(() => {
    return dataSource?.enableAdmin || false;
  }, [dataSource]);

  const isTHBCurrency = useMemo(() => {
    return dataSource?.currency === "THB";
  }, [dataSource]);

  const isHistory = useMemo(() => {
    return location.pathname.includes("history");
  }, [location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options: SelectProps["options"] = [];
  options.push({ value: EmailInfo.ANUCHAN, label: EmailInfo.ANUCHAN });
  options.push({ value: EmailInfo.NONGNUCH, label: EmailInfo.NONGNUCH });

  const contentReleaseModal = useMemo(() => {
    return (
      <div>
        {current === 1 && (
          <div
            className="new-line"
            style={{ overflow: "auto", height: "60vh" }}
            dangerouslySetInnerHTML={{ __html: previewEmail }}
          />
        )}
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={loading}
        >
          <Form
            form={approvalForm}
            layout="vertical"
            initialValues={{ sendTo: approver.map((item) => item.userId) }}
            className={`${current === 1 && "d-none"}`}
          >
            <Form.Item name="sendTo">
              <Checkbox.Group style={{ width: "100%" }}>
                {approver?.map((item) => (
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ paddingBottom: "8px" }}
                    key={item.userId}
                  >
                    <Col>
                      <Row align="middle">
                        <Col style={{ paddingRight: "16px" }}>
                          <Avatar size="large" />
                        </Col>
                        <Col
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography.Text
                            strong
                          >{`${item.fristName} ${item.lastName}`}</Typography.Text>
                          <Typography.Text type="secondary">
                            {item.email}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Checkbox value={item.userId} />
                    </Col>
                  </Row>
                ))}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item name="emailInfo" label="E-mail Info">
              <Select
                mode="tags"
                placeholder="E-mail Info"
                onChange={(val) => setEmailInfo(val?.join(";") || "")}
                className="FieldInputStyle"
                options={options}
              />
            </Form.Item>
            <Form.Item name="releaseDesc">
              <TextArea
                showCount
                maxLength={200}
                allowClear
                rows={3}
                placeholder="Release Description"
              />
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }, [approver, loading, form, current]);

  const next = useCallback(
    (context: string) => {
      setPreviewEmail(context);
      setCurrent(current + 1);
    },
    [current, setPreviewEmail, setCurrent]
  );

  const back = useCallback(() => {
    setPreviewEmail("");
    form.setFieldsValue({ releaseDesc: "" });
    setCurrent(current - 1);
  }, [current, setPreviewEmail, setCurrent, form]);

  const onPrevieweMail = useCallback(
    async (payIds: number[]) => {
      previeweMail({
        payId: payIds,
        sendBy: parseInt(auth?.userId || "0", 10),
        sendTo,
        emailInfo,
        releaseDesc: approvalForm.getFieldValue("releaseDesc"),
      }).then((result) => {
        next(result.responseData[0]);
      });
    },
    [auth?.userId, sendTo, emailInfo, approvalForm, next]
  );

  const footerReleaseModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button
          loading={loading}
          onClick={() => {
            setVisibleReleaseModal(false);
            setMultiConfirm(false);
          }}
        >
          Cancel
        </Button>
        {current === 1 && (
          <Button loading={loading} onClick={back}>
            Back
          </Button>
        )}
        <Button
          type="primary"
          loading={loading}
          disabled={sendTo?.length <= 0}
          onClick={() => {
            setLoading(true);
            setLoadingButton(true);
            if (current !== 1) {
              previeweMail({
                payId: selectedRowKeys.map((value: any) => value.valueOf()),
                sendBy: parseInt(auth?.userId || "0", 10),
                sendTo,
                emailInfo,
                releaseDesc: approvalForm.getFieldValue("releaseDesc"),
              }).then((result) => {
                setLoadingButton(false);
                setLoading(false);
                next(result.responseData[0]);
              });
            } else {
              sendApproveBanking({
                payId: selectedRowKeys.map((value: any) => value.valueOf()),
                sendBy: parseInt(auth?.userId || "0", 10),
                sendTo,
                emailInfo,
              })
                .then(() => {
                  Modal.success({
                    content: `Release เอกสารให้ Approver สำเร็จ`,
                    onOk() {
                      setLoadingButton(false);
                    },
                  });
                  setVisibleReleaseModal(false);
                  setPreviewEmail("");
                  setCurrent(0);
                  get();
                })
                // eslint-disable-next-line no-console
                .catch((err) => console.log("release error:", err))
                .finally(() => {
                  setLoading(false);
                  setMultiConfirm(false);
                  setPreviewEmail("");
                  setCurrent(0);
                  approvalForm.resetFields();
                });
            }
          }}
        >
          {current === 1 ? `Release to ${sendTo.length} people` : "Preview"}
        </Button>
      </Row>
    );
  }, [
    loading,
    sendTo,
    emailInfo,
    current,
    back,
    onPrevieweMail,
    setPreviewEmail,
    form,
  ]);

  useEffect(() => {
    if (multiConfirm) {
      setVisibleReleaseModal(true);
    }
  }, [multiConfirm]);
  return (
    <Spin spinning={loading}>
      <div style={{ padding: 20 }}>
        <TitleHeader
          title="Payment Details"
          onSaveRequest={onSaveRequest}
          loading={loadingButton}
          dataSizes={itemsPrepareDetail.length}
          paymentType={dataSource?.paymentType}
          isEnableAdmin={dataSource?.enableAdmin}
        />
        <div
          style={{
            borderRadius: "16px",
            padding: "32px",
            border: "1px solid #E2E2E2",
            boxSizing: "border-box",
          }}
        >
          {selectedRowKeys.length > 0 && isCheque && !isHistory ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 24,
                height: "57px",
              }}
            >
              <Typography.Text>
                Selecting {selectedRowKeys.length} items
              </Typography.Text>
              <Button
                icon={<SendFilled />}
                loading={multiConfirm}
                style={{
                  display: "flex",
                  background: "#174291",
                  alignItems: "center",
                  padding: 11,
                  gap: 10,
                  height: "30px",
                }}
                onClick={() => setMultiConfirm(true)}
              >
                <Typography.Text strong style={{ color: "white" }}>
                  Release to Approve
                </Typography.Text>
              </Button>
            </div>
          ) : (
            <BankingForm
              form={form}
              formatNumber={formatNumber}
              defaultCurrency={defaultCurrency}
              localCurrentAmount={localCurrentAmount}
            />
          )}
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Typography.Text strong>
                PAYMENT DOCUMENT ({itemsPrepareDetail.length})
              </Typography.Text>
            </Col>
            <Col span={24} ref={dataTableRef}>
              <TableBanking
                selectedRowKeys={selectedRowKeys}
                onSelectChange={onSelectChange}
                dataTable={itemsPrepareDetail}
                dataApprover={approver}
                setDataTable={setDataTable}
                payId={params.payId}
                isCheque={isCheque}
                isEnableAdmin={isEnableAdmin}
                isTHBCurrency={isTHBCurrency}
              />
            </Col>
          </Row>
        </div>
      </div>
      <CustomModal
        visible={visibleReleaseModal}
        setVisible={setVisibleReleaseModal}
        title="Release to CEO"
        onCancel={() => {
          setVisibleReleaseModal(false);
          setMultiConfirm(false);
        }}
        content={contentReleaseModal}
        width={1000}
        footer={footerReleaseModal}
      />
    </Spin>
  );
};

export default Component;
