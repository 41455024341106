import { Tag, Typography } from "antd";

const { Text } = Typography;

type Props = {
  text: string;
  icon: JSX.Element;
  color: string;
  textIcon: string;
  border: string;
};

const Component = (props: Props): JSX.Element => {
  const { text, textIcon, icon, color, border } = props;
  return (
    <Tag
      icon={icon}
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        borderRadius: "99px",
        border: `1px solid ${border}`,
      }}
      color={color}
    >
      &ensp;
      <Text style={{ color: `${textIcon}` }} strong>
        {text}
      </Text>
    </Tag>
  );
};

export default Component;
