/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Table } from "antd";
import type { ColumnsType } from "antd/lib/table";
import Footer from "./Footer";
import "./index.css";
import { getQueryObject, queryStringify } from "../../libs/query";

export interface ComponentProps<T extends unknown> {
  searchText?: string;
  variablePage?: { page: number; perPage: number };
  setSearchText: (val: string) => void;
  setOnTableSelect?: (val: React.Key[]) => void;
  setVariablePage?: (val: { page: number; perPage: number }) => void;
  columns: ColumnsType<T>;
  data: Array<T>;
  loading?: boolean;
  rowKey?: string;
  checkBox?: boolean;
  totalItem?: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const ComponentDetailTable = <T extends object>({
  searchText = "",
  rowKey = "payId",
  checkBox = true,
  variablePage = { page: 1, perPage: 10 },
  totalItem = -1,
  setOnTableSelect,
  setVariablePage = () => {},
  columns,
  data,
  loading,
}: ComponentProps<T>): ReactElement => {
  const history = useHistory();
  const location = useLocation();

  const [page, setPage] = useState(variablePage.page);
  const [pageSize, setPageSize] = useState(variablePage.perPage);

  const queryToObject = getQueryObject(false, location.search);

  useEffect(() => {
    if (queryToObject.page && queryToObject.perPage) {
      setPage(Number(queryToObject.page));
      setPageSize(Number(queryToObject.perPage));
    } else {
      setPage(1);
    }
  }, [data, queryToObject.page, queryToObject.perPage]);

  const dataIndex = useMemo(() => {
    return columns
      .filter((c: any) => {
        return c.dataIndex;
      })
      .map((c: any) => c.dataIndex);
  }, [columns]);

  const filteredData = useMemo(() => {
    const result = data.filter((d: any) => {
      return dataIndex.find((index) => {
        if (typeof d[index] === "string") {
          return (d[index] as unknown as string).includes(searchText.trim());
        }
        if (typeof d[index] === "number") {
          return d[index].toString().includes(searchText.trim());
        }
        return false;
      });
    });
    return result;
  }, [data, searchText, dataIndex]);

  const pageDate = useMemo(() => {
    return [...filteredData].splice((page - 1) * pageSize, pageSize);
  }, [filteredData, page, pageSize]);

  // check user filter when stay on page not 1
  // if (totalItem < (page - 1) * pageSize) {
  //   setPage(1);
  //   setVariablePage({ page: 1, perPage: variablePage.perPage });
  // }

  const checkboxDisabled = (record: any) => {
    if (record.paymentType) {
      if (!record.enableAdmin) {
        return true;
      }
      return record.paymentType === "CHEQUE";
    }
    return false;
  };

  const onChangePage = (current: number, currenPageSize: number) => {
    setPage(current);
    setPageSize(currenPageSize);
    setVariablePage({ page: current, perPage: currenPageSize });

    const searchParam = {
      ...queryToObject,
      page: current,
      perPage: currenPageSize,
    };

    history.replace({
      ...location,
      search: queryStringify(searchParam),
    });
  };

  return (
    <>
      <Table
        className="table-striped-rows"
        loading={loading}
        rowSelection={
          checkBox === false
            ? undefined
            : {
                type: "checkbox",
                onChange: (record) => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  setOnTableSelect && setOnTableSelect(record);
                },
                getCheckboxProps: (record: any) => ({
                  disabled: checkboxDisabled(record),
                }),
              }
        }
        rowKey={rowKey}
        bordered
        columns={columns}
        dataSource={totalItem < 0 ? pageDate : data}
        scroll={{ x: 1700, y: 800 }}
        style={{ overflowX: "scroll", backgroundColor: "white" }}
        pagination={false}
        footer={() => (
          <Footer
            pageSize={pageSize}
            pageSizeOption={[10, 20, 50, 100]}
            total={totalItem < 0 ? filteredData.length : totalItem}
            current={page}
            setPage={setPage}
            onChange={(current, currenPageSize) =>
              onChangePage(current, currenPageSize)
            }
          />
        )}
      />
    </>
  );
};

export default ComponentDetailTable;
