/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Typography,
  Form,
  Spin,
  Row,
  Tabs,
  Input,
  Col,
  notification,
} from "antd";
import {
  CloseOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import moment from "moment";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import TitleHeader from "../../components/TitleHeader";
import FilterInputTable from "../../components/FilterInputTable";
import { CustomDrawer } from "../../components/Drawer";
import { TabsHeader, TabsDetail } from "../../components/Drawer/TabsMemo";
import { TabsApprove, TabsAttached } from "../../components/Drawer/Tabs";
import { ReactComponent as TeamLogo } from "../../assets/icon/team.svg";
import { ReactComponent as CompanyLogo } from "../../assets/icon/company.svg";
import { updatePaymentListById } from "../../api/approval";
import {
  getPaymentListMemoAdmin,
  sendReCallPaymentList,
} from "../../api/paymentList";
import { Company, PurchasingGroup } from "../../interfaces/master.interface";
import { getMasterCompany, getMasterPurchasingGroup } from "../../api/master";
import CustomModal from "../../components/CustomModal";
import Save from "../../components/CustomIcon/Save";
import SendFilled from "../../components/CustomIcon/SendFilled";
import useAuthentication from "../../hooks/useAuthentication";
import DataTable from "../../components/DataTable";
import formatData from "../../libs/formatData";
import { uploadFileList } from "../../api/service";
import { Exclamation, Thunder } from "../../components/CustomIcon";
import TagIcon from "../../components/TagIcon";
import { modalRejected } from "../../api/modalConfirm";
import { RejectReason } from "../../enum/rejectReason.enum";
import {
  UploadFileRequest,
  UploadFileResponse,
} from "../../interfaces/serviceAPI.interface";
import { getFileName, getFileExtension } from "../../libs/file";
import {
  PRMemoResponseLists,
  ResponseMemoList,
} from "../../interfaces/prmemo.interface";
import useParseQuery from "../../hooks/useParseQuery";
import { renderPRAdminListColumns } from "./columns";
import { mapMemoDataSource } from "../../libs/array/mappingPaymentList";
import { getBooleanFromQuery, getQueryObject } from "../../libs/query";
import { docType } from "../../libs/antd/options/optionsValue";
import useOptions from "../../hooks/useOptions";
import { sendBack, sendAdminApprove } from "../../api/prmemo";
import setting from "../../setting";

const { TextArea } = Input;

dayjs.extend(isBetween);

const Component = (): JSX.Element => {
  const auth = useAuthentication();

  const history = useHistory();
  const location = useLocation();

  const [initial, setInital] = useState(true);
  const [dataSource, setDataSource] = useState<PRMemoResponseLists[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [variablePage, setVariablePage] = useState<{
    page: number;
    perPage: number;
  }>({ page: 1, perPage: 10 });
  const [errorPaymentDesc, setErrorPaymentDesc] = useState<
    "" | "success" | "warning" | "error" | "validating" | undefined
  >("");
  const [visibleSingleModal, setVisibleSingleModal] = useState<boolean>(false);
  const [visibleSendbackModal, setVisibleSendbackModal] =
    useState<boolean>(false);
  const [visibleMutiModal, setVisibleMutiModal] = useState<boolean>(false);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [selectRequestListPayment, setSelectRequestListPayment] =
    useState<PRMemoResponseLists>({} as PRMemoResponseLists);
  const [dataSourcePurchasingGroup, setDataSourcePurchasingGroup] = useState<
    PurchasingGroup[]
  >([]);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);
  const [checkOnMultiModal, setCheckOnMultiModal] = useState<string>("");
  const [timeUpdate, setTimeUpdate] = useState<string>();
  const [filterInput, setFilterInput] = useState<any | undefined>();
  const [onTableSelect, setOnTableSelect] = useState<React.Key[]>([]);
  const [saveFileList, setSaveFileList] = useState<any[]>([]);
  const [saveDeleteApi, setDeleteFileApi] = useState<any[]>([]);
  // const [disableSendbackModal, setDisableSendbackModal] =
  // useState<boolean>(false);
  const [queryUrlFirst, setQueryUrlFirst] = useState<boolean>(true);
  const [searchText, setSearchText] = useState("");
  const [reasonValue, setReasonValue] = useState<string>("");
  const [form] = Form.useForm();

  const { company, purchasingGroup } = useOptions({
    dataSourceCompany,
    dataSourcePurchasingGroup,
  });

  const {
    querycompid,
    querydate,
    querydatetype,
    querypayno,
    querypurgeid,
    querysearch,
    querydoctype,
    replaceLocation,
  } = useParseQuery();

  const columns = renderPRAdminListColumns({
    searchText,
    onClickEdit: (record) => {
      history.push(`/pr/admin/memo/${record.payId}`);
    },
    setVisibleDrawer,
    setVisibleSendbackModal,
    setVisibleSingleModal,
    setSelectRequestListPayment,
    setLoading,
  });

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([
        getMasterPurchasingGroup(auth.userId),
        getMasterCompany(auth.userId),
      ])
        .then(([responsePurchasing, responseCompany]) => {
          setDataSourcePurchasingGroup(responsePurchasing.responseData);
          setDataSourceCompany(responseCompany.responseData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  useEffect(() => {
    const queryToObject = getQueryObject(filterInput, location.search);

    const pagination = variablePage;

    if (initial) {
      pagination.page = Number(queryToObject?.page || 1);
      pagination.perPage = Number(queryToObject?.perPage || 10);
    }

    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      setLoading(true);

      const filterValidated = {
        ...queryToObject,
        isNonPo: getBooleanFromQuery(queryToObject?.isNonPo as string),
        compId: Number(queryToObject?.compId),
        purgId: Number(queryToObject?.purgId),
      };

      Promise.all([
        getPaymentListMemoAdmin({
          adminBy: `${auth.userId}`,
          apprType: "PR",
          ...filterValidated,
          ...pagination,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(mapMemoDataSource(responsePaymentList));
          setTotalItem(responsePaymentList.total);
          setTimeUpdate(moment().format());
        })
        .finally(() => {
          replaceLocation({
            ...filterInput,
            ...pagination,
          });
          setInital(false);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, variablePage]);

  useEffect(() => {
    if (!initial) {
      replaceLocation({
        ...filterInput,
        page: 1,
        perPage: variablePage.perPage,
      });

      setVariablePage({
        page: 1,
        perPage: variablePage.perPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput]);

  const onSyncDataSource = useCallback(() => {
    const queryToObject = getQueryObject(filterInput, location.search);
    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      const filterValidated = {
        ...queryToObject,
        isNonPo: getBooleanFromQuery(queryToObject?.isNonPo as string),
        compId: Number(queryToObject?.compId),
        purgId: Number(queryToObject?.purgId),
      };
      setLoading(true);
      Promise.all([
        getPaymentListMemoAdmin({
          adminBy: `${auth.userId}`,
          apprType: "PR",
          ...variablePage,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(mapMemoDataSource(responsePaymentList));
          setTotalItem(responsePaymentList.total);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("Error : ", error);
        })
        .finally(() => {
          setLoading(false);
          setTimeUpdate(moment().format());
        });
    }
  }, [filterInput, location.search, auth, searchText.length, variablePage]);

  const onPaymentSaveData = useCallback(
    (payload: PRMemoResponseLists) => {
      return new Promise((resolve, reject) => {
        try {
          const prepareFileList: UploadFileRequest[] = saveFileList.map(
            (file) => {
              const FileName = getFileName(file.name);
              const ExtensionFile = getFileExtension(file.name);

              return {
                FileBase64: file.base64,
                FileType: FileName,
                ExtensionFile,
              };
            }
          );

          Promise.all(
            prepareFileList.map(async (item: UploadFileRequest) =>
              uploadFileList(item)
            )
          )
            .then((response: UploadFileResponse[]) => {
              updatePaymentListById({
                payId: payload.payId,
                isUrgent: payload.isUrgent || false,
                apprType: "PR",
                paymentDesc: payload.paymentDesc || "",
                payAttc: [
                  ...saveDeleteApi.map((item) => {
                    return {
                      payAttcId: item.payAttcId,
                      filePath: item.filePath,
                      filename: item.filename,
                      fileExtension: item.fileExtension,
                      readOnly: false,
                      isDelete: item.isDelete,
                    };
                  }),
                  ...prepareFileList.map((item, index) => {
                    return {
                      payAttcId: 0,
                      filePath: response[index]?.responseData[0]?.filePath,
                      fileName: response[index]?.responseData[0]?.fileName,
                      fileExtension: item.ExtensionFile,
                      readOnly: false,
                      isDelete: false,
                    };
                  }),
                ],
                updateBy: auth?.userId.toString() || "",
              })
                .then((responseUpdate) => {
                  setDeleteFileApi([]);
                  resolve(responseUpdate);
                })
                .catch((error) => reject(error));
            })
            .catch((error) => reject(error));
        } catch (error) {
          reject(error);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth?.userId, dataSource, saveFileList, saveDeleteApi]
  );

  const onPaymentSendApprove = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        sendAdminApprove({
          payId: payIds,
          userId: auth?.userId.toString() || "",
          reason: "",
        }),
      ]).then(() => {
        setDataSource(
          dataSource.filter((item) => !payIds.includes(item.payId))
        );
        setVisibleDrawer(false);
      });
    },
    [auth, dataSource]
  );

  const titleDrawer = useMemo(() => {
    return (
      <Row align="middle">
        <InfoCircleOutlined />
        <Typography.Text style={{ paddingLeft: 10 }}>
          Information
        </Typography.Text>
      </Row>
    );
  }, []);

  const tagIcon = useMemo(() => {
    return (
      <Row
        style={{
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Col span="auto">
          {selectRequestListPayment.isUrgent && (
            <TagIcon
              textIcon="#0E1FC1"
              color="#F4F1FB"
              text="URGENT"
              icon={<Exclamation />}
              border="#F4F1FB"
            />
          )}
        </Col>
        <Col span="auto">
          {moment().format("YYYY-MM-DD") > selectRequestListPayment.dueDate && (
            <TagIcon
              textIcon="#C14F29"
              color="#FFF3EF"
              text="OVERDUE"
              icon={<Thunder />}
              border="#FFF3EF"
            />
          )}
        </Col>
      </Row>
    );
  }, [selectRequestListPayment.dueDate, selectRequestListPayment.isUrgent]);

  const contentDrawer = useMemo(() => {
    const prHeaderNote = selectRequestListPayment?.prHeaderNote;

    return (
      <>
        <Row>
          <Typography.Title
            level={3}
            style={{
              width: "100%",
              paddingLeft: 24,
              paddingRight: 24,
            }}
            className="new-line"
          >
            {prHeaderNote}
          </Typography.Title>
        </Row>

        {tagIcon}

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Headers" key="1">
            <TabsHeader data={selectRequestListPayment} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Details" key="2">
            <TabsDetail
              data={selectRequestListPayment}
              dataCompany={dataSourceCompany}
              dataPurGroup={dataSourcePurchasingGroup}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attacheds" key="3">
            <TabsAttached
              data={Object.values(
                omitBy(selectRequestListPayment?.paymentInfoAttach, (value) => {
                  return saveDeleteApi.find(
                    (element) => element.payAttcId === value.payAttcId
                  );
                })
              )}
              setData={setDeleteFileApi}
              userId={auth?.userId}
              visibleDeleteButton
              disabled={false}
              saveDeleteApi={saveDeleteApi}
              setSaveFileList={setSaveFileList}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Approval Status" key="4">
            <TabsApprove
              payId={selectRequestListPayment.payId}
              userInfo={selectRequestListPayment?.sendApproveBy}
              sendApproveDate={selectRequestListPayment?.sendApproveDate}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }, [
    auth?.userId,
    saveDeleteApi,
    selectRequestListPayment,
    tagIcon,
    dataSourceCompany,
    dataSourcePurchasingGroup,
  ]);

  const footerDrawer = useMemo(() => {
    return (
      <div className="drawer-footer">
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            Amount ({selectRequestListPayment.currency}):
          </Typography.Title>
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            <Typography.Text style={{ color: "#174291", paddingLeft: 5 }}>
              {formatData.toCurrency(selectRequestListPayment.amount)}
            </Typography.Text>
          </Typography.Title>
        </Row>

        <Row justify="space-between" align="middle">
          <Col span={6}>
            <Button
              loading={loading}
              icon={<Save color="#141414" />}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 20,
                gap: 10,
              }}
              onClick={() => {
                setLoading(true);
                history.push(
                  `/pr/admin/memo/${selectRequestListPayment.payId}`
                );
              }}
            >
              <Typography.Text strong>Edit</Typography.Text>
            </Button>
          </Col>
          <Col span={9}>
            <Button
              icon={<CloseOutlined style={{ fontSize: "x-large" }} />}
              danger
              type="primary"
              loading={loading}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 20,
                gap: 10,
              }}
              onClick={() => {
                setVisibleSendbackModal(true);
              }}
            >
              <Typography.Text strong style={{ color: "white" }}>
                Send Back
              </Typography.Text>
            </Button>
          </Col>
          <Col span={9}>
            <Button
              loading={loading}
              icon={<SendFilled />}
              style={{
                display: "flex",
                background: "#174291",
                alignItems: "center",
                padding: 20,
                gap: 10,
              }}
              onClick={() => setVisibleSingleModal(true)}
            >
              <Typography.Text strong style={{ color: "white" }}>
                Release to Approve
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }, [history, loading, selectRequestListPayment]);

  const contentSendbackModal = useMemo(() => {
    const prHeaderNote = selectRequestListPayment?.prHeaderNote;

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text className="new-line">
          Send Back Request : {prHeaderNote}
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectRequestListPayment]);

  const footerSendbackModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button
          loading={loading}
          onClick={() => setVisibleSendbackModal(false)}
        >
          Cancel
        </Button>
        <Button
          // type="primary"
          loading={loading}
          style={{
            background: "#545454",
          }}
          // disabled={!disableSendbackModal}
          onClick={() => {
            setLoading(true);
            // sendReCallPaymentList({
            //   payId: [selectRequestListPayment?.payId as number],
            //   sendApproveBy: `${auth?.userId}`,
            // });
            sendBack({
              payId: [selectRequestListPayment?.payId as number],
              sendAdminBy: `${auth?.userId}`,
            }).then(() => {
              form.resetFields();
              setLoading(false);
              setVisibleSendbackModal(false);
              setVisibleDrawer(false);
              onSyncDataSource();
            });
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Send Back
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [
    auth?.userId,
    onSyncDataSource,
    // disableSendbackModal,
    form,
    loading,
    selectRequestListPayment?.payId,
  ]);

  const contentSingleModal = useMemo(() => {
    const paymentDesc = selectRequestListPayment?.paymentDesc;
    const isUrgent = selectRequestListPayment?.isUrgent;

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Form layout="vertical">
          <Form.Item
            initialValue={paymentDesc}
            name="paymentDesc"
            required
            help={errorPaymentDesc ? "Payment Description is Required" : ""}
            validateStatus={errorPaymentDesc || undefined}
            rules={[]}
            label={
              <Typography.Title level={5}>Payment Description</Typography.Title>
            }
          >
            <Input.TextArea
              placeholder="Description"
              showCount
              rows={3}
              onFocus={() => setErrorPaymentDesc("")}
              maxLength={500}
              onChange={(event) => {
                setSelectRequestListPayment((prev) => {
                  return {
                    ...prev,
                    paymentDesc: event.target.value,
                  };
                });
              }}
            />
          </Form.Item>
        </Form>
      </Spin>
    );
  }, [
    setErrorPaymentDesc,
    errorPaymentDesc,
    loading,
    selectRequestListPayment,
  ]);

  const footerSingleModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button
          loading={loading}
          onClick={() => {
            setErrorPaymentDesc("");
            setVisibleSingleModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            if (isEmpty(selectRequestListPayment.paymentDesc)) {
              setErrorPaymentDesc("error");
              notification.error({
                message: "กรุณากรอกข้อมูลให้ครบถ้วน",
                description: "",
              });
              return;
            }
            setLoading(true);
            Promise.all([
              onPaymentSaveData(selectRequestListPayment),
              onPaymentSendApprove([selectRequestListPayment.payId]),
            ])
              .finally(() => {
                setLoading(false);
                setVisibleSingleModal(false);
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
              });
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [
    loading,
    onPaymentSaveData,
    selectRequestListPayment,
    onPaymentSendApprove,
  ]);

  const contentMutiModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text>
          ยืนยันการส่งข้อมูลจำนวน {onTableSelect.length} ชุด
        </Typography.Text>
      </Spin>
    );
  }, [loading, onTableSelect]);

  const footerMutiModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleMutiModal(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            if (checkOnMultiModal === "Approve") {
              setLoading(true);
              onPaymentSendApprove(
                onTableSelect.map((item) => Number(item))
              )?.finally(() => {
                setLoading(false);
                setVisibleMutiModal(false);
              });
            } else {
              sendBack({
                sendAdminBy: String(auth?.userId),
                payId: onTableSelect.map((item) => Number(item)),
              });
            }
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [
    loading,
    checkOnMultiModal,
    onPaymentSendApprove,
    onTableSelect,
    auth?.userId,
  ]);

  return (
    <div style={{ padding: 20 }}>
      <TitleHeader
        title="PR Admin Approval Lists"
        loading={loading}
        dataSizes={totalItem}
        timeUpdate={timeUpdate}
        onSyncDataSource={onSyncDataSource}
      />
      <FilterInputTable
        isMemo
        querypayno={querypayno}
        querysearch={querysearch}
        onTableSelect={onTableSelect}
        setFilterInput={setFilterInput}
        queryDateType={querydatetype}
        queryDate={querydate}
        setCheckOnMultiModal={setCheckOnMultiModal}
        setMultiConfirm={setVisibleMutiModal}
        setQueryUrlFirst={setQueryUrlFirst}
        selectItems={[
          {
            icon: CompanyLogo,
            name: "isNonPo",
            placeholder: "Document Type",
            dataItems: docType,
            itemIndex: querydoctype,
            span: 3,
          },
          {
            icon: TeamLogo,
            name: "purgId",
            placeholder: "Purchasing Group",
            dataItems: purchasingGroup,
            itemIndex: querypurgeid,
            span: 3,
          },
          {
            icon: CompanyLogo,
            name: "compId",
            placeholder: "Company",
            dataItems: company,
            itemIndex: querycompid,
            span: 3,
          },
        ]}
        checkBoxList={[]}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <DataTable
        data={dataSource}
        checkBox
        loading={loading}
        columns={columns}
        totalItem={totalItem}
        searchText={searchText}
        setSearchText={setSearchText}
        variablePage={variablePage}
        setVariablePage={setVariablePage}
        setOnTableSelect={setOnTableSelect}
      />
      {visibleDrawer ? (
        <CustomDrawer
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          title={titleDrawer}
          content={contentDrawer}
          footer={footerDrawer}
        />
      ) : null}
      {visibleSingleModal ? (
        <CustomModal
          key="ApproveModal"
          visible={visibleSingleModal}
          setVisible={setVisibleSingleModal}
          title="Confirm to Approve"
          content={contentSingleModal}
          footer={footerSingleModal}
        />
      ) : null}
      {visibleSendbackModal ? (
        <CustomModal
          key="SendbackModal"
          visible={visibleSendbackModal}
          setVisible={setVisibleSendbackModal}
          title="Confirm to Send Back"
          content={contentSendbackModal}
          footer={footerSendbackModal}
          // onCancel={handleCancelRejectModal}
        />
      ) : null}
      {visibleMutiModal ? (
        <CustomModal
          key="MultiModal"
          visible={visibleMutiModal}
          setVisible={setVisibleMutiModal}
          title={`Confirm to ${checkOnMultiModal}`}
          content={contentMutiModal}
          footer={footerMutiModal}
        />
      ) : null}
    </div>
  );
};

export default Component;
