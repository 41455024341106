/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Row,
  Col,
  Button,
  Typography,
} from "antd";
import {
  SearchOutlined,
  CalendarOutlined,
  CaretDownOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import "./index.css";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as ReactLogo } from "../../assets/icon/team.svg";
import { ReactComponent as Close } from "../../assets/icon/closeWhite.svg";
import { ReactComponent as Check } from "../../assets/icon/checkWhite.svg";
import { ReCallIcon, SendFilled } from "../CustomIcon";
import setting from "../../setting";

const { Text } = Typography;
export interface ComponentProps<T extends unknown> {
  isMemo?: boolean;
  isBanking?: boolean;
  querypayno?: string | null;
  querysearch?: string | null;
  search?: boolean;
  searchText?: string;
  setSearchText: (val: string) => void;
  setFilterInput?: (val: any) => void;
  setQueryUrlFirst?: (val: boolean) => void;
  setModalVisible?: (val: boolean) => void;
  setMultiConfirm?: (val: boolean) => void;
  setCheckOnMultiModal?: (val: string) => void;
  checkBoxList?: CheckBoxList[];
  datePicker?: boolean;
  queryDateType?: string | null;
  queryDate?: any | null;
  onTableSelect?: React.Key[];
  selectItems?: Array<{
    icon: any;
    name: string | Array<string>;
    placeholder: string;
    dataItems: Array<any>;
    itemIndex?: number | boolean | string | null;
    span: number | {};
  }>;
}

export interface CheckBoxList {
  name: string;
  dataIndex: string;
  queryData: boolean;
}
export interface ObjectDate {
  dueDate: null | {
    dateFrom: string;
    dateTo: string;
  };
  documentDate: null | {
    dateFrom: string;
    dateTo: string;
  };
  postingDate: null | {
    dateFrom: string;
    dateTo: string;
  };
}

const Component = <T extends object>({
  isMemo = false,
  isBanking = false,
  querypayno = null,
  querysearch = null,
  search = true,
  searchText = "",
  checkBoxList = [],
  setSearchText,
  onTableSelect = [],
  setQueryUrlFirst = () => {},
  setCheckOnMultiModal = () => {},
  setMultiConfirm = () => {},
  setModalVisible = () => {},
  setFilterInput = () => {},
  datePicker = true,
  queryDateType = null,
  queryDate = null,
  selectItems = [],
}: ComponentProps<T>) => {
  const [form] = Form.useForm();
  const location = useLocation();

  const [isEnter, setIsEnter] = useState<boolean>(false);

  const pathList = useMemo(() => {
    const pathname = location.pathname.replace("/", "");
    return pathname;
  }, [location]);

  const onFinish = (_: unknown, val: any) => {
    setQueryUrlFirst(false);
    const objectDate: ObjectDate = {
      dueDate: null,
      documentDate: null,
      postingDate: null,
    };
    const dateMoment =
      val.date === null
        ? null
        : {
            dateFrom: moment(val.date[0]).format("YYYY-MM-DD"),
            dateTo: moment(val.date[1]).format("YYYY-MM-DD"),
          };
    if (val.dateType === "Due Date") {
      objectDate.dueDate = dateMoment;
      objectDate.documentDate = null;
      objectDate.postingDate = null;
    } else if (val.dateType === "Document Date") {
      objectDate.dueDate = null;
      objectDate.documentDate = dateMoment;
      objectDate.postingDate = null;
    } else if (val.dateType === "Posting Date") {
      objectDate.dueDate = null;
      objectDate.documentDate = null;
      objectDate.postingDate = dateMoment;
    }
    setFilterInput({ ...val, ...objectDate });
  };

  const onEnter = () => {
    setIsEnter(true);
  };

  const styleCol = { paddingRight: 16 };
  const styleInput = { width: "100%" };

  // eslint-disable-next-line no-nested-ternary
  const dateType = isMemo
    ? ["Document Date"]
    : isBanking
    ? ["Due Date"]
    : ["Due Date", "Document Date", "Posting Date"];

  const renderMultipleRelease = (isSendAdmin = false) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 24,
        height: "57px",
      }}
    >
      <Text>Selecting {onTableSelect.length} items</Text>
      <Button
        icon={<SendFilled />}
        style={{
          display: "flex",
          background: "#174291",
          alignItems: "center",
          padding: 11,
          gap: 10,
          height: "30px",
        }}
        onClick={() => setMultiConfirm(true)}
      >
        <Text strong style={{ color: "white" }}>
          Release to Approve
          {/* {isSendAdmin ? "Send to Admin" : "Release to Approve"} */}
        </Text>
      </Button>
    </div>
  );

  const renderMultipleApprove = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 24,
        height: "57px",
      }}
    >
      <Text>Selecting {onTableSelect.length} items</Text>
      <Button
        icon={<CheckOutlined style={{ color: "white" }} />}
        style={{
          display: "flex",
          background: "#174291",
          alignItems: "center",
          padding: 11,
          gap: 10,
          height: "30px",
        }}
        onClick={() => setMultiConfirm(true)}
      >
        <Text strong style={{ color: "white" }}>
          Approve
        </Text>
      </Button>
    </div>
  );

  const renderMultipleReleaseAndReject = (isAdmin = false) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 15,
        height: "57px",
      }}
    >
      <Text>Selecting {onTableSelect.length} items</Text>
      <Button
        icon={<Close />}
        style={{
          display: "flex",
          background: "#D82E18",
          alignItems: "center",
          gap: 10,
          padding: 11,
          height: "30px",
          width: "90px",
        }}
        onClick={() => {
          setCheckOnMultiModal("Reject");
          setMultiConfirm(true);
        }}
      >
        <Text strong style={{ color: "white" }}>
          {isAdmin ? "Send Back" : "Reject"}
        </Text>
      </Button>
      <Button
        icon={<Check />}
        style={{
          display: "flex",
          background: "#03703C",
          alignItems: "center",
          gap: 10,
          padding: 11,
          height: "30px",
        }}
        onClick={() => {
          setCheckOnMultiModal("Approve");
          setMultiConfirm(true);
        }}
      >
        <Text strong style={{ color: "white" }}>
          {isAdmin ? "Release to Approve" : "Approve"}
        </Text>
      </Button>
    </div>
  );

  if (onTableSelect.length > 0) {
    switch (pathList) {
      case "requester":
        return renderMultipleRelease();
      case "pr/requester":
        return renderMultipleRelease(true);
      case "pr/admin":
        return renderMultipleReleaseAndReject(true);
      case "approver":
        return renderMultipleReleaseAndReject();
      case "pr/approver":
        return renderMultipleReleaseAndReject();
      case "banking":
        return renderMultipleRelease();
      case "banking/history":
        return renderMultipleApprove();
      default:
        return <></>;
    }
  }

  const DatePick = (): any => {
    return (
      <Col span={7} style={styleCol}>
        <Form.Item style={styleInput}>
          <Input.Group compact className="d-flex">
            <div className="select-table-date">
              <Form.Item initialValue={queryDateType} name="dateType" noStyle>
                <Select
                  placeholder="Date Type"
                  size="small"
                  allowClear
                  suffixIcon={
                    <Row justify="space-between">
                      <CalendarOutlined />
                      <CaretDownOutlined />
                    </Row>
                  }
                >
                  {dateType.map((item: string) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              initialValue={
                queryDate
                  ? [
                      moment(queryDate?.dateFrom, "YYYY-MM-DD"),
                      moment(queryDate?.dateTo, "YYYY-MM-DD"),
                    ]
                  : null
              }
              name="date"
              noStyle
            >
              <DatePicker.RangePicker
                disabled={!form.getFieldValue("dateType")}
                allowClear
                format="DD/MM/YYYY"
                className="date-picker-table"
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Col>
    );
  };

  return (
    <Form form={form} onValuesChange={onFinish}>
      <Row gutter={10}>
        {search && (
          <Col span={10} md={8} lg={6} xl={5} xxl={4}>
            <Form.Item
              initialValue={querypayno === null ? querysearch : querypayno}
              name="search"
              style={styleInput}
            >
              <Input
                placeholder="Search by text"
                prefix={<SearchOutlined />}
                allowClear
                onPressEnter={(e) => {
                  let a: unknown;
                  const v = e.target as HTMLInputElement;
                  if (
                    v.value.length === 0 ||
                    v.value.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10)
                  ) {
                    setSearchText(v.value);
                    onFinish(a, form.getFieldsValue());
                  } else {
                    setSearchText(setting.SEARCH_TEXT_TEMP);
                  }
                }}
                onChange={(v) => {
                  console.log(v.target.value);
                  if (v.target.value.length === 12) {
                    setSearchText(v.target.value);
                  } else {
                    setSearchText(setting.SEARCH_TEXT_TEMP);
                  }
                }}
                // onLoad
              />
            </Form.Item>
          </Col>
        )}
        {checkBoxList.map((item) => (
          <Col style={{ paddingRight: 2, width: "fit-content" }}>
            <Form.Item
              name={item.dataIndex}
              className="checkbox-table"
              style={styleInput}
              initialValue={item.queryData}
              valuePropName="checked"
            >
              <Checkbox value="true">{item.name}</Checkbox>
            </Form.Item>
          </Col>
        ))}
        {selectItems.map((data, index) => {
          return (
            <>
              <Col span={data.span as number} style={styleCol}>
                <div className="select-table" style={styleInput}>
                  <Form.Item
                    initialValue={
                      // eslint-disable-next-line no-nested-ternary
                      data.itemIndex === true
                        ? true
                        : // eslint-disable-next-line no-nested-ternary
                        data.itemIndex === false
                        ? false
                        : // eslint-disable-next-line no-nested-ternary
                        Number(data.itemIndex) > 0
                        ? data.itemIndex
                        : typeof data.itemIndex === "string"
                        ? data.itemIndex
                        : undefined
                    }
                    name={data.name}
                    noStyle
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          option?.label
                            ?.toLowerCase()
                            ?.indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      placeholder="ALL"
                      size="small"
                      allowClear
                      options={data.dataItems}
                      suffixIcon={
                        <Row justify="space-between">
                          <data.icon />
                          <CaretDownOutlined />
                        </Row>
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
              {datePicker && index === 1 && pathList === "approver/history" && (
                <DatePick />
              )}
              {datePicker &&
                index === 2 &&
                pathList === "requester/history" && <DatePick />}
            </>
          );
        })}
        {datePicker &&
          !(
            pathList === "requester/history" || pathList === "approver/history"
          ) && <DatePick />}
      </Row>
    </Form>
  );
};

export default Component;
