import axios from "../libs/axios";
import type {
  LoginRequest,
  LoginResponse,
} from "../interfaces/authenticationAPI.interface";
import { User } from "../interfaces/user.interface";

export const login = (data: LoginRequest): Promise<LoginResponse> =>
  new Promise((resolve, reject) => {
    axios
      .post("/service/login", data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const signInWithAzure = (token: string): Promise<LoginResponse> =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `/users/info`,
        {
          deviceToken: "",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getUserInfo = (): Promise<LoginResponse> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/userinfo/`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getProfile = (user: User): Promise<User> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/approval/master/userinfo/${user.userId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
