export const get = (): string | null => {
  return window.localStorage.getItem("token") || null;
};

export const set = (value: string | undefined): void => {
  return window.localStorage.setItem("token", value || "");
};

export const clear = (): void => {
  window.localStorage.clear();
};
