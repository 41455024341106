import React, { FC } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  FormProps,
  FormInstance,
  Typography,
} from "antd";

export interface MemoFormProps extends FormProps {
  form: FormInstance;
  formatNumber: (value: number | undefined) => string;
  defaultCurrency: string;
  localCurrentAmount: number | undefined;
}

export interface ItemProps {
  key: string;
  label: string;
  value: string;
}

const Component: FC<MemoFormProps> = ({
  form,
  formatNumber,
  defaultCurrency,
  localCurrentAmount,
  ...rest
}) => {
  const { Text } = Typography;
  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      initialValues={{ files: [] }}
      // eslint-disable-next-line no-template-curly-in-string
      validateMessages={{ required: "${label} is required" }}
      {...rest}
    >
      <Row gutter={32}>
        <Col span={24} md={14} lg={14} xl={24}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Text strong>HEADER</Text>
            </Col>
            <Col span={24}>
              <Row gutter={8}>
                <Col span={24}>
                  <Row gutter={[8, 8]}>
                    <Col span={24} sm={8} xl={2}>
                      <Form.Item name="payId" hidden initialValue={0} />
                      <Form.Item name="compId" hidden />
                      <Form.Item name="compCode" label="Company Name">
                        <Input
                          disabled
                          placeholder="Company"
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={16} xl={4}>
                      <Form.Item label=" " name="disableCompany">
                        <Input disabled className="FieldInputStyle" />
                      </Form.Item>
                    </Col>
                    {form.getFieldValue("batchNo") === "" || (
                      <Col span={24} sm={12} xl={3}>
                        <Form.Item label="Batch No." name="batchNo">
                          <Input disabled className="FieldInputStyle" />
                        </Form.Item>
                      </Col>
                    )}
                    {form.getFieldValue("batchNo") === "" || (
                      <Col span={24} sm={12} xl={3}>
                        <Form.Item label="Due Date" name="dueDate">
                          <Input disabled className="FieldInputStyle" />
                        </Form.Item>
                      </Col>
                    )}
                    {form.getFieldValue("batchNo") === "" && (
                      <Col span={12} sm={4} xl={2}>
                        <Form.Item name="totalDocument" label="Total Document">
                          <Input disabled className="FieldInputStyle" />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={12} sm={4} xl={2}>
                      <Form.Item
                        name="currency"
                        label="Currency"
                        initialValue={defaultCurrency}
                      >
                        <Input disabled className="FieldInputStyle" />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={8} xl={3}>
                      <Form.Item name="amount" label="Amount (Included Vat)">
                        <InputNumber
                          disabled
                          controls={false}
                          placeholder="Amount (Included Vat)"
                          min={0}
                          precision={2}
                          formatter={formatNumber}
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} sm={12} xl={3}>
                      <Form.Item name="amountCurr" label="Local Current Amount">
                        <InputNumber
                          value={localCurrentAmount}
                          controls={false}
                          disabled
                          formatter={formatNumber}
                          precision={2}
                          placeholder="Local Current Amount"
                          className="FieldInputStyle"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Component;
