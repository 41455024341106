import type { AxiosError } from "axios";
import {
  ApproveRejectedConfirm,
  ReleaseConfirm,
  SaveConfirm,
} from "../interfaces/payment.interface";
import axios from "../libs/axios";

export const releaseConfirm = (option: ReleaseConfirm): Promise<string> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/sendapprove", option)
      .then((result: { data: Promise<string> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const saveConfirm = (option: SaveConfirm): Promise<string> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist", option)
      .then((result: { data: Promise<string> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const modalConfirm = (option: ApproveRejectedConfirm): Promise<string> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/approved", option)
      .then((result: { data: Promise<string> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const modalRejected = (
  option: ApproveRejectedConfirm
): Promise<string> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/rejected", option)
      .then((result: { data: Promise<string> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

// export const modalRecall = (option: ApproveRejectedConfirm): Promise<string> =>
//   new Promise((resolve, reject) => {
//     axios
//       .post("/approval/paymentlist/recall", option)
//       .then((result: { data: Promise<string> }) => {
//         resolve(result.data);
//       })
//       .catch((error: AxiosError) => {
//         reject(error);
//       });
//   });
