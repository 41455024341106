/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from "dayjs";
import { head, includes, isMatch, omit, omitBy, pick } from "lodash";

interface Filter {
  search?: string;
  isImmediately?: boolean;
  purgId?: string;
  company?: string;
  dueDate?: {
    dateFrom: string;
    dateTo: string;
  };
  documentDate?: {
    dateFrom: string;
    dateTo: string;
  };
  postingDate?: {
    dateFrom: string;
    dateTo: string;
  };
}

export const filterDataSource = (
  dataSource: any[],
  filterValidated: Record<string, unknown>
): any[] => {
  const filter: Filter = omitBy(
    omit(filterValidated, ["date", "dateType"]),
    (value) => {
      if (value === false) {
        return value;
      }
      return !value;
    }
  );

  const filterKeys = Object.keys(filter);

  return dataSource.filter((object) => {
    if (filterKeys.includes("search")) {
      const objectOmit = omitBy(
        object,
        (value) => !(typeof value === "number" || typeof value === "string")
      );

      const result = includes(
        Object.values(objectOmit).join("").toUpperCase(),
        filter.search?.toUpperCase()
      );

      if (!result) {
        return false;
      }
    }

    const searchDate = head(
      Object.keys(pick(filter, ["dueDate", "documentDate", "postingDate"]))
    );

    if (searchDate) {
      let result = true;

      switch (searchDate) {
        case "dueDate":
          result = dayjs(object.dueDate)
            .add(1, "millisecond")
            .isBetween(
              filter.dueDate?.dateFrom,
              dayjs(filter.dueDate?.dateTo)
                .add(1, "day")
                .subtract(1, "millisecond")
                .format()
            );
          break;
        case "documentDate":
          result = dayjs(object.documentDate)
            .add(1, "millisecond")
            .isBetween(
              filter.documentDate?.dateFrom,
              dayjs(filter.documentDate?.dateTo)
                .add(1, "day")
                .subtract(1, "millisecond")
                .format()
            );
          break;
        case "postingDate":
          result = dayjs(object.postingDate)
            .add(1, "millisecond")
            .isBetween(
              filter.postingDate?.dateFrom,
              dayjs(filter.postingDate?.dateTo)
                .add(1, "day")
                .subtract(1, "millisecond")
                .format()
            );
          break;
        default:
      }

      if (!result) {
        return false;
      }
    }

    return isMatch(
      object,
      omit(filter, [
        "search",
        "dueDate",
        "documentDate",
        "postingDate",
        "page",
        "pageSize",
      ])
    );
  });
};
