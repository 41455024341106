import { message } from "antd";
import { AxiosError } from "axios";
import axios from "../libs/axios";
import {
  PayLoadSendApprove,
  PayloadUpdatePaymentList,
  ResponseRequestList,
  PayloadDeleteUndeletePaymentList,
  PayloadUpdatePaymentListAttach,
  ResponseRequestListDeleteUndelete,
} from "../interfaces/payment.interface";
import { RequestHistory } from "../interfaces/history.interface";
import type { Response } from "../interfaces/response.interface";

export const getPaymentList = (): Promise<ResponseRequestList> =>
  new Promise((resolve, reject) => {
    axios
      .get("/Approval/paymentlist")
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
export const paymentHistory = (
  data: RequestHistory | null,
  onPage: string,
  userId?: string,
  page?: number,
  perPage?: number
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post(
        "/approval/paymentlist/history",
        data === null
          ? {
              // `${userId}`
              sendApproveBy: onPage === "approve" ? "" : `${userId}`,
              activityState: null,
              compId: null,
              purgId: null,
              isImmediately: null,
              dueDate: null,
              documentDate: null,
              postingDate: null,
              waitApproveBy: onPage === "approve" ? `${userId}` : "",
              page,
              perPage,
            }
          : // `${userId}`
            {
              ...data,
              sendApproveBy: onPage === "approve" ? "" : `${userId}`,
              waitApproveBy: onPage === "approve" ? `${userId}` : "",
              page,
              perPage,
            }
      )
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const paymentHistoryApprove = (
  data: RequestHistory | null,
  onPage: string,
  userId?: string,
  page?: number,
  perPage?: number
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post(
        "/approval/paymentlist/history/log",
        data === null
          ? {
              // `${userId}`
              sendApproveBy: onPage === "approve" ? "" : `${userId}`,
              activityState: null,
              compId: null,
              purgId: null,
              isImmediately: null,
              dueDate: null,
              documentDate: null,
              postingDate: null,
              waitApproveBy: onPage === "approve" ? `${userId}` : "",
              page,
              perPage,
            }
          : // `${userId}`
            {
              ...data,
              sendApproveBy: onPage === "approve" ? "" : `${userId}`,
              waitApproveBy: onPage === "approve" ? `${userId}` : "",
              page,
              perPage,
            }
      )
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const updatePaymentListById = (
  payload: PayloadUpdatePaymentList
): Promise<string> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist", payload)
      .then((result: { data: Promise<string> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const updatePaymentListAttachFileById = (
  payload: PayloadUpdatePaymentListAttach
): Promise<string> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/attachfile", payload)
      .then((result: { data: Promise<string> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const deleteFilePaymentListById = (
  payload: PayloadUpdatePaymentList
): Promise<string> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist", payload)
      .then((result: { data: Promise<string> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const sendApprove = (data: PayLoadSendApprove): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/sendapprove", data)
      .then((result) => {
        message.success(`ส่งขออนุมัติสำเร็จ`, 10);
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error({
            key: "msgKey",
            content: `${error.response?.data.error}`,
            duration: 30,
            className: "new-line",
            onClick: () => message.destroy("msgKey"),
          });
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const deletePaymentListById = (
  payload: PayloadDeleteUndeletePaymentList
): Promise<ResponseRequestListDeleteUndelete> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/delete", payload)
      .then((result) => {
        message.success(`ทำการ Delete Request สำเร็จ`, 10);
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const undeletePaymentListById = (
  payload: PayloadDeleteUndeletePaymentList
): Promise<ResponseRequestListDeleteUndelete> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/undelete", payload)
      .then((result) => {
        message.success(`ทำการ Undelete Request สำเร็จ`, 10);
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });
