/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Typography,
  Form,
  Spin,
  Row,
  Tabs,
  Input,
  Col,
  Select,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import moment from "moment";
import omitBy from "lodash/omitBy";
import {
  RequestList,
  ResponseRequestList,
} from "../../interfaces/paymentList.interface";
import TitleHeader from "../../components/TitleHeader";
import FilterInputTable from "../../components/FilterInputTable";
import { CustomDrawer } from "../../components/Drawer";
import { TabsHeader, TabsDetail } from "../../components/Drawer/TabsMemo";
import { TabsApprove, TabsAttached } from "../../components/Drawer/Tabs";
import { ReactComponent as TeamLogo } from "../../assets/icon/team.svg";
import { ReactComponent as CompanyLogo } from "../../assets/icon/company.svg";
import { updatePaymentListAttachFileById } from "../../api/approval";
import { getPaymentListHistory } from "../../api/paymentList";
import { Company, PurchasingGroup } from "../../interfaces/master.interface";
import { getMasterCompany, getMasterPurchasingGroup } from "../../api/master";
import CustomModal from "../../components/CustomModal";
import Save from "../../components/CustomIcon/Save";
import useAuthentication from "../../hooks/useAuthentication";
// import DataTable from "../../components/DataTable/DataTableRequest";
import DataTable from "../../components/DataTable";
// import FilterDataTable from "../../components/FilterDataTable";
import formatData from "../../libs/formatData";
import { uploadFileList } from "../../api/service";
import { Exclamation, Thunder } from "../../components/CustomIcon";
import TagIcon from "../../components/TagIcon";
import { modalConfirm, modalRejected } from "../../api/modalConfirm";
import { RejectReason } from "../../enum/rejectReason.enum";
import {
  UploadFileRequest,
  UploadFileResponse,
} from "../../interfaces/serviceAPI.interface";
import { getFileName, getFileExtension } from "../../libs/file";
import useParseQuery from "../../hooks/useParseQuery";
import { renderPRAdminApproveListColumns } from "./columns";
import { getBooleanFromQuery, getQueryObject } from "../../libs/query";
import { docType } from "../../libs/antd/options/optionsValue";
import useOptions from "../../hooks/useOptions";
import { mapDataSource } from "../../libs/array/mappingPaymentList";
import setting from "../../setting";

const { TextArea } = Input;

dayjs.extend(isBetween);

const Component = (): JSX.Element => {
  const auth = useAuthentication();

  const location = useLocation();

  const {
    querypurgeid,
    querycompid,
    querydate,
    querydatetype,
    querysearch,
    querypayno,
    querydoctype,
    replaceLocation,
  } = useParseQuery();
  const [initial, setInital] = useState(true);
  const [dataSource, setDataSource] = useState<RequestList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [variablePage, setVariablePage] = useState<{
    page: number;
    perPage: number;
  }>({ page: 1, perPage: 10 });
  const [visibleApproveModal, setVisibleApproveModal] =
    useState<boolean>(false);
  const [visibleRejectModal, setVisibleRejectModal] = useState<boolean>(false);
  const [visibleMutiModal, setVisibleMutiModal] = useState<boolean>(false);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [selectRequestListPayment, setSelectRequestListPayment] =
    useState<RequestList>({} as RequestList);
  const [dataSourcePurchasingGroup, setDataSourcePurchasingGroup] = useState<
    PurchasingGroup[]
  >([]);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);
  const [checkOnMultiModal, setCheckOnMultiModal] = useState<string>("");
  const [timeUpdate, setTimeUpdate] = useState<string>();
  const [filterInput, setFilterInput] = useState<any | undefined>();
  const [onTableSelect, setOnTableSelect] = useState<React.Key[]>([]);
  const [saveFileList, setSaveFileList] = useState<any[]>([]);
  const [saveDeleteApi, setDeleteFileApi] = useState<any[]>([]);
  const [disableRejectModal, setDisableRejectModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryUrlFirst, setQueryUrlFirst] = useState<boolean>(true);
  const [searchText, setSearchText] = useState("");
  const [reasonValue, setReasonValue] = useState<string>("");
  const [form] = Form.useForm();

  const { company, purchasingGroup } = useOptions({
    dataSourceCompany,
    dataSourcePurchasingGroup,
  });

  const columns = renderPRAdminApproveListColumns({
    searchText,
    setLoading,
    setVisibleDrawer,
    setVisibleRejectModal,
    setVisibleApproveModal,
    setSelectRequestListPayment,
  });

  const requestPayload = useMemo(() => {
    return {
      activityState: "pending",
      dueDate: null,
      documentDate: null,
      postingDate: null,
      isUrgent: null,
      isOverdue: null,
      compId: null,
      purgId: null,
      isImmediately: null,
      apprType: "PR",
      page: 1,
      perPage: 10,
    };
  }, []);

  const onChangeRejectReason = useCallback(
    (value) => {
      setReasonValue(value);
      if (value === RejectReason.OTHER) {
        form.setFieldsValue({ reason: undefined });
        setDisableRejectModal(false);
      } else {
        form.setFieldsValue({ reason: value });
        setDisableRejectModal(true);
      }
    },
    [form]
  );

  const handleCancelRejectModal = () => {
    form.setFieldsValue({ reason: undefined });
    setReasonValue("");
    setDisableRejectModal(false);
    setVisibleRejectModal(false);
  };

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([
        getMasterPurchasingGroup(auth.userId),
        getMasterCompany(auth.userId),
      ])
        .then(([responsePurchasing, responseCompany]) => {
          setDataSourcePurchasingGroup(responsePurchasing.responseData);
          setDataSourceCompany(responseCompany.responseData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  useEffect(() => {
    const queryToObject = getQueryObject(filterInput, location.search);

    const pagination = variablePage;

    if (initial) {
      pagination.page = Number(queryToObject?.page || 1);
      pagination.perPage = Number(queryToObject?.perPage || 10);
    }

    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      setLoading(true);

      const filterValidated = {
        ...queryToObject,
        isNonPo: getBooleanFromQuery(queryToObject?.isNonPo as string),
        compId: Number(queryToObject?.compId),
        purgId: Number(queryToObject?.purgId),
      };

      Promise.all([
        getPaymentListHistory({
          sendApproveBy: "",
          waitApproveBy: `${auth.userId}`,
          search: `${searchText}`,
          ...requestPayload,
          ...filterValidated,
          ...pagination,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(
            mapDataSource(responsePaymentList as ResponseRequestList)
          );
          setTotalItem(responsePaymentList.total);
          setTimeUpdate(moment().format());
        })
        .finally(() => {
          replaceLocation({
            ...filterInput,
            ...pagination,
          });
          setInital(false);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, variablePage]);

  useEffect(() => {
    if (!initial) {
      replaceLocation({
        ...filterInput,
        page: 1,
        perPage: variablePage.perPage,
      });

      setVariablePage({
        page: 1,
        perPage: variablePage.perPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput]);

  const onSyncDataSource = useCallback(() => {
    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      setLoading(true);
      Promise.all([
        getPaymentListHistory({
          sendApproveBy: "",
          waitApproveBy: `${auth.userId}`,
          search: `${searchText}`,
          ...requestPayload,
          ...variablePage,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(
            mapDataSource(responsePaymentList as ResponseRequestList)
          );
          setTotalItem(responsePaymentList.total);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("Error : ", error);
        })
        .finally(() => {
          setLoading(false);
          setTimeUpdate(moment().format());
        });
    }
  }, [auth, requestPayload, variablePage, searchText]);

  const onPaymentUpdateAttachFile = useCallback(
    (payload: RequestList) => {
      return new Promise((resolve, reject) => {
        try {
          const prepareFileList: UploadFileRequest[] = saveFileList.map(
            (file) => {
              const FileName = getFileName(file.name);
              const ExtensionFile = getFileExtension(file.name);

              return {
                FileBase64: file.base64,
                FileType: FileName,
                ExtensionFile,
              };
            }
          );

          Promise.all(
            prepareFileList.map(async (item: UploadFileRequest) =>
              uploadFileList(item)
            )
          )
            .then((response: UploadFileResponse[]) => {
              updatePaymentListAttachFileById({
                payId: payload.payId,
                payAttc: [
                  ...saveDeleteApi.map((item) => {
                    return {
                      payAttcId: item.payAttcId,
                      filePath: item.filePath,
                      filename: item.filename,
                      fileExtension: item.fileExtension,
                      readOnly: false,
                      isDelete: item.isDelete,
                    };
                  }),
                  ...prepareFileList.map((item, index) => {
                    return {
                      payAttcId: 0,
                      filePath: response[index]?.responseData[0]?.filePath,
                      fileName: response[index]?.responseData[0]?.fileName,
                      fileExtension: item.ExtensionFile,
                      readOnly: false,
                      isDelete: false,
                    };
                  }),
                ],
                apprType: "PA",
                updateBy: auth?.userId.toString() || "",
              })
                .then((responseUpdate) => {
                  setDeleteFileApi([]);
                  resolve(responseUpdate);
                })
                .catch((error) => reject(error));
            })
            .catch((error) => reject(error));
        } catch (error) {
          reject(error);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth?.userId, dataSource, saveFileList, saveDeleteApi]
  );

  const titleDrawer = useMemo(() => {
    return (
      <Row align="middle">
        <InfoCircleOutlined />
        <Typography.Text style={{ paddingLeft: 10 }}>
          Information
        </Typography.Text>
      </Row>
    );
  }, []);

  const tagIcon = useMemo(() => {
    return (
      <Row
        style={{
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Col span="auto">
          {selectRequestListPayment.isUrgent && (
            <TagIcon
              textIcon="#0E1FC1"
              color="#F4F1FB"
              text="URGENT"
              icon={<Exclamation />}
              border="#F4F1FB"
            />
          )}
        </Col>
        <Col span="auto">
          {moment().format("YYYY-MM-DD") > selectRequestListPayment.dueDate && (
            <TagIcon
              textIcon="#C14F29"
              color="#FFF3EF"
              text="OVERDUE"
              icon={<Thunder />}
              border="#FFF3EF"
            />
          )}
        </Col>
      </Row>
    );
  }, [selectRequestListPayment.dueDate, selectRequestListPayment.isUrgent]);

  const contentDrawer = useMemo(() => {
    const prHeaderNote = selectRequestListPayment?.prHeaderNote;

    return (
      <>
        <Row>
          <Typography.Title
            level={3}
            style={{
              width: "100%",
              paddingLeft: 24,
              paddingRight: 24,
            }}
            className="new-line"
          >
            {prHeaderNote}
          </Typography.Title>
        </Row>

        {tagIcon}

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Headers" key="1">
            <TabsHeader data={selectRequestListPayment} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Details" key="2">
            <TabsDetail
              data={selectRequestListPayment}
              dataCompany={dataSourceCompany}
              dataPurGroup={dataSourcePurchasingGroup}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attacheds" key="3">
            <TabsAttached
              data={Object.values(
                omitBy(selectRequestListPayment?.paymentInfoAttach, (value) => {
                  return saveDeleteApi.find(
                    (element) => element.payAttcId === value.payAttcId
                  );
                })
              )}
              setData={setDeleteFileApi}
              userId={auth?.userId}
              visibleDeleteButton
              disabled={false}
              saveDeleteApi={saveDeleteApi}
              setSaveFileList={setSaveFileList}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Approval Status" key="4">
            <TabsApprove
              payId={selectRequestListPayment.payId}
              userInfo={selectRequestListPayment?.sendApproveBy}
              sendApproveDate={selectRequestListPayment?.sendApproveDate}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }, [
    auth?.userId,
    saveDeleteApi,
    dataSourceCompany,
    dataSourcePurchasingGroup,
    selectRequestListPayment,
    tagIcon,
  ]);

  const footerDrawer = useMemo(() => {
    return (
      <div className="drawer-footer">
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            Amount ({selectRequestListPayment.currency}):
          </Typography.Title>
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            <Typography.Text style={{ color: "#174291", paddingLeft: 5 }}>
              {formatData.toCurrency(selectRequestListPayment.amount)}
            </Typography.Text>
          </Typography.Title>
        </Row>

        <Row justify="space-between" align="middle">
          <Col span={6}>
            <Button
              loading={loading}
              icon={<Save color="#141414" />}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 20,
                gap: 10,
              }}
              onClick={() => {
                setLoading(true);
                onPaymentUpdateAttachFile(selectRequestListPayment).finally(
                  () => {
                    setLoading(false);
                    setVisibleDrawer(false);
                    onSyncDataSource();
                  }
                );
              }}
            >
              <Typography.Text strong>Save</Typography.Text>
            </Button>
          </Col>
          <Col span={9}>
            <Button
              type="primary"
              icon={<CloseOutlined style={{ fontSize: "x-large" }} />}
              danger
              block
              style={{
                display: "flex",
                width: "90%",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
                gap: 10,
              }}
              onClick={() => {
                setVisibleRejectModal(true);
              }}
            >
              <Typography.Text strong style={{ color: "white" }}>
                Reject
              </Typography.Text>
            </Button>
          </Col>
          <Col span={9}>
            <Button
              type="primary"
              icon={<CheckOutlined style={{ fontSize: "x-large" }} />}
              block
              style={{
                display: "flex",
                width: "90%",
                background: "#03703C",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
                gap: 10,
              }}
              onClick={() => {
                setVisibleApproveModal(true);
              }}
            >
              <Typography.Text strong style={{ color: "white" }}>
                Approve
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }, [
    loading,
    onPaymentUpdateAttachFile,
    onSyncDataSource,
    selectRequestListPayment,
  ]);

  const contentApproveModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text className="new-line">
          Are you sure to approve {selectRequestListPayment.prHeaderNote}{" "}
          {formatData.toCurrency(selectRequestListPayment.amount)}
          {selectRequestListPayment.currency}?
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectRequestListPayment]);

  const contentRejectModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text className="new-line" style={{ marginBottom: "20px" }}>
          Are you sure to reject {selectRequestListPayment.prHeaderNote}{" "}
          {formatData.toCurrency(selectRequestListPayment.amount)}
          {selectRequestListPayment.currency}?
        </Typography.Text>
        <Form layout="vertical" form={form}>
          <Form.Item name="reason" label="Reason" rules={[{ required: true }]}>
            <Select
              onChange={(e) => onChangeRejectReason(e)}
              placeholder="- Select -"
            >
              <Select.Option value={RejectReason.INVALID_TEXT}>
                ระบุข้อความไม่ถูกต้อง / ข้อความไม่ครบถ้วน
              </Select.Option>
              <Select.Option value={RejectReason.INVALID_AMOUNT}>
                ระบุจำนวนเงินไม่ถูกต้อง
              </Select.Option>
              <Select.Option value={RejectReason.INVALID_FILE}>
                แนบเอกสารไฟล์ไม่ถูกต้อง
              </Select.Option>
              <Select.Option value={RejectReason.CANCEL_TRANSACTION}>
                ยกเลิกการชำระเงิน
              </Select.Option>
              <Select.Option value={RejectReason.OTHER}>
                อื่น ๆ (โปรดระบุ)
              </Select.Option>
            </Select>
            {reasonValue === RejectReason.OTHER && (
              <TextArea
                showCount
                maxLength={200}
                onChange={(value) => {
                  setDisableRejectModal(!!value.target.value);
                  form.setFieldsValue({ reason: value.target.value });
                }}
                placeholder="Field is Required"
              />
            )}
          </Form.Item>
        </Form>
      </Spin>
    );
  }, [
    form,
    loading,
    onChangeRejectReason,
    reasonValue,
    selectRequestListPayment,
  ]);

  const footerApproveModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CheckOutlined style={{ fontSize: "x-large" }} />}
          block
          style={{
            display: "flex",
            background: "#03703C",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          onClick={() => {
            setLoading(true);
            modalConfirm({
              payId: [selectRequestListPayment?.payId as number],
              userId: `${auth?.userId}`,
              reason: "",
              isCancel: false,
            }).then(() => {
              setLoading(false);
              setVisibleApproveModal(false);
              setVisibleDrawer(false);
              onSyncDataSource();
            });
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Approve
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [
    loading,
    onSyncDataSource,
    selectRequestListPayment?.payId,
    auth?.userId,
  ]);

  const footerRejectModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CloseOutlined />}
          danger
          block
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          disabled={!disableRejectModal}
          onClick={() => {
            setLoading(true);
            modalRejected({
              payId: [selectRequestListPayment?.payId as number],
              userId: `${auth?.userId}`,
              reason: form.getFieldValue("reason"),
              isCancel: false,
            }).then(() => {
              form.resetFields();
              setLoading(false);
              setVisibleRejectModal(false);
              setVisibleDrawer(false);
              onSyncDataSource();
            });
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Reject
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [
    auth?.userId,
    onSyncDataSource,
    disableRejectModal,
    form,
    loading,
    selectRequestListPayment?.payId,
  ]);

  const contentMutiModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text>
          ยืนยันการส่งข้อมูลจำนวน {onTableSelect.length} ชุด
        </Typography.Text>
      </Spin>
    );
  }, [loading, onTableSelect]);

  const footerMutiModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleMutiModal(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            if (checkOnMultiModal === "Approve") {
              modalConfirm({
                payId: onTableSelect,
                userId: `${auth?.userId}`,
                reason: "",
                isCancel: false,
              }).then(() => {
                setLoading(false);
                setVisibleMutiModal(false);
                onSyncDataSource();
              });
            } else {
              modalRejected({
                payId: onTableSelect,
                userId: `${auth?.userId}`,
                reason: "",
                isCancel: false,
              }).then(() => {
                setLoading(false);
                setVisibleMutiModal(false);
                onSyncDataSource();
              });
            }
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [
    loading,
    checkOnMultiModal,
    onTableSelect,
    auth?.userId,
    onSyncDataSource,
  ]);

  return (
    <div style={{ padding: 20 }}>
      <TitleHeader
        title="PR Approval Lists"
        loading={loading}
        dataSizes={totalItem}
        timeUpdate={timeUpdate}
        onSyncDataSource={onSyncDataSource}
      />
      <FilterInputTable
        isMemo
        querypayno={querypayno}
        querysearch={querysearch}
        onTableSelect={onTableSelect}
        setFilterInput={setFilterInput}
        queryDateType={querydatetype}
        queryDate={querydate}
        setCheckOnMultiModal={setCheckOnMultiModal}
        setMultiConfirm={setVisibleMutiModal}
        setQueryUrlFirst={setQueryUrlFirst}
        selectItems={[
          {
            icon: CompanyLogo,
            name: "isNonPo",
            placeholder: "Document Type",
            dataItems: docType,
            itemIndex: querydoctype,
            span: 3,
          },
          {
            icon: TeamLogo,
            name: "purgId",
            placeholder: "Purchasing Group",
            dataItems: purchasingGroup,
            itemIndex: querypurgeid,
            span: 3,
          },
          {
            icon: CompanyLogo,
            name: "compId",
            placeholder: "Company",
            dataItems: company,
            itemIndex: querycompid,
            span: 3,
          },
        ]}
        checkBoxList={[]}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <DataTable
        data={dataSource}
        checkBox
        loading={loading}
        columns={columns}
        totalItem={totalItem}
        searchText={searchText}
        setSearchText={setSearchText}
        variablePage={variablePage}
        setVariablePage={setVariablePage}
        setOnTableSelect={setOnTableSelect}
      />
      {visibleDrawer ? (
        <CustomDrawer
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          title={titleDrawer}
          content={contentDrawer}
          footer={footerDrawer}
        />
      ) : null}
      {visibleApproveModal ? (
        <CustomModal
          key="ApproveModal"
          visible={visibleApproveModal}
          setVisible={setVisibleApproveModal}
          title="Confirm to Approve"
          content={contentApproveModal}
          footer={footerApproveModal}
        />
      ) : null}
      {visibleRejectModal ? (
        <CustomModal
          key="RejectModal"
          visible={visibleRejectModal}
          setVisible={setVisibleRejectModal}
          title="Confirm to Reject"
          content={contentRejectModal}
          footer={footerRejectModal}
          onCancel={handleCancelRejectModal}
        />
      ) : null}
      {visibleMutiModal ? (
        <CustomModal
          key="MultiModal"
          visible={visibleMutiModal}
          setVisible={setVisibleMutiModal}
          title={`Confirm to ${checkOnMultiModal}`}
          content={contentMutiModal}
          footer={footerMutiModal}
        />
      ) : null}
    </div>
  );
};

export default Component;
