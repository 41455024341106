/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Typography,
  Spin,
  Row,
  Tabs,
  Input,
  Form,
  Checkbox,
  Col,
  InputNumber,
  Divider,
  message,
} from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import qs from "qs";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import moment from "moment";
import TitleHeader from "../../components/TitleHeader";
import FilterInputTable from "../../components/FilterInputTable";
import { CustomDrawer } from "../../components/Drawer";
import { TabsHeader, TabsDetail } from "../../components/Drawer/TabsMemo";
import { TabsApprove, TabsAttached } from "../../components/Drawer/Tabs";
import { ReactComponent as Reject } from "../../assets/icon/reject.svg";
import { ReactComponent as TeamLogo } from "../../assets/icon/team.svg";
import { ReactComponent as CompanyLogo } from "../../assets/icon/company.svg";
import {
  getPaymentListMemoAdmin,
  sendReCallPaymentList,
  getPaymentListMemoHistory,
} from "../../api/paymentList";
import { sendToPayment } from "../../api/prmemo";
import { Company, PurchasingGroup } from "../../interfaces/master.interface";
import { getMasterCompany, getMasterPurchasingGroup } from "../../api/master";
import CustomModal from "../../components/CustomModal";
import useAuthentication from "../../hooks/useAuthentication";
import DataTable from "../../components/DataTable";
import formatData from "../../libs/formatData";
import { ReCallIcon } from "../../components/CustomIcon";
import {
  PaymentItemMemoForImmediately,
  PRMemoCheckAmount,
  PRMemoResponseLists,
} from "../../interfaces/prmemo.interface";
import { renderPRRequestListHistoryColumns } from "./columns";
import useParseQuery from "../../hooks/useParseQuery";
import { mapMemoDataSource } from "../../libs/array/mappingPaymentList";
import { getBooleanFromQuery, getQueryObject } from "../../libs/query";
import { docType } from "../../libs/antd/options/optionsValue";
import setting from "../../setting";

dayjs.extend(isBetween);

const Component = (): JSX.Element => {
  const auth = useAuthentication();
  const location = useLocation();
  const history = useHistory();

  const [initial, setInital] = useState(true);
  const [dataSource, setDataSource] = useState<PRMemoResponseLists[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [variablePage, setVariablePage] = useState<{
    page: number;
    perPage: number;
  }>({ page: 1, perPage: 10 });
  const [visibleSingleModal, setVisibleSingleModal] = useState<boolean>(false);
  const [visibleCreateIMModal, setVisibleCreateIMModal] =
    useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [visiblePVModal, setVisiblePVModal] = useState<boolean>(false);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [disableCreateButton, setDisableCreateButton] =
    useState<boolean>(false);
  const [selectRequestListPayment, setSelectRequestListPayment] =
    useState<PRMemoResponseLists>({} as PRMemoResponseLists);
  const [selectCheckAmountMemo, setSelectCheckAmountMemo] =
    useState<PRMemoCheckAmount>({} as PRMemoCheckAmount);
  const [dataSourcePurchasingGroup, setDataSourcePurchasingGroup] = useState<
    PurchasingGroup[]
  >([]);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);
  const [timeUpdate, setTimeUpdate] = useState<string>();
  const [searchText, setSearchText] = useState("");
  const [userId, setUserId] = useState("");
  const [filterInput, setFilterInput] = useState<any | undefined>();

  const [form] = Form.useForm<PRMemoCheckAmount>();

  const itemsArray = Form.useWatch(["items"], form);

  const {
    querypurgeid,
    querycompid,
    querydate,
    querydatetype,
    querysearch,
    queryactivity,
    querydoctype,
    replaceLocation,
  } = useParseQuery();

  const columns = renderPRRequestListHistoryColumns({
    searchText,
    userId,
    setVisibleDrawer,
    setVisiblePVModal,
    setVisibleSingleModal,
    setVisibleCreateIMModal,
    setSelectCheckAmountMemo,
    setSelectRequestListPayment,
    setLoading,
  });

  const requestPayload = useMemo(() => {
    return {
      apprType: "PR",
      activityState: null,
      compId: null,
      purgId: null,
      isImmediately: null,
      dueDate: null,
      documentDate: null,
      postingDate: null,
      waitApproveBy: "",
      page: 1,
      perPage: 10,
    };
  }, []);

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([
        getMasterPurchasingGroup(auth.userId),
        getMasterCompany(auth.userId),
      ])
        .then(([responsePurchasing, responseCompany]) => {
          setDataSourcePurchasingGroup(responsePurchasing.responseData);
          setDataSourceCompany(responseCompany.responseData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  useEffect(() => {
    const queryToObject = getQueryObject(filterInput, location.search);

    const pagination = variablePage;

    if (initial) {
      pagination.page = Number(queryToObject?.page || 1);
      pagination.perPage = Number(queryToObject?.perPage || 10);
    }

    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      setLoading(true);

      const filterValidated = {
        ...queryToObject,
        isNonPo: getBooleanFromQuery(queryToObject?.isNonPo as string),
        compId: Number(queryToObject?.compId),
        purgId: Number(queryToObject?.purgId),
      };

      Promise.all([
        getPaymentListMemoHistory({
          sendApproveBy: `${auth.userId}`,
          search: `${searchText}`,
          ...requestPayload,
          ...filterValidated,
          ...pagination,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(mapMemoDataSource(responsePaymentList));
          setTotalItem(responsePaymentList.total);
          setTimeUpdate(moment().format());
          setUserId(auth?.userId);
        })
        .finally(() => {
          replaceLocation({
            ...filterInput,
            ...pagination,
          });
          setInital(false);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, variablePage]);

  useEffect(() => {
    if (!initial) {
      replaceLocation({
        ...filterInput,
        page: 1,
        perPage: variablePage.perPage,
      });

      setVariablePage({
        page: 1,
        perPage: variablePage.perPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput]);

  useEffect(() => {
    if (visibleCreateIMModal) {
      // console.log(selectCheckAmountMemo);
      form.setFieldsValue(selectCheckAmountMemo);
    }
  }, [form, selectCheckAmountMemo, visibleCreateIMModal]);

  const getTotalUsedAmount = (
    items: PaymentItemMemoForImmediately[],
    isCalVat: boolean
  ) => {
    if (!items) return 0;

    const itemValue = items
      .map((item) => item.totalValue)
      .reduce((prev, cur) => prev + cur, 0)
      .toFixed(2);

    if (Number(itemValue) === 0) {
      setDisableCreateButton(true);
    } else {
      setDisableCreateButton(false);
    }
    // console.log(items);
    // console.log(isCalVat);
    if (isCalVat) {
      const totalValueExcVat = items
        .filter((item) => item.isSelected && !item.isCalculateTax)
        .map((item) => item.usedAmount)
        .reduce((prev, cur) => prev + cur, 0)
        .toFixed(2);

      const totalValueIncVat = items
        .filter((item) => item.isSelected && item.isCalculateTax)
        .map((item) => item.usedAmount)
        .reduce((prev, cur) => prev + (cur * 107) / 100, 0)
        .toFixed(2);

      // console.log(totalValueExcVat);
      // console.log(totalValueIncVat);
      return Number(totalValueExcVat) + Number(totalValueIncVat);
    }

    const totalValue = items
      .filter((item) => item.isSelected)
      .map((item) => item.usedAmount)
      .reduce((prev, cur) => prev + cur, 0)
      .toFixed(2);

    // console.log(totalValue);
    return Number(totalValue);
  };

  const onSyncDataSource = useCallback(() => {
    const queryToObject = getQueryObject(filterInput, location.search);
    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      const filterValidated = {
        ...queryToObject,
        isImmediately: getBooleanFromQuery(
          queryToObject?.isImmediately as string
        ),
        isCancelable: queryToObject?.isCancelable === "true",
        compId: Number(queryToObject?.compId),
        purgId: Number(queryToObject?.purgId),
        overDueUrgent: queryToObject?.overDueUrgent,
      };
      setLoading(true);
      Promise.all([
        getPaymentListMemoAdmin({
          createBy: `${auth.userId}`,
          search: `${searchText}`,
          apprType: "PR",
          ...variablePage,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(mapMemoDataSource(responsePaymentList));
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("Error : ", error);
        })
        .finally(() => {
          setLoading(false);
          setTimeUpdate(moment().format());
        });
    }
  }, [filterInput, location.search, auth, searchText, variablePage]);

  const onSendReCallPaymentList = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        sendReCallPaymentList({
          payId: payIds,
          sendApproveBy: auth?.userId.toString() || "",
        }),
      ]).then(() => {
        setDataSource(
          dataSource.filter((item) => !payIds.includes(item.payId))
        );
        setTotalItem(totalItem - 1);
        setVisibleDrawer(false);
      });
    },
    [auth, dataSource, totalItem]
  );

  const onSendToPaymentList = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        sendToPayment({
          payId: payIds,
          sendPaymentBy: auth?.userId.toString() || "",
        }),
      ]).then(() => {
        // setDataSource(
        //   dataSource.filter((item) => !payIds.includes(item.payId))
        // );
        // setTotalItem(totalItem - 1);
        // dataSource.map((item) => {
        //   if (item.payId === payIds[0]) {
        //     // eslint-disable-next-line no-param-reassign
        //     item.isSendPayment = true;
        //   }
        //   return item;
        // });
        // setDataSource(dataSource);
        window.location.reload();
        setVisibleDrawer(false);
      });
    },
    [auth]
  );

  const titleDrawer = useMemo(() => {
    return (
      <Row align="middle">
        <InfoCircleOutlined />
        <Typography.Text style={{ paddingLeft: 10 }}>
          Information
        </Typography.Text>
      </Row>
    );
  }, []);

  const contentDrawer = useMemo(() => {
    const prHeaderNote = selectRequestListPayment?.prHeaderNote;

    return (
      <>
        <Row>
          <Typography.Title
            level={3}
            style={{
              width: "100%",
              paddingLeft: 24,
              paddingRight: 24,
            }}
            className="new-line"
          >
            <div style={{ position: "relative" }}>{prHeaderNote}</div>
          </Typography.Title>
        </Row>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Headers" key="1">
            <TabsHeader data={selectRequestListPayment} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Details" key="2">
            <TabsDetail
              data={selectRequestListPayment}
              dataCompany={dataSourceCompany}
              dataPurGroup={dataSourcePurchasingGroup}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attacheds" key="3">
            <TabsAttached
              data={selectRequestListPayment?.paymentInfoAttach || []}
              disabled
              visibleDeleteButton={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Approval Status" key="4">
            <TabsApprove
              payId={selectRequestListPayment.payId}
              userInfo={selectRequestListPayment?.sendApproveBy}
              sendApproveDate={selectRequestListPayment?.sendApproveDate}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }, [dataSourceCompany, dataSourcePurchasingGroup, selectRequestListPayment]);

  const footerDrawer = useMemo(() => {
    return (
      <div className="drawer-footer">
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            Amount ({selectRequestListPayment.currency}):
          </Typography.Title>
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            <Typography.Text style={{ color: "#174291", paddingLeft: 5 }}>
              {formatData.toCurrency(selectRequestListPayment.amount)}
            </Typography.Text>
          </Typography.Title>
        </Row>
        <Row justify="start" align="middle">
          {selectRequestListPayment.approvers &&
            !selectRequestListPayment.approvers[0].isApprove &&
            !selectRequestListPayment.approvers[0].isreject && (
              <Button
                loading={loading}
                icon={<ReCallIcon />}
                style={{
                  display: "flex",
                  background: "#545454",
                  alignItems: "center",
                  padding: 20,
                  gap: 10,
                }}
                onClick={() => setVisibleSingleModal(true)}
              >
                <Typography.Text strong style={{ color: "white" }}>
                  Recall Request
                </Typography.Text>
              </Button>
            )}
        </Row>
      </div>
    );
  }, [loading, selectRequestListPayment]);

  const contentSingleModal = useMemo(() => {
    const prHeaderNote = selectRequestListPayment?.prHeaderNote;

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text className="new-line">
          Recall Request : {prHeaderNote}
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectRequestListPayment]);

  const footerSingleModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleSingleModal(false)}>
          Cancel
        </Button>
        <Button
          style={{
            background: "#545454",
          }}
          loading={loading}
          onClick={() => {
            setLoading(true);
            onSendReCallPaymentList([selectRequestListPayment.payId])?.finally(
              () => {
                setLoading(false);
                setVisibleSingleModal(false);
              }
            );
          }}
        >
          <Typography.Text style={{ color: "white" }}>Recall</Typography.Text>
        </Button>
      </Row>
    );
  }, [loading, onSendReCallPaymentList, selectRequestListPayment.payId]);

  const contentPVModal = useMemo(() => {
    const payNumber = selectRequestListPayment?.payNumber;

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text>Send To Payment: {payNumber}</Typography.Text>
      </Spin>
    );
  }, [loading, selectRequestListPayment]);

  const footerPVModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisiblePVModal(false)}>
          Cancel
        </Button>
        <Button
          style={{
            background: "#545454",
          }}
          loading={loading}
          onClick={() => {
            setLoading(true);
            onSendToPaymentList([selectRequestListPayment.payId])?.finally(
              () => {
                setLoading(false);
                setVisiblePVModal(false);
              }
            );
          }}
        >
          <Typography.Text style={{ color: "white" }}>
            Send To Payment
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [loading, onSendToPaymentList, selectRequestListPayment.payId]);

  const contentCreateIMModal = useMemo(() => {
    const availableAmount =
      selectCheckAmountMemo.usedAmount - getTotalUsedAmount(itemsArray, false);

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Form form={form} layout="vertical">
          <Typography.Text strong>HEADER</Typography.Text>
          <Form.Item name="payId" hidden />
          <Form.Item label="Header Text" name="prHeaderNote">
            <Input
              disabled
              placeholder="PR header Note"
              style={{ borderRadius: "8px" }}
            />
          </Form.Item>
          <Row>
            <Col>
              <Typography.Text strong>DETAILS</Typography.Text>
            </Col>
          </Row>
          <Col style={{ paddingBottom: "8px" }}>
            <Typography.Text>
              ยอดเงินคงเหลือที่สามารถใช้ได้ :{" "}
              {Number(availableAmount.toFixed(2))}
            </Typography.Text>
          </Col>
          <Form.List name="items">
            {(fields) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row
                    align="middle"
                    style={{ paddingBottom: "8px" }}
                    key={key}
                  >
                    <Col span={3}>
                      <Form.Item
                        {...restField}
                        name={[name, "isSelected"]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          disabled={
                            // availableAmount <= 0 &&
                            form.getFieldValue(["items", name, "totalValue"]) <=
                              0 &&
                            !form.getFieldValue(["items", name, "isSelected"])
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={20}>
                      <Row align="middle">
                        <Col
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography.Text strong>
                            {key + 1}.{" "}
                            {`${form.getFieldValue([
                              "items",
                              name,
                              "paymentItem",
                            ])} ${form.getFieldValue([
                              "items",
                              name,
                              "paymentText",
                            ])}`}
                          </Typography.Text>
                          <Typography.Text>
                            Amount ที่สามารถใช้ได้:{" "}
                            {form.getFieldValue(["items", name, "totalValue"])}
                            {form.getFieldValue([
                              "items",
                              name,
                              "isCalculateTax",
                            ])
                              ? ` (Calc Tax: Yes) `
                              : ` (Calc Tax: No) `}
                            <Row>
                              <Form.Item
                                {...restField}
                                label="กรุณาระบุ Amount ที่ต้องการ"
                                name={[name, "usedAmount"]}
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        !value &&
                                        getFieldValue([
                                          "items",
                                          name,
                                          "isSelected",
                                        ])
                                      ) {
                                        return Promise.reject(
                                          new Error("Amount is required")
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  max={form.getFieldValue([
                                    "items",
                                    name,
                                    "totalValue",
                                  ])}
                                  style={{ borderRadius: "8px" }}
                                  disabled={
                                    !form.getFieldValue([
                                      "items",
                                      name,
                                      "isSelected",
                                    ])
                                  }
                                />
                              </Form.Item>
                            </Row>
                          </Typography.Text>
                          <Row>
                            <Typography.Text>
                              เอกสารจ่าย:{" "}
                              {`${form.getFieldValue([
                                "items",
                                name,
                                "paymentDocuments",
                              ])}`}
                            </Typography.Text>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Divider />
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </Spin>
    );
  }, [form, itemsArray, loading, selectCheckAmountMemo.usedAmount]);

  const footerCreateIMModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button
          loading={loading}
          onClick={() => setVisibleCreateIMModal(false)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          disabled={disableCreateButton}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                const selectedItems = values.items.filter(
                  (item) => item.isSelected
                );
                console.log("selectedItems");
                console.log(selectedItems);
                if (selectedItems.length > 0) {
                  const totalValue = getTotalUsedAmount(selectedItems, true);
                  const queryString = qs.stringify({
                    payId: values.payId,
                    apprType: "PR",
                    amount: totalValue,
                    items: values.items
                      .filter((item) => item.isSelected)
                      .map((val) => ({
                        payItemId: val.payItemId,
                        payId: val.payId,
                        usedAmount: val.usedAmount,
                      })),
                  });
                  history.push({
                    pathname: "/requester/immediately/memo",
                    search: `${queryString}`,
                  });
                } else {
                  message.error(
                    "กรุณาเลือกรายการที่ต้องการ เพื่อทำเอกสารจ่าย",
                    10
                  );
                }
              })
              .catch(() => {});
          }}
        >
          Create Immediately
        </Button>
      </Row>
    );
  }, [form, history, loading, disableCreateButton]);

  const purchasingGroup = useMemo(() => {
    const data = dataSourcePurchasingGroup?.map((item) => {
      return {
        label: `${item.purGroupId} ${item.purGroupName}`,
        value: item.purgId,
      };
    });

    return [
      { label: "All Purchasing Group", value: null, name: "ALL" },
      ...data,
    ];
  }, [dataSourcePurchasingGroup]);

  const company = useMemo(() => {
    const data = dataSourceCompany?.map((item) => {
      return {
        label: `${item.companyId} ${item.abbr}`,
        value: item.compId,
      };
    });

    return [{ label: "All Company", value: null, name: "ALL" }, ...data];
  }, [dataSourceCompany]);

  const activityState = [
    {
      label: "All Approval Status",
      value: null,
      name: "ALL",
    },
    {
      label: "approved",
      value: "approved",
      name: "approved",
    },
    {
      label: "pending",
      value: "pending",
      name: "pending",
    },
    {
      label: "rejected",
      value: "rejected",
      name: "rejected",
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <TitleHeader
        title="History"
        loading={loading}
        dataSizes={totalItem}
        timeUpdate={timeUpdate}
        onSyncDataSource={onSyncDataSource}
      />
      <FilterInputTable
        isMemo
        setFilterInput={setFilterInput}
        querysearch={querysearch}
        queryDateType={querydatetype}
        queryDate={querydate}
        selectItems={[
          {
            icon: CompanyLogo,
            name: "isNonPo",
            placeholder: "Document Type",
            dataItems: docType,
            itemIndex: querydoctype,
            span: 3,
          },
          {
            icon: TeamLogo,
            name: "purgId",
            placeholder: "Purchasing Group",
            dataItems: purchasingGroup,
            itemIndex: querypurgeid,
            span: 3,
          },
          {
            icon: CompanyLogo,
            name: "compId",
            placeholder: "Company",
            dataItems: company,
            itemIndex: querycompid,
            span: 3,
          },
          {
            icon: Reject,
            name: "activityState",
            placeholder: "Rejected",
            dataItems: activityState,
            itemIndex: queryactivity,
            span: 3,
          },
        ]}
        searchText={searchText}
        setSearchText={setSearchText}
        setModalVisible={setVisibleSingleModal}
      />
      <DataTable
        data={dataSource}
        checkBox={false}
        loading={loading}
        columns={columns}
        totalItem={totalItem}
        searchText={searchText}
        setSearchText={setSearchText}
        variablePage={variablePage}
        setVariablePage={setVariablePage}
      />
      {visibleDrawer ? (
        <CustomDrawer
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          title={titleDrawer}
          content={contentDrawer}
          footer={footerDrawer}
        />
      ) : null}
      {visibleSingleModal ? (
        <CustomModal
          key="SingleModal"
          visible={visibleSingleModal}
          setVisible={setVisibleSingleModal}
          title="Recall Request"
          content={contentSingleModal}
          footer={footerSingleModal}
        />
      ) : null}
      {visiblePVModal ? (
        <CustomModal
          key="PVModal"
          visible={visiblePVModal}
          setVisible={setVisiblePVModal}
          title="Send to Payment Request"
          content={contentPVModal}
          footer={footerPVModal}
        />
      ) : null}
      {visibleCreateIMModal ? (
        <CustomModal
          key="IMModal"
          visible={visibleCreateIMModal}
          setVisible={setVisibleCreateIMModal}
          title="Create Immediately"
          content={contentCreateIMModal}
          footer={footerCreateIMModal}
        />
      ) : null}
    </div>
  );
};

export default Component;
