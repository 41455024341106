import {
  PaperClipOutlined,
  InfoCircleOutlined,
  SendOutlined,
  EditOutlined,
  RollbackOutlined,
  DeleteOutlined,
  CheckOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Tooltip, Space, Typography, Row, Button, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
// import moment from "moment";
import Highlighter from "react-highlight-words";
import { Exclamation } from "../../components/CustomIcon";
import { getPaymentListMemoPreview } from "../../api/prmemo";
import { PRMemoResponseLists } from "../../interfaces/prmemo.interface";
import formatData from "../../libs/formatData";

interface ColumnsProps {
  searchText: string;
  onClickEdit: (record: PRMemoResponseLists) => void;
  setVisibleDrawer: (value: boolean) => void;
  setVisibleSingleModal: (value: boolean) => void;
  setVisibleDeleteModal: (value: boolean) => void;
  setSelectRequestListPayment: (record: PRMemoResponseLists) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDeleteFileApi: (value: any[]) => void;
  setLoading: (value: boolean) => void;
}

export const renderPRRequestListColumns = ({
  searchText,
  onClickEdit,
  setVisibleDrawer,
  setVisibleSingleModal,
  setVisibleDeleteModal,
  setSelectRequestListPayment,
  setDeleteFileApi,
  setLoading,
}: ColumnsProps): ColumnsType<PRMemoResponseLists> => {
  return [
    {
      title: "Status",
      width: 55,
      render: (record) => (
        <div style={{ display: "flex" }}>
          {record.isUrgent && (
            <Tooltip title="Urgent">
              <Exclamation />
            </Tooltip>
          )}
          {/* {moment().format("YYYY-MM-DD") > record.dueDate && (
            <Tooltip title="Overdue">
              <Thunder style={{ marginRight: "5px" }} />
            </Tooltip>
          )} */}
        </div>
      ),
    },
    {
      title: "Request No./PR Number",
      dataIndex: "payNumber",
      width: 100,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Memo No.",
      dataIndex: "memoNo",
      width: 110,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "prHeaderNote",
      title: "PR Header Note",
      dataIndex: "prHeaderNote",
      width: 200,
      render: (value: string, record) => (
        <>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchText]}
            // autoEscape={true}
            textToHighlight={value}
            className="new-line"
          />
          {record.isNonPo || (
            <Tag
              color="geekblue"
              style={{ marginLeft: "8px", borderRadius: "8px" }}
            >
              PO <CheckOutlined />
            </Tag>
          )}
        </>
      ),
    },
    {
      key: "company",
      title: "Company",
      dataIndex: "company",
      width: 80,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "purchasingGroup",
      title: "Purchasing Group",
      // dataIndex: "purGroup",
      width: 110,
      // render: (value: string) => (
      //   <Highlighter
      //     highlightClassName="highlight"
      //     searchWords={[searchText]}
      //     // autoEscape={true}
      //     textToHighlight={value}
      //   />
      // ),
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {record.purGroup} {record.purGroupName}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "item",
      title: "Items",
      width: 50,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {record.paymentInfoItem?.length || 0}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "amount",
      title: "Amount",
      width: 100,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {`
            ${formatData.toCurrency(record.amount)} ${record.currency}
            `}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "files",
      title: "Attachment",
      width: 75,
      render: (record) => (
        <Space size="small">
          <PaperClipOutlined rotate={135} />
          <Typography.Text>
            {record.fileSapnum + record.fileApprNum}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "modifiedDate",
      title: "Modified Date Time",
      width: 100,
      render: (record) => (
        <Typography.Text>
          {dayjs(record.updateDate).format("DD.MM.YYYY, hh:mm")}
        </Typography.Text>
      ),
    },
    {
      key: "action",
      title: "Actions",
      fixed: "right",
      width: 155,
      render: (record: PRMemoResponseLists) => (
        <Row>
          <Tooltip placement="top" title="Detail">
            <Button
              disabled={record.isDelete}
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={() => {
                setVisibleDrawer(true);
                setSelectRequestListPayment(record);
                setDeleteFileApi([]);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Preview Document">
            <Button
              type="text"
              icon={<FileSearchOutlined />}
              onClick={() => {
                setLoading(true);
                setSelectRequestListPayment(record);
                getPaymentListMemoPreview({
                  payId: record.payId,
                  sendApproveBy: "",
                  documentType: "PRWM",
                })
                  .then((result) => {
                    const file = new Blob([result], {
                      type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                  })
                  // eslint-disable-next-line no-console
                  .catch((error) => console.log("can't open file:", error))
                  .finally(() => setLoading(false));
              }}
            />
          </Tooltip>
          <Tooltip
            placement="top"
            title={record.isNonPo ? "Release to Approve" : "Send to Admin"}
          >
            <Button
              disabled={record.isDelete}
              type="text"
              icon={
                <SendOutlined
                  style={record.isDelete ? {} : { color: "#174291" }}
                />
              }
              onClick={() => {
                setVisibleSingleModal(true);
                setSelectRequestListPayment(record);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Edit">
            <Button
              disabled={record.isDelete}
              type="text"
              icon={<EditOutlined />}
              onClick={() => onClickEdit(record)}
            />
          </Tooltip>
          <Tooltip
            placement="top"
            title={record.isDelete ? "Undelete Request" : "Delete Request"}
          >
            <Button
              type="text"
              icon={
                record.isDelete ? (
                  <RollbackOutlined style={{ color: "#174291" }} />
                ) : (
                  <DeleteOutlined style={{ color: "#FF0000" }} />
                )
              }
              onClick={() => {
                setVisibleDeleteModal(true);
                setSelectRequestListPayment(record);
              }}
            />
          </Tooltip>
        </Row>
      ),
    },
  ];
};
