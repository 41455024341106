import { AxiosError } from "axios";
import { message } from "antd";
import axios from "../libs/axios";
import type { Response } from "../interfaces/response.interface";
import { RequestImmediately } from "../interfaces/immediately.interface";

export const submitImmediately = (
  option: RequestImmediately
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("approval/paymentlist/immediately", option)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          // console.log(error.response?.data);
          message.error(`${error.response?.data.error} `, 10);
          // resolve(error.response?.data.error);
          // message.error(`Please full fill all form`, 3);
          // <Alert
          //   message="Error"
          //   description="This is an error message about copywriting."
          //   type="error"
          //   showIcon
          // />
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
          // message.error(`Error`, 3);
        }
        reject(error);
      });
  });
