import { FC, useState, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { Table } from "antd";
import type { ColumnsType } from "antd/lib/table";
import type { Key, TableRowSelection } from "antd/lib/table/interface";
// import { RequestListPayment } from "../../interfaces/payment.interface";
import { RequestList } from "../../interfaces/paymentList.interface";
import Footer from "./Footer";
import "./index.css";
import { getQueryObject, queryStringify } from "../../libs/query";

interface Props {
  data: RequestList[];
  columns: ColumnsType<RequestList>;
  rowKey: string;
  loading: boolean;
  isRowSelection?: boolean;
  onSelectRowTable?: (record: Key[]) => void;
}

const Component: FC<Props> = ({
  data,
  columns,
  rowKey,
  loading,
  isRowSelection,
  onSelectRowTable,
}: Props) => {
  const location = useLocation();
  const history = useHistory();

  const [isInitial, setIsInitial] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const queryToObject = getQueryObject(false, location.search);

  useEffect(() => {
    if (!isInitial) return;

    setPage(Number(queryToObject.page) || 1);
    setPageSize(Number(queryToObject.pageSize) || 10);
    setIsInitial(false);
  }, [data, isInitial, queryToObject.page, queryToObject.pageSize]);

  const onChangePage = (current: number, currentPageSize: number) => {
    setPage(current);
    setPageSize(currentPageSize);

    const searchParam = {
      ...queryToObject,
      page: current,
      pageSize: currentPageSize,
    };

    history.replace({
      ...location,
      search: queryStringify(searchParam),
    });
  };

  const dataSource: RequestList[] = useMemo(() => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize - 1;

    return data.filter(
      (_item, index) => index >= startIndex && index <= endIndex
    );
  }, [data, page, pageSize]);

  const rowSelection: TableRowSelection<RequestList> | undefined =
    useMemo(() => {
      if (!isRowSelection) {
        return undefined;
      }

      return {
        type: "checkbox",
        onChange: (record: Key[]) => {
          if (typeof onSelectRowTable === "function") {
            onSelectRowTable(record);
          }
        },
        getCheckboxProps: (record: RequestList) => ({
          disabled:
            record.paymentDesc === "" ||
            record.paymentDesc === null ||
            record.isDelete,
        }),
      };
    }, [isRowSelection, onSelectRowTable]);

  return (
    <Table
      className="table-striped-rows"
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      rowKey={rowKey}
      rowSelection={rowSelection}
      pagination={false}
      bordered
      scroll={{ x: 1700, y: 800 }}
      style={{ overflowX: "scroll", backgroundColor: "white" }}
      footer={() => (
        <Footer
          pageSize={pageSize}
          pageSizeOption={[10, 20, 50, 100]}
          total={data.length}
          current={page}
          setPage={setPage}
          onChange={(current, currentPageSize) => {
            onChangePage(current, currentPageSize);
          }}
        />
      )}
    />
  );
};

Component.defaultProps = {
  isRowSelection: false,
  onSelectRowTable: () => {},
};

export default Component;
