/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { PRMemoResponseLists } from "../../../interfaces/prmemo.interface";
import { Company, PurchasingGroup } from "../../../interfaces/master.interface";
import DetailItem from "./DetailItem";

type Props = {
  data?: any;
  dataCompany?: Company[];
  dataPurGroup?: PurchasingGroup[];
};

const Component: FC<Props> = ({ data, dataCompany, dataPurGroup }: Props) => {
  return (
    <>
      {(data?.paymentInfoItem || []).map((item: any, index: number) => (
        <>
          <DetailItem
            item={item}
            data={data}
            index={index}
            dataCompany={dataCompany}
            dataPurGroup={dataPurGroup}
          />
        </>
      ))}
    </>
  );
};

Component.defaultProps = {
  data: {} as PRMemoResponseLists,
  dataCompany: [] as Company[],
  dataPurGroup: [] as PurchasingGroup[],
};

export default Component;
