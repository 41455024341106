/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Typography,
  Form,
  Spin,
  Row,
  Tabs,
  Input,
  Col,
  Select,
} from "antd";
import {
  CloseOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import moment from "moment";
import { RequestList } from "../../interfaces/paymentList.interface";
import TitleHeader from "../../components/TitleHeader";
import FilterInputTable from "../../components/FilterInputTable";
import { CustomDrawer } from "../../components/Drawer";
import { TabsHeader, TabsDetail } from "../../components/Drawer/TabsMemo";
import { TabsApprove, TabsAttached } from "../../components/Drawer/Tabs";
import { ReactComponent as Close } from "../../assets/icon/close.svg";
import { ReactComponent as Check } from "../../assets/icon/check.svg";
import { ReactComponent as ConfirmBadge } from "../../assets/icon/confirmBadge.svg";
import { ReactComponent as RejectBadge } from "../../assets/icon/rejectBadge.svg";
import { ReactComponent as ReversedBadge } from "../../assets/icon/reversedBadge.svg";
import { ReactComponent as Redo } from "../../assets/icon/redo.svg";
import { ReactComponent as TeamLogo } from "../../assets/icon/team.svg";
import { ReactComponent as CompanyLogo } from "../../assets/icon/company.svg";
import { sendApprove } from "../../api/approval";
import { getPaymentHistoryApprove } from "../../api/paymentList";
import { Company, PurchasingGroup } from "../../interfaces/master.interface";
import { getMasterCompany, getMasterPurchasingGroup } from "../../api/master";
import CustomModal from "../../components/CustomModal";
import useAuthentication from "../../hooks/useAuthentication";
import DataTable from "../../components/DataTable";
import formatData from "../../libs/formatData";
import TagIcon from "../../components/TagIcon";
import { CancelByApproval } from "../../libs/cancelRequest";
import { modalConfirm, modalRejected } from "../../api/modalConfirm";
import { RejectReason } from "../../enum/rejectReason.enum";
import useParseQuery from "../../hooks/useParseQuery";
import { renderApproveListHistoryColumns } from "./columns";
import { getBooleanFromQuery, getQueryObject } from "../../libs/query";
import useOptions from "../../hooks/useOptions";
import { mapDataSource } from "../../libs/array/mappingPaymentList";
import { docType } from "../../libs/antd/options/optionsValue";
import setting from "../../setting";

const { TextArea } = Input;

dayjs.extend(isBetween);

const Component = (): JSX.Element => {
  const auth = useAuthentication();

  const location = useLocation();

  const [initial, setInital] = useState(true);
  const [dataSource, setDataSource] = useState<RequestList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [variablePage, setVariablePage] = useState<{
    page: number;
    perPage: number;
  }>({ page: 1, perPage: 10 });
  const [visibleSingleModal, setVisibleSingleModal] = useState<boolean>(false);
  const [visibleRejectModal, setVisibleRejectModal] = useState<boolean>(false);
  const [visibleMutiModal, setVisibleMutiModal] = useState<boolean>(false);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [selectRequestListPayment, setSelectRequestListPayment] =
    useState<RequestList>({} as RequestList);
  const [dataSourcePurchasingGroup, setDataSourcePurchasingGroup] = useState<
    PurchasingGroup[]
  >([]);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);

  const [timeUpdate, setTimeUpdate] = useState<string>();
  const [searchText, setSearchText] = useState("");
  const [filterInput, setFilterInput] = useState<any | undefined>();
  const [onTableSelect, setOnTableSelect] = useState<React.Key[]>([]);
  const [saveFileList, setSaveFileList] = useState<any[]>([]);
  const [disableRejectModal, setDisableRejectModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [reasonValue, setReasonValue] = useState<string>("");

  const {
    querypurgeid,
    querycompid,
    querydate,
    querydatetype,
    querysearch,
    querydoctype,
    replaceLocation,
  } = useParseQuery();

  const { company, purchasingGroup } = useOptions({
    dataSourceCompany,
    dataSourcePurchasingGroup,
  });

  const columns: ColumnsType<RequestList> = renderApproveListHistoryColumns({
    auth,
    searchText,
    setVisibleDrawer,
    setVisibleRejectModal,
    setSelectRequestListPayment,
  });

  const requestPayload = useMemo(() => {
    return {
      activityState: null,
      compId: null,
      purgId: null,
      isImmediately: null,
      isOverdue: false,
      isUrgent: false,
      isCancelable: false,
      dueDate: null,
      documentDate: null,
      postingDate: null,
      apprType: "PR",
      page: 1,
      perPage: 10,
    };
  }, []);

  const onChangeRejectReason = useCallback(
    (value) => {
      setReasonValue(value);
      if (value === RejectReason.OTHER) {
        form.setFieldsValue({ reason: undefined });
        setDisableRejectModal(false);
      } else {
        form.setFieldsValue({ reason: value });
        setDisableRejectModal(true);
      }
    },
    [form]
  );

  const handleCancelRejectModal = () => {
    form.setFieldsValue({ reason: undefined });
    setReasonValue("");
    setDisableRejectModal(false);
    setVisibleRejectModal(false);
  };

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([
        getMasterPurchasingGroup(auth.userId),
        getMasterCompany(auth.userId),
      ])
        .then(([responsePurchasing, responseCompany]) => {
          setDataSourcePurchasingGroup(responsePurchasing.responseData);
          setDataSourceCompany(responseCompany.responseData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  useEffect(() => {
    const queryToObject = getQueryObject(filterInput, location.search);

    const pagination = variablePage;

    if (initial) {
      pagination.page = Number(queryToObject?.page || 1);
      pagination.perPage = Number(queryToObject?.perPage || 10);
    }

    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      setLoading(true);

      const filterValidated = {
        ...queryToObject,
        isNonPo: getBooleanFromQuery(queryToObject?.isNonPo as string),
        compId: Number(queryToObject?.compId),
        purgId: Number(queryToObject?.purgId),
      };

      Promise.all([
        getPaymentHistoryApprove({
          sendApproveBy: "",
          waitApproveBy: `${auth.userId}`,
          search: `${searchText}`,
          ...requestPayload,
          ...filterValidated,
          ...pagination,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(mapDataSource(responsePaymentList));
          setTotalItem(responsePaymentList.total);
          setTimeUpdate(moment().format());
        })
        .finally(() => {
          replaceLocation({
            ...filterInput,
            ...pagination,
          });
          setInital(false);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, variablePage]);

  useEffect(() => {
    if (!initial) {
      replaceLocation({
        ...filterInput,
        page: 1,
        perPage: variablePage.perPage,
      });

      setVariablePage({
        page: 1,
        perPage: variablePage.perPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput]);

  const checkSubmit =
    selectRequestListPayment !== undefined
      ? CancelByApproval(
          selectRequestListPayment.approvers as any,
          selectRequestListPayment.pvDocNo as string
        )
      : false;

  const onSyncDataSource = useCallback(() => {
    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      setLoading(true);
      Promise.all([
        getPaymentHistoryApprove({
          sendApproveBy: "",
          waitApproveBy: `${auth.userId}`,
          search: `${searchText}`,
          ...requestPayload,
          ...variablePage,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(mapDataSource(responsePaymentList));
          setTotalItem(responsePaymentList.total);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("Error : ", error);
        })
        .finally(() => {
          setLoading(false);
          setTimeUpdate(moment().format());
        });
    }
  }, [auth, requestPayload, variablePage, searchText]);

  const onPaymentSendApprove = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        sendApprove({
          payId: payIds,
          sendApproveBy: auth?.userId.toString() || "",
        }),
      ]).then(() => {
        setDataSource(
          dataSource.filter((item) => !payIds.includes(item.payId))
        );
        setVisibleDrawer(false);
      });
    },
    [auth, dataSource]
  );

  const titleDrawer = useMemo(() => {
    return (
      <Row align="middle">
        <InfoCircleOutlined />
        <Typography.Text style={{ paddingLeft: 10 }}>
          Information
        </Typography.Text>
      </Row>
    );
  }, []);

  const badgeNote = useMemo(() => {
    const styles: React.CSSProperties = {
      position: "absolute",
      top: 0,
      right: 0,
    };
    if (selectRequestListPayment.activityState === "approved") {
      return <ConfirmBadge style={styles} />;
    } else if (selectRequestListPayment.activityState === "rejected") {
      if (selectRequestListPayment.reverseDoc !== "") {
        return <ReversedBadge style={styles} />;
      }
      return <RejectBadge style={styles} />;
    }
    return null;
  }, [
    selectRequestListPayment.activityState,
    selectRequestListPayment.reverseDoc,
  ]);

  const tagIcon = useMemo(() => {
    return (
      <Row
        style={{
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        <Col span="auto">
          {selectRequestListPayment.approverStatus === "APPROVED" && (
            <TagIcon
              textIcon="#01713C"
              color="#E5F2ED"
              text="APPROVED"
              icon={<Check />}
              border="#66D19E"
            />
          )}
          {selectRequestListPayment.approverStatus === "REJECTED" && (
            <TagIcon
              textIcon="#D82E18"
              color="#FFEFED"
              text="REJECTED"
              icon={<Close />}
              border="#F1998E"
            />
          )}
        </Col>
      </Row>
    );
  }, [selectRequestListPayment.approverStatus]);

  const contentDrawer = useMemo(() => {
    const prHeaderNote = selectRequestListPayment?.prHeaderNote;

    return (
      <>
        <Row>
          <Typography.Title
            level={3}
            style={{
              width: "100%",
              paddingLeft: 24,
              paddingRight: 24,
            }}
            className="new-line"
          >
            <div style={{ position: "relative" }}>
              {prHeaderNote}
              {badgeNote}
            </div>
          </Typography.Title>
        </Row>

        {tagIcon}

        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Headers" key="1">
            <TabsHeader data={selectRequestListPayment} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Details" key="2">
            <TabsDetail
              data={selectRequestListPayment}
              dataCompany={dataSourceCompany}
              dataPurGroup={dataSourcePurchasingGroup}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attacheds" key="3">
            <TabsAttached
              data={selectRequestListPayment?.paymentInfoAttach || []}
              disabled
              setSaveFileList={setSaveFileList}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Approval Status" key="4">
            <TabsApprove
              payId={selectRequestListPayment.payId}
              userInfo={selectRequestListPayment?.sendApproveBy}
              sendApproveDate={selectRequestListPayment?.sendApproveDate}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }, [
    dataSourceCompany,
    dataSourcePurchasingGroup,
    selectRequestListPayment,
    badgeNote,
    tagIcon,
  ]);

  const contentRejectModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text className="new-line" style={{ marginBottom: "20px" }}>
          Are you sure to cancel {selectRequestListPayment.prHeaderNote}{" "}
          {formatData.toCurrency(selectRequestListPayment.amount)}
          {selectRequestListPayment.currency}?
        </Typography.Text>
        <Form layout="vertical" form={form}>
          <Form.Item name="reason" label="Reason" rules={[{ required: true }]}>
            <Select
              onChange={(e) => onChangeRejectReason(e)}
              placeholder="- Select -"
            >
              <Select.Option value={RejectReason.INVALID_TEXT}>
                ระบุข้อความไม่ถูกต้อง / ข้อความไม่ครบถ้วน
              </Select.Option>
              <Select.Option value={RejectReason.INVALID_AMOUNT}>
                ระบุจำนวนเงินไม่ถูกต้อง
              </Select.Option>
              <Select.Option value={RejectReason.INVALID_FILE}>
                แนบเอกสารไฟล์ไม่ถูกต้อง
              </Select.Option>
              <Select.Option value={RejectReason.CANCEL_TRANSACTION}>
                ยกเลิกการชำระเงิน
              </Select.Option>
              <Select.Option value={RejectReason.OTHER}>
                อื่น ๆ (โปรดระบุ)
              </Select.Option>
            </Select>
            {reasonValue === RejectReason.OTHER && (
              <TextArea
                showCount
                maxLength={200}
                onChange={(value) => {
                  setDisableRejectModal(!!value.target.value);
                  form.setFieldsValue({ reason: value.target.value });
                }}
                placeholder="Field is Required"
              />
            )}
          </Form.Item>
        </Form>
      </Spin>
    );
  }, [
    form,
    loading,
    onChangeRejectReason,
    reasonValue,
    selectRequestListPayment,
  ]);

  const footerRejectModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CloseOutlined />}
          danger
          block
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          disabled={!disableRejectModal}
          onClick={() => {
            setLoading(true);
            if (selectRequestListPayment?.approverStatus === "APPROVED") {
              modalConfirm({
                payId: [selectRequestListPayment?.payId as number],
                userId: `${auth?.userId}`,
                reason: form.getFieldValue("reason"),
                isCancel: true,
              }).then(() => {
                setLoading(false);
                setVisibleDrawer(false);
                setVisibleRejectModal(false);
                onSyncDataSource();
              });
            } else {
              modalRejected({
                payId: [selectRequestListPayment?.payId as number],
                userId: `${auth?.userId}`,
                reason: form.getFieldValue("reason"),
                isCancel: true,
              }).then(() => {
                setLoading(false);
                setVisibleDrawer(false);
                setVisibleRejectModal(false);
                onSyncDataSource();
              });
            }
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            {selectRequestListPayment?.approverStatus === "APPROVED"
              ? "Cancel Approved"
              : "Cancel Rejected"}
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [
    auth?.userId,
    disableRejectModal,
    form,
    loading,
    onSyncDataSource,
    selectRequestListPayment,
  ]);

  const footerDrawer = useMemo(() => {
    return (
      <div className="drawer-footer">
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            Amount ({selectRequestListPayment.currency}):
          </Typography.Title>
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            <Typography.Text style={{ color: "#174291", paddingLeft: 5 }}>
              {formatData.toCurrency(selectRequestListPayment.amount)}
            </Typography.Text>
          </Typography.Title>
        </Row>
        {checkSubmit && (
          <Row justify="space-between" align="middle">
            <Button
              type="primary"
              icon={<Redo style={{ fontSize: "x-large" }} />}
              danger
              block
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
                gap: 10,
              }}
              onClick={() => {
                setVisibleRejectModal(true);
              }}
            >
              <Typography.Text strong style={{ color: "white" }}>
                Cancel{" "}
                {selectRequestListPayment?.approverStatus === "APPROVED"
                  ? "Approved"
                  : "Rejected"}
              </Typography.Text>
            </Button>
          </Row>
        )}
      </div>
    );
  }, [checkSubmit, selectRequestListPayment]);

  const contentSingleModal = useMemo(() => {
    const paymentDesc = selectRequestListPayment?.paymentDesc;
    const isUrgent = selectRequestListPayment?.isUrgent;

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <></>
      </Spin>
    );
  }, [loading, selectRequestListPayment]);

  const footerSingleModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleSingleModal(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);
            onPaymentSendApprove([selectRequestListPayment.payId])?.finally(
              () => {
                setLoading(false);
                setVisibleSingleModal(false);
              }
            );
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [loading, selectRequestListPayment, onPaymentSendApprove]);

  const contentMutiModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text>
          ยืนยันการส่งข้อมูลจำนวน {onTableSelect.length} ชุด
        </Typography.Text>
      </Spin>
    );
  }, [loading, onTableSelect]);

  const footerMutiModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleMutiModal(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);
            onPaymentSendApprove(
              onTableSelect.map((item) => Number(item))
            )?.finally(() => {
              setLoading(false);
              setVisibleMutiModal(false);
            });
          }}
        >
          Confirm
        </Button>
      </Row>
    );
  }, [loading, onTableSelect, onPaymentSendApprove]);

  return (
    <div style={{ padding: 20 }}>
      <TitleHeader
        title="History"
        loading={loading}
        dataSizes={totalItem}
        timeUpdate={timeUpdate}
        onSyncDataSource={onSyncDataSource}
      />
      <FilterInputTable
        isMemo
        onTableSelect={onTableSelect}
        setFilterInput={setFilterInput}
        querysearch={querysearch}
        queryDateType={querydatetype}
        queryDate={querydate}
        setMultiConfirm={setVisibleMutiModal}
        selectItems={[
          {
            icon: CompanyLogo,
            name: "isNonPo",
            placeholder: "Document Type",
            dataItems: docType,
            itemIndex: querydoctype,
            span: 3,
          },
          {
            icon: TeamLogo,
            name: "purgId",
            placeholder: "Purchasing Group",
            dataItems: purchasingGroup,
            itemIndex: querypurgeid,
            span: 3,
          },
          {
            icon: CompanyLogo,
            name: "compId",
            placeholder: "Company",
            dataItems: company,
            itemIndex: querycompid,
            span: 3,
          },
        ]}
        searchText={searchText}
        setSearchText={setSearchText}
        // setModalVisible={setVisibleSingleModal}
      />
      <DataTable
        data={dataSource}
        checkBox
        loading={loading}
        columns={columns}
        totalItem={totalItem}
        searchText={searchText}
        setSearchText={setSearchText}
        variablePage={variablePage}
        setVariablePage={setVariablePage}
      />
      {visibleDrawer ? (
        <CustomDrawer
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          title={titleDrawer}
          content={contentDrawer}
          footer={footerDrawer}
        />
      ) : null}
      {visibleSingleModal ? (
        <CustomModal
          key="SingleModal"
          visible={visibleSingleModal}
          setVisible={setVisibleSingleModal}
          title="Confirm to Release"
          content={contentSingleModal}
          footer={footerSingleModal}
        />
      ) : null}
      {visibleRejectModal ? (
        <CustomModal
          key="RejectModal"
          visible={visibleRejectModal}
          setVisible={setVisibleRejectModal}
          title={`Confirm to ${
            selectRequestListPayment?.approverStatus === "APPROVED"
              ? "Cancel"
              : "Cancel"
          }`}
          content={contentRejectModal}
          footer={footerRejectModal}
          onCancel={handleCancelRejectModal}
        />
      ) : null}
    </div>
  );
};

export default Component;
