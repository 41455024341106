/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PaperClipOutlined,
  InfoCircleOutlined,
  FileSearchOutlined,
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Tooltip, Space, Typography, Row, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import moment from "moment";
import Highlighter from "react-highlight-words";
import { getPaymentListPreview } from "../../api/history";
import { Exclamation, Thunder } from "../../components/CustomIcon";
import UserStatusRow from "../../components/UserStatusRow";
import { RequestList } from "../../interfaces/paymentList.interface";
import formatData from "../../libs/formatData";

interface ColumnsProps {
  searchText: string;
  setVisibleDrawer: (value: boolean) => void;
  setVisibleRejectModal: (value: boolean) => void;
  setVisibleApproveModal: (value: boolean) => void;
  setSelectRequestListPayment: (record: RequestList) => void;
  setLoading: (value: boolean) => void;
}

export const renderApproveListColumns = ({
  searchText,
  setVisibleDrawer,
  setVisibleRejectModal,
  setVisibleApproveModal,
  setSelectRequestListPayment,
  setLoading,
}: ColumnsProps): ColumnsType<RequestList> => {
  return [
    {
      title: "Status",
      width: 90,
      render: (record) => (
        <div style={{ display: "flex" }}>
          {record.isUrgent && (
            <Tooltip title="Urgent">
              <Exclamation />
            </Tooltip>
          )}
          {moment().format("YYYY-MM-DD") > record.dueDate && (
            <Tooltip title="Overdue">
              <Thunder style={{ marginRight: "5px" }} />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      width: 100,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          textToHighlight={dayjs(value).format("DD.MM.YYYY")}
        />
      ),
      sorter: (a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix(),
    },
    {
      title: "Pay Request No",
      dataIndex: "payNumber",
      width: 120,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 100,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Purchasing Group",
      dataIndex: "purGroup",
      width: 160,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Payment Description",
      dataIndex: "paymentDesc",
      width: 120,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          // textToHighlight={`${value.substring(0, 30)}...`}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Vendor",
      dataIndex: "accountText",
      // width: 200,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "amount",
      title: "Amount",
      width: 120,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {`
            ${formatData.toCurrency(record.amount)} ${record.currency}
            `}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "files",
      title: "Attachment",
      width: 100,
      render: (record) => (
        <Space size="small">
          <PaperClipOutlined rotate={135} />
          <Typography.Text>
            {record.paymentInfoAttachHistory?.length || 0}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: "Approval Status",
      dataIndex: "accountText",
      width: 230,
      render: (value: string, row) => {
        return (
          <UserStatusRow
            approvers={row.approvers as any}
            userInfo={row.sendApproveBy}
            isBanking={false}
          />
        );
      },
    },
    {
      key: "action",
      title: "Detail/Reject/Approve",
      fixed: "right",
      width: 170,
      render: (record: RequestList) => (
        <Row>
          <Tooltip placement="top" title="Detail">
            <Button
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={() => {
                setVisibleDrawer(true);
                setSelectRequestListPayment(record);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Preview Document">
            <Button
              type="text"
              icon={<FileSearchOutlined />}
              onClick={() => {
                setLoading(true);
                setSelectRequestListPayment(record);
                getPaymentListPreview({
                  payId: record.payId,
                  sendApproveBy: String(record.sendApproveBy.userId) || "",
                  documentType: record.isImmediately ? "PVIM" : "PVCR",
                })
                  .then((result) => {
                    const file = new Blob([result], {
                      type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                  })
                  // eslint-disable-next-line no-console
                  .catch((error) => console.log("can't open file:", error))
                  .finally(() => setLoading(false));
              }}
            />
          </Tooltip>
          {record.reverseDoc === "" && (
            <Tooltip placement="top" title="Reject">
              <Button
                type="text"
                icon={<CloseOutlined style={{ color: "#FF4D4E" }} />}
                onClick={() => {
                  setSelectRequestListPayment(record);
                  setVisibleRejectModal(true);
                }}
              />
            </Tooltip>
          )}
          {record.reverseDoc === "" && (
            <Tooltip placement="top" title="Approve">
              <Button
                type="text"
                icon={<CheckOutlined style={{ color: "#03703C" }} />}
                onClick={() => {
                  setSelectRequestListPayment(record);
                  setVisibleApproveModal(true);
                }}
              />
            </Tooltip>
          )}
          {record.reverseDoc !== "" && (
            <Tooltip placement="top" title="Reversed by SAP">
              <Button
                type="text"
                icon={<WarningOutlined style={{ color: "#FFA500" }} />}
                disabled
              />
            </Tooltip>
          )}
        </Row>
      ),
    },
  ];
};
