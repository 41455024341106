/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Row,
  Typography,
  Form,
  Spin,
  message,
  notification,
  Col,
  InputNumber,
  Modal,
} from "antd";
import cloneDeep from "lodash/cloneDeep";
import { RcFile } from "antd/lib/upload/interface";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import TitleHeader from "../../components/TitleHeader";
import TableMemoNonPODetail from "../../components/DetailsTableMemo";
import TableMemoWithPODetail from "../../components/DetailsTableMemoWithPO";
import {
  AccountAssignment,
  Asset,
  Company,
  CostCenter,
  Currency,
  InfoRecord,
  MaterialGroup,
  MaterialSet,
  PurchasingGroup,
  PurchasingOrg,
  StorageLoCode,
  Unit,
  Vendor,
} from "../../interfaces/master.interface";
import {
  getMasterAccountAssignment,
  getMasterAsset,
  getMasterCompany,
  getMasterCostCenter,
  getMasterCurrency,
  getMasterInfoRecord,
  getMasterMaterialGroup,
  getMasterMaterialSet,
  getMasterPurchasingGroup,
  getMasterPurchasingOrg,
  getMasterStorageLoCode,
  getMasterUnit,
  getMasterVendor,
} from "../../api/master";
import useAuthentication from "../../hooks/useAuthentication";
import {
  FileListUpload,
  ResponseAPIUploadFile,
} from "../../interfaces/uploadFile.interface";
import { checkLimitSize, getFileName } from "../../libs/file";
import MemoForm from "../../components/Form/MemoForm";
import {
  FormPRMemo,
  PaymentInfoItemMemo,
  PRMemoList,
} from "../../interfaces/prmemo.interface";
import { uploadFileList } from "../../api/service";
import {
  getMemoDetailForAdmin,
  saveMemoDetailForAdmin,
  sendToAdmin,
  submitMemoNonPO,
  submitMemoWithPO,
  sendAdminApprove,
} from "../../api/prmemo";
import { getPaymentListMemoPayId } from "../../api/paymentList";
import { sendApprove } from "../../api/approval";
import useOptions from "../../hooks/useOptions";
import { User } from "../../interfaces/user.interface";
import { getUserInfo } from "../../api/authentication";

interface UploadFileCustom {
  // eslint-disable-next-line react/require-default-props
  value?: File[];
  file: File;
  // eslint-disable-next-line react/require-default-props
  fileList: File[];
}

interface PrepareFileData {
  FileBase64: string;
  FileType: string;
  ExtensionFile: string;
}

const defaultCurrency = "THB";

const Component = (): JSX.Element => {
  const auth = useAuthentication();
  const location = useLocation();
  const history = useHistory();

  const [isInitial, setIsInitial] = useState<boolean>(false);
  const [dataSourceUser, setDataSourceUser] = useState<User[]>([]);
  const [vatValue, setVat] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [count, setCount] = useState(0);
  const [fileList, setFileList] = useState<
    Array<ResponseAPIUploadFile | FileListUpload>
  >([]);
  const [dataTable, setDataTable] = useState<PaymentInfoItemMemo[]>([]);
  const [dataSourcePurchasingGroup, setDataSourcePurchasingGroup] = useState<
    PurchasingGroup[]
  >([]);
  const [dataSourcePayByPurchasingGroup, setDataSourcePayByPurchasingGroup] =
    useState<PurchasingGroup[]>([]);
  const [dataSourcePurchasingOrg, setDataSourcePurchasingOrg] = useState<
    PurchasingOrg[]
  >([]);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);
  const [dataSourceMaterialGroup, setDataSourceMaterialGroup] = useState<
    MaterialGroup[]
  >([]);
  const [dataSourceMaterialSet, setDataSourceMaterialSet] = useState<
    MaterialSet[]
  >([]);
  const [dataSourceAccountAssign, setDataSourceAccountAssign] = useState<
    AccountAssignment[]
  >([]);
  const [dataSourceVendor, setDataSourceVendor] = useState<Vendor[]>([]);
  const [dataSourceAsset, setDataSourceAsset] = useState<Asset[]>([]);
  const [dataSourceCostCenter, setDataSourceCostCenter] = useState<
    CostCenter[]
  >([]);
  const [dataSourceInfoRecord, setDataSourceInfoRecord] = useState<
    InfoRecord[]
  >([]);
  const [dataSourceStorageLoc, setDataSourceStorageLoc] = useState<
    StorageLoCode[]
  >([]);
  const [dataSourceCurrency, setDataSourceCurrency] = useState<Currency[]>([]);
  const [dataSourceUnit, setDataSourceUnit] = useState<Unit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [isDisableVendorInput, setIsDisableVendorInput] =
    useState<boolean>(true);
  const [form] = Form.useForm<FormPRMemo>();
  const isCalculateTaxValue = Form.useWatch<boolean>("isCalculateTax", form);
  const selectedCompany = Form.useWatch<string>("compId", form);
  const selectedPurchase = Form.useWatch<string>("purgId", form);
  const currentPayBy = Form.useWatch<string>("payBy", form);
  const selectedPayPurchase = Form.useWatch<string>("payPurgId", form);
  const selectedAccount = Form.useWatch<string>("accoId", form);
  const selectedMaterial = Form.useWatch<string>("matgId", form);
  const selectedVendor = Form.useWatch<string>("accountNum", form);
  const amount = Form.useWatch<number>("amount", form);
  const currency = Form.useWatch<string>("currency", form);
  const exchangeRate = Form.useWatch<number>("exchangeRate", form);
  const isApprProject = Form.useWatch<string>("isApprProject", form);
  const createByEmail = Form.useWatch<string>("isApprProject", form);
  const [isDisabledITAdmin, setIsDisabledITAdmin] = useState<boolean>(false);
  const inputNames = ["memoProject"];
  const [itemsPrepareDetail, setItemsPrepareDetail] = useState<
    PaymentInfoItemMemo[]
  >([]);

  const {
    companies,
    purchasingGroups,
    payPurchasingGroup,
    accountAssign,
    materialSet,
    expenseType,
    vendorData,
    currencyData,
    purchasingOrg,
    units,
    assets,
    costCenter,
    infoRecord,
    storageLoc,
    userList,
  } = useOptions({
    dataSourceCompany,
    dataSourcePurchasingGroup,
    dataSourcePayByPurchasingGroup,
    dataSourceAccountAssign,
    dataSourceMaterialGroup,
    dataSourceMaterialSet,
    dataSourceVendor,
    dataSourceCurrency,
    dataSourcePurchasingOrg,
    dataSourceUnit,
    dataSourceAsset,
    dataSourceCostCenter,
    dataSourceInfoRecord,
    dataSourceStorageLoc,
    dataSourceUser,
  });

  const params: { payId: string } = useParams();

  const dataTableRef = useRef<HTMLDivElement>(null);

  const pathList = useMemo(() => {
    return location.pathname.replace("/", "").split("/");
  }, [location]);

  const isAdmin = useMemo(() => {
    return pathList[1] === "admin";
  }, []);

  const isNonPO = useMemo(() => {
    return pathList[3] === "nonpo";
  }, []);

  const getTitleName = () => {
    if (isAdmin) return "Edit PR Memo (Admin)";
    else if (isNonPO) return "Create PR Memo (Without PO)";
    return "Create PR Memo (With PO)";
  };

  const localCurrentAmount = useMemo(() => {
    if (!amount) {
      return undefined;
    }

    if (currency === "THB") {
      if (isCalculateTaxValue) {
        return amount;
      }

      return amount;
    }

    if (isCalculateTaxValue) {
      return amount * exchangeRate;
    }

    return amount * exchangeRate;
  }, [amount, currency, exchangeRate, isCalculateTaxValue]);

  const formatNumber = (value: number | undefined) => {
    if (isEmpty(value)) {
      return "";
    }
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 4,
    }).format(Number(value || null));
  };

  const getTotalAmount: number = useMemo(() => {
    return dataTable
      .filter((value: PaymentInfoItemMemo) => !value.isDelete)
      .reduce((acc, cur) => {
        const setNewAmount = cur.isCalculateTax
          ? (cur.totalValue * 100) / 100
          : cur.totalValue;
        return acc + setNewAmount;
      }, 0);
  }, [dataTable]);

  const getTotalTaxAmount: number = useMemo(() => {
    return dataTable
      .filter((value: PaymentInfoItemMemo) => !value.isDelete)
      .reduce((acc, cur) => {
        // const allTax = cur.isCalculateTax ? (cur.totalValue * 7) / 107 : 0;
        const allTax = cur.isCalculateTax
          ? Math.round(cur.totalValue * 7) / 100
          : 0;
        return acc + allTax;
      }, 0);
  }, [dataTable]);

  useEffect(() => {
    setVat(Number(getTotalTaxAmount.toFixed(2)));
  }, [getTotalTaxAmount]);

  const getMemoData = (id: string) => {
    return isAdmin ? getMemoDetailForAdmin(id) : getPaymentListMemoPayId(id);
  };

  useEffect(() => {
    setLoading(true);
    if (params.payId) {
      getMemoData(params.payId).then(async (responseDocument) => {
        const document = responseDocument.responseData[0];

        await Promise.all([
          getMasterPurchasingGroup(auth?.userId),
          getMasterCompany(auth?.userId),
          getMasterAccountAssignment(auth?.userId),
          getMasterMaterialGroup(auth?.userId),
          getMasterMaterialSet(Number(document.compId)),
          getMasterVendor(Number(document.compId)),
          getMasterPurchasingOrg(),
          getMasterUnit(),
          getMasterCurrency(),
          getUserInfo(),
        ])
          .then(
            ([
              purchasingGroupData,
              companyData,
              accountAssignment,
              responseMaterialGroup,
              responseMaterialSet,
              responseVendor,
              purchasingOrgData,
              responseUnit,
              currencyResponse,
              responseUserList,
            ]) => {
              setDataSourcePurchasingGroup(purchasingGroupData.responseData);
              setDataSourcePayByPurchasingGroup(
                purchasingGroupData.responseData
              );
              setDataSourceCompany(companyData.responseData);
              setDataSourceAccountAssign(accountAssignment.responseData);
              setDataSourceMaterialGroup(responseMaterialGroup.responseData);
              setDataSourceMaterialSet(responseMaterialSet.responseData);
              setDataSourceVendor(responseVendor.responseData);
              setDataSourceCurrency(currencyResponse.responseData);
              setDataSourcePurchasingOrg(purchasingOrgData.responseData);
              setDataSourceUnit(responseUnit.responseData);
              setDataSourceUser(responseUserList.responseData);

              const companyObject = companyData.responseData.find(
                (item) => item.compId === document.compId
              );
              const purchaseObject = purchasingGroupData.responseData.find(
                (item) => item.purgId === document.purgId
              );
              const accountObject = accountAssignment.responseData.find(
                (item) => item.accoId === document.accoId
              );
              const materialObject = responseMaterialGroup.responseData.find(
                (item) => item.matgId === document.matgId
              );
              const vendorObject = responseVendor.responseData.find(
                (item) => item.accountNum === document.accountNum
              );
              const payPurgObject = purchasingGroupData.responseData.find(
                (item) => item.purgId === document.payPurgId
              );

              const prepareDetail = document.paymentInfoItem;
              setItemsPrepareDetail(prepareDetail);

              const prepareFile = document.paymentInfoAttach?.map(
                (element: any) => {
                  const fileData: any = {
                    payAttcId: element.payAttcId,
                    filePath: element.filePath,
                    name: element.filename,
                    filename: element.filename,
                    fileExtension: element.fileExtension,
                    readOnly: element.readOnly,
                    isDelete: element.isDelete,
                  };
                  return fileData;
                }
              );

              setFileList(prepareFile || []);

              if (vendorObject?.accountNum.substring(0, 2) === "29") {
                setIsDisableVendorInput(false);
              }
              const firstSpace = document.accountText.indexOf(" ");
              const accountTextSplit = document.accountText.substring(
                firstSpace + 1,
                document.accountText.length
              );
              setVat(document.vat);
              setIsDisabledITAdmin(
                (auth?.userId || "") !== document.createBy.userId
              );
              if (document.budgetYear === null) {
                form.setFieldsValue({
                  ...document,
                  payId: parseInt(params.payId, 10),
                  docYear: document?.docYear ? document.docYear : "",
                  prNo: document.payNumber,
                  compId: document.compId.toString(),
                  disableCompany: companyObject?.companyName,
                  purgId: purchaseObject?.purgId.toString(),
                  disablePurchasing: purchaseObject?.purGroupName,
                  accoId: accountObject?.accoId.toString(),
                  disableAssignment: accountObject?.accAssName,
                  matgId: materialObject?.matgId.toString(),
                  disableMaterial: materialObject?.materialGroupName,
                  accountNum: vendorObject?.accountNum.toString(),
                  accountText: accountTextSplit,
                  payPurgId: payPurgObject?.purgId.toString(),
                  disablePayPurchasing: payPurgObject?.purGroupName,
                  disableVendor: vendorObject?.accountText,
                  emailInfo: document?.emailInfo
                    ? document?.emailInfo.split(";")
                    : undefined,
                  period: [
                    moment(document.periodFrom),
                    moment(document.periodTo),
                  ],
                  invoiceDate: moment(document.invoiceDate),
                  budgetYear: undefined,
                  documentDate: moment(document.documentDate),
                });
              } else {
                form.setFieldsValue({
                  ...document,
                  payId: parseInt(params.payId, 10),
                  docYear: document?.docYear ? document.docYear : "",
                  prNo: document.payNumber,
                  compId: document.compId.toString(),
                  disableCompany: companyObject?.companyName,
                  purgId: purchaseObject?.purgId.toString(),
                  disablePurchasing: purchaseObject?.purGroupName,
                  accoId: accountObject?.accoId.toString(),
                  disableAssignment: accountObject?.accAssName,
                  matgId: materialObject?.matgId.toString(),
                  disableMaterial: materialObject?.materialGroupName,
                  accountNum: vendorObject?.accountNum.toString(),
                  accountText: accountTextSplit,
                  payPurgId: payPurgObject?.purgId.toString(),
                  disablePayPurchasing: payPurgObject?.purGroupName,
                  disableVendor: vendorObject?.accountText,
                  emailInfo: document?.emailInfo
                    ? document?.emailInfo.split(";")
                    : undefined,
                  period: [
                    moment(document.periodFrom),
                    moment(document.periodTo),
                  ],
                  invoiceDate: moment(document.invoiceDate),
                  budgetYear: moment(document.budgetYear),
                  documentDate: moment(document.documentDate),
                });
              }
            }
          )
          .finally(() => {
            setIsInitial(true);
            setLoading(false);
          });
      });
    } else {
      Promise.all([
        getMasterPurchasingGroup(auth?.userId),
        getMasterCompany(auth?.userId),
        getMasterAccountAssignment(auth?.userId),
        getMasterMaterialGroup(auth?.userId),
        getMasterPurchasingOrg(),
        getMasterUnit(),
        getMasterCurrency(),
        getUserInfo(),
      ])
        .then(
          ([
            purchasingGroupData,
            companyData,
            accountAssignment,
            responseMaterialGroup,
            purchasingOrgData,
            responseUnit,
            currencyResponse,
            responseUserList,
          ]) => {
            setDataSourcePurchasingGroup(purchasingGroupData.responseData);
            setDataSourcePayByPurchasingGroup(purchasingGroupData.responseData);
            setDataSourceCompany(companyData.responseData);
            setDataSourceAccountAssign(accountAssignment.responseData);
            setDataSourceMaterialGroup(responseMaterialGroup.responseData);
            setDataSourceCurrency(currencyResponse.responseData);
            setDataSourcePurchasingOrg(purchasingOrgData.responseData);
            setDataSourceUnit(responseUnit.responseData);
            setDataSourceUser(responseUserList.responseData);
          }
        )
        .finally(() => {
          setIsInitial(true);
          setLoading(false);
        });
    }
  }, [auth?.userId]);

  useEffect(() => {
    if (selectedCompany !== undefined && dataSourceCompany.length > 0) {
      if (!params.payId) {
        form.setFieldsValue({
          accountNum: "",
          disableVendor: "",
        });
      }

      setLoading(true);
      const { compId } = dataSourceCompany.filter(
        (value) => value.compId === parseInt(selectedCompany || "0", 10)
      )[0];
      if (isNonPO) {
        getMasterVendor(compId).then((result) => {
          setDataSourceVendor(result.responseData);
          setLoading(false);
        });
      } else {
        Promise.all([
          getMasterVendor(compId),
          getMasterAsset(compId),
          getMasterCostCenter(compId),
          getMasterInfoRecord(compId),
          getMasterStorageLoCode(compId),
          getMasterMaterialSet(compId),
        ])
          .then(([vendor, asset, costCenters, info, sloc, materialSets]) => {
            setDataSourceVendor(vendor.responseData);
            setDataSourceAsset(asset.responseData);
            setDataSourceCostCenter(costCenters.responseData);
            setDataSourceInfoRecord(info.responseData);
            setDataSourceStorageLoc(sloc.responseData);
            setDataSourceMaterialSet(materialSets.responseData);
          })
          .catch(() => setLoading(false))
          .finally(() => setLoading(false));
      }
    }
  }, [dataSourceCompany, form, selectedCompany]);

  useEffect(() => {
    if (currentPayBy && isInitial) {
      setLoading(true);
      getMasterPurchasingGroup(auth?.userId)
        .then((response) => {
          setDataSourcePayByPurchasingGroup(response.responseData);
          const payByPurg = response.responseData.find(
            (item) => item.purgId.toString() === selectedPayPurchase
          );
          if (!payByPurg) {
            form.setFieldsValue({
              payPurgId: undefined,
              disablePayPurchasing: undefined,
            });
          }
        })
        .finally(() => setLoading(false));
    }
  }, [currentPayBy, dataSourceUser, isInitial, auth?.userId]);

  const getBase64Upload = (
    file: RcFile,
    callback: { (value: string | ArrayBuffer | null): void }
  ) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const onUploadFileChange = (file: RcFile): void => {
    if (file && checkLimitSize(file)) {
      notification.error({
        message: "ไม่สามารถอัพโหลดไฟล์ได้",
        description: "ไฟล์มีขนาดใหญ่เกินกว่า 30MB",
      });
      return;
    }

    getBase64Upload(file, (imageUrl: any) => {
      const prepareFile: any = file;

      prepareFile.rawName = getFileName(file.name);
      prepareFile.base64 = imageUrl;

      setFileList([...fileList, prepareFile] as any);
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      disableCompany:
        dataSourceCompany.find((item) => `${item.compId}` === selectedCompany)
          ?.companyName || "",
    });
  }, [dataSourceCompany, selectedCompany]);

  useEffect(() => {
    const payByPurg = dataSourcePayByPurchasingGroup.find(
      (item) => item.purgId.toString() === selectedPayPurchase
    );

    form.setFieldsValue({
      disablePurchasing:
        dataSourcePurchasingGroup.find(
          (item) => `${item.purgId}` === selectedPurchase
        )?.purGroupName || "",
    });
    if (payByPurg) {
      form.setFieldsValue({
        payPurgId: selectedPayPurchase,
        disablePayPurchasing:
          dataSourcePayByPurchasingGroup.find(
            (item) => `${item.purgId}` === selectedPayPurchase
          )?.purGroupName || "",
      });
    } else {
      form.setFieldsValue({
        payPurgId: undefined,
        disablePayPurchasing: undefined,
      });
    }
  }, [
    dataSourcePayByPurchasingGroup,
    dataSourcePurchasingGroup,
    selectedPurchase,
    selectedPayPurchase,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      disablePayPurchasing:
        dataSourcePayByPurchasingGroup.find(
          (item) => `${item.purgId}` === selectedPayPurchase
        )?.purGroupName || "",
    });
  }, [dataSourcePayByPurchasingGroup, selectedPayPurchase]);

  useEffect(() => {
    form.setFieldsValue({
      disableAssignment:
        dataSourceAccountAssign.find(
          (item) => `${item.accoId}` === selectedAccount
        )?.accAssName || "",
    });
  }, [dataSourceAccountAssign, selectedAccount]);

  useEffect(() => {
    form.setFieldsValue({
      disableMaterial:
        dataSourceMaterialGroup.find(
          (item) => `${item.matgId}` === selectedMaterial
        )?.materialGroupName || "",
    });
  }, [dataSourceMaterialGroup, selectedMaterial]);

  useEffect(() => {
    if (selectedVendor?.substring(0, 2) === "29") {
      setIsDisableVendorInput(false);
    } else {
      setIsDisableVendorInput(true);
    }
    form.setFieldsValue({
      disableVendor:
        dataSourceVendor.find((item) => `${item.accountNum}` === selectedVendor)
          ?.accountText || "",
    });
  }, [dataSourceVendor, selectedVendor]);

  const getFile = (file: UploadFileCustom) => {
    if (Array.isArray(file)) {
      return file;
    }
    return file && file.fileList;
  };

  const onClear = () => {
    setFileList([]);
    setItemsPrepareDetail([]);
    setDataSourceVendor([]);
    form.resetFields();
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      fileList.map((file) => {
        // eslint-disable-next-line no-console
        console.log("file : ", file);
        return file;
      })
    );
  }, [fileList]);

  const onChangeVendorName = (name: string) => {
    const newDataArr = dataSourceVendor.map((item) => {
      if (item.accountNum === selectedVendor)
        return {
          ...item,
          accountText: name,
        };

      return item;
    });
    setDataSourceVendor(newDataArr);
  };

  const submitData = (data: any) => {
    if (isAdmin)
      return saveMemoDetailForAdmin({
        ...data,
        updateBy: `${auth?.userId}`,
      });
    else if (isNonPO) return submitMemoNonPO(data);
    return submitMemoWithPO(data);
  };

  const onSaveRequest = async (type: string, values: FormPRMemo) => {
    const companyObject = dataSourceCompany.find(
      (item) => `${item.compId}` === selectedCompany
    );
    const purchaseObject = dataSourcePurchasingGroup.find(
      (item) => `${item.purgId}` === selectedPurchase
    );
    const payByPurchaseObject = dataSourcePayByPurchasingGroup.find(
      (item) => `${item.purgId}` === selectedPayPurchase
    );
    const accountObject = dataSourceAccountAssign.find(
      (item) => `${item.accoId}` === selectedAccount
    );
    const materialObject = dataSourceMaterialGroup.find(
      (item) => `${item.matgId}` === selectedMaterial
    );
    const vendorObject = dataSourceVendor.find(
      (item) => `${item.accountNum}` === selectedVendor
    );

    const prepareDetail: PaymentInfoItemMemo[] = dataTable
      .filter((item) => {
        if (item.isDelete) {
          if (!item.payItemId) {
            return false;
          }
        }
        return true;
      })
      .map((item) => {
        return {
          ...item,
          isDelete: item.isDelete || false,
          exchangeRate: values.exchangeRate || 0,
          currency,
        };
      });
    const prepareData: PRMemoList = {
      ...form.getFieldsValue(),
      payBy: Number(values.payBy) || 0,
      docYear: values.docYear,
      compId: companyObject?.compId || 0,
      company: companyObject?.companyId || "",
      companyName: companyObject?.abbr || "",
      purgId: purchaseObject?.purgId || 0,
      purGroup: purchaseObject?.purGroupId || "",
      purGroupName: purchaseObject?.purGroupName || "",
      accoId: accountObject?.accoId || 0,
      accountAssignCategory: accountObject?.accAssName || "",
      accountAssignCategoryId: accountObject?.accAssId || "",
      matgId: materialObject?.matgId || 0,
      matGroup: materialObject?.materialGroupId || "",
      createBy: Number(auth?.userId),
      payPurgId: Number(values.payPurgId) || 0,
      payPurGroup: payByPurchaseObject?.purGroupId || "",
      payPurGroupName: payByPurchaseObject?.purGroupName || "",
      emailInfo: values.emailInfo?.join(";") || "",
      accountText: vendorObject?.accountText || "",
      accountNum: vendorObject?.accountNum || "",
      exchangeRate: values.exchangeRate || 0,
      periodFrom: moment(values.period[0]).format(),
      periodTo: moment(values.period[1]).format(),
      invoiceDate: values.invoiceDate
        ? moment(values.invoiceDate).format()
        : null,
      budgetYear: values.budgetYear
        ? moment(values.budgetYear).format("YYYY")
        : null,
      documentDate: moment(values.documentDate).format(),
      vat: vatValue,
      paymentInfoItem: prepareDetail,
      paymentInfoAttach: [],
    };

    setLoading(true);
    setLoadingButton(true);

    const fileNewUpload = fileList.filter((item) => item instanceof File);
    const fileUploaded = fileList.filter((item) => !(item instanceof File));

    await Promise.all(
      fileNewUpload.map((file: any) => {
        const FileName = file.rawName;
        const ExtensionFile = file.fileExtension;

        const prepareFileData: PrepareFileData = {
          FileBase64: file.base64,
          FileType: FileName,
          ExtensionFile,
        };

        return uploadFileList(prepareFileData)
          .then((res) => {
            return {
              fileName: res.responseData[0].fileName,
              filePath: res.responseData[0].filePath,
              ExtensionFile,
            };
          })
          .catch((err) => {
            return err;
          });
      })
    )
      // .then(() => console.log("final data", prepareData))
      .then(async (responseFile) => {
        const paymentInfoAttach = [
          ...fileUploaded.map((item: any) => {
            return {
              payAttcId: item.payAttcId,
              filePath: item.filePath,
              filename: item.filename,
              fileExtension: item.fileExtension,
              readOnly: false,
              isDelete: item.isDelete || false,
            };
          }),
          ...responseFile.map((item) => {
            return {
              payAttcId: 0,
              filePath: item.filePath,
              filename: item.fileName,
              fileExtension: item.ExtensionFile,
              readOnly: false,
              isDelete: false,
            };
          }),
        ];

        const submittedData = {
          ...prepareData,
          paymentInfoAttach,
          dueDate: "",
          isNonPO,
          isSameVendor: false,
          isImmediately: false,
          assigment: "",
          docType: "",
          reference: "",
          paymentRef: "",
          taxInvoiceNo: "",
        };

        await submitData(submittedData)
          .then((result: any) => {
            if (type === "release") {
              if (isNonPO) {
                sendApprove({
                  payId: [result.responseData[0].payId],
                  sendApproveBy: `${auth?.userId}`,
                })
                  .then(() => {
                    Modal.success({
                      content: `บันทึกข้อมูลสำเร็จ Pay Request No คือ ${result.responseData[0].payNumber}`,
                      onOk() {
                        setLoadingButton(false);
                        history.push("/pr/requester");
                      },
                    });
                  })
                  .catch(() => setLoadingButton(false));
              } else {
                sendToAdmin({
                  payId: [result.responseData[0].payId],
                  sendAdminBy: `${auth?.userId}`,
                })
                  .then(() => {
                    Modal.success({
                      content: `บันทึกข้อมูลสำเร็จ Pay Request No คือ ${result.responseData[0].payNumber}`,
                      onOk() {
                        setLoadingButton(false);
                        history.push("/pr/requester");
                      },
                    });
                  })
                  .catch(() => setLoadingButton(false));
              }
            } else if (type === "admin_release") {
              // sendApprove({
              //   payId: [parseInt(params.payId, 10)],
              //   sendApproveBy: `${auth?.userId}`,
              // })
              sendAdminApprove({
                payId: [parseInt(params.payId, 10)],
                userId: `${auth?.userId}`,
                reason: "",
              })
                .then(() => {
                  Modal.success({
                    content: `ส่งคำขออนุมัติสำเร็จ`,
                    onOk() {
                      setLoadingButton(false);
                      history.push("/pr/admin");
                    },
                  });
                })
                .catch(() => setLoadingButton(false));
            } else {
              Modal.success({
                content: `บันทึกข้อมูลสำเร็จ Pay Request No คือ ${result.responseData[0].payNumber}`,
                onOk() {
                  setLoadingButton(false);
                  if (isAdmin) {
                    history.push("/pr/admin");
                  } else {
                    history.push("/pr/requester");
                  }
                },
              });
            }
          })
          .catch(() => {
            setLoadingButton(false);
            // Modal.error({
            //   title: `เกิดข้อผิดพลาด`,
            //   content: `โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            // });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSaveRequestValidate = (type: string) => {
    form
      .validateFields()
      .then(async (values) => {
        if (!dataTable.length) {
          message.error("กรุณากรอกข้อมูลในตารางให้ครบถ้วน", 15);
          if (dataTableRef.current) dataTableRef.current.scrollIntoView();
          return;
        }
        const vat = (getTotalAmount + vatValue) * 100;

        if (Math.round(vat) / 100 !== values.amount) {
          message.error("กรุณากรอกจำนวนเงินให้ถูกต้อง", 15);
          form.scrollToField("amount");
          return;
        }

        let isSameVendor = true;
        if (!isNonPO) {
          dataTable.forEach((item) => {
            if (item.desiredAccountNum !== values.accountNum) {
              isSameVendor = false;
              message.error(
                "ไม่สามารถเลือก Vendor ให้ต่างกับ Desired Vendor ได้",
                15
              );
              form.scrollToField("accountNum");
            }
          });
        }
        if (!isSameVendor) return;
        // console.log("success", type, values);
        onSaveRequest(type, values);
      })
      .catch((error) => {
        message.error("กรุณากรอกข้อมูลให้ครบถ้วน", 15);
        form.scrollToField(error.errorFields[0].name);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteFile = (fileIndex: number) => {
    if (params.payId) {
      const prepareFileList = fileList
        .filter((file, index) => !(file instanceof File && fileIndex === index))
        .map((item: any, index) => {
          return {
            ...item,
            isDelete: fileIndex === index ? true : item.isDelete,
          };
        });

      setFileList(cloneDeep(prepareFileList));
    } else {
      const newFileList = fileList.filter((ele, index) => {
        return index !== fileIndex;
      });
      setFileList(cloneDeep(newFileList));
    }
  };

  return (
    <Spin spinning={loading}>
      <div style={{ padding: 20 }}>
        <TitleHeader
          title={getTitleName()}
          dataSizes={count}
          onSaveRequest={onSaveRequestValidate}
          onClear={onClear}
          loading={loadingButton}
          disableITAdmin={isDisabledITAdmin}
        />
        <div
          style={{
            borderRadius: "16px",
            padding: "32px",
            border: "1px solid #E2E2E2",
            boxSizing: "border-box",
          }}
        >
          {/* <Button onClick={() => console.log(form.getFieldsValue(), dataTable)}>
            Console
          </Button> */}
          {/* Form Component */}
          <MemoForm
            form={form}
            auth={auth}
            inputNames={inputNames}
            company={companies}
            purchasingGroup={purchasingGroups}
            payPurchasingGroup={payPurchasingGroup}
            accountAssign={accountAssign}
            materialGroup={expenseType}
            vendorData={vendorData}
            onChangeVendorName={onChangeVendorName}
            isDisableVendorInput={isDisableVendorInput}
            isApprProject={isApprProject}
            formatNumber={formatNumber}
            defaultCurrency={defaultCurrency}
            currencyData={currencyData}
            userList={userList}
            localCurrentAmount={localCurrentAmount}
            getFile={getFile}
            fileList={fileList}
            onUploadFileChange={onUploadFileChange}
            onDeleteFile={onDeleteFile}
            isNonPO={isNonPO}
            isAdmin={isAdmin}
            createByEmail={createByEmail}
          />
          {/* Table */}
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Typography.Text strong>DETAILS</Typography.Text>
            </Col>
            <Col span={24} ref={dataTableRef}>
              {isNonPO ? (
                <TableMemoNonPODetail
                  isCalculateTax={isCalculateTaxValue}
                  dataTable={itemsPrepareDetail}
                  setDataTable={setDataTable}
                />
              ) : (
                <TableMemoWithPODetail
                  isAdmin={isAdmin}
                  isCalculateTax={isCalculateTaxValue}
                  dataSourceAccountAssign={dataSourceAccountAssign}
                  dataTable={itemsPrepareDetail}
                  setDataTable={setDataTable}
                  dataSourceMaterialGroup={dataSourceMaterialGroup}
                  dataSourceMaterialSet={dataSourceMaterialSet}
                  dataSourceVendor={dataSourceVendor}
                  headerForm={form}
                  isSameVendor
                  currencyData={currencyData}
                  optionsVendor={vendorData}
                  optionsAccount={accountAssign}
                  optionsCompany={companies}
                  optionsMaterial={materialSet}
                  optionsExpense={expenseType}
                  optionsPurGroup={purchasingGroups}
                  datasourceCompany={dataSourceCompany}
                  dataSourcePurGroup={dataSourcePurchasingGroup}
                  optionsPurOrg={purchasingOrg}
                  optionsUnit={units}
                  dataSourcePurOrg={dataSourcePurchasingOrg}
                  dataSourceUnit={dataSourceUnit}
                  optionsAsset={assets}
                  optionsCostCenter={costCenter}
                  optionsInfoRecord={infoRecord}
                  optionsStorageLoc={storageLoc}
                />
              )}
            </Col>
          </Row>
          {/* Total Amount */}
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Typography.Text strong>TOTAL</Typography.Text>
            </Col>
            <Col span={24}>
              <Row gutter={[8, 8]}>
                <Col span={24} sm={{ span: 12, offset: 6 }}>
                  <Form>
                    <Form.Item
                      label="Total Amount"
                      colon
                      className="FormItemInputStyle"
                    >
                      <InputNumber
                        value={getTotalAmount}
                        disabled
                        formatter={formatNumber}
                        placeholder="Total Amount"
                        className="FieldInputStyle"
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24} sm={{ span: 12, offset: 6 }}>
                  <Form>
                    <Form.Item
                      label="Total Tax Amount"
                      colon
                      className="FormItemInputStyle"
                    >
                      <InputNumber
                        onChange={(value) => {
                          setVat(value);
                        }}
                        disabled={isAdmin}
                        value={vatValue}
                        formatter={formatNumber}
                        placeholder="Total Tax Amount"
                        className="FieldInputStyle"
                      />
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={24} sm={{ span: 12, offset: 6 }}>
                  <Form>
                    <Form.Item
                      label="Grand Total"
                      colon
                      className="FormItemInputStyle"
                    >
                      <InputNumber
                        value={getTotalAmount + vatValue}
                        disabled
                        formatter={formatNumber}
                        placeholder="Grand Total"
                        className="FieldInputStyle"
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
};

export default Component;
