/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getBooleanFromQuery, queryParse, queryStringify } from "../libs/query";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useParseQuery = () => {
  const location = useLocation();
  const history = useHistory();

  const queryObject = queryParse(location.search);

  const queryParams = new URLSearchParams(history.location.search);
  const querysearch: string | undefined | null = queryParams.get("search");
  const querypayno: string | undefined | null = queryParams.get("payno");
  const querydatetype: string | undefined | null = queryParams.get("dateType");
  const querycancel = !!queryObject.isCancelable;

  const querydoctype = useMemo(() => {
    return getBooleanFromQuery(queryObject.isNonPo as string);
  }, [queryObject.isNonPo]);

  const queryimmediate = useMemo(() => {
    return getBooleanFromQuery(queryObject.isImmediately as string);
  }, [queryObject.isImmediately]);

  const querypurgeid = useMemo(() => {
    return Number(queryObject.purgId);
  }, [queryObject.purgId]);

  const querycompid = useMemo(() => {
    return Number(queryObject.compId);
  }, [queryObject.compId]);

  const querydate = useMemo(() => {
    return (
      queryObject.dueDate ||
      queryObject.documentDate ||
      queryObject.postingDate ||
      null
    );
  }, [queryObject.documentDate, queryObject.dueDate, queryObject.postingDate]);

  const queryactivity = useMemo(() => {
    return (queryObject.activityState as string) || null;
  }, [queryObject.activityState]);

  const queryoverdue = useMemo(() => {
    return (queryObject.overDueUrgent as string) || null;
  }, [queryObject.overDueUrgent]);

  const queryPaymentType = useMemo(() => {
    return (queryObject.paymentType as string) || null;
  }, [queryObject.paymentType]);

  const queryBankingStatus = useMemo(() => {
    return (queryObject.bankingStatus as string) || null;
  }, [queryObject.bankingStatus]);

  const replaceLocation = (filterInput: any) => {
    const queryFilterWithoutDate = {
      ...filterInput,
      date: null,
    };

    if (filterInput) {
      const queryParam = queryStringify(queryFilterWithoutDate);
      history.replace({
        pathname: location.pathname,
        search: queryParam,
      });
    }
  };

  return {
    querysearch,
    querypayno,
    querydatetype,
    queryimmediate,
    querypurgeid,
    querycompid,
    querydate,
    queryactivity,
    queryoverdue,
    querycancel,
    querydoctype,
    queryPaymentType,
    queryBankingStatus,
    replaceLocation,
  };
};

export default useParseQuery;
