import { FC, useMemo } from "react";
import { Route, useHistory, Redirect } from "react-router-dom";
import { DefaultLayout } from "../../layouts";
import useAuthentication from "../../hooks/useAuthentication";
import { checkPermission, getPermissionUser } from "../permission";

const pageToGo = (userPermissions: string[]) => {
  if (checkPermission(userPermissions, "Requestor")) {
    return "/requester";
  }
  if (checkPermission(userPermissions, "Approve")) {
    return "/approver";
  }
  if (checkPermission(userPermissions, "Setting")) {
    return "/system/configurations";
  }
  if (checkPermission(userPermissions, "Admin")) {
    return "/system/users";
  }

  return "/";
};

interface PermissionProviderProps {
  module: string;
}

const PermissionProvider: FC<PermissionProviderProps> = ({
  children,
  module,
}) => {
  const history = useHistory();
  const auth = useAuthentication();

  const usePermission = useMemo((): string[] | undefined => {
    if (auth) {
      return getPermissionUser(auth);
    }

    return undefined;
  }, [auth]);

  // console.log("usePermission : ", usePermission);

  if (usePermission && checkPermission(usePermission, module)) {
    return <>{children}</>;
  }

  if (usePermission) {
    history.replace(pageToGo(usePermission));
    return null;
  }

  return <Redirect to="/login" />;
};

export type RouteConfig = {
  path: string;
  component: FC<unknown>;
  exact?: boolean;
  module?: string;
};

export const generateRoutes = (
  configs: Array<RouteConfig>
): Array<JSX.Element> => {
  return configs.map((route, idx) => {
    return (
      <Route
        key={idx}
        exact={route.exact}
        path={route.path}
        render={() => {
          return (
            <>
              {route.module ? (
                <PermissionProvider module={route.module}>
                  <DefaultLayout>
                    <route.component />
                  </DefaultLayout>
                </PermissionProvider>
              ) : (
                <DefaultLayout>
                  <route.component />
                </DefaultLayout>
              )}
            </>
          );
        }}
      />
    );
  });
};
