/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import {
  Table,
  Button,
  Typography,
  Badge,
  Col,
  Row,
  Checkbox,
  Form,
  Spin,
  Tabs,
  Tooltip,
  Avatar,
  Space,
  Modal,
  Select,
  Input,
  InputRef,
  SelectProps,
} from "antd";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  PaperClipOutlined,
  SendOutlined,
  TrademarkCircleOutlined,
  RetweetOutlined,
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { FilterConfirmProps } from "antd/es/table/interface";
import "./index.css";
import moment from "moment";
import { cloneDeep, omitBy } from "lodash";
import { useLocation } from "react-router-dom";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import Highlighter from "react-highlight-words";
import type { ColumnType } from "antd/es/table";
import formatData from "../../libs/formatData";
import CustomModal from "../CustomModal";
import Attached from "../Drawer/Tabs/Attached";
import { getHistoryPayId } from "../../api/history";
import { uploadFileList } from "../../api/service";
import {
  UploadFileRequest,
  UploadFileResponse,
} from "../../interfaces/serviceAPI.interface";
import { getFileName, getFileExtension } from "../../libs/file";
import Save from "../CustomIcon/Save";
import {
  BankingPayments,
  PaymentInfoItemBanking,
} from "../../interfaces/banking.interface";
import useAuthentication from "../../hooks/useAuthentication";
import {
  getBankingListDetailById,
  sendApproveBanking,
  updateBankingAttachFileById,
  recallBanking,
  cancelBanking,
  renewBanking,
  approveBanking,
  cancelApproveBanking,
  previeweMail,
} from "../../api/banking";
import { CustomDrawer } from "../Drawer";
import { TabsApprove, TabsAttached } from "../Drawer/Tabs";
import { TabsHeader, TabsDetail } from "../Drawer/TabsRequest";
import { User } from "../../interfaces/user.interface";
import { mapDataSource } from "../../libs/array/mappingPaymentList";
import { EmailInfo } from "../../enum/bankingEmailInfo.enum";

const { TextArea } = Input;

interface Props {
  payId: string;
  dataTable?: BankingPayments[];
  setDataTable: (value: BankingPayments[]) => void;
  dataApprover?: User[];
  isCheque?: boolean;
  isEnableAdmin?: boolean;
  isTHBCurrency?: boolean;
  selectedRowKeys: React.Key[];
  onSelectChange: (value: React.Key[]) => void;
}

const Component = ({
  payId,
  dataTable,
  setDataTable,
  dataApprover,
  isCheque = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isEnableAdmin = false,
  isTHBCurrency = true,
  selectedRowKeys,
  onSelectChange,
}: Props): JSX.Element => {
  const auth = useAuthentication();
  const [tabKey, setTabKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [dataSource, setDataSource] = useState<BankingPayments[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleReleaseModal, setVisibleReleaseModal] =
    useState<boolean>(false);
  const [visibleSingleModal, setVisibleSingleModal] = useState<boolean>(false);
  const [visibleRenewModal, setVisibleRenewModal] = useState<boolean>(false);
  const [visibleCancelModal, setVisibleCancelModal] = useState<boolean>(false);
  const [visibleCancelApproveModal, setVisibleCancelApproveModal] =
    useState<boolean>(false);
  const [visibleApproveModal, setVisibleApproveModal] =
    useState<boolean>(false);

  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<BankingPayments>();
  const [selectedSubRow, setSelectedSubRow] = useState<any>();
  const [saveFileList, setSaveFileList] = useState<any[]>([]);
  const [saveDeleteApi, setDeleteFileApi] = useState<any[]>([]);
  const [isClearUploadList, setIsClaerUploadList] = useState<boolean>(false);
  const [sendTo, setSendTo] = useState<string[]>([]);
  const [emailInfo, setEmailInfo] = useState<string>("");
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [current, setCurrent] = useState<number>(0);
  const [previewEmail, setPreviewEmail] = useState<string>("");
  const [form] = Form.useForm();

  useEffect(() => {
    setDataTable(dataTable || []);
    setDataSource(dataTable || []);
  }, [dataTable, setDataTable]);

  useEffect(() => {
    const data = cloneDeep(dataSource);
    setDataTable(data);
  }, [dataSource, setDataTable]);

  useEffect(() => {
    if (!visibleDrawer) {
      console.log("not visible");
      setTabKey("1");
    }
  }, [visibleDrawer]);

  interface DataType {
    key: string;
    payDoc: string;
    nameCheque: string;
    chequeNo: string;
    payDesc: string;
    paymentDate: string;
  }

  type DataIndex = keyof DataType;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      // close,
    }) => (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            // type="link"
            size="small"
            style={{ width: 90 }}
            onClick={() => {
              // close();
              confirm({ closeDropdown: true });
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : "#555555",
          fontSize: "16px",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    // onFilterDropdownOpenChange: (visible) => {
    //   if (visible) {
    //     setTimeout(() => searchInput.current?.select(), 100);
    //   }
    // },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const isHistory = useMemo(() => {
    return location.pathname.includes("history");
  }, [location]);

  const isCEO = useMemo(() => {
    return location.pathname.includes("ceo");
  }, [location]);

  // const records = dataSource.filter((value) => !value.isDelete);
  const records = dataSource.filter((value) => value.status !== "");

  const columns = [
    {
      dataIndex: "payDoc",
      sorter: (a: { payDoc: string }, b: { payDoc: any }) =>
        a.payDoc.localeCompare(b.payDoc),
      title: "เอกสารจ่าย",
      key: "payDoc",
      ...getColumnSearchProps("payDoc"),
    },
    {
      dataIndex: "docYear",
      width: 70,
      sorter: (a: { docYear: string }, b: { docYear: any }) =>
        a.docYear.localeCompare(b.docYear),
      title: "ปีเอกสาร",
    },
    {
      dataIndex: "actualPaymentDate",
      title: "วันที่ตัด STMT",
      sorter: (
        a: { actualPaymentDate: moment.MomentInput },
        b: { actualPaymentDate: moment.MomentInput }
      ) =>
        moment(a.actualPaymentDate).unix() - moment(b.actualPaymentDate).unix(),
      render: (value: string) => {
        return value && moment(value).format("DD.MM.YYYY");
      },
    },
    {
      dataIndex: "nameCheque",
      title: "รหัสและชื่อเจ้าหนี้",
      key: "nameCheque",
      ...getColumnSearchProps("nameCheque"),
    },
    {
      dataIndex: "payDesc",
      title: "รายละเอียดค่าใช้จ่าย",
      key: "payDesc",
      ...getColumnSearchProps("payDesc"),
    },
    {
      dataIndex: "currency",
      title: "สกุลเงิน",
      sorter: (a: { currency: string }, b: { currency: any }) =>
        a.currency.localeCompare(b.currency),
    },
    {
      dataIndex: "amount",
      title: "Amount (Curr)",
      render: (value: number) => {
        return formatData.toCurrency(value);
      },
    },
    {
      dataIndex: "amountInLocal",
      title: "Amount (THB)",
      render: (value: number) => {
        return formatData.toCurrency(value);
      },
    },
    {
      dataIndex: isTHBCurrency ? "wTaxAmount" : "wTaxAmountInLocal",
      title: "ภาษีหักที่จ่าย",
      render: (value: number) => {
        return formatData.toCurrency(value);
      },
    },
    ...(!isCheque
      ? [
          {
            dataIndex: "identification",
            title: "Iden No.",
            sorter: (
              a: { identification: string },
              b: { identification: any }
            ) => a.identification.localeCompare(b.identification),
            key: "identification",
          },
        ]
      : [
          {
            dataIndex: "chequeNo",
            title: "Cheque No.",
            key: "chequeNo",
            sorter: (a: { chequeNo: string }, b: { chequeNo: any }) =>
              a.chequeNo.localeCompare(b.chequeNo),
            ...getColumnSearchProps("chequeNo"),
          },
        ]),
    ...(!isCheque
      ? [
          {
            dataIndex: "bankName",
            sorter: (a: { bankName: string }, b: { bankName: any }) =>
              a.bankName.localeCompare(b.bankName),
            title: "ธนาคาร",
          },
        ]
      : [
          {
            dataIndex: "paymentDate",
            title: "วันที่ครบกำหนด",
            sorter: (
              a: { paymentDate: moment.MomentInput },
              b: { paymentDate: moment.MomentInput }
            ) => moment(a.paymentDate).unix() - moment(b.paymentDate).unix(),
            render: (value: string) => {
              return value && moment(value).format("DD.MM.YYYY");
            },
            // ...getColumnSearchProps("paymentDate"),
          },
        ]),
    ...(!isCheque
      ? [
          {
            dataIndex: "bankAccount",
            title: "เลขที่บัญชี",
          },
        ]
      : []),
    {
      dataIndex: "recEmail",
      title: "Email",
    },
    {
      dataIndex: "updateDate",
      title: "Update Date,Time",
      // defaultSortOrder: "descend",
      sorter: (
        a: { updateDate: moment.MomentInput },
        b: { updateDate: moment.MomentInput }
      ) => moment(a.updateDate).unix() - moment(b.updateDate).unix(),
      render: (value: string) => {
        return value && moment(value).format("DD.MM.YYYY HH:MM");
      },
    },
    {
      align: "left",
      width: isCheque ? 140 : 60,
      render: (_value: never, record: BankingPayments) => (
        <Space size={[16, 0]}>
          <Tooltip placement="top" title="Attach Files">
            <Badge
              count={record.totalAttachFile || 0}
              size="small"
              offset={[2, 0]}
            >
              {!record.isDelete && (
                // {!record.isDelete && !isCEO && !isHistory && (
                <PaperClipOutlined
                  rotate={135}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRow(record);
                    setVisibleModal(true);
                  }}
                />
              )}
            </Badge>
          </Tooltip>
          {record.isDelete && (
            <Tooltip placement="top" title="Delete">
              <DeleteOutlined style={{ color: "#FF0000" }} />
            </Tooltip>
          )}
          {isCheque &&
            isEnableAdmin &&
            record.status !== "NEW" &&
            record.status !== "APPROVED" && (
              <Tooltip placement="top" title="Recall">
                <Button
                  type="text"
                  icon={<TrademarkCircleOutlined />}
                  onClick={() => {
                    setVisibleSingleModal(true);
                    setSelectedRow(record);
                  }}
                />
              </Tooltip>
            )}
          {isCheque && record.status === "APPROVED" && isEnableAdmin && (
            <Tooltip placement="top" title="Cancel Approved">
              <Button
                type="text"
                icon={<CloseOutlined style={{ color: "#FF4D4E" }} />}
                onClick={() => {
                  setSelectedRow(record);
                  setVisibleCancelApproveModal(true);
                }}
              />
            </Tooltip>
          )}
          {isCheque && record.status === "WAITING" && isEnableAdmin && (
            <>
              <Tooltip placement="top" title="Renew">
                <Button
                  type="text"
                  icon={<RetweetOutlined style={{ color: "#FF4D4E" }} />}
                  onClick={() => {
                    setSelectedRow(record);
                    setVisibleRenewModal(true);
                  }}
                />
              </Tooltip>
              {/* <Tooltip placement="top" title="Cancel">
                <Button
                  type="text"
                  icon={<CloseOutlined style={{ color: "#FF4D4E" }} />}
                  onClick={() => {
                    setSelectedRow(record);
                    setVisibleCancelModal(true);
                  }}
                />
              </Tooltip> */}
              <Tooltip placement="top" title="Approve">
                <Button
                  type="text"
                  icon={<CheckOutlined style={{ color: "#03703C" }} />}
                  onClick={() => {
                    setSelectedRow(record);
                    setVisibleApproveModal(true);
                  }}
                />
              </Tooltip>
            </>
          )}
          {isCheque &&
            record.status === "NEW" &&
            isEnableAdmin &&
            !record.isDelete && (
              <Tooltip placement="top" title="Release">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setVisibleReleaseModal(true);
                    setSelectedRow(record);
                  }}
                >
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      <SendOutlined style={{ color: "#174291" }} />
                      <Typography.Text strong>Release</Typography.Text>
                    </Col>
                  </Row>
                </Button>
              </Tooltip>
            )}
        </Space>
      ),
    },
  ];

  const columnsItems = [
    {
      dataIndex: "fiDoc",
      title: "FI Doc",
      width: "10%",
    },
    {
      dataIndex: "docDate",
      title: "วันที่เอกสาร",
      render: (value: string) => value && moment(value).format("DD.MM.YYYY"),
      width: "10%",
    },
    {
      dataIndex: "paymentRef",
      title: "เอกสารตั้งเบิก",
      width: "10.5%",
    },
    {
      dataIndex: "reference",
      title: "เอกสารอนุมัติ",
      width: "11%",
    },
    {
      dataIndex: "currency",
      title: "สกุลเงิน",
      width: "5%",
    },
    {
      dataIndex: "amount",
      title: "Amount (Curr)",
      width: "8.5%",
      render: (value: number) => {
        return formatData.toCurrency(value);
      },
    },
    {
      dataIndex: "amountInLocal",
      title: "Amount (THB)",
      width: "8.5%",
      render: (value: number) => {
        return formatData.toCurrency(value);
      },
    },
    {
      dataIndex: isTHBCurrency ? "wTaxAmount" : "wTaxAmountInLocal",
      title: "ภาษีหักที่จ่าย",
      width: "8%",
      render: (value: number) => {
        return formatData.toCurrency(value);
      },
    },
    {
      align: "center",
      fixed: "right",
      render: (_value: never, record: PaymentInfoItemBanking) => (
        <InfoCircleOutlined
          onClick={() => {
            setTableLoading(true);
            getHistoryPayId(record.paymentId)
              .then((res) => {
                setSelectedSubRow(mapDataSource(res as any)[0]);
                setVisibleDrawer(true);

                console.log(selectedSubRow);
              })
              .catch((error) => console.log(error))
              .finally(() => setTableLoading(false));
          }}
        />
      ),
    },
  ];

  const onUpdateDataSource = useCallback(() => {
    if (auth) {
      setLoading(true);
      Promise.all([getBankingListDetailById(payId)])
        .then(([responsePaymentList]) => {
          setDataSource(responsePaymentList.responseData[0].payments);
        })
        .catch((error) => {
          console.log("Error : ", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth, payId]);

  const onPaymentSendApprove = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        sendApproveBanking({
          payId: payIds,
          sendBy: parseInt(auth?.userId || "0", 10),
          sendTo,
          emailInfo,
        }),
      ])
        .then(() => {
          setDataSource(
            dataSource.filter((item) => !payIds.includes(item.payId))
          );
          Modal.success({
            content: `Release เอกสารให้ Approver สำเร็จ`,
          });
        })
        .catch((err) => console.log("release error", err));
    },
    [auth?.userId, dataSource, sendTo, emailInfo]
  );

  const onPaymentUpdateAttachFile = useCallback(
    (payload: BankingPayments) => {
      return new Promise((resolve, reject) => {
        try {
          const prepareFileList: UploadFileRequest[] = saveFileList.map(
            (file) => {
              const FileName = getFileName(file.name);
              const ExtensionFile = getFileExtension(file.name);

              return {
                FileBase64: file.base64,
                FileType: FileName,
                ExtensionFile,
              };
            }
          );

          Promise.all(
            prepareFileList.map(async (item: UploadFileRequest) =>
              uploadFileList(item)
            )
          )
            .then((response: UploadFileResponse[]) => {
              updateBankingAttachFileById({
                payId: payload.payId,
                payAttc: [
                  ...saveDeleteApi.map((item) => {
                    return {
                      payAttcId: item.payAttcId,
                      filePath: item.filePath,
                      filename: item.filename,
                      fileExtension: item.fileExtension,
                      readOnly: false,
                      isDelete: item.isDelete,
                    };
                  }),
                  ...prepareFileList.map((item, index) => {
                    return {
                      payAttcId: 0,
                      filePath: response[index]?.responseData[0]?.filePath,
                      fileName: response[index]?.responseData[0]?.fileName,
                      fileExtension: item.ExtensionFile,
                      readOnly: false,
                      isDelete: false,
                    };
                  }),
                ],
                updateBy: auth?.userId || "",
              })
                .then((responseUpdate) => {
                  setDeleteFileApi([]);
                  resolve(responseUpdate);
                })
                .catch((error) => reject(error));
            })
            .catch((error) => reject(error));
        } catch (error) {
          reject(error);
        }
      });
    },
    [auth?.userId, saveFileList, saveDeleteApi]
  );

  const titleDrawer = useMemo(() => {
    return (
      <Row align="middle">
        <InfoCircleOutlined />
        <Typography.Text style={{ paddingLeft: 10 }}>
          Information
        </Typography.Text>
      </Row>
    );
  }, []);

  const contentDrawer = useMemo(() => {
    const prHeaderNote = selectedSubRow?.prHeaderNote;

    return (
      <>
        <Row>
          <Typography.Title
            level={3}
            style={{
              width: "100%",
              paddingLeft: 24,
              paddingRight: 24,
            }}
            className="new-line"
          >
            {prHeaderNote}
          </Typography.Title>
        </Row>
        <Tabs
          defaultActiveKey="1"
          activeKey={tabKey}
          onChange={(key) => setTabKey(key)}
        >
          <Tabs.TabPane tab="Headers" key="1">
            <TabsHeader data={selectedSubRow} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Details" key="2">
            <TabsDetail data={selectedSubRow} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attacheds" key="3">
            <TabsAttached
              data={Object.values(
                omitBy(selectedSubRow?.paymentInfoAttachHistory, (value) => {
                  return saveDeleteApi.find(
                    (element) => element.payAttcId === value.payAttcId
                  );
                })
              )}
              disabled
              setData={setDeleteFileApi}
              saveDeleteApi={saveDeleteApi}
              userId={auth?.userId}
              visibleDeleteButton
              setSaveFileList={setSaveFileList}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Approval Status" key="4">
            <TabsApprove
              payId={selectedSubRow?.payId || 0}
              sendApproveDate={selectedSubRow?.releasedBy}
              userInfo={selectedSubRow?.releasedDate}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }, [auth?.userId, saveDeleteApi, selectedSubRow, tabKey]);

  const footerDrawer = useMemo(() => {
    return (
      <div className="drawer-footer">
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            Amount ({selectedSubRow?.currency}):
          </Typography.Title>
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            <Typography.Text style={{ color: "#174291", paddingLeft: 5 }}>
              {formatData.toCurrency(selectedSubRow?.amount || 0)}
            </Typography.Text>
          </Typography.Title>
        </Row>
      </div>
    );
  }, [selectedSubRow]);

  const contentModal = useMemo(() => {
    return (
      <Attached
        data={Object.values(
          omitBy(selectedRow?.paymentInfoAttach, (value) => {
            return saveDeleteApi.find(
              (element) => element.payAttcId === value.payAttcId
            );
          })
        )}
        setData={setDeleteFileApi}
        userId={auth?.userId}
        visibleDeleteButton
        disabled={false}
        saveDeleteApi={saveDeleteApi}
        setSaveFileList={setSaveFileList}
        isClearUploadList={isClearUploadList}
        setIsClearUploadList={setIsClaerUploadList}
      />
    );
  }, [
    auth?.userId,
    isClearUploadList,
    saveDeleteApi,
    selectedRow?.paymentInfoAttach,
  ]);

  const footerModal = useMemo(() => {
    return (
      <div className="drawer-footer">
        <Row justify="end" align="middle">
          <Col span={6}>
            <Button
              loading={loading}
              style={{
                width: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
                gap: 10,
              }}
              onClick={() => {
                setVisibleModal(false);
                setIsClaerUploadList(true);
              }}
            >
              <Typography.Text strong>Cancel</Typography.Text>
            </Button>
          </Col>
          <Col span={6}>
            <Button
              type="primary"
              icon={<Save />}
              style={{
                display: "flex",
                width: "90%",
                alignItems: "center",
                justifyContent: "center",
                padding: 10,
                gap: 10,
              }}
              onClick={() => {
                setLoading(true);
                if (selectedRow) {
                  onPaymentUpdateAttachFile(selectedRow)
                    .then(() => {
                      onUpdateDataSource();
                    })
                    .finally(() => {
                      setLoading(false);
                      setVisibleModal(false);
                      setIsClaerUploadList(true);
                    });
                }
              }}
            >
              <Typography.Text strong style={{ color: "white" }}>
                Save
              </Typography.Text>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }, [loading, onPaymentUpdateAttachFile, onUpdateDataSource, selectedRow]);

  useEffect(() => {
    if (visibleReleaseModal) {
      const userIds = dataApprover?.map((item) => item.userId);
      setSendTo(userIds || []);
    } else {
      setSendTo([]);
    }
  }, [visibleReleaseModal, dataApprover]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options: SelectProps["options"] = [];
  options.push({ value: EmailInfo.ANUCHAN, label: EmailInfo.ANUCHAN });
  options.push({ value: EmailInfo.NONGNUCH, label: EmailInfo.NONGNUCH });

  const next = useCallback(
    (context: string) => {
      setPreviewEmail(context);
      setCurrent(current + 1);
    },
    [current, setPreviewEmail, setCurrent]
  );

  const onPrevieweMail = useCallback(
    async (payIds: number[]) => {
      await previeweMail({
        payId: payIds,
        sendBy: parseInt(auth?.userId || "0", 10),
        sendTo,
        emailInfo,
        releaseDesc: form.getFieldValue("releaseDesc").toString(),
      }).then((result) => {
        next(result.responseData[0]);
      });
    },
    [auth?.userId, sendTo, emailInfo, form, next]
  );

  const back = useCallback(() => {
    setPreviewEmail("");
    form.setFieldsValue({ releaseDesc: "" });
    setCurrent(current - 1);
  }, [current, setPreviewEmail, setCurrent, form]);

  const contentReleaseModal = useMemo(() => {
    const toggleChecked = (e: CheckboxChangeEvent) => {
      const cerrent = e.target.value;
      const found = sendTo.find((value: string) => value === cerrent);
      let values: string[] = [...sendTo];
      if (found) {
        values = values.filter((value) => value !== cerrent);
      } else {
        values.push(cerrent);
      }
      setSendTo(values);
    };

    if (current === 1) {
      return (
        <div
          className="new-line"
          style={{ overflow: "auto", height: "60vh" }}
          dangerouslySetInnerHTML={{ __html: previewEmail }}
        />
      );
    }

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        {dataApprover?.map((item) => (
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingBottom: "8px" }}
            key={item.userId}
          >
            <Col>
              <Row align="middle">
                <Col style={{ paddingRight: "16px" }}>
                  <Avatar size="large" />
                </Col>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  <Typography.Text
                    strong
                  >{`${item.fristName} ${item.lastName}`}</Typography.Text>
                  <Typography.Text type="secondary">
                    {item.email}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Checkbox
                onChange={toggleChecked}
                checked={!!sendTo.find((value) => value === item.userId)}
                value={item.userId}
              />
            </Col>
          </Row>
        ))}
        <Select
          mode="tags"
          placeholder="E-mail Info"
          onChange={(val) => setEmailInfo(val?.join(";") || "")}
          className="FieldInputStyle"
          options={options}
        />

        <Form form={form}>
          <Form.Item name="releaseDesc">
            <TextArea
              showCount
              maxLength={200}
              allowClear
              rows={3}
              placeholder="Release Description"
            />
          </Form.Item>
        </Form>
      </Spin>
    );
  }, [dataApprover, loading, sendTo, options, previewEmail, current, form]);

  const footerReleaseModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleReleaseModal(false)}>
          Cancel
        </Button>
        {current === 1 && (
          <Button loading={loading} onClick={back}>
            Back
          </Button>
        )}
        <Button
          type="primary"
          loading={loading}
          disabled={sendTo.length <= 0}
          onClick={() => {
            if (!selectedRow) return;
            if (current !== 1) {
              onPrevieweMail([selectedRow.payId]).then(() => setLoading(false));
            } else {
              setLoading(true);
              Promise.all([onPaymentSendApprove([selectedRow.payId])])
                .catch((error) => {
                  console.log(error);
                })
                .finally(() => {
                  setLoading(false);
                  setVisibleReleaseModal(false);
                  setPreviewEmail("");
                  setCurrent(0);
                });
            }
          }}
        >
          {current === 1 ? `Release to ${sendTo.length} people` : "Preview"}
        </Button>
      </Row>
    );
  }, [
    loading,
    sendTo,
    selectedRow,
    onPaymentSendApprove,
    back,
    current,
    onPrevieweMail,
  ]);

  const rowSelection = {
    selectedRowKeys,
    getCheckboxProps: (record: any) => ({
      disabled: record.isDelete,
      key: record.payId,
    }),
    onChange: onSelectChange,
  };

  const onRecallBanking = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        recallBanking({
          payId: payIds,
          sendBy: parseInt(auth?.userId || "0", 10),
        }),
      ]).then(() => {
        setDataSource(
          dataSource.filter((item) => !payIds.includes(item.payId))
        );
      });
    },
    [auth?.userId, dataSource]
  );

  const contentRecallModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text style={{ marginBottom: "20px" }}>
          Are you sure to recall cheque no.{" "}
          {selectedRow?.chequeNo ? selectedRow?.chequeNo : ""} ?
        </Typography.Text>
        {/* {onTableSelect.length > 0 ? (
          <Typography.Text style={{ marginBottom: "20px" }}>
            Are you sure to recall {onTableSelect.length} item(s) ?
          </Typography.Text>
        ) : (
          <Typography.Text style={{ marginBottom: "20px" }}>
            Are you sure to recall payment batch no.{" "}
            {selectBankingListPayment.batchNo} ?
          </Typography.Text>
        )} */}
      </Spin>
    );
    // }, [loading, onTableSelect.length, selectBankingListPayment.batchNo]);
  }, [loading, selectedRow]);

  const contentApproveModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text>
          Are you sure to approve{" "}
          {selectedRow?.chequeNo ? selectedRow?.chequeNo : ""}{" "}
          {formatData.toCurrency(selectedRow?.amount ? selectedRow?.amount : 0)}
          {selectedRow?.currency ? selectedRow?.currency : ""}?
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectedRow]);

  const contentCancelApproveModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text style={{ marginBottom: "20px" }}>
          Are you sure to cancel approve{" "}
          {selectedRow?.chequeNo ? selectedRow?.chequeNo : ""}{" "}
          {formatData.toCurrency(selectedRow?.amount ? selectedRow?.amount : 0)}
          {selectedRow?.currency ? selectedRow?.currency : ""}?
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectedRow]);

  const contentRejectModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text style={{ marginBottom: "20px" }}>
          Are you sure to reject{" "}
          {selectedRow?.chequeNo ? selectedRow?.chequeNo : ""}{" "}
          {formatData.toCurrency(selectedRow?.amount ? selectedRow?.amount : 0)}
          {selectedRow?.currency ? selectedRow?.currency : ""}?
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectedRow]);

  const contentRenewModal = useMemo(() => {
    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text style={{ marginBottom: "20px" }}>
          Are you sure to renew{" "}
          {selectedRow?.chequeNo ? selectedRow?.chequeNo : ""}{" "}
          {formatData.toCurrency(selectedRow?.amount ? selectedRow?.amount : 0)}
          {selectedRow?.currency ? selectedRow?.currency : ""}?
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectedRow]);

  const footerSingleModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleSingleModal(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);
            Promise.all([
              onRecallBanking([
                selectedRow?.payId
                  ? parseInt(selectedRow?.payId.toString(), 10)
                  : 0,
              ]),
            ])
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
              })
              .finally(() => {
                setLoading(false);
                setVisibleSingleModal(false);
              });
          }}
        >
          Recall
        </Button>
      </Row>
    );
    // }, [loading, selectBankingListPayment, onRecallBanking]);
  }, [loading, selectedRow, onRecallBanking]);

  const footerApproveModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CheckOutlined style={{ fontSize: "x-large" }} />}
          block
          style={{
            display: "flex",
            background: "#03703C",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          onClick={() => {
            if (auth) {
              setLoading(true);
              setVisibleApproveModal(true);
              approveBanking({
                payId: [
                  selectedRow?.payId
                    ? parseInt(selectedRow?.payId.toString(), 10)
                    : 0,
                ],
                sendBy: Number(auth.userId),
              }).then(() => {
                setLoading(false);
                setVisibleApproveModal(false);
                setDataSource(
                  dataSource.filter(
                    (item) =>
                      item.payId.toString() !== selectedRow?.payId.toString()
                  )
                );
              });
            }
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Approve
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [loading, selectedRow, auth, dataSource]);

  const footerCancelApproveModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CloseOutlined />}
          danger
          block
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          onClick={() => {
            if (auth) {
              setLoading(true);
              setVisibleCancelApproveModal(true);
              cancelApproveBanking({
                payId: [
                  selectedRow?.payId
                    ? parseInt(selectedRow?.payId.toString(), 10)
                    : 0,
                ],
                sendBy: Number(auth.userId),
              }).then(() => {
                setLoading(false);
                setVisibleCancelApproveModal(false);
                setDataSource(
                  dataSource.filter(
                    (item) =>
                      item.payId.toString() !== selectedRow?.payId.toString()
                  )
                );
              });
            }
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Cancel Approve
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [auth, loading, selectedRow, dataSource]);

  const footerRejectModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CloseOutlined />}
          danger
          block
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          onClick={() => {
            if (auth) {
              setLoading(true);
              setVisibleCancelModal(true);
              cancelBanking({
                payId: [
                  selectedRow?.payId
                    ? parseInt(selectedRow?.payId.toString(), 10)
                    : 0,
                ],
                sendBy: Number(auth.userId),
              }).then(() => {
                setLoading(false);
                setVisibleCancelModal(false);
                setDataSource(
                  dataSource.filter(
                    (item) =>
                      item.payId.toString() !== selectedRow?.payId.toString()
                  )
                );
              });
            }
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Cancel
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [auth, loading, selectedRow, dataSource]);

  const footerRenewModal = useMemo(() => {
    return (
      <Row>
        <Button
          type="primary"
          loading={loading}
          icon={<CloseOutlined />}
          danger
          block
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            gap: 10,
          }}
          onClick={() => {
            if (auth) {
              setLoading(true);
              setVisibleRenewModal(true);
              renewBanking({
                payId: [
                  selectedRow?.payId
                    ? parseInt(selectedRow?.payId.toString(), 10)
                    : 0,
                ],
                sendBy: Number(auth.userId),
              }).then(() => {
                setLoading(false);
                setVisibleRenewModal(false);
                setDataSource(
                  dataSource.filter(
                    (item) =>
                      item.payId.toString() !== selectedRow?.payId.toString()
                  )
                );
              });
            }
          }}
        >
          <Typography.Text strong style={{ color: "white" }}>
            Renew
          </Typography.Text>
        </Button>
      </Row>
    );
  }, [auth, loading, selectedRow, dataSource]);

  return (
    <>
      {isHistory || isCEO || !isCheque ? (
        <Table
          bordered
          // rowSelection={rowSelection}
          className="DetailsTable table-striped-rows"
          columns={columns as any}
          loading={tableLoading}
          dataSource={records}
          scroll={{ x: 1500, y: 500 }}
          style={{ overflowX: "scroll", backgroundColor: "white" }}
          rowKey="payId"
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                bordered
                rowKey="payItemId"
                className="DetailsTable"
                style={{ marginTop: "8px" }}
                columns={columnsItems as any}
                dataSource={record.paymentInfoItem || []}
              />
            ),
            rowExpandable: (record) => record.paymentInfoItem.length > 0,
            onExpand: (_expanded, record) => setSelectedRow(record),
          }}
        />
      ) : (
        <Table
          bordered
          rowSelection={rowSelection}
          className="DetailsTable table-striped-rows"
          columns={columns as any}
          loading={tableLoading}
          dataSource={records}
          scroll={{ x: 1500, y: 500 }}
          style={{ overflowX: "scroll", backgroundColor: "white" }}
          rowKey="payId"
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                bordered
                rowKey="payItemId"
                className="DetailsTable"
                style={{ marginTop: "8px" }}
                columns={columnsItems as any}
                dataSource={record.paymentInfoItem || []}
              />
            ),
            rowExpandable: (record) => record.paymentInfoItem.length > 0,
            onExpand: (_expanded, record) => setSelectedRow(record),
          }}
        />
      )}
      <CustomModal
        visible={visibleModal}
        setVisible={setVisibleModal}
        title="Attached New Files"
        onCancel={() => setVisibleModal(false)}
        content={contentModal}
        footer={footerModal}
      />
      <CustomModal
        visible={visibleReleaseModal}
        setVisible={setVisibleReleaseModal}
        title="Release to CEO"
        onCancel={() => setVisibleReleaseModal(false)}
        content={contentReleaseModal}
        width={1000}
        footer={footerReleaseModal}
      />
      {visibleSingleModal ? (
        <CustomModal
          key="SingleModal"
          visible={visibleSingleModal}
          setVisible={setVisibleSingleModal}
          title="Confirm to Recall single"
          content={contentRecallModal}
          footer={footerSingleModal}
        />
      ) : null}
      {visibleApproveModal ? (
        <CustomModal
          key="ApproveModal"
          visible={visibleApproveModal}
          setVisible={setVisibleApproveModal}
          title="Confirm to Approve"
          content={contentApproveModal}
          footer={footerApproveModal}
        />
      ) : null}
      {visibleCancelApproveModal ? (
        <CustomModal
          key="CancelApproveModal"
          visible={visibleCancelApproveModal}
          setVisible={setVisibleCancelApproveModal}
          title="Confirm to Cancel Approve"
          content={contentCancelApproveModal}
          footer={footerCancelApproveModal}
        />
      ) : null}
      {visibleCancelModal ? (
        <CustomModal
          key="CancelModal"
          visible={visibleCancelModal}
          setVisible={setVisibleCancelModal}
          title="Confirm to Cancel"
          content={contentRejectModal}
          footer={footerRejectModal}
        />
      ) : null}
      {visibleRenewModal ? (
        <CustomModal
          key="RenewModal"
          visible={visibleRenewModal}
          setVisible={setVisibleRenewModal}
          title="Confirm to Renew"
          content={contentRenewModal}
          footer={footerRenewModal}
        />
      ) : null}
      <CustomDrawer
        visible={visibleDrawer}
        setVisible={setVisibleDrawer}
        title={titleDrawer}
        content={contentDrawer}
        footer={footerDrawer}
      />
    </>
  );
};

export default Component;

Component.defaultProps = {
  dataTable: [],
  dataApprover: [],
  isCheque: false,
  isEnableAdmin: false,
  isTHBCurrency: true,
};
