import React, { FC } from "react";

const Component: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 16C0 17.1 0.89 18 1.99 18H16C17.1 18 18 17.1 18 16V6L12 0ZM2 16V2H11V7H16V16H2ZM6 5C6 5.55 5.55 6 5 6C4.45 6 4 5.55 4 5C4 4.45 4.45 4 5 4C5.55 4 6 4.45 6 5ZM6 9C6 9.55 5.55 10 5 10C4.45 10 4 9.55 4 9C4 8.45 4.45 8 5 8C5.55 8 6 8.45 6 9ZM6 13C6 13.55 5.55 14 5 14C4.45 14 4 13.55 4 13C4 12.45 4.45 12 5 12C5.55 12 6 12.45 6 13Z"
        fill="#545454"
      />
    </svg>
  );
};

export default Component;
