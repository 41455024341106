/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from "react";
import {
  HistoryIcon,
  RequestListIcon,
  PersonIcon,
} from "../components/CustomIcon";

interface MenuProps {
  onItemClick: (path: string) => void;
}

export interface HeaderMenu {
  path: string;
  key: string;
  icon: ReactNode;
  label: string;
}

export const getSideMenuItems = ({ onItemClick }: MenuProps): any => {
  return [
    {
      label: "Approver",
      key: "approverUser",
      module: "Approve",
      icon: <PersonIcon />,
      children: [
        {
          label: "PR",
          key: "pr/approver",
          onClick: () => onItemClick("pr/approver"),
        },
        {
          label: "Payment",
          key: "approver",
          onClick: () => onItemClick("approver"),
        },
      ],
    },
    {
      label: "Requester",
      key: "requesterUser",
      module: "Requestor",
      icon: <PersonIcon />,
      children: [
        {
          label: "PR",
          key: "pr/requester",
          onClick: () => onItemClick("pr/requester"),
        },
        {
          label: "Payment",
          key: "requester",
          onClick: () => onItemClick("requester"),
        },
      ],
    },
    {
      label: "Admin",
      key: "adminUser",
      module: "Admin",
      icon: <PersonIcon />,
      children: [
        // {
        //   label: "PA System",
        //   key: "admin",
        //   onClick: () => onItemClick("admin"),
        // },
        {
          label: "PR",
          key: "pr/admin",
          onClick: () => onItemClick("pr/admin"),
        },
      ],
    },
    {
      label: "Banking",
      key: "bankingUser",
      module: "Banking",
      icon: <PersonIcon />,
      children: [
        {
          label: "Requester Banking",
          key: "banking",
          onClick: () => onItemClick("banking"),
        },
      ],
    },
    {
      label: "Banking",
      key: "bankingCEO",
      module: "Executive",
      icon: <PersonIcon />,
      children: [
        {
          label: "Approver Banking",
          key: "bankingCEO",
          onClick: () => onItemClick("banking/ceo"),
        },
      ],
    },
  ];
};

export const headerMenus: { [k: string]: HeaderMenu[] } = {
  requester: [
    {
      path: "requester",
      key: "requester",
      icon: <RequestListIcon />,
      label: "Payment Request Lists",
    },
    {
      path: "requester/history",
      key: "history",
      icon: <HistoryIcon />,
      label: "Payment Request History",
    },
  ],
  approver: [
    {
      path: "approver",
      key: "approver",
      icon: <RequestListIcon />,
      label: "Payment Approval Lists",
    },
    {
      path: "approver/history",
      key: "history",
      icon: <HistoryIcon />,
      label: "Payment Approval History",
    },
  ],
  prRequester: [
    {
      path: "pr/requester",
      key: "requester",
      icon: <RequestListIcon />,
      label: "PR Request Lists",
    },
    {
      path: "pr/requester/history",
      key: "history",
      icon: <HistoryIcon />,
      label: "PR Request History",
    },
  ],
  prApprover: [
    {
      path: "pr/approver",
      key: "approver",
      icon: <RequestListIcon />,
      label: "PR Approval Lists",
    },
    {
      path: "pr/approver/history",
      key: "history",
      icon: <HistoryIcon />,
      label: "PR Approval History",
    },
  ],
  prAdmin: [
    {
      path: "pr/admin",
      key: "admin",
      icon: <RequestListIcon />,
      label: "PR Admin Request Lists",
    },
    {
      path: "pr/admin/history",
      key: "history",
      icon: <HistoryIcon />,
      label: "PR Admin Request History",
    },
  ],
  banking: [
    {
      path: "banking",
      key: "banking",
      icon: <RequestListIcon />,
      label: "In Progress",
    },
    {
      path: "banking/history",
      key: "history",
      icon: <HistoryIcon />,
      label: "History",
    },
  ],
  bankingCEO: [
    {
      path: "banking/ceo",
      key: "ceo",
      icon: <RequestListIcon />,
      label: "Inbox",
    },
    {
      path: "banking/ceo/history",
      key: "history",
      icon: <HistoryIcon />,
      label: "History",
    },
  ],
  // config: [
  //   {
  //     icon: <ConfigIcon />,
  //     path: "system/configurations",
  //     label: "Configurations",
  //     module: "Setting",
  //   },
  // ],
  // params: [
  //   {
  //     icon: <SearchOutlined />,
  //     path: "system/parameter",
  //     label: "Parameter",
  //     module: "Setting",
  //   },
  // ],
  // account: [
  //   {
  //     icon: <BankIcon />,
  //     path: "system/users",
  //     label: "Accountant",
  //     module: "Admin",
  //   },
  // ],
};

// export default function getMenu(name: string) {
//   return menus[name];
// }
