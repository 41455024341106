/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ResponseRequestList } from "../../interfaces/paymentList.interface";
import { ResponseMemoList } from "../../interfaces/prmemo.interface";

export const mapDataSource = (responsePayment: ResponseRequestList) => {
  return responsePayment.responseData.map((item) => {
    return {
      ...item,
      paymentInfoItem: item.paymentInfoItemHistory
        ? item.paymentInfoItemHistory
        : item.paymentInfoItem,
      paymentInfoAttach: item.paymentInfoAttachHistory
        ? item.paymentInfoAttachHistory
        : item.paymentInfoAttach,
    };
  });
};

export const mapMemoDataSource = (responsePayment: ResponseMemoList) => {
  return responsePayment.responseData.map((item) => {
    return {
      ...item,
      paymentInfoItem: item.paymentInfoItemHistory
        ? item.paymentInfoItemHistory
        : item.paymentInfoItem,
      paymentInfoAttach: item.paymentInfoAttachHistory
        ? item.paymentInfoAttachHistory
        : item.paymentInfoAttach,
    };
  });
};
