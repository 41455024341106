import { RcFile } from "antd/lib/upload";
import last from "lodash/last";

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
        return;
      }
      reject(new Error("something wrong"));
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });

export const getFileNameFromUrl = (url: string): string => {
  const { pathname } = new URL(url);
  const path = pathname.split("/");
  return path[path.length - 1] || url;
};

export const getFileNameFromContentDisposition = (
  contentDisposition: string
): string | undefined => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, "");
  }
  return undefined;
};

export const getFileName = (fileName: string): string => {
  const splitFileName = fileName.split(".");

  if (splitFileName.length <= 1) {
    return fileName;
  }

  splitFileName.pop();

  return splitFileName.join();
};

export const getFileExtension = (fileName: string): string => {
  const splitFileName = fileName.split(".");
  const fileExtension = last(splitFileName);

  if (!fileExtension) {
    return "";
  }

  return fileExtension;
};

export const checkLimitSize = (file: RcFile): boolean => {
  const limitSizeFile = 1024 * 1024 * 30;

  const isFileSize = file.size > limitSizeFile;

  return isFileSize;
};
