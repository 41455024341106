import {
  PaperClipOutlined,
  InfoCircleOutlined,
  CloseOutlined,
  EditOutlined,
  FileSearchOutlined,
  CheckOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Tooltip, Space, Typography, Row, Button, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import Highlighter from "react-highlight-words";
import { getPaymentListMemoPreview } from "../../api/prmemo";
import { Exclamation } from "../../components/CustomIcon";
// import UserStatusRow from "../../components/UserStatusRow";
import { PRMemoResponseLists } from "../../interfaces/prmemo.interface";
import formatData from "../../libs/formatData";

interface ColumnsProps {
  searchText: string;
  onClickEdit: (record: PRMemoResponseLists) => void;
  setVisibleDrawer: (value: boolean) => void;
  setVisibleSingleModal: (value: boolean) => void;
  setVisibleSendbackModal: (value: boolean) => void;
  setSelectRequestListPayment: (record: PRMemoResponseLists) => void;
  setLoading: (value: boolean) => void;
}

export const renderPRAdminListColumns = ({
  searchText,
  onClickEdit,
  setVisibleDrawer,
  setVisibleSingleModal,
  setVisibleSendbackModal,
  setSelectRequestListPayment,
  setLoading,
}: ColumnsProps): ColumnsType<PRMemoResponseLists> => {
  return [
    {
      title: "Status",
      width: 50,
      render: (record) => (
        <div style={{ display: "flex" }}>
          {record.isUrgent && (
            <Tooltip title="Urgent">
              <Exclamation />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Request No.",
      dataIndex: "payNumber",
      width: 90,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Memo No.",
      dataIndex: "memoNo",
      width: 90,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Header Note",
      dataIndex: "prHeaderNote",
      width: 200,
      render: (value: string, record) => (
        <>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchText]}
            // autoEscape={true}
            textToHighlight={value}
            className="new-line"
          />
          {record.isNonPo || (
            <Tag
              color="geekblue"
              style={{ marginLeft: "8px", borderRadius: "8px" }}
            >
              PO <CheckOutlined />
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 90,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "purchasingGroup",
      title: "Purchasing Group",
      dataIndex: "purGroup",
      width: 110,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "amount",
      title: "Amount",
      width: 110,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {`
            ${formatData.toCurrency(record.amount)} ${record.currency}
            `}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "item",
      title: "Items",
      width: 45,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {record.paymentInfoItem?.length || 0}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "files",
      title: "Attachment",
      width: 75,
      render: (record) => (
        <Space size="small">
          <PaperClipOutlined rotate={135} />
          <Typography.Text>
            {record.paymentInfoAttach?.length || 0}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "action",
      title: "Action",
      fixed: "right",
      width: 145,
      render: (record: PRMemoResponseLists) => (
        <Row>
          <Tooltip placement="top" title="Detail">
            <Button
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={() => {
                setVisibleDrawer(true);
                setSelectRequestListPayment(record);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Preview Document">
            <Button
              type="text"
              icon={<FileSearchOutlined />}
              onClick={() => {
                setLoading(true);
                setSelectRequestListPayment(record);
                getPaymentListMemoPreview({
                  payId: record.payId,
                  sendApproveBy: "",
                  documentType: "PRWM",
                })
                  .then((result) => {
                    const file = new Blob([result], {
                      type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                  })
                  // eslint-disable-next-line no-console
                  .catch((error) => console.log("can't open file:", error))
                  .finally(() => setLoading(false));
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Release to Approve">
            <Button
              disabled={record.isDelete}
              type="text"
              icon={
                <SendOutlined
                  style={record.isDelete ? {} : { color: "#174291" }}
                />
              }
              onClick={() => {
                setVisibleSingleModal(true);
                setSelectRequestListPayment(record);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Edit">
            <Button
              disabled={record.isDelete}
              type="text"
              icon={<EditOutlined />}
              onClick={() => onClickEdit(record)}
            />
          </Tooltip>
          <Tooltip placement="top" title="Send Back">
            <Button
              type="text"
              icon={<CloseOutlined style={{ color: "#FF4D4E" }} />}
              onClick={() => {
                setSelectRequestListPayment(record);
                setVisibleSendbackModal(true);
              }}
            />
          </Tooltip>
        </Row>
      ),
    },
  ];
};
