import { message } from "antd";
import { AxiosError } from "axios";
import axios from "../libs/axios";
import {
  PayloadBankingAttach,
  PayloadRecallBanking,
  PayloadSendApproveBanking,
  ResponseBankingDetail,
  ResponseBankingList,
} from "../interfaces/banking.interface";
import { PayloadBankingList } from "../interfaces/paymentList.interface";
import { Response, ResponseString } from "../interfaces/response.interface";
import { LoginResponse } from "../interfaces/authenticationAPI.interface";

export const syncsapBanking = (): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .get("/banking/info")
      .then((result) => {
        message.success(`ทำการ Sync ข้อมูลสำเร็จ`, 10);
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        // message.error(`${error} `, 10);
        // if (error.response?.status === 400) {
        //   message.warning(`${error.response?.data.error} `, 10);
        // } else {
        message.warning(`กำลังดำเนินการ Sync ข้อมูล กรุณารอสักครู่`, 10);
        // }
        reject(error);
      });
  });

export const getBankingList = (
  payload: PayloadBankingList
): Promise<ResponseBankingList> =>
  new Promise((resolve, reject) => {
    axios
      .post("/banking/search", payload)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getBankingListDetailById = (
  payId: string
): Promise<ResponseBankingDetail> =>
  new Promise((resolve, reject) => {
    axios
      .get(`/banking/${payId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const updateBankingAttachFileById = (
  payload: PayloadBankingAttach
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/banking/attachfile", payload)
      .then((result: { data: Promise<Response> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const sendApproveBanking = (
  payload: PayloadSendApproveBanking
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/banking/released", payload)
      .then((result: { data: Promise<Response> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const recallBanking = (
  payload: PayloadRecallBanking
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/banking/recall", payload)
      .then((result: { data: Promise<Response> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const approveBanking = (
  payload: PayloadRecallBanking
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/banking/approved", payload)
      .then((result: { data: Promise<Response> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const cancelBanking = (
  payload: PayloadRecallBanking
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/banking/cancel", payload)
      .then((result: { data: Promise<Response> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const renewBanking = (
  payload: PayloadRecallBanking
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/banking/renew", payload)
      .then((result: { data: Promise<Response> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const cancelApproveBanking = (
  payload: PayloadRecallBanking
): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/banking/cancelapproved", payload)
      .then((result: { data: Promise<Response> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const getBankingApproverList = (): Promise<LoginResponse> =>
  new Promise((resolve, reject) => {
    axios
      .get("/banking/approver")
      .then((result: { data: Promise<LoginResponse> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });

export const previeweMail = (
  payload: PayloadSendApproveBanking
): Promise<ResponseString> =>
  new Promise((resolve, reject) => {
    axios
      .post("/banking/previewemail", payload)
      .then((result: { data: Promise<ResponseString> }) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
