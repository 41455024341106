/* eslint-disable @typescript-eslint/no-explicit-any */
import { Approvers } from "../../interfaces/paymentList.interface";
import { getStatus } from "../../libs/getUserStatus";
import AvaterStatus from "../AvaterStatus";

const Component = ({
  approvers,
  userInfo,
  isBanking,
}: {
  approvers: Approvers[] | null;
  userInfo: any;
  isBanking: boolean;
}): JSX.Element => {
  const lastName = userInfo?.lastName === null ? "" : userInfo?.lastName;
  const lastNameChar =
    lastName === "" ? "" : userInfo?.lastName[0].toUpperCase();
  const newName =
    userInfo === null
      ? ""
      : `${userInfo?.fristName[0].toUpperCase()}${lastNameChar}`;
  let checkConfirm = 0;
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "2px" }}>
      <AvaterStatus status="REQUESTED" name={newName} />
      {approvers?.map(
        ({ isreject, isApprove, approveBy, approvalLevel }, index) => {
          const lastNameApprover =
            approveBy?.lastName === null ? "" : approveBy?.lastName;
          const lastNameApproverChar =
            lastNameApprover?.length === 0
              ? ""
              : lastNameApprover?.[0].toUpperCase();
          if (!isApprove && !isreject) {
            checkConfirm += 1;
          }
          const approverReject = approvers.filter((appr) => {
            return appr.isreject;
          });
          if (approverReject.length > 0) {
            checkConfirm = 0;
          }
          const firstNameApproverChar =
            approveBy?.fristName?.[0]?.toUpperCase();
          if (isBanking) {
            checkConfirm = 1;
          }
          const isAdmin = approvalLevel === 1;
          return (
            <AvaterStatus
              status={getStatus(
                isApprove,
                isreject,
                approvers[index].isReviewer,
                isAdmin,
                checkConfirm === 1
              )}
              name={`${firstNameApproverChar}${lastNameApproverChar}`}
            />
          );
        }
      )}
    </div>
  );
};

export default Component;
