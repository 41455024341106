import { Typography } from "antd";
import { ReactComponent as CheckBadge } from "../../assets/icon/checkBadge.svg";
import { ReactComponent as OkayBadge } from "../../assets/icon/okayBadge.svg";
import { ReactComponent as SentBadge } from "../../assets/icon/sentBadge.svg";
import { ReactComponent as RejectedBadge } from "../../assets/icon/rejectedBadge.svg";
import { ReactComponent as CancelledBadge } from "../../assets/icon/cancelledBadge.svg";
import { ReactComponent as CheckBadgeOrange } from "../../assets/icon/checkBadgeOrange.svg";
import { ReactComponent as OkayBadgeOrange } from "../../assets/icon/okayBadgeOrange.svg";
import { ReactComponent as RejectedBadgeOrange } from "../../assets/icon/rejectedBadgeOrange.svg";
import { ReactComponent as CheckBadgeBronze } from "../../assets/icon/checkBadgeBronze.svg";
import { ReactComponent as OkayBadgeBronze } from "../../assets/icon/okayBadgeBronze.svg";
import { ReactComponent as RejectedBadgeBronze } from "../../assets/icon/rejectedBadgeBronze.svg";

const { Text } = Typography;

type Props = {
  status: string;
  name: string | undefined;
  badge?: string | undefined;
  onlyDrawer?: string | undefined;
};

export const BadgeStatus = (
  status: string,
  nonePosition?: string
): JSX.Element => {
  switch (status) {
    case "REQUESTED":
      return (
        <SentBadge
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "ADMIN PENDING":
      return (
        <OkayBadgeBronze
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "VERIFIED":
      return (
        <CheckBadgeBronze
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "ADMIN REJECTED":
      return (
        <RejectedBadgeBronze
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "REVIEW PENDING":
      return (
        <OkayBadgeOrange
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "REVIEWED":
      return (
        <CheckBadgeOrange
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "REVIEW REJECTED":
      return (
        <RejectedBadge
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "APPROVED":
      return (
        <CheckBadge
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "PENDING":
      return (
        <OkayBadge
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "REJECTED":
      return (
        <RejectedBadge
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "CANCEL APPROVED":
      return (
        <CancelledBadge
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    case "CANCEL REJECTED":
      return (
        <CancelledBadge
          style={{
            position: nonePosition === "none" ? "relative" : "absolute",
            // zIndex: 99,
            bottom: 0,
            right: 0,
          }}
        />
      );
    default:
      return <></>;
  }
};

export const ColorBadge = (status: string): string => {
  switch (status) {
    case "REQUESTED":
      return "#744C3A";
    case "ADMIN":
      return "#643B9F";
    case "ADMIN PENDING":
      return "#643B9F"; // PURPLE
    case "VERIFIED":
      return "#643B9F";
    case "ADMIN REJECTED":
      return "#643B9F";
    case "REVIEW":
      return "#FEC98F";
    case "REVIEW PENDING":
      return "#FEC98F"; // ORANGE
    case "REVIEWED":
      return "#FEC98F";
    case "REVIEW REJECTED":
      return "#D82E18";
    case "APPROVED":
      return "#03703C";
    case "PENDING":
      return "#174291";
    case "REJECTED":
      return "#D82E18";
    case "CANCEL APPROVED":
      return "#D82E18";
    case "CANCEL REJECTED":
      return "#D82E18";
    default:
      return "#757575";
  }
};

const Component = ({ status, name, badge, onlyDrawer }: Props): JSX.Element => {
  return (
    <div style={{ position: "relative", width: "38px", height: "38px" }}>
      <div
        style={{
          display: "flex",
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          backgroundColor: onlyDrawer || ColorBadge(status),
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            display: "",
            color: "white",
          }}
          strong
        >
          {name}
          {/* {status} */}
        </Text>
      </div>
      {badge === "none" ? <></> : BadgeStatus(status)}
    </div>
  );
};

export default Component;

Component.defaultProps = {
  badge: undefined,
  onlyDrawer: undefined,
};
