import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import setting from "../../setting";
import * as accessTokenManager from "../accessTokenManager";
import * as userInforManager from "../userInforManager";
import { msalConfig, silentRequest } from "../azureAd/authConfig";

const instance = axios.create({
  baseURL: setting.API_URL,
});

instance.interceptors.request.use((config) => {
  const token = `${accessTokenManager.get()}`;

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.common.Authorization = `Bearer ${token}`;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest.retry) {
      const msalInstance = new PublicClientApplication(msalConfig);
      const user = userInforManager.get();
      const username = user?.email;
      const currentAccount = msalInstance.getAccountByUsername(username || "");
      silentRequest.account = currentAccount || undefined;
      // const request = {
      //   scopes: ["Mail.Read"],
      //   loginHint: currentAccount?.username,
      // };
      let response;
      try {
        response = await msalInstance.acquireTokenSilent(silentRequest);
      } catch (err) {
        window.location.href = "/login";
        return Promise.reject(err);
      }
      accessTokenManager.set(response.accessToken);
      originalRequest.retry = true;
      originalRequest.headers.Authorization = `Bearer ${response.accessToken}`;
      const res = await instance(originalRequest);
      return res;
    }
    return Promise.reject(error);
  }
);

export default instance;
