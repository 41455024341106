/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  UploadFileRequest,
  UploadFileResponse,
} from "../interfaces/serviceAPI.interface";
import axios from "../libs/axios";

export const uploadFileList = (
  file: UploadFileRequest
): Promise<UploadFileResponse> =>
  new Promise((resolve, reject) => {
    axios
      .post("/service/uploadfile", file)
      .then((result: any) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const syncsap = (): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .get("/service/syncsap")
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
