import { ReactElement } from "react";
import { Modal, Typography } from "antd";
import "./index.css";

const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ComponentProps<T extends unknown> {
  visible: boolean | string;
  setVisible: (val: boolean) => void;
  title: string;
  content: ReactElement;
  footer: ReactElement;
  onCancel?: () => void;
  width?: number;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Component = <T extends object>({
  visible,
  setVisible,
  title,
  content,
  footer,
  onCancel,
  width,
}: ComponentProps<T>): ReactElement => {
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Modal
        title={<Text strong>{title}</Text>}
        visible={visible as boolean}
        footer={footer}
        centered
        onCancel={onCancel || handleCancel}
        className="custom-modal"
        width={width}
      >
        {content}
      </Modal>
    </>
  );
};

export default Component;
