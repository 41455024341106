import { FC, useMemo } from "react";
import { Col, Divider, Row, Typography } from "antd";
// import remove from "lodash/remove";
import formatData from "../../../libs/formatData";
import { RequestListPayment } from "../../../interfaces/payment.interface";
import { FiDocNo } from "../../../enum/fiDocNo.enum";

type Props = {
  data?: RequestListPayment;
};

const Component: FC<Props> = ({ data }: Props) => {
  const dataSource = useMemo(() => {
    return (data?.paymentInfoItem || []).map((item) => {
      let paymentAssignment = "";
      let accountAssignment = "";
      let valueMaterialGroup = "";
      let valueWBS = "";
      let valueOrdernum = "";
      let valueCostCenter = "";
      let valueVendor = "";
      let valueExceptionBudget = "";
      // use paymentText first when not have value set use accountShortText
      const titleDetail =
        item.paymentText !== "" ? item.paymentText : item.accountShortText;
      if (item.paymentAssignment === null) {
        paymentAssignment = "";
      } else {
        paymentAssignment = item.paymentAssignment;
      }
      if (item.accountAssignCode === "" && item.accountAssignCategory === "") {
        accountAssignment = "-";
      } else {
        accountAssignment = `${item.accountAssignCode} ${item.accountAssignCategory}`;
      }

      valueMaterialGroup = item.materailCode ? item.materailCode : "";
      valueMaterialGroup = item.materialGroup
        ? `${valueMaterialGroup} ${item.materialGroup}`
        : valueMaterialGroup;

      valueWBS = item.orderNumberWbs ? item.orderNumberWbs : "";
      valueWBS = item.orderNumberWbsdesc
        ? `${valueWBS} ${item.orderNumberWbsdesc}`
        : valueWBS;
      valueOrdernum = item.orderNumber ? item.orderNumber : "";
      valueOrdernum = item.orderName
        ? `${valueOrdernum} ${item.orderName}`
        : valueOrdernum;

      if (valueWBS === "" && valueOrdernum === "") {
        valueWBS = "-";
      }
      if (item.costCenter && item.costCtname) {
        const intCostCenter = parseInt(item.costCenter, 10).toString();
        valueCostCenter = `${intCostCenter} ${item.costCtname}`;
      }
      if (item.accountNum && item.accountText) {
        // const intCostCenter = parseInt(item.costCenter, 10).toString();
        valueVendor = `${item.accountNum} ${item.accountText}`;
      }

      if (item.skipBudget) {
        const fiDocNo = item.fiDocNo ? item.fiDocNo : "";
        const docYear = item.docYear ? item.docYear : "";
        if (fiDocNo !== "") {
          switch (fiDocNo) {
            case FiDocNo.PAYROLL:
            case FiDocNo.ADVANCE:
            case FiDocNo.REIMBURSE:
            case FiDocNo.PETTY_CASH:
            case FiDocNo.ALLOCATE_EXP:
            case FiDocNo.CASH_DISCOUNT:
              valueExceptionBudget = `${fiDocNo}`;
              break;
            default:
              valueExceptionBudget = `${fiDocNo} / ${docYear}`;
          }
        }
      }
      const result = [
        {
          key: "Title",
          title: titleDetail,
          value: formatData.toCurrency(item.totalValue),
        },
        {
          key: "Assignment",
          title: "Assignment:",
          value: `${paymentAssignment}` || "-",
        },
        {
          key: "AccountAssignment",
          title: "Budget Type:",
          value: `${accountAssignment}`,
        },
        {
          key: "MaterialGroup",
          title: "Expense Type:",
          value: `${valueMaterialGroup}` || "-",
        },
        {
          key: "OrderNumber",
          title: "WBS/Order No:",
          value: valueWBS || valueOrdernum,
        },
        {
          key: "CostCenter",
          title: "Cost Center:",
          value: `${valueCostCenter}` || "-",
        },
        {
          key: "Vendor",
          title: "Vendor:",
          value: `${valueVendor}` || "-",
        },
        {
          key: "fiDocNo",
          title: "Exception Budget:",
          value: `${valueExceptionBudget}` || "-",
        },
      ];

      return result;
    });
  }, [data]);

  return (
    <>
      {dataSource.map((paymentInfo, paymentInfoIndex) => {
        return (
          <>
            {paymentInfo.map((item, index) => (
              <Row
                style={{
                  backgroundColor: index % 2 ? "#F6F6F6" : "#FFFFFF",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                <Col span={2} style={{ textAlign: "center" }}>
                  {index ? null : (
                    <Typography.Text strong>
                      {paymentInfoIndex + 1}
                    </Typography.Text>
                  )}
                </Col>
                <Col span={index ? 8 : 18}>
                  <Typography.Text>{item.title}</Typography.Text>
                </Col>
                <Col span={index ? 14 : 4}>
                  <Typography.Text>{item.value}</Typography.Text>
                </Col>
              </Row>
            ))}

            {dataSource.length - 1 !== paymentInfoIndex && <Divider />}
          </>
        );
      })}
    </>
  );
};

Component.defaultProps = {
  data: {} as RequestListPayment,
};

export default Component;
