import React, { FC, useState, useMemo, useCallback } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Layout, Menu, Image, Dropdown, Avatar, Spin } from "antd";
import {
  CaretDownOutlined,
  LoadingOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { LogoutIcon } from "../components/CustomIcon";
import { getSideMenuItems, HeaderMenu, headerMenus } from "./menu";
import * as actions from "../store/actions";
import useAuthentication from "../hooks/useAuthentication";
import { checkPermission, getPermissionUser } from "../libs/permission";
import "./index.css";

const { Header, Sider, Content, Footer } = Layout;

export interface CurrentPathnameProps {
  primary: string;
  secondary: string;
}

const Component: FC = ({ children }) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const history = useHistory();
  const [collapsed, setCallapsed] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [current, setCurrent] = useState<CurrentPathnameProps>({
    primary: "",
    secondary: "",
  });
  const location = useLocation();
  const auth = useAuthentication();
  const toggle = () => {
    setCallapsed((prev) => !prev);
  };

  const onClickMenuItem = useCallback(
    (path: string) => {
      history.push(`/${path}`);
    },
    [history]
  );

  const menuItems = useMemo(() => {
    return getSideMenuItems({ onItemClick: onClickMenuItem });
  }, [onClickMenuItem]);

  const userPermission = useMemo(() => {
    return getPermissionUser(auth);
  }, [auth]);

  const getPrimaryName = (names: string[]) => {
    if (names[0] === "pr") {
      if (names[1] === "requester") return "prRequester";
      else if (names[1] === "admin") return "prAdmin";
      return "prApprover";
    } else if (names[0] === "banking") {
      if (names[1] === "ceo") return "bankingCEO";
    }
    return names[0];
  };

  const getDefaultSelectedKeyValue = (currentValue: CurrentPathnameProps) => {
    if (currentValue.primary === "prRequester") return ["pr/requester"];
    else if (currentValue.primary === "prApprover") return ["pr/approver"];
    else if (currentValue.primary === "prAdmin") return ["pr/admin"];
    else if (currentValue.primary === "bankingCEO") return ["banking/ceo"];
    return [currentValue.primary];
  };

  const getCurrentSecondary = (names: string[]) => {
    const keyArray = [
      "history",
      "ceo",
      "admin",
      "requester",
      "approver",
      "banking",
    ];

    const key = keyArray.find((item) => names.includes(item));

    return key || "";
  };

  const menuSide = useMemo(() => {
    const userMenu = [...menuItems];
    return userMenu.filter((menu) =>
      checkPermission(userPermission, menu.module)
    );
  }, [menuItems, userPermission]);

  const menuHeader = useMemo(() => {
    const pathname = location.pathname.replace("/", "");
    const names = pathname.split("/");
    const name = getPrimaryName(names);
    setCurrent({
      primary: name,
      secondary: names.length > 1 ? getCurrentSecondary(names) : names[0],
    });
    return headerMenus[name]?.filter((menu: HeaderMenu) => menu.key);
  }, [location]);

  const lastName = auth?.lastName === null ? "" : auth?.lastName;
  const lastNameChar =
    lastName?.length === 0 ? "" : lastName?.[0].toUpperCase();

  const menuUser = (
    <Menu
      style={{
        borderRadius: 10,
      }}
    >
      {/* <Menu.Item style={{ display: "flex", gap: 10 }} icon={<TemplateIcon />}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          My Templates
        </a>
      </Menu.Item> */}
      <Menu.Item
        style={{ display: "flex", gap: 10 }}
        icon={<LogoutIcon />}
        onClick={() => {
          setLoading(true);
          instance
            .logoutPopup()
            .then(() => {
              // setTimeout(() => {
              // console.log("isAuthenticated : ", isAuthenticated);
              // if (!isAuthenticated) {
              dispatch(actions.logout());
              // } else {
              setLoading(false);
              // }
              // }, 1000);
            })
            .catch(() => {
              setLoading(false);
            });
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      spinning={loading}
    >
      <Layout>
        <Header className="layout-container-header">
          <div>
            <MenuOutlined className="hamburger-menu" onClick={toggle} />
            <div className="container-logo-image">
              <Image
                className="logo-image"
                src="https://www.becworld.com/assets/images/logo-bec.png"
                preview={false}
              />
              <div
                style={{
                  color: "#545454",
                  fontSize: "16px",
                  lineHeight: "18px",
                  marginLeft: 10,
                }}
              >
                <span> BEC PR &</span>
                <br />
                <span>Payment Approval</span>
              </div>
            </div>
            <Menu
              defaultSelectedKeys={[current.secondary]}
              mode="horizontal"
              style={{ width: "100%" }}
              forceSubMenuRender={false}
            >
              {menuHeader?.map((menu: HeaderMenu) => (
                <Menu.Item
                  key={menu.key}
                  style={{ display: "flex", gap: 10, alignItems: "center" }}
                  icon={menu.icon}
                >
                  <Link to={`/${menu.path}`}>{menu.label}</Link>
                </Menu.Item>
              ))}
            </Menu>
          </div>
          <div>
            <Dropdown overlay={menuUser} trigger={["click"]}>
              <div className="container-user-info">
                <div className="user-info">
                  <p className="user-info-fullname">
                    {auth?.fristName} {auth?.lastName}
                  </p>
                  <p className="user-info-role">{userPermission.join(" ")}</p>
                </div>
                <Avatar size={40} style={{ backgroundColor: "#545454" }}>
                  {`${auth?.fristName?.[0].toUpperCase()}${lastNameChar}`}
                </Avatar>
                <CaretDownOutlined />
              </div>
            </Dropdown>
          </div>
        </Header>
        <Layout style={{ minHeight: "85vh" }}>
          <Sider
            className="layout-container-sidebar"
            trigger={null}
            collapsible
            collapsed={collapsed}
            collapsedWidth={0}
          >
            <Menu
              className="container-side-navigation"
              mode="inline"
              defaultSelectedKeys={getDefaultSelectedKeyValue(current)}
              items={menuSide}
            />
          </Sider>

          <Content className="container-layout-content">{children}</Content>
        </Layout>
        <Footer className="layout-container-footer">
          Copyright © 2021 BEC World Public Company Limited. All right reserved.
        </Footer>
      </Layout>
    </Spin>
  );
};

export default Component;
