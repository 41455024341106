import { FC, ReactElement } from "react";
import { Drawer } from "antd";
import "./index.css";

type Props = {
  visible: boolean;
  setVisible: (value: boolean) => void;
  title: ReactElement;
  content: ReactElement;
  footer: ReactElement;
};

const Component: FC<Props> = ({
  visible,
  setVisible,
  title,
  content,
  footer,
}: Props) => {
  const onClose = () => {
    setVisible(false);
  };

  return (
    <Drawer
      title={title}
      placement="right"
      width={512}
      onClose={onClose}
      visible={visible}
      className="custom-drawer"
      headerStyle={{
        paddingLeft: 24,
        paddingRight: 24,
      }}
    >
      <div className="custom-drawer-content">{content}</div>
      <div className="custom-drawer-footer">{footer}</div>
    </Drawer>
  );
};

Component.defaultProps = {};

export default Component;
