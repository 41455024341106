/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import qs from "qs";

export const queryParse = (search: string): qs.ParsedQs => {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
    strictNullHandling: true,
  });
};

export const queryStringify = (filterInput: any): string => {
  return qs.stringify(filterInput, {
    skipNulls: true,
  });
};

export const getBooleanFromQuery = (value?: string): boolean | null => {
  if (value) {
    return value === "true";
  }
  return null;
};

export const getQueryObject = (
  filterInput: any,
  search: string
): qs.ParsedQs => {
  if (filterInput) {
    const searchObject = queryParse(search);
    const filterQuery = queryStringify({ ...searchObject, ...filterInput });
    return queryParse(filterQuery);
  }
  return queryParse(search);
};
