import { ColumnsType } from "antd/lib/table";
import { Space, Button, Typography } from "antd";
import {
  InfoCircleOutlined,
  PaperClipOutlined,
  SendOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import DataTable from "../../components/DataTable";
import TitleHeader from "../../components/TitleHeader";
import FilterInputTable from "../../components/FilterInputTable";
import { ReactComponent as TeamLogo } from "../../assets/icon/team.svg";

interface Request {
  id: number;
  dueDate: Date | string;
  company: string;
  purchasingGroup: string;
  prNumber: string;
  poNumber: string;
  fiDocNumber: string;
  prHeaderNote: string;
  accountText: string;
  files: number;
  amount: number;
}

interface IconTypeProps {
  width: number;
  height: number;

  color: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type IconType = (props: IconTypeProps) => JSX.Element;

const Component = (): JSX.Element => {
  const columns: ColumnsType<Request> = [
    {
      title: "Due Date",
      dataIndex: "dueDate",
      render: (date: string) => (
        <Typography.Text>{dayjs(date).format("DD.MM.YYYY")}</Typography.Text>
      ),
      sorter: (a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix(),
    },
    {
      title: "Company",
      dataIndex: "company",
    },
    {
      title: "Purchasing Group",
      dataIndex: "purchasingGroup",
    },
    {
      title: "PR No.",
      dataIndex: "prNumber",
    },
    {
      title: "PO No.",
      dataIndex: "poNumber",
    },
    {
      title: "FI Doc No.",
      dataIndex: "fiDocNumber",
    },
    {
      title: "PR Header Note",
      dataIndex: "prHeaderNote",
    },
    {
      title: "Account Text",
      dataIndex: "accountText",
    },
    {
      title: "Files",
      dataIndex: "files",
      render: (fileNumber) => (
        <Space size="middle">
          <PaperClipOutlined rotate={135} />
          <Typography.Text> {fileNumber}</Typography.Text>
        </Space>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      key: "action",
      render: () => (
        <Space size="middle">
          <Button type="text" icon={<InfoCircleOutlined />} />
          <Button
            type="text"
            icon={<SendOutlined style={{ color: "#174291" }} />}
          />
        </Space>
      ),
    },
  ];
  const data: Array<Request> = [
    {
      id: 1,
      dueDate: "2030-01-25",
      company: "BKK",
      purchasingGroup: "COAFR กิจการสาธารณะ",
      prNumber: "1055098506",
      poNumber: "8820211120",
      fiDocNumber: "33000004",
      prHeaderNote: "ขออนุมัติค่าใช้จ่ายในการบำรุงรักษาซอฟต์แวร์สำหรับแผนกไอที",
      accountText: "บจม.เทอร์ราไบท์ เน็ท โซลูชั่น",
      files: 1,
      amount: 10000,
    },
  ];

  const selectData = ["CEO", "Admin", "General"];
  const company = ["BECI", "BECW", "BECT", "BKK"];
  // const TeamIcon = (props: IconType) => (
  //   <Icon component={teamIcon} {...props} />
  // );

  return (
    <div style={{ padding: 20 }}>
      <TitleHeader title="Users" dataSizes={4} />
      <FilterInputTable
        setSearchText={() => {}}
        selectItems={[
          {
            icon: TeamLogo,
            name: "Role",
            placeholder: "Role",
            dataItems: selectData,
            span: 1,
          },
          {
            icon: TeamLogo,
            name: "company",
            placeholder: "Company",
            dataItems: company,
            span: 1,
          },
          {
            icon: TeamLogo,
            name: "purchasingGroup",
            placeholder: "Purchasing Group",
            dataItems: company,
            span: 1,
          },
        ]}
      />
      <DataTable setSearchText={() => {}} columns={columns} data={data} />
    </div>
  );
};

export default Component;
