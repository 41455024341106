/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Col, Row } from "antd";
import { PRMemoResponseLists } from "../../../interfaces/prmemo.interface";

type Props = {
  data?: any;
};

const Component: React.FC<Props> = ({ data }: Props) => {
  return (
    <div className="tab-header-content">
      <Row key="payNumber">
        <Col span={8}>Request No:</Col>
        <Col span={16}>{data?.payNumber}</Col>
      </Row>
      <Row key="docType">
        <Col span={8}>Document Type:</Col>
        <Col span={16}>{data?.docType || "-"}</Col>
      </Row>
      <Row key="createBy">
        <Col span={8}>Requester:</Col>
        <Col span={16}>{`${data?.createBy.email}` || "-"}</Col>
      </Row>
      <Row key="emailInfo">
        <Col span={8}>E-mail Info:</Col>
        <Col span={16}>{`${data?.emailInfo}` || "-"}</Col>
      </Row>
      <Row key="prHeaderNote">
        <Col span={8}>Header Note:</Col>
        <Col span={16} className="new-line">
          {`${data?.prHeaderNote}` || "-"}
        </Col>
      </Row>
    </div>
  );
};

Component.defaultProps = {
  data: {} as PRMemoResponseLists,
};

export default Component;
