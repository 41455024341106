import {
  Configuration,
  PopupRequest,
  SilentRequest,
} from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_AD_CLIENT_ID}`,
    authority: `${process.env.REACT_APP_AZURE_AD_AUTHORITY}`,
    redirectUri: `${process.env.REACT_APP_AZURE_AD_REDIRECT_URL}`,
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};

export const silentRequest: SilentRequest = {
  scopes: ["User.Read"],
  forceRefresh: false,
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: `${process.env.REACT_APP_AZURE_AD_GRAPH}`,
};
