import { User } from "../../interfaces/user.interface";

export const checkPermission = (
  userPermission: string[],
  module: string
): boolean => {
  return userPermission.includes(module);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getPermissionUser = (user: User | undefined) => {
  const permisssion = user?.functionData || [];
  return permisssion;
};
