/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Typography, Spin, Row, Tabs } from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import moment from "moment";
import TitleHeader from "../../components/TitleHeader";
import FilterInputTable from "../../components/FilterInputTable";
import { CustomDrawer } from "../../components/Drawer";
import { TabsHeader, TabsDetail } from "../../components/Drawer/TabsMemo";
import { TabsApprove, TabsAttached } from "../../components/Drawer/Tabs";
import { ReactComponent as TeamLogo } from "../../assets/icon/team.svg";
import { ReactComponent as CompanyLogo } from "../../assets/icon/company.svg";
import { getPaymentListMemoAdminHistory } from "../../api/paymentList";
import { Company, PurchasingGroup } from "../../interfaces/master.interface";
import { getMasterCompany, getMasterPurchasingGroup } from "../../api/master";
import CustomModal from "../../components/CustomModal";
import useAuthentication from "../../hooks/useAuthentication";
import DataTable from "../../components/DataTable";
import formatData from "../../libs/formatData";
import { renderPRAdminHistoryListColumns } from "./columns";
import { getBooleanFromQuery, getQueryObject } from "../../libs/query";
import useParseQuery from "../../hooks/useParseQuery";
import { mapMemoDataSource } from "../../libs/array/mappingPaymentList";
import { docType } from "../../libs/antd/options/optionsValue";
import useOptions from "../../hooks/useOptions";
import { sendAdminRecall } from "../../api/prmemo";
import {
  PRMemoResponseLists,
  ResponseMemoList,
} from "../../interfaces/prmemo.interface";
import { ReCallIcon } from "../../components/CustomIcon";
import setting from "../../setting";

dayjs.extend(isBetween);

const Component = (): JSX.Element => {
  const auth = useAuthentication();
  const location = useLocation();
  const [initial, setInital] = useState(true);
  const [dataSource, setDataSource] = useState<PRMemoResponseLists[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [variablePage, setVariablePage] = useState<{
    page: number;
    perPage: number;
  }>({ page: 1, perPage: 10 });
  const [visibleSingleModal, setVisibleSingleModal] = useState<boolean>(false);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [selectRequestListPayment, setSelectRequestListPayment] =
    useState<PRMemoResponseLists>({} as PRMemoResponseLists);
  const [dataSourcePurchasingGroup, setDataSourcePurchasingGroup] = useState<
    PurchasingGroup[]
  >([]);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);

  const [timeUpdate, setTimeUpdate] = useState<string>();
  const [searchText, setSearchText] = useState("");
  const [filterInput, setFilterInput] = useState<any | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [onTableSelect, setOnTableSelect] = useState<React.Key[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saveFileList, setSaveFileList] = useState<any[]>([]);

  const { company, purchasingGroup } = useOptions({
    dataSourceCompany,
    dataSourcePurchasingGroup,
  });

  const {
    querypurgeid,
    querycompid,
    querydate,
    querydatetype,
    querysearch,
    querydoctype,
    replaceLocation,
  } = useParseQuery();

  const columns: ColumnsType<PRMemoResponseLists> =
    renderPRAdminHistoryListColumns({
      searchText,
      setVisibleDrawer,
      setVisibleSingleModal,
      setSelectRequestListPayment,
      setLoading,
    });

  const requestPayload = useMemo(() => {
    return {
      activityState: null,
      dueDate: null,
      documentDate: null,
      postingDate: null,
      isUrgent: null,
      isOverdue: null,
      compId: null,
      purgId: null,
      isImmediately: null,
      apprType: "PR",
      isSendToAdmin: true,
      page: 1,
      perPage: 10,
    };
  }, []);

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([
        getMasterPurchasingGroup(auth.userId),
        getMasterCompany(auth.userId),
      ])
        .then(([responsePurchasing, responseCompany]) => {
          setDataSourcePurchasingGroup(responsePurchasing.responseData);
          setDataSourceCompany(responseCompany.responseData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  useEffect(() => {
    const queryToObject = getQueryObject(filterInput, location.search);

    const pagination = variablePage;

    if (initial) {
      pagination.page = Number(queryToObject?.page || 1);
      pagination.perPage = Number(queryToObject?.perPage || 10);
    }

    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      setLoading(true);

      const filterValidated = {
        ...queryToObject,
        isNonPo: getBooleanFromQuery(queryToObject?.isNonPo as string),
        compId: Number(queryToObject?.compId),
        purgId: Number(queryToObject?.purgId),
      };

      Promise.all([
        getPaymentListMemoAdminHistory({
          sendApproveBy: "",
          waitApproveBy: `${auth.userId}`,
          search: `${searchText}`,
          ...requestPayload,
          ...filterValidated,
          ...pagination,
        }),
      ])
        .then(([responseAdminList]) => {
          setDataSource(
            mapMemoDataSource(responseAdminList as ResponseMemoList)
          );
          setTotalItem(responseAdminList.total);
          setTimeUpdate(moment().format());
        })
        .finally(() => {
          replaceLocation({
            ...filterInput,
            ...pagination,
          });
          setInital(false);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, variablePage]);

  useEffect(() => {
    if (!initial) {
      replaceLocation({
        ...filterInput,
        page: 1,
        perPage: variablePage.perPage,
      });

      setVariablePage({
        page: 1,
        perPage: variablePage.perPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput]);

  const onSyncDataSource = useCallback(() => {
    const queryToObject = getQueryObject(filterInput, location.search);
    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      const filterValidated = {
        ...queryToObject,
        isNonPo: getBooleanFromQuery(queryToObject?.isNonPo as string),
        compId: Number(queryToObject?.compId),
        purgId: Number(queryToObject?.purgId),
      };
      setLoading(true);
      Promise.all([
        getPaymentListMemoAdminHistory({
          sendApproveBy: "",
          waitApproveBy: `${auth.userId}`,
          search: `${searchText}`,
          activityState: null,
          compId: null,
          purgId: null,
          isImmediately: null,
          isOverdue: false,
          isUrgent: false,
          isCancelable: false,
          dueDate: null,
          documentDate: null,
          postingDate: null,
          apprType: "PR",
          isSendToAdmin: true,
          ...variablePage,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(
            mapMemoDataSource(responsePaymentList as ResponseMemoList)
          );
          setTotalItem(responsePaymentList.total);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log("Error : ", error);
        })
        .finally(() => {
          setLoading(false);
          setTimeUpdate(moment().format());
        });
    }
  }, [filterInput, location.search, auth, searchText, variablePage]);

  const onSendReCallPaymentList = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        sendAdminRecall({
          payId: payIds,
          userId: auth?.userId.toString() || "",
          reason: "",
        }),
      ]).then(() => {
        setDataSource(
          dataSource.filter((item) => !payIds.includes(item.payId))
        );
        setVisibleDrawer(false);
      });
    },
    [auth, dataSource]
  );

  const titleDrawer = useMemo(() => {
    return (
      <Row align="middle">
        <InfoCircleOutlined />
        <Typography.Text style={{ paddingLeft: 10 }}>
          Information
        </Typography.Text>
      </Row>
    );
  }, []);

  const contentDrawer = useMemo(() => {
    return (
      <>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Headers" key="1">
            <TabsHeader data={selectRequestListPayment} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Details" key="2">
            <TabsDetail
              data={selectRequestListPayment}
              dataCompany={dataSourceCompany}
              dataPurGroup={dataSourcePurchasingGroup}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Attacheds" key="3">
            <TabsAttached
              data={selectRequestListPayment?.paymentInfoAttach || []}
              disabled
              setSaveFileList={setSaveFileList}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Approval Status" key="4">
            <TabsApprove
              payId={selectRequestListPayment.payId}
              userInfo={selectRequestListPayment?.sendApproveBy}
              sendApproveDate={selectRequestListPayment?.sendApproveDate}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }, [selectRequestListPayment, dataSourceCompany, dataSourcePurchasingGroup]);

  const footerDrawer = useMemo(() => {
    return (
      <div className="drawer-footer">
        <Row justify="space-between" align="middle">
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            Amount ({selectRequestListPayment.currency}):
          </Typography.Title>
          <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            <Typography.Text style={{ color: "#174291", paddingLeft: 5 }}>
              {formatData.toCurrency(selectRequestListPayment.amount)}
            </Typography.Text>
          </Typography.Title>
        </Row>
        <Row justify="start" align="middle">
          {selectRequestListPayment.approvers &&
            !selectRequestListPayment.approvers[0].isApprove &&
            !selectRequestListPayment.approvers[0].isreject && (
              <Button
                loading={loading}
                icon={<ReCallIcon />}
                style={{
                  display: "flex",
                  background: "#545454",
                  alignItems: "center",
                  padding: 20,
                  gap: 10,
                }}
                onClick={() => setVisibleSingleModal(true)}
              >
                <Typography.Text strong style={{ color: "white" }}>
                  Recall Request
                </Typography.Text>
              </Button>
            )}
        </Row>
      </div>
    );
  }, [loading, selectRequestListPayment]);

  const contentSingleModal = useMemo(() => {
    const prHeaderNote = selectRequestListPayment?.prHeaderNote;

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <Typography.Text className="new-line">
          Recall Request : {prHeaderNote}
        </Typography.Text>
      </Spin>
    );
  }, [loading, selectRequestListPayment]);

  const footerSingleModal = useMemo(() => {
    return (
      <Row justify="end">
        <Button loading={loading} onClick={() => setVisibleSingleModal(false)}>
          Cancel
        </Button>
        <Button
          style={{
            background: "#545454",
          }}
          loading={loading}
          onClick={() => {
            setLoading(true);
            onSendReCallPaymentList([selectRequestListPayment.payId])?.finally(
              () => {
                setLoading(false);
                setVisibleSingleModal(false);
              }
            );
          }}
        >
          <Typography.Text style={{ color: "white" }}>Recall</Typography.Text>
        </Button>
      </Row>
    );
  }, [loading, onSendReCallPaymentList, selectRequestListPayment.payId]);

  return (
    <div style={{ padding: 20 }}>
      <TitleHeader
        title="PR Admin Request History"
        loading={loading}
        dataSizes={totalItem}
        timeUpdate={timeUpdate}
        onSyncDataSource={onSyncDataSource}
      />
      <FilterInputTable
        isMemo
        onTableSelect={onTableSelect}
        setFilterInput={setFilterInput}
        querysearch={querysearch}
        queryDateType={querydatetype}
        queryDate={querydate}
        selectItems={[
          {
            icon: CompanyLogo,
            name: "isNonPo",
            placeholder: "Document Type",
            dataItems: docType,
            itemIndex: querydoctype,
            span: 3,
          },
          {
            icon: TeamLogo,
            name: "purgId",
            placeholder: "Purchasing Group",
            dataItems: purchasingGroup,
            itemIndex: querypurgeid,
            span: 3,
          },
          {
            icon: CompanyLogo,
            name: "compId",
            placeholder: "Company",
            dataItems: company,
            itemIndex: querycompid,
            span: 3,
          },
        ]}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <DataTable
        data={dataSource}
        checkBox
        loading={loading}
        columns={columns}
        totalItem={totalItem}
        searchText={searchText}
        setSearchText={setSearchText}
        variablePage={variablePage}
        setVariablePage={setVariablePage}
      />
      {visibleDrawer ? (
        <CustomDrawer
          visible={visibleDrawer}
          setVisible={setVisibleDrawer}
          title={titleDrawer}
          content={contentDrawer}
          footer={footerDrawer}
        />
      ) : null}
      {visibleSingleModal ? (
        <CustomModal
          key="SingleModal"
          visible={visibleSingleModal}
          setVisible={setVisibleSingleModal}
          title="Confirm to Recall"
          content={contentSingleModal}
          footer={footerSingleModal}
        />
      ) : null}
    </div>
  );
};

export default Component;
