/* eslint-disable @typescript-eslint/no-explicit-any */
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Spin, Typography } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { getHistoryPayId } from "../../../api/history";
import { Payment } from "../../../interfaces/payment.interface";
// import { Approvers } from "../../../interfaces/paymentList.interface";
import { getStatus } from "../../../libs/getUserStatus";
import AvaterStatus, { BadgeStatus, ColorBadge } from "../../AvaterStatus";
import SentBadgeIcon from "../../CustomIcon/SentBadgeIcon";
import CustomModal from "../../CustomModal";

const { Text } = Typography;

interface Props {
  payId: number;
  userInfo: any;
  sendApproveDate: any;
}

const Component = ({
  userInfo,
  sendApproveDate,
  payId,
}: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [visibleSingleModal, setVisibleSingleModal] = useState<boolean>(false);
  const [nameHistoryLog, setNameHistoryLog] = useState<any>();
  const [historyLog, setHistoryLog] = useState<Payment[]>([]);
  const [approveLog, setApproveLog] = useState<any[] | undefined>([]);
  const lastName = userInfo?.lastName === null ? "" : userInfo?.lastName;
  const lastNameChar =
    lastName === "" ? "" : userInfo?.lastName[0].toUpperCase();
  const newName =
    userInfo === null
      ? ""
      : `${userInfo?.fristName[0].toUpperCase()}${lastNameChar}`;
  let checkConfirm = 0;
  let checkConfirmModal = 0;
  useEffect(() => {
    setLoading(true);
    getHistoryPayId(payId)
      .then(({ responseData }) => {
        const data = responseData;
        setHistoryLog(data as Payment[]);
      })
      .finally(() => setLoading(false));
  }, [payId]);

  const CheckDatewithStatus = ({
    isreject,
    isApprove,
    approveDate,
    checkConfirms,
  }: {
    isreject: boolean;
    isApprove: boolean;
    approveDate: string;
    checkConfirms: number;
  }): JSX.Element => {
    switch (getStatus(isApprove, isreject, false, false, checkConfirms === 1)) {
      case "APPROVED":
        return (
          <>
            <div>{moment(approveDate).format("DD.MM.YYYY")}</div>
            <div style={{ textAlign: "center" }}>
              {moment(approveDate).format("HH:mm")}
            </div>
          </>
        );
      case "REJECTED":
        return (
          <>
            <div>{moment(approveDate).format("DD.MM.YYYY")}</div>
            <div style={{ textAlign: "center" }}>
              {moment(approveDate).format("HH:mm")}
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const contentSingleModal = useMemo(() => {
    return (
      <>
        {approveLog?.map(
          ({
            isreject,
            isApprove,
            reason,
            updateDate,
            isCancelApprove,
            isCancelReject,
            isReviewer,
          }) => {
            let tmpStatus = getStatus(
              isApprove,
              isreject,
              isReviewer,
              false,
              false
            );
            if (tmpStatus === "REVIEW") {
              if (isCancelApprove) {
                tmpStatus = "CANCEL APPROVED";
              }
              if (isCancelReject) {
                tmpStatus = "CANCEL REJECTED";
              }
            }
            if (tmpStatus === "pending2") {
              if (isCancelApprove) {
                tmpStatus = "CANCEL APPROVED";
              }
              if (isCancelReject) {
                tmpStatus = "CANCEL REJECTED";
              }
            }
            const status = tmpStatus;

            if (!isApprove && !isreject) {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              checkConfirmModal += 1;
            }
            const approverReject = historyLog[0].approvers.filter((appr) => {
              return appr.isreject;
            });
            if (approverReject.length > 0) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              checkConfirmModal = 0;
            }

            const lastNameApprover =
              nameHistoryLog?.lastName === null ? "" : nameHistoryLog?.lastName;
            const lastNameApproverChar =
              lastNameApprover?.length === 0
                ? ""
                : lastNameApprover?.[0].toUpperCase();

            return (
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    width: "2px",
                    backgroundColor: "#E2E2E2",
                    height: "50px",
                    top: "-28px",
                    left: "15px",
                    position: "absolute",
                    zIndex: -55,
                  }}
                />
                <div
                  style={{
                    margin: "14px 0",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>
                      <AvaterStatus
                        status={status}
                        name={`${nameHistoryLog?.fristName?.[0]?.toUpperCase()}${lastNameApproverChar}`}
                        badge="none"
                        onlyDrawer={isReviewer ? "#FEC98F" : undefined}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "5px",
                      }}
                    >
                      <div>
                        <Text>{`${nameHistoryLog.fristName} ${lastNameApprover}`}</Text>
                      </div>
                      <div style={{ display: "flex" }}>
                        {BadgeStatus(status, "none")}
                        <Text
                          strong
                          style={{
                            color: ColorBadge(status),
                            paddingLeft: "5px",
                          }}
                        >
                          {status}
                        </Text>
                      </div>
                      <div style={{ width: "330px" }}>
                        <Text strong>
                          {status === "REJECTED" ||
                          status === "REVIEW REJECTED" ? (
                            <Text>{reason}</Text>
                          ) : (
                            <Text>{reason}</Text>
                          )}
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <CheckDatewithStatus
                      isApprove={isApprove}
                      isreject={isreject}
                      approveDate={
                        (approveDate || rejectDate || updateDate) as string
                      }
                      checkConfirms={checkConfirmModal}
                    /> */}
                    <>
                      <div>{moment(updateDate).format("DD.MM.YYYY")}</div>
                      <div style={{ textAlign: "center" }}>
                        {moment(updateDate).format("HH:mm")}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            );
          }
        )}
      </>
    );
  }, [approveLog]);

  return (
    <Spin spinning={loading} style={{ height: "100px" }}>
      {historyLog.length !== 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0 24px",
          }}
        >
          <div
            style={{
              margin: "14px 0",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <AvaterStatus
                  status="REQUESTED"
                  name={newName}
                  badge="none"
                  onlyDrawer="#744C3A"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "5px",
                }}
              >
                <Text>{userInfo?.displayName}</Text>
                <div style={{ display: "flex" }}>
                  <SentBadgeIcon color="#744C3A" />
                  <Text
                    strong
                    style={{
                      color: "#744C3A",
                      paddingLeft: "5px",
                    }}
                  >
                    RELEASED
                  </Text>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>{moment(sendApproveDate).format("DD.MM.YYYY")}</div>
              <div style={{ textAlign: "center" }}>
                {moment(sendApproveDate).format("HH:mm")}
              </div>
            </div>
          </div>
          {historyLog[0].approvers?.map(
            ({
              isreject,
              isApprove,
              isReviewer,
              approveDate,
              rejectDate,
              approveBy,
              reason,
              approversLog,
              approvalLevel,
            }) => {
              if (!isApprove && !isreject) {
                checkConfirm += 1;
              }
              const approverReject = historyLog[0].approvers.filter((appr) => {
                return appr.isreject;
              });
              if (approverReject.length > 0) {
                checkConfirm = 0;
              }

              const lastNameApprover =
                approveBy?.lastName === null ? "" : approveBy?.lastName;
              const lastNameApproverChar =
                lastNameApprover?.length === 0
                  ? ""
                  : lastNameApprover?.[0].toUpperCase();
              const firstNameApproverChar =
                approveBy?.fristName?.[0]?.toUpperCase();
              // if (isReviewer) {
              //   firstNameApproverChar = "";
              //   lastNameApproverChar = "Asst.";
              // }
              const isAdmin = approvalLevel === 1;
              return (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      width: "2px",
                      backgroundColor: "#E2E2E2",
                      height: "50px",
                      top: "-28px",
                      left: "15px",
                      position: "absolute",
                      zIndex: -55,
                    }}
                  />
                  <div
                    style={{
                      margin: "14px 0",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div>
                        <AvaterStatus
                          status={getStatus(
                            isApprove,
                            isreject,
                            isReviewer,
                            isAdmin,
                            checkConfirm === 1
                          )}
                          name={`${firstNameApproverChar}${lastNameApproverChar}`}
                          badge="none"
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "5px",
                        }}
                      >
                        <div>
                          <Text>{`${approveBy.fristName} ${lastNameApprover}`}</Text>
                          {approversLog === undefined ||
                          approversLog === null ||
                          approversLog?.length <= 1 ? (
                            <></>
                          ) : (
                            <Button
                              onClick={() => {
                                setNameHistoryLog(approveBy);
                                setApproveLog(approversLog);
                                setVisibleSingleModal(true);
                              }}
                              style={{ paddingLeft: "10px" }}
                              type="text"
                              icon={<InfoCircleOutlined />}
                            />
                          )}
                        </div>
                        <div style={{ display: "flex" }}>
                          {BadgeStatus(
                            getStatus(
                              isApprove,
                              isreject,
                              isReviewer,
                              isAdmin,
                              checkConfirm === 1
                            ),
                            "none"
                          )}
                          <Text
                            strong
                            style={{
                              color: ColorBadge(
                                getStatus(
                                  isApprove,
                                  isreject,
                                  isReviewer,
                                  isAdmin,
                                  checkConfirm === 1
                                )
                              ),
                              paddingLeft: "5px",
                              width: "300px",
                            }}
                          >
                            {getStatus(
                              isApprove,
                              isreject,
                              isReviewer,
                              isAdmin,
                              checkConfirm === 1
                            ) === "pending2" ||
                            getStatus(
                              isApprove,
                              isreject,
                              isReviewer,
                              isAdmin,
                              checkConfirm === 1
                            ) === "REVIEW"
                              ? ""
                              : getStatus(
                                  isApprove,
                                  isreject,
                                  isReviewer,
                                  isAdmin,
                                  checkConfirm === 1
                                )}

                            {getStatus(
                              isApprove,
                              isreject,
                              isReviewer,
                              isAdmin,
                              checkConfirm === 1
                            ) === "REJECTED" ? (
                              <Text style={{ paddingLeft: "5px" }}>
                                {reason}
                              </Text>
                            ) : (
                              <Text style={{ paddingLeft: "5px" }}>
                                {reason}
                              </Text>
                            )}
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CheckDatewithStatus
                        isApprove={isApprove}
                        isreject={isreject}
                        approveDate={(approveDate || rejectDate) as string}
                        checkConfirms={checkConfirm}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      ) : (
        <div style={{ height: "100px" }} />
      )}
      <CustomModal
        key="Approvers Log"
        visible={visibleSingleModal}
        setVisible={setVisibleSingleModal}
        title="Approvers Log"
        content={contentSingleModal}
        footer={
          <>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={() => setVisibleSingleModal(false)}
            >
              Close
            </Button>
          </>
        }
      />
    </Spin>
  );
};

export default Component;
