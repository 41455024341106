import React from "react";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import { RequestListPayment } from "../../../interfaces/payment.interface";

type Props = {
  data?: RequestListPayment;
};

const Component: React.FC<Props> = ({ data }: Props) => {
  const formatDate = (date: string | undefined) => {
    if (!date) {
      return "-";
    }

    return dayjs(date).format("DD.MM.YYYY");
  };

  const invoiceDate = data?.invoiceDate ? data?.invoiceDate : "";
  const documentDate = data?.documentDate ? data?.documentDate : "";
  const docinvDate = data?.isImmediately ? invoiceDate : documentDate;
  const emailInfo = data?.emailInfo === null ? "" : data?.emailInfo;
  return (
    <div className="tab-header-content">
      <Row key="payNumber">
        <Col span={8}>Pay Request No:</Col>
        <Col span={16}>{data?.payNumber}</Col>
      </Row>
      <Row key="dueDate">
        <Col span={8}>Due Date:</Col>
        <Col span={16}>{formatDate(data?.dueDate) || "-"}</Col>
      </Row>
      <Row key="postingDate">
        <Col span={8}>Posting Date:</Col>
        <Col span={16}>{formatDate(data?.postingDate) || "-"}</Col>
      </Row>
      <Row key="documentDate">
        <Col span={8}>Document Date:</Col>
        <Col span={16}>{formatDate(docinvDate) || "-"}</Col>
      </Row>
      <Row key="company">
        <Col span={8}>Company:</Col>
        <Col span={16}>{`${data?.company}` || "-"}</Col>
      </Row>
      <Row key="purGroup">
        <Col span={8}>Purchasing Group:</Col>
        <Col span={16}>{`${data?.purGroup}` || "-"}</Col>
      </Row>
      <Row key="prNo">
        <Col span={8}>PR No:</Col>
        <Col span={16}>{`${data?.prNo}` || "-"}</Col>
      </Row>
      <Row key="poNo">
        <Col span={8}>PO No:</Col>
        <Col span={16}>{`${data?.poNo}` || "-"}</Col>
      </Row>
      <Row key="paymentDesc">
        <Col span={8}>Payment Description:</Col>
        <Col span={16}>{data?.paymentDesc}</Col>
      </Row>
      <Row key="accountText">
        <Col span={8}>Vendor:</Col>
        <Col span={16}>{`${data?.accountText}` || "-"}</Col>
      </Row>
      <Row key="fiDocNo">
        <Col span={8}>FI Doc No:</Col>
        <Col span={16}>{`${data?.fiDocNo}` || "-"}</Col>
      </Row>
      <Row key="taxCode">
        <Col span={8}>Tax Code:</Col>
        <Col span={16}>{`${data?.taxCode}` || "-"}</Col>
      </Row>
      <Row key="assigment">
        <Col span={8}>Assignment:</Col>
        <Col span={16}>{`${data?.assigment}` || "-"}</Col>
      </Row>
      <Row key="paymentTerm">
        <Col span={8}>Payment Term:</Col>
        <Col span={16}>
          {`${data?.paymentTerm || ""} ${data?.paymentText || ""}`}
        </Col>
      </Row>
      <Row key="reference">
        <Col span={8}>Reference:</Col>
        <Col span={16}>{`${data?.reference}` || "-"}</Col>
      </Row>
      <Row key="emailInfo">
        <Col span={8}>Email Info:</Col>
        <Col span={16}>{`${emailInfo}` || "-"}</Col>
      </Row>
    </div>
  );
};

Component.defaultProps = {
  data: {} as RequestListPayment,
};

export default Component;
