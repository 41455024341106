import React from "react";
import { Form, Input, Button } from "antd";
import "./index.css";

const Component = (): JSX.Element => {
  const [form] = Form.useForm();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = (value: any) => {
    // eslint-disable-next-line no-console
    console.log(value);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="Configurations">
      <h2>Configurations</h2>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="Rejected"
          label="Rejected Cancelable (Days)"
          extra="จำนวนวันสำหรับ Approver ที่ยังสามารถกด Cancel to Rejected"
        >
          <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
          name="Remind"
          label="Remind Overdue (Days)"
          extra="จำนวนวันที่ต้องการแสดงสถานะ Overdue ก่อนถึงกำหนดวันที่ Due Date"
        >
          <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
          name="MaxFile"
          label="Max. File Attached (MB)"
          extra="ขนาดสูงสุดของ Attached ที่สามารถอัปโหลดได้ต่อ 1 คำร้อง"
        >
          <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item
          name="DailyUpdate"
          label="Daily Data Update"
          extra="ช่วงเวลาที่ระบบจะทำการอัปเดต Request Lists จากระบบ SAP"
        >
          <Input placeholder="input placeholder" />
        </Form.Item>
        <Form.Item style={{ marginTop: "56px" }}>
          <Button
            style={{ marginRight: "12px" }}
            type="primary"
            htmlType="submit"
          >
            Save Changes
          </Button>
          <Button onClick={onReset}>Reset</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Component;
