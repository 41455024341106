const Component = (): JSX.Element => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span style={{ fontSize: 100, fontWeight: "bolder" }}>404</span>
      <span style={{ fontSize: 25, fontWeight: "bold", textAlign: "center" }}>
        We can&apos;t find the page you&apos;re looking for.
      </span>
    </div>
  );
};

export default Component;
