import { User } from "../../interfaces/user.interface";

export const get = (): User | null => {
  return JSON.parse(window.localStorage.getItem("user") as string) || null;
};

export const set = (value: User | undefined): void => {
  return window.localStorage.setItem("user", JSON.stringify(value));
};

export const clear = (): void => {
  window.localStorage.clear();
};
