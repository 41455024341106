/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosError } from "axios";
import { message } from "antd";
import axios from "../libs/axios";
import {
  PRMemoList,
  PRMemoResponseLists,
  ResponseMemoList,
  SendToAdmin,
  SendAdminApprove,
  CheckAmount,
  MemoForImmediately,
  ResponseCheckAmountMemo,
  SendToPayment,
} from "../interfaces/prmemo.interface";
import {
  PayloadPreviewPaymentList,
  ResponseRequestList,
} from "../interfaces/paymentList.interface";

export const submitMemoNonPO = (
  payload: PRMemoList
): Promise<PRMemoResponseLists> =>
  new Promise((resolve, reject) => {
    axios
      .post("approval/paymentlist/memo", payload)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const submitMemoWithPO = (
  payload: PRMemoList
): Promise<PRMemoResponseLists> =>
  new Promise((resolve, reject) => {
    axios
      .post("approval/paymentlist/memo", payload)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const sendToAdmin = (data: SendToAdmin): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/memo/sendadmin", data)
      .then((result) => {
        message.success(`ส่งเอกสารให้ Admin สำเร็จ`, 10);
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const sendBack = (data: SendToAdmin): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/memo/sendback", data)
      .then((result) => {
        message.success(`ส่งเอกสารกลับสำเร็จ`, 10);
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const sendToPayment = (data: SendToPayment): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/memo/sendpayment", data)
      .then((result) => {
        message.success(`ส่งเอกสารเพื่อทำจ่ายสำเร็จ`, 10);
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const getMemoDetailForAdmin = (
  payId: string
): Promise<ResponseMemoList> =>
  new Promise((resolve, reject) => {
    axios
      .get(`approval/paymentlist/admin/memo/${payId}`)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const saveMemoDetailForAdmin = (
  payload: any
): Promise<ResponseMemoList> =>
  new Promise((resolve, reject) => {
    axios
      .post(`approval/paymentlist/admin/memo/`, payload)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });
export const getPaymentListMemoPreview = (
  payload: PayloadPreviewPaymentList
): Promise<Blob> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/memo/preview", payload, {
        responseType: "blob",
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        reject(error);
      });
  });
export const getPaymentListMemoPrint = (
  payload: PayloadPreviewPaymentList
): Promise<Blob> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/memo/print", payload, {
        responseType: "blob",
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        message.error(`ไม่สามารถพิมพ์เอกสารได้มากกว่า 3 ครั้ง `, 10);
        reject(error);
      });
  });
export const sendAdminApprove = (data: SendAdminApprove): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/admin/approve", data)
      .then((result) => {
        message.success(`ส่งขออนุมัติสำเร็จ`, 10);
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const sendAdminRecall = (data: SendAdminApprove): Promise<Response> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/admin/recall", data)
      .then((result) => {
        message.success(`ส่งขอ Recall สำเร็จ`, 10);
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const checkAmountMemo = (
  data: CheckAmount
): Promise<ResponseCheckAmountMemo> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/memo/checkamount", data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });

export const getImmediateFromMemo = (
  data: MemoForImmediately
): Promise<ResponseRequestList> =>
  new Promise((resolve, reject) => {
    axios
      .post("/approval/paymentlist/history/memo/immediately", data)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 400) {
          message.error(`${error.response?.data.error} `, 10);
        } else {
          message.error(
            `เกิดข้อผิดพลาด โปรดลองใหม่อีกครั้งหรือติดต่อเจ้าหน้าที่`,
            10
          );
        }
        reject(error);
      });
  });
