import useAuthentication from "../../hooks/useAuthentication";
import { Approvers, UpdateByWho } from "../../interfaces/payment.interface";

export const cancelRequest = (updateByWho: UpdateByWho): boolean => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const auth = useAuthentication();
  const userIndex = auth && parseInt(auth.userId, 10);
  const updateBy = updateByWho && updateByWho.userId;
  if (userIndex === updateBy) {
    return true;
  }
  return false;
};

export const CancelByApproval = (
  approvalList: Approvers[] | undefined,
  _pvDoc: string | undefined
): boolean => {
  const auth = useAuthentication();
  if (approvalList === undefined) {
    return false;
  }
  const indexUser = approvalList?.findIndex(
    (element) =>
      element.approveBy.userId === (auth && parseInt(auth.userId, 10))
  );
  if (indexUser === approvalList.length - 1) {
    if (_pvDoc !== null && _pvDoc !== "") return false;
    return true;
  }
  if (
    !approvalList[indexUser + 1].isApprove &&
    !approvalList[indexUser + 1].isreject &&
    !(!approvalList[indexUser].isApprove && !approvalList[indexUser].isreject)
  )
    return true;
  return false;
};

export const CancelByRow = (
  approvalList: Approvers[] | undefined,
  authUserId: number | 0
): boolean => {
  if (approvalList === undefined) {
    return false;
  }
  const indexUser = approvalList?.findIndex(
    (element) => element.approveBy.userId === authUserId
  );
  if (indexUser < approvalList.length - 1) {
    if (
      !approvalList[indexUser + 1].isApprove &&
      !approvalList[indexUser + 1].isreject &&
      !(!approvalList[indexUser].isApprove && !approvalList[indexUser].isreject)
    )
      return true;
  } else {
    return true;
  }
  return false;
};
