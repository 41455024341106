import {
  PaperClipOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  FileSearchOutlined,
  TrademarkCircleOutlined,
} from "@ant-design/icons";
import { Tooltip, Space, Typography, Row, Button, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
// import moment from "moment";
import Highlighter from "react-highlight-words";
import { Exclamation } from "../../components/CustomIcon";
import UserStatusRow from "../../components/UserStatusRow";
import formatData from "../../libs/formatData";
import { PRMemoResponseLists } from "../../interfaces/prmemo.interface";
import { getPaymentListMemoPreview } from "../../api/prmemo";

interface ColumnsProps {
  searchText: string;
  setVisibleDrawer: (value: boolean) => void;
  setVisibleSingleModal: (value: boolean) => void;
  setSelectRequestListPayment: (record: PRMemoResponseLists) => void;
  setLoading: (value: boolean) => void;
}

export const renderPRAdminHistoryListColumns = ({
  searchText,
  setVisibleDrawer,
  setVisibleSingleModal,
  setSelectRequestListPayment,
  setLoading,
}: ColumnsProps): ColumnsType<PRMemoResponseLists> => {
  return [
    {
      title: "Status",
      width: 60,
      render: (record) => (
        <div style={{ display: "flex" }}>
          {record.isUrgent && (
            <Tooltip title="Urgent">
              <Exclamation />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Request No",
      dataIndex: "payNumber",
      width: 110,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Memo No.",
      dataIndex: "memoNo",
      width: 100,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Header Note",
      dataIndex: "prHeaderNote",
      width: 200,
      render: (value: string, record) => (
        <>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchText]}
            // autoEscape={true}
            textToHighlight={value}
            className="new-line"
          />
          {record.isNonPo || (
            <Tag
              color="geekblue"
              style={{ marginLeft: "8px", borderRadius: "8px" }}
            >
              PO <CheckOutlined />
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 90,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "purchasingGroup",
      title: "Purchasing Group",
      dataIndex: "purGroup",
      width: 130,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      key: "amount",
      title: "Amount",
      width: 110,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {`
            ${formatData.toCurrency(record.amount)} ${record.currency}
            `}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "item",
      title: "Items",
      width: 60,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {record.paymentInfoItem?.length || 0}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "files",
      title: "Attachment",
      width: 85,
      render: (record) => (
        <Space size="small">
          <PaperClipOutlined rotate={135} />
          <Typography.Text>
            {record.paymentInfoAttachHistory?.length || 0}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: "Approval Status",
      dataIndex: "accountText",
      width: 230,
      render: (value: string, row) => {
        return (
          <div style={{ zIndex: -99, overflowX: "scroll" }}>
            <UserStatusRow
              approvers={row.approvers}
              userInfo={row.sendApproveBy}
              isBanking={false}
            />
          </div>
        );
      },
    },
    {
      key: "action",
      title: "Detail/Cancel",
      dataIndex: "amount",
      fixed: "right",
      width: 120,
      render: (value: number, record) => {
        return (
          <Row>
            <Tooltip placement="top" title="Detail">
              <Button
                type="text"
                icon={<InfoCircleOutlined />}
                onClick={() => {
                  setVisibleDrawer(true);
                  setSelectRequestListPayment(record);
                }}
              />
            </Tooltip>
            {record.approvers &&
              !record.approvers[1]?.isApprove &&
              !record.approvers[1]?.isreject && (
                <Tooltip placement="top" title="Recall">
                  <Button
                    type="text"
                    icon={<TrademarkCircleOutlined />}
                    onClick={() => {
                      setSelectRequestListPayment(record);
                      setVisibleSingleModal(true);
                    }}
                  />
                </Tooltip>
              )}
            <Tooltip placement="top" title="Preview Document">
              <Button
                type="text"
                icon={<FileSearchOutlined />}
                onClick={() => {
                  setLoading(true);
                  setSelectRequestListPayment(record);
                  getPaymentListMemoPreview({
                    payId: record.payId,
                    sendApproveBy: "",
                    documentType: "PRWM",
                  })
                    .then((result) => {
                      const file = new Blob([result], {
                        type: "application/pdf",
                      });
                      const fileURL = URL.createObjectURL(file);
                      window.open(fileURL);
                    })
                    .finally(() => setLoading(false));
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];
};
