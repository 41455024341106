import {
  PaperClipOutlined,
  InfoCircleOutlined,
  PrinterOutlined,
  TrademarkCircleOutlined,
  CheckOutlined,
  FileSearchOutlined,
  FileAddOutlined,
  FilePptOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Tooltip, Space, Typography, Row, Button, Tag, Badge } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import Highlighter from "react-highlight-words";
import { Exclamation } from "../../components/CustomIcon";
import UserStatusRow from "../../components/UserStatusRow";
import formatData from "../../libs/formatData";
import {
  PRMemoCheckAmount,
  PRMemoResponseLists,
} from "../../interfaces/prmemo.interface";
import {
  checkAmountMemo,
  getPaymentListMemoPreview,
  getPaymentListMemoPrint,
} from "../../api/prmemo";

interface ColumnsProps {
  searchText: string;
  userId: string;
  setVisibleDrawer: (value: boolean) => void;
  setVisiblePVModal: (value: boolean) => void;
  setVisibleSingleModal: (value: boolean) => void;
  setVisibleCreateIMModal: (value: boolean) => void;
  setSelectCheckAmountMemo: (record: PRMemoCheckAmount) => void;
  setSelectRequestListPayment: (record: PRMemoResponseLists) => void;
  setLoading: (value: boolean) => void;
}

export const renderPRRequestListHistoryColumns = ({
  searchText,
  userId,
  setVisibleDrawer,
  setVisiblePVModal,
  setVisibleSingleModal,
  setVisibleCreateIMModal,
  setSelectCheckAmountMemo,
  setSelectRequestListPayment,
  setLoading,
}: ColumnsProps): ColumnsType<PRMemoResponseLists> => {
  return [
    {
      title: "Status",
      width: 60,
      render: (record) => (
        <div style={{ display: "flex" }}>
          {record.isUrgent && (
            <Tooltip title="Urgent">
              <Exclamation />
            </Tooltip>
          )}
          {/* {moment().format("YYYY-MM-DD") > record.dueDate && (
            <Tooltip title="Overdue">
              <Thunder style={{ marginRight: "5px" }} />
            </Tooltip>
          )} */}
        </div>
      ),
    },
    {
      title: "Request No./PR Number",
      dataIndex: "payNumber",
      width: 110,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Memo No.",
      dataIndex: "memoNo",
      width: 100,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Header Note",
      dataIndex: "prHeaderNote",
      width: 150,
      render: (value: string, record) => (
        <>
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchText]}
            // autoEscape={true}
            textToHighlight={value}
            className="new-line"
          />
          {record.isNonPo || (
            <Tag
              color="geekblue"
              style={{ marginLeft: "8px", borderRadius: "8px" }}
            >
              PO <CheckOutlined />
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 90,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    {
      title: "Purchasing Group",
      dataIndex: "purGroup",
      width: 110,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={value}
        />
      ),
    },
    // {
    //   key: "amount",
    //   title: "Amount",
    //   width: 110,
    //   render: (record) => (
    //     <Space size="small">
    //       <Typography.Text>
    //         {`
    //         ${formatData.toCurrency(record.amount)} ${record.currency}
    //         `}
    //       </Typography.Text>
    //     </Space>
    //   ),
    // },
    {
      key: "item",
      title: "Items",
      width: 55,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {record.paymentInfoItem?.length || 0}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "amount",
      title: "Amount",
      width: 100,
      render: (record) => (
        <Space size="small">
          <Typography.Text>
            {`
            ${formatData.toCurrency(record.amount)} ${record.currency}
            `}
          </Typography.Text>
        </Space>
      ),
    },
    {
      key: "files",
      title: "Attachment",
      width: 85,
      render: (record) => (
        <Space size="small">
          <PaperClipOutlined rotate={135} />
          <Typography.Text>
            {record.fileSapnum + record.fileApprNum}
          </Typography.Text>
        </Space>
      ),
    },
    {
      title: "Approval Status",
      dataIndex: "accountText",
      width: 200,
      render: (value: string, row) => {
        return (
          <div style={{ zIndex: -99, overflowX: "scroll" }}>
            <UserStatusRow
              approvers={row.approvers}
              userInfo={row.sendApproveBy}
              isBanking={false}
            />
          </div>
        );
      },
    },
    {
      title: "Release Date,Time",
      dataIndex: "sendApproveDate",
      width: 90,
      render: (value: string) => (
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchText]}
          // autoEscape={true}
          textToHighlight={dayjs(value).format("DD.MM.YYYY, HH:mm")}
        />
      ),
      // sorter: (a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix(),
    },
    {
      key: "action",
      title: "Detail/Recall",
      fixed: "right",
      width: 120,
      render: (record: PRMemoResponseLists) => (
        <Row>
          <Tooltip placement="top" title="Detail">
            <Button
              type="text"
              icon={<InfoCircleOutlined />}
              onClick={() => {
                setVisibleDrawer(true);
                setSelectRequestListPayment(record);
              }}
            />
          </Tooltip>
          {record.approvers &&
            !record.approvers[0]?.isApprove &&
            !record.approvers[0]?.isreject && (
              <Tooltip placement="top" title="Recall">
                <Button
                  type="text"
                  icon={<TrademarkCircleOutlined />}
                  onClick={() => {
                    setSelectRequestListPayment(record);
                    setVisibleSingleModal(true);
                  }}
                />
              </Tooltip>
            )}
          <Tooltip placement="top" title="Preview Document">
            <Button
              // disabled={record.isDelete}
              type="text"
              icon={<FileSearchOutlined />}
              onClick={() => {
                setLoading(true);
                setSelectRequestListPayment(record);
                getPaymentListMemoPreview({
                  payId: record.payId,
                  sendApproveBy: "",
                  documentType: "PRWM",
                })
                  .then((result) => {
                    const file = new Blob([result], {
                      type: "application/pdf",
                    });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                  })
                  // .catch((error) => console.log("can't open file:", error))
                  .finally(() => setLoading(false));
              }}
            />
          </Tooltip>
          {record.activityState === "approved" && (
            <Badge size="small" count={record.printCount} offset={[-10, 5]}>
              <Tooltip placement="top" title="Print">
                <Button
                  type="text"
                  icon={<PrinterOutlined style={{ fontSize: "x-large" }} />}
                  onClick={() => {
                    setLoading(true);
                    setSelectRequestListPayment(record);
                    getPaymentListMemoPrint({
                      payId: record.payId,
                      sendApproveBy: "",
                      documentType: "PRWM",
                    })
                      .then((result) => {
                        const file = new Blob([result], {
                          type: "application/pdf",
                        });
                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(file);
                        link.download = `${record.purGroup}_${record.payNumber}`;
                        link.click();
                        const fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                        setLoading(false);
                        window.location.reload();
                      })
                      .finally(() => setLoading(false));
                  }}
                />
              </Tooltip>
            </Badge>
          )}
          {/* Send to Payment Button for CreateBy */}
          {record.isNonPo &&
            // !record.isSendPayment &&
            record.activityState === "approved" &&
            record.printCount > 0 &&
            record.payBy.userId !== userId &&
            record.createBy.userId !== record.payBy.userId && (
              <Tooltip placement="top" title="Send To Payment">
                <Button
                  type="text"
                  disabled={record.isSendPayment}
                  icon={<FilePptOutlined />}
                  onClick={() => {
                    setSelectRequestListPayment(record);
                    setVisiblePVModal(true);
                  }}
                />
              </Tooltip>
            )}
          {/* Create Immediately Button for CreateBy */}
          {record.isNonPo &&
            record.activityState === "approved" &&
            record.printCount > 0 &&
            record.payBy.userId === record.createBy.userId && (
              <Tooltip placement="top" title="Create Immediately">
                <Button
                  type="text"
                  // disabled
                  icon={
                    <FileAddOutlined
                      style={{
                        color:
                          // eslint-disable-next-line no-nested-ternary
                          record.amount === record.usedAmount
                            ? "#60D160"
                            : record.usedAmount !== 0
                            ? "#FAAD14"
                            : "#000000",
                      }}
                    />
                  }
                  onClick={() => {
                    checkAmountMemo({
                      payId: record.payId,
                      apprType: "PR",
                    }).then((res) => {
                      setSelectCheckAmountMemo(res.responseData[0]);
                      setVisibleCreateIMModal(true);
                    });
                  }}
                />
              </Tooltip>
            )}
          {/* Create Immediately Button for PayBy */}
          {record.isNonPo &&
            record.activityState === "approved" &&
            record.printCount > 0 &&
            record.payBy.userId === userId &&
            (record.payBy.userId !== record.createBy.userId ||
              record.isSendPayment) && (
              <Tooltip placement="top" title="Create Immediately">
                <Button
                  type="text"
                  icon={
                    <FileAddOutlined
                      style={{
                        color:
                          record.amount === record.usedAmount
                            ? "#1E7126"
                            : "#FAAD14",
                      }}
                    />
                  }
                  onClick={() => {
                    checkAmountMemo({
                      payId: record.payId,
                      apprType: "PR",
                    }).then((res) => {
                      setSelectCheckAmountMemo(res.responseData[0]);
                      setVisibleCreateIMModal(true);
                    });
                  }}
                />
              </Tooltip>
            )}
        </Row>
      ),
    },
  ];
};
