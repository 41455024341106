interface Props {
  color?: string;
}

const Component = ({ color }: Props): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_51110_12258)">
        <rect width="16" height="16" rx="8" fill="white" />
        <path
          d="M4.00476 13L14 8L4 3V7.11111L10 8L4 8.88889L4.00476 13Z"
          fill={color || "#744C3A"}
        />
      </g>
      <defs>
        <clipPath id="clip0_51110_12258">
          <rect width="16" height="16" rx="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Component;

Component.defaultProps = {
  color: undefined,
};
