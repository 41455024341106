/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Typography,
  Form,
  Spin,
  Row,
  Checkbox,
  Col,
  Avatar,
  Select,
  SelectProps,
  Input,
  Modal,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import TitleHeader from "../../components/TitleHeader";
import FilterInputTable from "../../components/FilterInputTable";
import { ReactComponent as CompanyLogo } from "../../assets/icon/company.svg";
import { Company } from "../../interfaces/master.interface";
import { getMasterCompanyBanking } from "../../api/master";
import CustomModal from "../../components/CustomModal";
import useAuthentication from "../../hooks/useAuthentication";
import DataTable from "../../components/DataTable";
import { renderBankingListColumns } from "./columns";
import { getQueryObject } from "../../libs/query";
import useParseQuery from "../../hooks/useParseQuery";
import useOptions from "../../hooks/useOptions";
import { paymentType } from "../../libs/antd/options/optionsValue";
import {
  getBankingApproverList,
  getBankingList,
  sendApproveBanking,
  syncsapBanking,
  previeweMail,
} from "../../api/banking";
import { BankingList } from "../../interfaces/banking.interface";
import { PayloadBankingList } from "../../interfaces/paymentList.interface";
import { User } from "../../interfaces/user.interface";
import setting from "../../setting";
import { EmailInfo } from "../../enum/bankingEmailInfo.enum";

const { TextArea } = Input;

dayjs.extend(isBetween);

const Component = (): JSX.Element => {
  const auth = useAuthentication();
  const {
    queryPaymentType,
    querycompid,
    querydate,
    querydatetype,
    querysearch,
    replaceLocation,
  } = useParseQuery();
  const [initial, setInital] = useState(true);
  const [dataSource, setDataSource] = useState<BankingList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [variablePage, setVariablePage] = useState<{
    page: number;
    perPage: number;
  }>({ page: 1, perPage: 10 });
  const [visibleSingleModal, setVisibleSingleModal] = useState<boolean>(false);
  const [visibleMutiModal, setVisibleMutiModal] = useState<boolean>(false);
  const [selectBankingListPayment, setSelectBankingListPayment] =
    useState<BankingList>({} as BankingList);
  const [dataSourceCompany, setDataSourceCompany] = useState<Company[]>([]);
  const [dataSourceApprover, setDataSourceApprover] = useState<User[]>([]);

  const [timeUpdate, setTimeUpdate] = useState<string>();
  const [searchText, setSearchText] = useState("");
  const [filterInput, setFilterInput] = useState<any | undefined>();
  const [onTableSelect, setOnTableSelect] = useState<React.Key[]>([]);
  const [sendTo, setSendTo] = useState<string[]>([]);
  const [emailInfo, setEmailInfo] = useState<string>("");
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [current, setCurrent] = useState<number>(0);
  const [previewEmail, setPreviewEmail] = useState<string>("");

  const next = useCallback(
    (context: string) => {
      setPreviewEmail(context);
      setCurrent(current + 1);
    },
    [current, setPreviewEmail, setCurrent]
  );

  const back = useCallback(() => {
    setPreviewEmail("");
    form.setFieldsValue({ releaseDesc: "" });
    setCurrent(current - 1);
  }, [current, setPreviewEmail, setCurrent, form]);

  const { company } = useOptions({
    dataSourceCompany,
  });

  const isCEO = useMemo(() => {
    return location.pathname.includes("ceo");
  }, [location]);

  const columns = renderBankingListColumns({
    searchText,
    onClickEdit: (record: BankingList) =>
      isCEO
        ? history.push(`/banking/ceo/detail/${record.payId}`)
        : history.push(`/banking/detail/${record.payId}`),
    setVisibleSingleModal,
    setSelectBankingListPayment,
    isCEO,
  });

  const payload: PayloadBankingList = useMemo(() => {
    return {
      compId: null,
      dueDate: {
        dateFrom: null,
        dateTo: null,
      },
      // entryDate: {
      //   dateFrom: null,
      //   dateTo: null,
      // },
      isPaymented: null,
      isReleased: isCEO ? true : null,
      paymentType: null,
      status: null,
      search: null,
      page: 1,
      perPage: 10,
    };
  }, [isCEO]);

  const pagination = useMemo(() => {
    const q = getQueryObject(false, location.search);
    return {
      page: q.page ? Number(q.page) : 1,
      perPage: q.perPage ? Number(q.perPage) : 10,
    };
  }, [location.search]);

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([
        getMasterCompanyBanking(auth.userId),
        getBankingApproverList(),
      ])
        .then(([responseCompany, responseApprover]) => {
          setDataSourceCompany(responseCompany.responseData || []);
          setDataSourceApprover(responseApprover.responseData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth]);

  useEffect(() => {
    if (auth) {
      setLoading(true);
      Promise.all([
        getBankingList({ ...payload, ...pagination }),
        getMasterCompanyBanking(auth.userId),
        getBankingApproverList(),
      ])
        .then(([responsePaymentList, responseCompany, responseApprover]) => {
          setDataSource(responsePaymentList.responseData);
          setDataSourceCompany(responseCompany.responseData || []);
          setTimeUpdate(moment().format());
          setDataSourceApprover(responseApprover.responseData);
          setTotalItem(responsePaymentList.total || 0);
          setVariablePage((prev) => ({ ...prev, ...pagination }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, payload]);

  useEffect(() => {
    const queryToObject = getQueryObject(filterInput, location.search);

    const paginationinUseEffect = variablePage;

    if (initial) {
      paginationinUseEffect.page = Number(queryToObject?.page || 1);
      paginationinUseEffect.perPage = Number(queryToObject?.perPage || 10);
    }

    if (
      auth &&
      (searchText.length === 0 ||
        searchText.length >= parseInt(setting.SEARCH_TEXT_LENGTH, 10))
    ) {
      setLoading(true);

      const filterValidated = {
        ...queryToObject,
        compId: Number(queryToObject?.compId),
      };

      Promise.all([
        getBankingList({
          ...payload,
          ...filterValidated,
          ...paginationinUseEffect,
        }),
      ])
        .then(([responsePaymentList]) => {
          setDataSource(responsePaymentList.responseData);
          setTotalItem(responsePaymentList.total || 0);
          setTimeUpdate(moment().format());
        })
        .finally(() => {
          replaceLocation({
            ...filterInput,
            ...pagination,
          });
          setInital(false);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, variablePage]);

  useEffect(() => {
    if (!initial) {
      replaceLocation({
        ...filterInput,
        page: 1,
        perPage: variablePage.perPage,
      });

      setVariablePage({
        page: 1,
        perPage: variablePage.perPage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInput]);

  const onPrevieweMail = useCallback(
    async (payIds: number[]) => {
      previeweMail({
        payId: payIds,
        sendBy: parseInt(auth?.userId || "0", 10),
        sendTo,
        emailInfo,
        releaseDesc: form.getFieldValue("releaseDesc").toString(),
      }).then((result) => {
        next(result.responseData[0]);
      });
    },
    [auth?.userId, sendTo, emailInfo, form, next]
  );

  const onPaymentSendApprove = useCallback(
    async (payIds: number[]) => {
      await Promise.all([
        sendApproveBanking({
          payId: payIds,
          sendBy: parseInt(auth?.userId || "0", 10),
          sendTo,
          emailInfo,
        }),
      ]).then(() => {
        setDataSource(
          dataSource.filter((item) => !payIds.includes(item.payId))
        );
        Modal.success({
          content: `Release เอกสารให้ Approver สำเร็จ`,
        });
      });
    },
    [auth?.userId, dataSource, sendTo, emailInfo]
  );

  const onSyncDataSource = useCallback(() => {
    if (auth) {
      setLoading(true);
      syncsapBanking()
        .then(() => {
          Promise.all([getBankingList(payload)]).then(
            ([responsePaymentList]) => {
              setDataSource(responsePaymentList.responseData);
              setTimeUpdate(moment().format());
              setTotalItem(responsePaymentList.total || 0);
            }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [auth, payload]);

  useEffect(() => {
    if (visibleMutiModal || visibleSingleModal) {
      const userIds = dataSourceApprover?.map((item) => item.userId);
      setSendTo(userIds);
    } else {
      setSendTo([]);
    }
  }, [visibleMutiModal, visibleSingleModal, dataSourceApprover]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const options: SelectProps["options"] = [];
  options.push({ value: EmailInfo.ANUCHAN, label: EmailInfo.ANUCHAN });
  options.push({ value: EmailInfo.NONGNUCH, label: EmailInfo.NONGNUCH });

  const contentReleaseModal = useMemo(() => {
    const toggleChecked = (e: CheckboxChangeEvent) => {
      const cerrent = e.target.value;
      const found = sendTo.find((value: string) => value === cerrent);
      let values: string[] = [...sendTo];
      if (found) {
        values = values.filter((value) => value !== cerrent);
      } else {
        values.push(cerrent);
      }
      setSendTo(values);
    };
    if (current === 1) {
      return (
        <div
          className="new-line"
          style={{ overflow: "auto", height: "60vh" }}
          dangerouslySetInnerHTML={{ __html: previewEmail }}
        />
      );
    }

    return (
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        {dataSourceApprover.map((item) => (
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingBottom: "8px" }}
            key={item.userId}
          >
            <Col>
              <Row align="middle">
                <Col style={{ paddingRight: "16px" }}>
                  <Avatar size="large" />
                </Col>
                <Col style={{ display: "flex", flexDirection: "column" }}>
                  <Typography.Text
                    strong
                  >{`${item.fristName} ${item.lastName}`}</Typography.Text>
                  <Typography.Text type="secondary">
                    {item.email}
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
            <Col>
              <Checkbox
                onChange={toggleChecked}
                checked={!!sendTo.find((value) => value === item.userId)}
                value={item.userId}
              />
            </Col>
          </Row>
        ))}
        <Select
          mode="tags"
          placeholder="E-mail Info"
          onChange={(val) => setEmailInfo(val?.join(";") || "")}
          className="FieldInputStyle"
          options={options}
        />
        <Form form={form}>
          <Form.Item name="releaseDesc">
            <TextArea
              showCount
              maxLength={200}
              allowClear
              rows={3}
              placeholder="Release Description"
            />
          </Form.Item>
        </Form>
      </Spin>
    );
  }, [
    dataSourceApprover,
    loading,
    sendTo,
    options,
    form,
    current,
    previewEmail,
  ]);

  const footerSingleModal = useMemo(() => {
    const onCancel = () => {
      setVisibleSingleModal(false);
      setPreviewEmail("");
      form.setFieldsValue({ releaseDesc: "" });
      setCurrent(0);
    };
    return (
      <Row justify="end">
        <Button loading={loading} onClick={onCancel}>
          Cancel
        </Button>
        {current === 1 && (
          <Button loading={loading} onClick={back}>
            Back
          </Button>
        )}
        <Button
          type="primary"
          loading={loading}
          disabled={sendTo.length <= 0}
          onClick={() => {
            setLoading(true);
            if (current !== 1) {
              onPrevieweMail([selectBankingListPayment.payId]).then(() =>
                setLoading(false)
              );
            } else {
              Promise.all([
                onPaymentSendApprove([selectBankingListPayment.payId]),
              ])
                .catch((error) => {
                  // eslint-disable-next-line no-console
                  console.log(error);
                })
                .finally(() => {
                  setLoading(false);
                  setVisibleSingleModal(false);
                });
            }
          }}
        >
          {current === 1 ? `Release to ${sendTo.length} people` : "Preview"}
        </Button>
      </Row>
    );
  }, [
    loading,
    sendTo,
    selectBankingListPayment,
    onPaymentSendApprove,
    current,
    back,
    onPrevieweMail,
    setVisibleSingleModal,
    setPreviewEmail,
    form,
  ]);

  const footerMutiModal = useMemo(() => {
    const onCancel = () => {
      setVisibleMutiModal(false);
      setPreviewEmail("");
      form.setFieldsValue({ releaseDesc: "" });
      setCurrent(0);
    };
    return (
      <Row justify="end">
        <Button loading={loading} onClick={onCancel}>
          Cancel
        </Button>
        {current === 1 && (
          <Button loading={loading} onClick={back}>
            Back
          </Button>
        )}
        <Button
          type="primary"
          loading={loading}
          disabled={sendTo.length <= 0}
          onClick={() => {
            setLoading(true);
            if (current !== 1) {
              onPrevieweMail(onTableSelect.map((item) => Number(item))).then(
                () => setLoading(false)
              );
            } else {
              onPaymentSendApprove(
                onTableSelect.map((item) => Number(item))
              )?.finally(() => {
                setLoading(false);
                setVisibleMutiModal(false);
              });
            }
          }}
        >
          {current === 1 ? `Release to ${sendTo.length} people` : "Preview"}
        </Button>
      </Row>
    );
  }, [
    loading,
    sendTo,
    onPaymentSendApprove,
    onTableSelect,
    onPrevieweMail,
    current,
    form,
    back,
  ]);

  return (
    <div style={{ padding: 20 }}>
      <TitleHeader
        title={isCEO ? "Inbox" : "In-Progress"}
        loading={loading}
        dataSizes={dataSource.length}
        timeUpdate={timeUpdate}
        onSyncDataSource={onSyncDataSource}
      />
      <FilterInputTable
        isBanking
        setFilterInput={setFilterInput}
        querysearch={querysearch}
        queryDateType={querydatetype}
        queryDate={querydate}
        onTableSelect={onTableSelect}
        selectItems={[
          {
            icon: CompanyLogo,
            name: "paymentType",
            placeholder: "Payment Type",
            dataItems: paymentType,
            itemIndex: queryPaymentType,
            span: 3,
          },
          {
            icon: CompanyLogo,
            name: "compId",
            placeholder: "Company",
            dataItems: company,
            itemIndex: querycompid,
            span: 3,
          },
        ]}
        searchText={searchText}
        setMultiConfirm={setVisibleMutiModal}
        setSearchText={setSearchText}
        setModalVisible={setVisibleSingleModal}
      />
      <DataTable
        data={dataSource}
        checkBox
        loading={loading}
        columns={columns}
        totalItem={totalItem}
        searchText={searchText}
        setSearchText={setSearchText}
        variablePage={variablePage}
        setVariablePage={setVariablePage}
        setOnTableSelect={setOnTableSelect}
      />
      {visibleSingleModal ? (
        <CustomModal
          key="SingleModal"
          visible={visibleSingleModal}
          setVisible={setVisibleSingleModal}
          title="Release to CEO"
          content={contentReleaseModal}
          footer={footerSingleModal}
          width={1000}
        />
      ) : null}
      {visibleMutiModal ? (
        <CustomModal
          key="MutipleModal"
          visible={visibleMutiModal}
          setVisible={setVisibleMutiModal}
          title="Release to CEO"
          content={contentReleaseModal}
          footer={footerMutiModal}
          width={1000}
        />
      ) : null}
    </div>
  );
};

export default Component;
